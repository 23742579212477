import React from 'react'
import styled from 'styled-components/macro'

import { Text, Spacing, SpacingItem } from '../../../../ui'
import { TabledDataSummary } from '../'

const RowWrap = styled.div`
  flex: 1 0 50%;
  max-width: 50%;
  margin-bottom: ${({ theme }) => theme.padding.xs};
`

export const AdSummary = ({ ad, name, rowWrap = false }) => (
  <Spacing size="s">
    <Text h6>Ad information</Text>
    <>
      <SpacingItem as={rowWrap && RowWrap}>
        <TabledDataSummary
          label="- Public headline: "
          value={name}
        ></TabledDataSummary>
      </SpacingItem>
      <SpacingItem as={rowWrap && RowWrap}>
        <TabledDataSummary
          label="- Link:"
          value={ad.hostUrl}
        ></TabledDataSummary>
      </SpacingItem>
      <SpacingItem as={rowWrap && RowWrap}>
        <TabledDataSummary
          label="- Budget:"
          value={ad.budget + ' USD'}
        ></TabledDataSummary>
      </SpacingItem>
      {ad.imageUrl && (
        <SpacingItem as={rowWrap && RowWrap}>
          <Spacing direction="row" key={'image'}>
            <SpacingItem flex="0 1 40%">
              <Text t3>- Image: </Text>
            </SpacingItem>
            <SpacingItem flex="0 1 60%">
              <img src={ad.imageUrl} width={70} height={70} alt={ad.hostUrl} />
            </SpacingItem>
          </Spacing>
        </SpacingItem>
      )}
    </>
  </Spacing>
)
