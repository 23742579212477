import React from 'react'

import {
  Text,
  Box,
  Spacing,
  SpacingItem,
  GradientWrapper,
  Graphic
} from '../../../../ui'
import { QuestionsSummary } from '../QuestionsSummary'
import { anyAudienceTagFilled } from '../../../../../util/validators'
import { AudienceBucketSummary } from '../AudienceBucketsSummary/components'
import { isEmpty } from 'ramda'
import { AdPreview } from '../AdPreview'

const PREVIEW_SECTIONS = [
  {
    key: 'Audience',
    title: 'How was I privately matched?',
    iconName: 'InsightIcon'
  },
  {
    key: 'Survey',
    title: 'This study includes a survey',
    iconName: 'SurveyIcon'
  },
  {
    key: 'Data',
    title: 'Data to be analyzed',
    iconName: 'DataIcon'
  }
]

export const Preview = ({ opportunity }) => (
  <Spacing size="xl">
    {opportunity.category === 'Study' ? (
      <StudyPreviewCard opportunity={opportunity} />
    ) : (
      <AdPreview opportunity={opportunity} />
    )}
    <StudyPreviewDetails opportunity={opportunity} />
  </Spacing>
)

const StudyPreviewCard = ({ opportunity }) => (
  <Box as={GradientWrapper} size="l" gradient="gradient3">
    <Spacing size="l" direction="column">
      <Text h2 color="white">
        {opportunity.name}
      </Text>
      <Text t3 color="white">
        {opportunity.shortDescription}
      </Text>
      <Spacing direction="row" justify="space-between">
        <Spacing size="s" direction="row">
          <Graphic name="Dollar" size={20} color="white" />
          <Text t2 color="white">
            {opportunity.pricePerPerson}
          </Text>
        </Spacing>
        <Text t3 color="white">
          {opportunity.reach.maxPermitted} spots left
        </Text>
      </Spacing>
    </Spacing>
  </Box>
)

const StudyPreviewDetails = ({ opportunity }) => {
  const getSectionData = section => {
    switch (section.key) {
      case 'Audience': {
        return {
          data: opportunity.audienceRequirements,
          renderSection: audienceRequirements => (
            <PreviewDetail {...section}>
              {anyAudienceTagFilled(audienceRequirements) && (
                <AudienceBucketSummary
                  audienceRequirements={audienceRequirements}
                />
              )}
            </PreviewDetail>
          )
        }
      }
      case 'Survey': {
        return {
          data: !isEmpty(opportunity.survey) ? opportunity.survey : null,
          renderSection: survey => (
            <PreviewDetail {...section}>
              <Text t3 color="main">
                {survey.totalQuestions} questions to complete
              </Text>
            </PreviewDetail>
          )
        }
      }
      case 'Data': {
        return {
          data: !isEmpty(opportunity.inputDataSources)
            ? opportunity.inputDataSources
            : null,
          renderSection: inputDataSources => (
            <PreviewDetail {...section}>
              <QuestionsSummary inputDataSources={inputDataSources} />
            </PreviewDetail>
          )
        }
      }

      default:
        return null
    }
  }

  return (
    <Spacing size="s">
      {PREVIEW_SECTIONS.map(getSectionData)
        .filter(({ data }) => data)
        .map(({ data, renderSection }, n) => (
          <Box background="white" key={n}>
            <Spacing size="s">{renderSection(data)}</Spacing>
          </Box>
        ))}
    </Spacing>
  )
}

const PreviewDetail = ({ children, iconName, title }) => (
  <Spacing direction="row">
    <Graphic name={iconName} size={40} color="white" />
    <SpacingItem flex={1}>
      <Spacing size="xs">
        <Text h5 color="dark">
          {title}
        </Text>
        {children}
      </Spacing>
    </SpacingItem>
  </Spacing>
)
