import React from 'react'
import { Field } from 'react-final-form'

import {
  Spacing,
  Button,
  SubmitError,
  Text,
  Graphic
} from '../../../../../components/ui'
import { RenderFromQuery } from '../../../../../components/util'
import { AudienceSchemaQuery } from '../../../../../state/queries'

import { anyAudienceTagFilled } from '../../../../../util/validators'
import { TagFiltersOverview } from '../../../../../components/blocks'
import { TagQuestionsOverview } from '../../../../../components/blocks/TagFiltersOverview/TagQuestionsOverview'

import { useModal } from '../../../../../hooks'

const AddTagsHeader = ({ handleTagEdit }) => (
  <Spacing direction="row" justify="space-between" align="center">
    <Spacing direction="row" align="center">
      <Graphic size={40} name="Star" themeColor="main" />
      <Text t1 color="dark">
        Target by traits
      </Text>
    </Spacing>
    <Button
      outline
      block
      disabled={!handleTagEdit}
      onClick={() => handleTagEdit && handleTagEdit()}
    >
      Select tags
    </Button>
  </Spacing>
)

export const AddTags = ({ submitError, input, onUpdate, ...props }) => {
  const { openModal } = useModal()
  const handleTagEdit = () => {
    openModal('DefineAudience', {
      tags: input.value,
      onSave: onUpdate
    })
  }

  const fieldName = `${input.name}.and`

  return (
    <RenderFromQuery
      query={AudienceSchemaQuery}
      renderLoading={() => <AddTagsHeader />}
      renderData={({ audienceSchema }) => {
        const empty = !anyAudienceTagFilled(input.value)

        return (
          <Spacing>
            {!empty && (
              <Spacing size="l">
                {submitError && <SubmitError submitError={submitError} />}
                <Field
                  name={fieldName}
                  component={TagFiltersOverview}
                  categories={audienceSchema}
                  renderFilterComponent={props => (
                    <TagQuestionsOverview {...props} />
                  )}
                />
              </Spacing>
            )}
            <AddTagsHeader handleTagEdit={handleTagEdit} />
          </Spacing>
        )
      }}
    />
  )
}
