import React, { useState } from 'react'
import { RenderFromQuery } from '../../../components/util'
import { OpportunityResponseInsightResultsQuery } from '../../../state/queries/OpportunityResponsesQueries'
import { Text, Table, Spacing } from '../../../components/ui'
import styled from 'styled-components/macro'
import { parse, format } from 'date-fns'
import { equals } from 'ramda'

const ROWS_PER_PAGE = 10

const TableWrap = styled.div`
  flex: 1;
  thead tr {
    background: ${({ theme }) => theme.gradient.gradient2};
    th {
      padding: ${({ theme }) => theme.padding.m};
    }
  }
  tbody tr {
    td {
      max-width: 130px;
    }
    &:hover {
      cursor: default;
      td div {
        color: ${({ theme }) => theme.color.dark};
      }
    }
  }
`

export const InsightsView = ({ shortId, insight }) => {
  const [page, setPage] = useState(1)

  const fetchMore = (opportunityResponseInsightResults, queryRenderParams) => {
    const name = 'opportunityResponseInsightResults'
    queryRenderParams.fetchMore({
      variables: {
        after: opportunityResponseInsightResults.edges[0].node.answers.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        prev[name].edges[0].node.answers = prev[
          name
        ].edges[0].node.answers.concat(
          fetchMoreResult[name].edges[0].node.answers
        )
        setPage(page + 1)
        return {
          [name]: {
            ...fetchMoreResult[name],
            edges: prev[name].edges
          }
        }
      }
    })
  }

  const getPreviousPage = () => {
    setPage(page - 1)
  }

  const getNextPage = (
    opportunityResponseInsightResults,
    queryRenderParams
  ) => {
    if (
      page * ROWS_PER_PAGE >=
      opportunityResponseInsightResults.edges[0].node.answers.length
    ) {
      fetchMore(opportunityResponseInsightResults, queryRenderParams)
    } else {
      setPage(page + 1)
    }
  }

  const parseDateAnswer = answer => {
    const date = /^[0-9]*$/.test(answer) ? parseInt(answer) : answer
    return format(parse(date), 'YYYY-MM-DD')
  }

  return (
    <React.Fragment>
      <RenderFromQuery
        query={OpportunityResponseInsightResultsQuery}
        variables={{
          shortId,
          dataSourceId: insight.dataSourceId,
          objectTypeId: insight.objectTypeId,
          after: null,
          first: ROWS_PER_PAGE
        }}
        renderData={(
          { opportunityResponseInsightResults },
          queryRenderParams
        ) => {
          const dateColumnIndex = opportunityResponseInsightResults.columns.findIndex(
            equals('Date')
          )
          return (
            <Spacing size="m">
              <Text h5>
                {opportunityResponseInsightResults.type.replace(/ /, ' > ')}
              </Text>
              <Spacing size="xxl">
                <TableWrap>
                  <Table
                    showPagination
                    page={page}
                    numberOfPages={Math.ceil(
                      opportunityResponseInsightResults.totalCount /
                        ROWS_PER_PAGE
                    )}
                    previousPage={getPreviousPage}
                    nextPage={() =>
                      getNextPage(
                        opportunityResponseInsightResults,
                        queryRenderParams
                      )
                    }
                    tableHead={opportunityResponseInsightResults.columns.map(
                      (column, n) => (
                        <th key={`${column}-${n}`}>
                          <Text h6 color="white">
                            {column}
                          </Text>
                        </th>
                      )
                    )}
                  >
                    {opportunityResponseInsightResults.edges[0].node.answers
                      .slice((page - 1) * ROWS_PER_PAGE, page * ROWS_PER_PAGE)
                      .map((answers, index) => (
                        <tr key={index}>
                          {opportunityResponseInsightResults.columns.map(
                            (column, index) => (
                              <td key={index}>
                                <Text t4>
                                  {dateColumnIndex === index
                                    ? parseDateAnswer(answers[index])
                                    : answers[index]}
                                </Text>
                              </td>
                            )
                          )}
                        </tr>
                      ))}
                  </Table>
                </TableWrap>
              </Spacing>
            </Spacing>
          )
        }}
      />
    </React.Fragment>
  )
}
