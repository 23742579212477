// Graphics
import { ReactComponent as Circles } from './circles.svg'
import { ReactComponent as ArrowUp } from './arrowUp.svg'
import { ReactComponent as ArrowDown } from './arrowDown.svg'
import { ReactComponent as Logo } from './logo.svg'
import { ReactComponent as Info } from './info.svg'
import { ReactComponent as General } from './general.svg'
import { ReactComponent as Social } from './social.svg'
import { ReactComponent as Calendar } from './calendar.svg'
import { ReactComponent as X } from './x.svg'
import { ReactComponent as Tick } from './tick.svg'
import { ReactComponent as Edit } from './edit.svg'
import { ReactComponent as Back } from './back.svg'
import { ReactComponent as Export } from './export.svg'
import { ReactComponent as Spinner } from './spinner.svg'
import { ReactComponent as Insight } from './insight.svg'
import { ReactComponent as Survey } from './survey.svg'
import { ReactComponent as Link } from './link.svg'
import { ReactComponent as Circle } from './circle.svg'
import { ReactComponent as Briefcase } from './briefcase.svg'
import { ReactComponent as Hourglass } from './hourglass.svg'
import { ReactComponent as One } from './one.svg'
import { ReactComponent as EyeCrossed } from './eyeCrossed.svg'
import { ReactComponent as Forbid } from './forbid.svg'
import { ReactComponent as Revert } from './revert.svg'
import { ReactComponent as Rect } from './rect.svg'
import { ReactComponent as Timer } from './timer.svg'
import { ReactComponent as Paper } from './paper.svg'
import { ReactComponent as People } from './people.svg'
import { ReactComponent as Friends } from './friends.svg'
import { ReactComponent as Users } from './users.svg'
import { ReactComponent as Coins } from './coins.svg'
import { ReactComponent as WorldMap } from './worldMap.svg'
import { ReactComponent as Skipped } from './skipped.svg'
import { ReactComponent as Filter } from './filter.svg'
import { ReactComponent as Loading } from './loading.svg'
import { ReactComponent as StudyType } from './studyType.svg'
import { ReactComponent as XInCircle } from './xInCircle.svg'
import { ReactComponent as Hamburger } from './hamburger.svg'
import { ReactComponent as Dollar } from './dollar.svg'
import { ReactComponent as DataIcon } from './data-icon.svg'
import { ReactComponent as SurveyIcon } from './survey-icon.svg'
import { ReactComponent as InsightIcon } from './insight-icon.svg'
import { ReactComponent as Add } from './add.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as Star } from './star.svg'
import { ReactComponent as UbdiLogo } from './ubdi_logo.svg'
import { ReactComponent as Eye } from './eye.svg'
import { ReactComponent as OneFinger } from './one-finger.svg'
import { ReactComponent as DataBank } from './dataBank.svg'

// Audience Icons
import { ReactComponent as Audience_family } from './audience_family.svg'
import { ReactComponent as Audience_fitness } from './audience_fitness.svg'
import { ReactComponent as Audience_general } from './audience_general.svg'
import { ReactComponent as Audience_leisure } from './audience_leisure.svg'
import { ReactComponent as Audience_nutrition } from './audience_nutrition.svg'
import { ReactComponent as Audience_ownership } from './audience_ownership.svg'
import { ReactComponent as Audience_worldview } from './audience_worldview.svg'
import { ReactComponent as Audience_custom } from './audience_custom.svg'
import { ReactComponent as Audience_filter } from './audience_filter.svg'

// Charts
import { ReactComponent as barChart } from './chart_bar.svg'
import { ReactComponent as pieChart } from './chart_pie.svg'
import { ReactComponent as comboChart } from './chart_combo.svg'
import { ReactComponent as lineChart } from './chart_line.svg'

// Sources
import { ReactComponent as SourceFacebook } from './source_facebook.svg'
import { ReactComponent as SourceFitbit } from './source_fitbit.svg'
import { ReactComponent as SourceGarmin } from './source_garmin.svg'
import { ReactComponent as SourceInstagram } from './source_instagram.svg'
import { ReactComponent as SourceFinance } from './source_finance.svg'
import { ReactComponent as SourceSpotify } from './source_spotify.svg'
import { ReactComponent as SourceTwitter } from './source_twitter.svg'
import { ReactComponent as SourcePinterest } from './source_pinterest.svg'
import { ReactComponent as SourceFlickr } from './source_flickr.svg'
import { ReactComponent as SourceYouTube } from './source_youtube.svg'
import { ReactComponent as SourceGoogleFit } from './source_googlefit.svg'
import { ReactComponent as SourceAppleHealth } from './source_applehealth.svg'
import { ReactComponent as SourceAmazon } from './source_amazon.svg'
import { ReactComponent as SourceBattlenet } from './source_battlenet.svg'
import { ReactComponent as SourceBinance } from './source_binance.svg'
import { ReactComponent as SourceCoinbase } from './source_coinbase.svg'
import { ReactComponent as SourceGemini } from './source_gemini.svg'
import { ReactComponent as SourceGoogle } from './source_google.svg'
import { ReactComponent as SourceLinkedin } from './source_linkedin.svg'
import { ReactComponent as SourceMetamask } from './source_metamask.svg'
import { ReactComponent as SourceNetflix } from './source_netflix.svg'
import { ReactComponent as SourceRobinhood } from './source_robinhood.svg'
import { ReactComponent as SourceSteam } from './source_steam.svg'
import { ReactComponent as SourceStrava } from './source_strava.svg'
import { ReactComponent as SourceTwitch } from './source_twitch.svg'
import { ReactComponent as SourceUphold } from './source_uphold.svg'

// Survey builder icons
import { ReactComponent as Dashes } from './dashes.svg'
import { ReactComponent as singleChoice } from './single_choice.svg'
import { ReactComponent as shortInput } from './short_answer.svg'
import { ReactComponent as longInput } from './long_answer.svg'
import { ReactComponent as multipleChoice } from './multiple_choice.svg'

import * as pngs from '../pngs'

export default {
  // svgs
  Circles,
  ArrowUp,
  ArrowDown,
  Logo,
  Info,
  General,
  Social,
  Calendar,
  X,
  Tick,
  Edit,
  Back,
  Export,
  Spinner,
  Insight,
  Survey,
  Link,
  Circle,
  Briefcase,
  Hourglass,
  One,
  EyeCrossed,
  Forbid,
  Revert,
  Rect,
  Timer,
  Paper,
  People,
  Friends,
  Users,
  Coins,
  WorldMap,
  Skipped,
  Filter,
  Loading,
  StudyType,
  XInCircle,
  Hamburger,
  Dollar,
  DataIcon,
  SurveyIcon,
  InsightIcon,
  Add,
  Search,
  Star,
  UbdiLogo,
  Eye,
  OneFinger,
  DataBank,

  // Charts
  barChart,
  comboChart,
  pieChart,
  lineChart,

  // Audience icons
  Audience_family,
  Audience_fitness,
  Audience_general,
  Audience_leisure,
  Audience_nutrition,
  Audience_ownership,
  Audience_worldview,
  Audience_custom,
  Audience_dataverified: Audience_filter,

  // sources
  SourceFacebook,
  SourceFitbit,
  SourceGarmin,
  SourceInstagram,
  SourceGlobalFinance: SourceFinance,
  SourceUSFinance: SourceFinance,
  SourceSpotify,
  SourceTwitter,
  SourcePinterest,
  SourceFlickr,
  SourceYouTube,
  SourceGoogleFit,
  SourceAppleHealth,
  SourceAmazon,
  SourceBattlenet,
  SourceBinance,
  SourceCoinbase,
  SourceGemini,
  SourceGoogle,
  SourceLinkedin,
  SourceMetamask,
  SourceNetflix,
  SourceRobinhood,
  SourceSteam,
  SourceStrava,
  SourceTwitch,
  SourceUphold,

  // Survey builder icons
  Dashes,
  singleChoice,
  shortInput,
  longInput,
  multipleChoice,
  // pngs
  ...pngs
}
