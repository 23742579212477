import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { Route, Switch } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { pipe, isEmpty, equals, mergeAll, keys, last, isNil } from 'ramda'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import {
  Spacing,
  Button,
  Text,
  Container,
  Notice,
  SubmitButton,
  SubmitError
} from '../../components/ui'
import { StudyDetails, StudyName } from '../../components/blocks'
import { withMutation } from '../../components/wrappers'
import { OpportunityDetailsQuery, CurrentUserQuery } from '../../state/queries'
import { RenderFromQuery } from '../../components/util'
import {
  validatorsBeforePublishing,
  validatorAllRequirementQueriesFilled,
  validatorInHouseSurveyFilled
} from '../../util/validators'
import { useSteps, useValidation, useModal } from '../../hooks'

// Child components
import {
  StudyAudience,
  StudyDataSources,
  StudySteps,
  StudyCreateAd,
  StoryScreenTemplates,
  StoryQueryBuilder
} from './components'

import { TotalSummary } from '../../components/blocks/StudyDetails/components'
import { SurveyBuilder } from './components/SurveyBuilder'

const Summary = styled.div`
  width: 30%;
  height: 100%;
  min-height: calc(100vh - 170px);
  position: sticky;
  top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.purpleL4};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  margin-left: ${({ theme }) => theme.padding.xl};
  padding: ${({ theme }) => theme.padding.xl};
`

const DurationPriceWrap = styled.div`
  margin-top: ${({ theme }) => theme.padding.xl};
  padding-top: ${({ theme }) => theme.padding.xl};
`

const getStepFromUrl = (pathFull, stepsTotal) => {
  const pathSplitted = pathFull.split('/')
  const hasStepInUrl = pathSplitted.length === 5

  const maybeStep = last(pathSplitted)
  const isValidStep = (step, stepsTotal) => {
    return step > 0 && step <= stepsTotal
  }

  if (hasStepInUrl && isValidStep(maybeStep, stepsTotal)) {
    const normalizedStep = maybeStep - 1
    return normalizedStep
  }

  if (!hasStepInUrl) return 0

  return null
}

const renderInvalidStep = () => {
  return <Route render={() => <div>Invalid step</div>} />
}

const StudyBuilder = ({
  data: { opportunity },
  history,
  updateOpportunity,
  location
}) => {
  if (!opportunity) return null

  const { openModal } = useModal()

  const categoryRoute = location.pathname.split('/')[1]
  const steps = StudySteps.getSteps(opportunity)

  const initialStep = getStepFromUrl(location.pathname, steps.length)
  if (isNil(initialStep)) return renderInvalidStep()

  const handleStepChange = currentStep => {
    history.push(
      `/${categoryRoute}/builder/${opportunity.id}/${currentStep + 1}`
    )
  }

  useEffect(() => {
    const step = getStepFromUrl(location.pathname, steps.length)
    if (step !== currentStep) goToStep({ shouldHandleStepChange: false })(step)
  }, [location.pathname])

  const { currentStep, nextStep, goToStep } = useSteps({
    initialStep,
    handleStepChange,
    steps,
    onCompleted: () => onContinueToPublish()
  })

  const CurrentStepComponent = steps[currentStep][1]

  const onSubmit = (values, form) => {
    // completed step
    return updateOpportunity({
      variables: {
        id: opportunity.id,
        dependsOn: values.dependsOn,
        ...parseOpportunityValues({ values, form, opportunity })
      }
    }).then(({ userErrors, errors }) => {
      if (!userErrors && !errors) {
        if (values.continue) nextStep()
      }

      return userErrors || errors
    })
  }

  const {
    validate: validatePublish,
    failedValidations: failedPublishValidations
  } = useValidation(validatorsBeforePublishing)

  const {
    validate: validateRequirementQueries,
    failedValidations: failedRequirementQueriesValidations
  } = useValidation(validatorAllRequirementQueriesFilled)

  const {
    validate: validateInHouseSurvey,
    failedValidations: failedInHouseSurveyValidations
  } = useValidation(validatorInHouseSurveyFilled)

  const failedValidations = mergeAll([
    failedPublishValidations,
    failedRequirementQueriesValidations,
    failedInHouseSurveyValidations
  ])

  const onContinueToPublish = ({ skipValidation = false } = {}) => {
    const validationIssues = validatePublish(opportunity)
    if (skipValidation || !validationIssues) {
      return history.push(`/${categoryRoute}/publish/${opportunity.id}`)
    }
  }

  const getDraftButtonState = ({ dirty, submitting }) => {
    if (submitting) {
      return { disabled: true, text: 'Saving...' }
    }

    if (dirty) {
      return { disabled: false, text: 'Save Draft' }
    }

    return { disabled: true, text: '✔️ Saved' }
  }

  return (
    <Form
      mutators={arrayMutators}
      initialValues={{
        ...opportunity,
        targetAudienceBuckets: StudyAudience.format(
          opportunity.targetAudienceBuckets
        ),
        inputDataSources: StudyDataSources.format(opportunity.inputDataSources),
        outputDataQueries: StoryQueryBuilder.format(
          opportunity.outputDataQueries
        ),
        requestedQueryDescription: opportunity.requestedQueryDescription,
        ad: StudyCreateAd.format(opportunity.ad),
        survey: SurveyBuilder.format(opportunity.survey)
      }}
      initialValuesEqual={equals}
      onSubmit={onSubmit}
      validate={CurrentStepComponent.validate}
    >
      {({ handleSubmit, form, values, invalid, submitError, ...formProps }) => {
        const onContinueSubmit = () => {
          form.change('continue', true)
          handleSubmit()
        }

        const onConfirmation = () => {
          return openModal('Confirmation', {
            title: 'Confirm audience',
            text:
              'Please confirm the audience to continue building the study. We need the confirmation to start collecting the data and check if there is enough people who match the requirements you set.',
            onConfirm: () => onContinueSubmit()
          })
        }

        const onContinue = () => {
          if (currentStep === 0) {
            // Audience Query requirements validation
            if (validateRequirementQueries(values)) {
              return
            }

            // Audience Step confirmation
            if (StudyAudience.isCompleted(opportunity)) {
              return onConfirmation()
            }
          }

          if (currentStep === 1) {
            // Survey validation
            if (validateInHouseSurvey(values)) {
              return
            }
          }

          onContinueSubmit()
        }

        const onDraftSave = () => {
          form.change('continue', false)
          handleSubmit()
        }

        const { disabled, text } = getDraftButtonState(form.getState())

        const renderSteps = () => {
          const stepProps = {
            opportunity,
            onChangeSave: onDraftSave,
            form,
            ...formProps,
            expanded: true
          }

          const stepsRoute = []

          //when no step is provided in url, CurrentStepComponent will be first one by default
          const defaultRoute = (
            <Route
              path={`/${categoryRoute}/builder/${opportunity.id}`}
              render={props => (
                <CurrentStepComponent {...props} {...stepProps} />
              )}
              key={0}
            />
          )

          stepsRoute.push(defaultRoute)

          steps.forEach(([, StepComponent], n) => {
            stepsRoute.push(
              <Route
                path={`/${categoryRoute}/builder/${opportunity.id}/${n + 1}`}
                render={props => <StepComponent {...props} {...stepProps} />}
                key={n}
              />
            )
          })

          return stepsRoute
        }

        return (
          <Container size="xxl">
            <Spacing size="xxl" stretched>
              <StudyName
                opportunity={opportunity}
                backRouteName={`/${categoryRoute}`}
              />
              <StudySteps
                opportunity={opportunity}
                currentStep={currentStep}
                goToStep={goToStep}
              />
              <Switch location={location}>
                {renderSteps()}
                {renderInvalidStep()}
              </Switch>
            </Spacing>
            <Summary>
              <StudyDetails opportunity={opportunity} sidebar />
              <DurationPriceWrap>
                <Spacing size="xl">
                  <TotalSummary opportunity={opportunity} />
                  <Spacing>
                    {opportunity.published && (
                      <Text t2 color="grey">
                        The study has been published and cannot be edited
                        anymore.
                      </Text>
                    )}
                    {submitError && <SubmitError submitError={submitError} />}
                    {!isEmpty(failedValidations) && (
                      <Notice error>
                        <Spacing>
                          {Object.keys(failedValidations).map(
                            failed => failedValidations[failed]
                          )}
                        </Spacing>
                      </Notice>
                    )}
                    <Spacing direction="row" size="l" stretchChildren>
                      <Button
                        outline
                        block
                        disabled={opportunity.published || disabled}
                        onClick={onDraftSave}
                      >
                        {text}
                      </Button>
                      <SubmitButton
                        onClick={onContinue}
                        {...formProps}
                        disabled={invalid || opportunity.published}
                        block
                      >
                        Continue
                      </SubmitButton>
                    </Spacing>

                    <RenderFromQuery
                      query={CurrentUserQuery}
                      renderData={({ currentUser: { currentRole } }) =>
                        currentRole === 'admin' && (
                          <Button
                            block
                            outline
                            onClick={() =>
                              onContinueToPublish({ skipValidation: true })
                            }
                          >
                            Publish without validation
                          </Button>
                        )
                      }
                    />
                  </Spacing>
                </Spacing>
              </DurationPriceWrap>
            </Summary>
          </Container>
        )
      }}
    </Form>
  )
}

const parseOpportunityValues = ({ values, form, opportunity }) => {
  const { dirtyFields } = form.getState()
  const dirtyRootFields = keys(dirtyFields).map(
    key => key.split('.')[0].split('[')[0]
  )

  const maybeSubmitRootField = (key, parser) => {
    const shouldSubmit = dirtyRootFields.includes(key)
      ? { [key]: parser(values[key], opportunity) }
      : {}

    return shouldSubmit
  }

  return mergeAll([
    maybeSubmitRootField('targetAudienceBuckets', StudyAudience.parse),
    maybeSubmitRootField('inputDataSources', StudyDataSources.parse),
    maybeSubmitRootField('ad', StudyCreateAd.parse),
    maybeSubmitRootField('name', name => name),
    maybeSubmitRootField('survey', SurveyBuilder.parse),
    maybeSubmitRootField('story', StoryScreenTemplates.parse),
    maybeSubmitRootField('outputDataQueries', StoryQueryBuilder.parse)
  ])
}

export const StudyBuilderScreen = pipe(
  withMutation('updateOpportunity'),
  graphql(OpportunityDetailsQuery, {
    options: props => ({
      variables: {
        id: props.match.params.opportunityId
      }
    })
  })
)(StudyBuilder)
