import React from 'react'

import { CategoriesChart } from './CategoriesChart'
import { StudySection } from '../../StudySection'

export const StudyInteractionOverview = ({ interactions }) => (
  <StudySection title="Demographics">
    <CategoriesChart tagCategories={interactions} />
  </StudySection>
)
