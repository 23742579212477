import React from 'react'
import styled, { css } from 'styled-components/macro'
import { withRouter } from 'react-router-dom'

import { Graphic } from '../../ui'

const Wrapper = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    border-radius: ${theme.borderRadius.base};
    background-color: ${theme.color.purpleL3};
    border: 1px solid ${theme.color.purpleL3};
    padding: 10px;
    transition: all ${theme.animationSpeed.normal};

    &:hover {
      background-color: #fff;
      border-color: ${theme.color.purple};
    }
  `}
`

const BackInner = ({ history, backRouteName }) => (
  <Wrapper onClick={() => history.push(backRouteName)}>
    <Graphic size={20} name="Back" themeColor="main" />
  </Wrapper>
)

export const Back = withRouter(BackInner)
