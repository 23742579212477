import posed from 'react-pose'

const dampedTransition = {
  type: 'spring',
  damping: 50,
  duration: 500,
  stiffness: 200
}

export const VerticalFade = posed.div({
  preEnter: { y: ({ noEnter }) => (noEnter ? 0 : '-50%'), opacity: 0 },
  enter: {
    y: 0,
    opacity: 1,
    transition: { y: dampedTransition }
  },
  exit: {
    opacity: 0,
    transition: { duration: 200 }
  }
})
