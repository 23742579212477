import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { Modal, Button, Spacing } from '../../components/ui'
import { withMutation } from '../../components/wrappers'
import { TypeformEditor } from '../../screens/StudyBuilder/components'

const ModalWrap = styled.div`
  width: 900px;
  position: relative;
`

const CreateTypeformView = ({ onDismiss, params = {}, updateOpportunity }) => {
  const { opportunity } = params
  if (!opportunity) return null

  const [pendingTypeformId, setPendingTypeformId] = useState()
  const [editorDisabled, setEditorDisabled] = useState(false)

  const onUpdate = onDismiss => () => {
    updateOpportunity({
      variables: {
        id: opportunity.id,
        survey: {
          typeformId: pendingTypeformId
        }
      }
    }).then(({ userErrors, errors }) => {
      setEditorDisabled(false)
      if (!userErrors && !errors) onDismiss()
    })
  }

  const onSaveDelayed = onDismiss => () => {
    // We need to wait until user's data is saved to `Typeform`
    // before proceeding with our flow
    // but we dont have any way to get response from Typeform when its done
    // so we wait for 5 second and hope for the best
    setEditorDisabled(true)
    setTimeout(onUpdate(onDismiss), 5000)
  }

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title="Build a Survey"
    >
      {({ onDismiss }) => (
        <ModalWrap>
          <TypeformEditor
            {...opportunity}
            onCreate={setPendingTypeformId}
            pendingTypeformId={pendingTypeformId}
            editorDisabled={editorDisabled}
          />

          <Modal.Footer>
            <Spacing direction="row" justify="flex-end">
              <Button outline onClick={onDismiss}>
                Cancel
              </Button>
              <Button
                disabled={editorDisabled}
                onClick={onSaveDelayed(onDismiss)}
              >
                Save
              </Button>
            </Spacing>
          </Modal.Footer>
        </ModalWrap>
      )}
    </Modal>
  )
}

export const CreateTypeform = withMutation('updateOpportunity')(
  CreateTypeformView
)
