import { useCallback, useState } from 'react'
import { pipe, not, ifElse, isNil, identity } from 'ramda'

export const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState)
  const toggle = useCallback(
    explicitState =>
      pipe(
        ifElse(isNil, () => not, identity),
        setState
      )(explicitState),
    []
  )

  return [state, toggle]
}
