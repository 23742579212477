import React from 'react'
import { Form, Field } from 'react-final-form'

import {
  Graphic,
  Text,
  Spacing,
  Button,
  Input,
  SubmitButton
} from '../../../components/ui'
import { mergeValidators, validatorRequired } from '../../../util/validators'
import { withMutation } from '../../../components/wrappers'
import { RenderFromQuery } from '../../../components/util'
import { SignUpCompanyQuery } from '../../../state/queries'

import { VerticalFade } from '../components/AnimatedWizardItems'

const onSubmit = ({ onNextStep, signUp }) => values =>
  signUp({ variables: values }).then(({ errors, userErrors, data }) => {
    if (!userErrors && data && data.signUp) {
      localStorage.setItem('token', data.signUp.token)

      onNextStep()
    }

    return userErrors || errors
  })

export const Step = props => (
  <Spacing size="xxxxl">
    <Graphic name="Logo" png size={80} />
    <RenderFromQuery
      query={SignUpCompanyQuery}
      renderLoading={() => <EmailForm {...props} />}
      renderError={() => <EmailForm {...props} />}
      renderData={({ currentUser }) => (
        <EmailForm {...props} currentUser={currentUser} signUpStarted />
      )}
    />
  </Spacing>
)

const EmailForm = ({ onNextStep, signUp, currentUser, signUpStarted }) => (
  <Form
    initialValues={{ role: 'company', ...currentUser }}
    onSubmit={signUpStarted ? onNextStep : onSubmit({ onNextStep, signUp })}
    validate={mergeValidators([validatorRequired('email', 'Email address')])}
  >
    {({ handleSubmit, ...formProps }) => (
      <form onSubmit={handleSubmit}>
        <Spacing size="xl">
          <Text h0>Sign Up</Text>

          <VerticalFade>
            <Text t1>First, what’s your email address?</Text>
          </VerticalFade>
          <VerticalFade>
            <Field
              component={Input}
              name="email"
              label="Email address"
              disabled={signUpStarted}
              autoFocus
            />
          </VerticalFade>

          <VerticalFade noEnter>
            <Spacing direction="row">
              <SubmitButton {...formProps}>Continue</SubmitButton>
              {signUpStarted && (
                <Button transparent themeColor="grey" onClick={restartSignUp}>
                  Not you?
                </Button>
              )}
            </Spacing>
          </VerticalFade>
        </Spacing>
      </form>
    )}
  </Form>
)

const restartSignUp = () => {
  localStorage.removeItem('token')
  window.location.reload()
}

export const StepEmail = withMutation('signUp')(Step)
