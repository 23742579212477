import gql from 'graphql-tag'
import { UserFragment } from '../fragments'

export const refetchHyperwalletVerification = gql`
  mutation RefetchHyperwalletVerification($id: String!) {
    refetchHyperwalletVerification(id: $id) {
      errors {
        key
        message
      }
      affectedUser {
        id
        ...User
      }
    }
  }
  ${UserFragment}
`
