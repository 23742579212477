import React from 'react'
import styled from 'styled-components/macro'

import { Text, Spacing } from '../../../../components/ui'
import { StudyStateTag } from '../../../../components/blocks'
import { getOpportunityType, calculateCTR } from '../../../../util'
import { format } from 'date-fns'
import Formats from '../../../../util/Formats'
import { SummaryCardSmall } from '../../../StudySummary/components'

const DotSeparator = styled.span`
  color: ${({ theme }) => theme.color.dark};

  ::before {
    content: '·';
  }
`

const AdImagePlaceholder = styled.div`
  width: 89px;
  height: 89px;
  background-color: ${({ theme }) => theme.color.greyL2};
`

const AdImage = ({ ad }) =>
  ad.imageUrl ? (
    <img src={ad.imageUrl} width={89} alt={ad.hostUrl} />
  ) : (
    <AdImagePlaceholder />
  )

const renderStudyInfo = opportunity => {
  const StudyType = <Text t3>{getOpportunityType(opportunity)}</Text>
  const StudyCreatedAt = (
    <Text t3>
      created at {format(parseInt(opportunity.createdAt), Formats.date.full)}
    </Text>
  )

  if (opportunity.state === 'draft') return StudyCreatedAt

  return (
    <Spacing direction="row" size="s">
      {StudyType}
      <DotSeparator />
      {StudyCreatedAt}
    </Spacing>
  )
}

export const AdCardDetails = ({ opportunity, opportunity: { ad } }) => (
  <Spacing direction="row" justify="space-between" stretched stretchChildren>
    <Spacing direction="row" size="xl">
      <AdImage ad={ad} />
      <Spacing size="xl">
        <Text h2>{opportunity.name}</Text>
        <Spacing direction="row" size="l" align="center">
          <StudyStateTag state={opportunity.state} />
          {renderStudyInfo(opportunity)}
        </Spacing>
      </Spacing>
    </Spacing>
    <Spacing direction="row" stretchChildren size="s">
      <SummaryCardSmall title="Total spent" value={`$${ad.stats.totalSpent}`} />
      <SummaryCardSmall title="Impressions" value={ad.stats.impressions} />
      <SummaryCardSmall title="Visits" value={ad.stats.visits} />
      <SummaryCardSmall title="CTR" value={calculateCTR(ad.stats)} />
    </Spacing>
  </Spacing>
)
