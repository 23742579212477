import React from 'react'
import { Text, Spacing, Graphic } from '../../../components/ui'
import styled from 'styled-components'
import { withMutation } from '../../../components/wrappers'
import { StudiesQueryVariables, StudiesQuery } from '../../../state/queries'
import { pipe, map, values } from 'ramda'
import { format } from 'date-fns'
import Formats from '../../../util/Formats'

const Star = styled.div`
  cursor: pointer;
  font-size: 30px;
  transition: filter 0.1s;
  margin-left: ${({ theme }) => theme.padding.xl};
`

const StateCard = styled.div`
  background-color: ${({ theme }) => theme.color.main};
  padding: ${({ theme }) => `${theme.padding.xs} ${theme.padding.s}`};
  border-radius: ${({ theme }) => theme.borderRadius.base};
`

const OpportunitySpecificationsInner = ({
  opportunity,
  opportunity: { id, favorite },
  favoriteOpportunity
}) => {
  const onFavoriteToggle = favorite => {
    return favoriteOpportunity({
      variables: {
        id,
        favorite: !favorite
      },
      refetchQueries: pipe(
        values,
        map(variables => ({ query: StudiesQuery, variables }))
      )(StudiesQueryVariables)
    }).then(({ errors }) => {
      if (errors) {
        return errors
      }
    })
  }

  return (
    <Spacing direction="row" align="center" size="xl">
      <Star
        title={favorite ? 'Unstar' : 'Star'}
        onClick={() => onFavoriteToggle(favorite)}
        checked={favorite}
      >
        <Graphic
          name="Star"
          size={24}
          themeColor={opportunity.favorite ? 'main' : 'transparent'}
        />
      </Star>

      <StateCard>
        <Text h7 color="white">
          {opportunity.state}
        </Text>
      </StateCard>
      <Spacing direction="row" size="s">
        <Text t3>{opportunity.category}</Text>
        <Text t3>·</Text>
        <Spacing direction="row" size="xs">
          <Text t3>created on</Text>
          <Text t3>
            {format(parseInt(opportunity.createdAt), Formats.date.full)}
          </Text>
        </Spacing>
      </Spacing>
    </Spacing>
  )
}

export const OpportunitySpecifications = pipe(
  withMutation('favoriteOpportunity')
)(OpportunitySpecificationsInner)
