import React from 'react'
import styled from 'styled-components/macro'
import { EmptyState, Graphic, Container } from '../../../../components/ui'
import { RenderFromQuery } from '../../../../components/util'
import { UserDetailsQuery } from '../../../../state/queries'
import { ContainerSpinner } from '../../../../components/ui'
import { useParams } from 'react-router-dom'
import { UserPayoutDetailsCard } from '../UserPayoutDetailsCard'
import { path } from 'ramda'

const columns = [
  {
    title: 'User',
    accessor: 'username'
  },
  {
    title: 'Phone type',
    accessor: 'deviceModel'
  },
  {
    title: 'IP Address',
    accessor: 'ipAddress'
  },
  {
    title: 'Verified',
    accessor: 'verified'
  }
]

const transactionColumns = [
  {
    title: 'Name',
    accessor: 'name'
  },
  {
    title: 'Amount',
    accessor: 'totalUsd'
  }
]

const DetailsWrap = styled.div`
  flex: 1;
`

export const UserPayoutDetails = () => {
  const { id } = useParams()

  const prepareData = user => {
    return {
      ...user,
      payoutUser: {
        ...user.payoutUser,
        ipAddress: path(['payoutUser', 'member', 'ipAddress'], user),
        deviceModel: path(['payoutUser', 'member', 'deviceModel'], user)
      },
      referrerUser: {
        ...user.referrerUser,
        ipAddress: path(['referrerUser', 'member', 'ipAddress'], user),
        deviceModel: path(['referrerUser', 'member', 'deviceModel'], user)
      },
      referredUsers: user.referredUsers.map(referredUser => {
        return {
          ...referredUser,
          ipAddress: path(['member', 'ipAddress'], referredUser),
          deviceModel: path(['member', 'deviceModel'], referredUser)
        }
      })
    }
  }

  return (
    <Container size="xxl">
      <RenderFromQuery
        query={UserDetailsQuery}
        variables={{
          id
        }}
        renderLoading={() => (
          <DetailsWrap>
            <ContainerSpinner />
          </DetailsWrap>
        )}
        renderError={() => (
          <DetailsWrap>
            <EmptyState
              title="Error"
              text="User does not exist."
              graphic={
                <Graphic
                  name="Loading"
                  width={145}
                  height={60}
                  themeColor="main"
                />
              }
            />
          </DetailsWrap>
        )}
        renderData={({ user }, queryRenderParams) => {
          return (
            <DetailsWrap>
              <UserPayoutDetailsCard
                columns={columns}
                transactionColumns={transactionColumns}
                data={prepareData(user)}
                id={id}
                queryRenderParams={queryRenderParams}
              />
            </DetailsWrap>
          )
        }}
      />
    </Container>
  )
}
