export const flattenAudienceSchema = data =>
  data.reduce(
    (acc, item) =>
      item.children
        ? {
            ...acc,
            [item.uniqueId]: item,
            ...flattenAudienceSchema(item.children)
          }
        : { ...acc, [item.uniqueId]: item },
    {}
  )
