import React from 'react'
import { pipe, propEq, filter, complement, equals, path } from 'ramda'

import { Spacing, Text, SpacingItem, Button } from '../../../../components/ui'
import { UserQuery } from '../../../../state/queries'
import styled from 'styled-components'
import { Back } from '../../../../components/blocks/Back'
import { TransactionsList } from '../TransactionsList'
import { PayoutRequestsList } from '../PayoutRequestList'
import { useHistory } from 'react-router-dom'
import { withMutation } from '../../../../components/wrappers'
import { parseDateAsString } from '../../../../util/date'

const Container = styled.div`
  padding: ${({ theme }) => theme.padding.xl};
  background-color: ${({ theme }) => theme.color.purpleL4};
`

const HeadlineWrap = styled.div`
  padding-bottom: ${({ theme }) => theme.padding.m};
  border-bottom: 1px solid ${({ theme }) => theme.color.greyL2};
`

const TextStyle = styled(Text)`
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.color.purpleL1};
  }
`

const TextWrap = styled(Text)`
  display: inline;
`

export const UserPayoutDetailsCardInner = ({
  data,
  columns,
  transactionColumns,
  queryRenderParams,
  deactivateMemberAccount,
  id
}) => {
  const backRouteName = '/payouts'
  const terminatedUser = path(['payoutUser', 'terminated'], data)

  const prepareTransactions = transactions =>
    filter(
      transaction =>
        complement(propEq('totalUsd', null))(transaction) &&
        complement(propEq('totalUsd', 0))(transaction)
    )(transactions)

  const onBlockPayout = id => {
    if (window.confirm('Are you sure?')) {
      deactivateMemberAccount({
        variables: { id },
        refetchQueries: [
          {
            query: UserQuery,
            variables: queryRenderParams.variables
          }
        ]
      }).then(({ userErrors }) => {
        if (userErrors) return userErrors
      })
    }
  }

  const VerifiedField = ({ verified }) => (
    <Text h6 color={verified ? 'green' : 'red'}>
      {verified ? 'Yes' : 'No'}
    </Text>
  )

  const PayoutUserDetails = () => (
    <Spacing direction="row">
      {columns.map((column, columnIndex) => {
        return (
          <SpacingItem flex="0 20%" key={column.accessor + '-' + columnIndex}>
            <Spacing size="s">
              <Text t3 color="dark">
                {column.title}
              </Text>
              {equals(column.accessor, 'verified') ? (
                <VerifiedField verified={data.payoutUser.verified} />
              ) : (
                <Text h6 color="dark">
                  {data.payoutUser[column.accessor] || '-'}
                </Text>
              )}
            </Spacing>
          </SpacingItem>
        )
      })}
    </Spacing>
  )

  const UsernameField = ({ id, username }) => {
    const { push } = useHistory()
    if (username) {
      return (
        <TextStyle h6 color="main" onClick={() => push(`/payouts/${id}`)}>
          {username}
        </TextStyle>
      )
    }
    return (
      <Text h6 color="dark">
        -
      </Text>
    )
  }

  const ReffererUserDetails = () => {
    const reffererUserId = path(['referrerUser', 'id'], data)

    const renderFields = column => {
      if (equals(column.accessor, 'verified')) {
        return <VerifiedField verified={data.referrerUser.verified} />
      }
      if (equals(column.accessor, 'username')) {
        return (
          <UsernameField
            id={reffererUserId}
            username={data.referrerUser[column.accessor]}
          />
        )
      }
      return (
        <Text h6 color="dark">
          {data.referrerUser[column.accessor] || '-'}
        </Text>
      )
    }

    return (
      <Spacing size="s">
        <Text t3 color="dark">
          Referred by
        </Text>
        <Spacing direction="row">
          {columns.map((column, columnIndex) => (
            <SpacingItem flex="0 20%" key={columnIndex}>
              {renderFields(column)}
            </SpacingItem>
          ))}
        </Spacing>
      </Spacing>
    )
  }

  const RefferedUsersDetails = () => {
    const renderFields = (referredUser, column) => {
      if (equals(column.accessor, 'verified')) {
        return (
          <VerifiedField
            verified={referredUser.verified}
            key={column.accessor}
          />
        )
      }
      if (equals(column.accessor, 'username')) {
        return (
          <UsernameField
            key={column.accessor}
            id={referredUser.id}
            username={referredUser.username}
          />
        )
      }
      return (
        <Text h6 color="dark" key={column.accessor}>
          {referredUser[column.accessor] || '-'}
        </Text>
      )
    }
    return (
      <Spacing size="s">
        <Text t3 color="dark">
          Referred users ({data.referredUsers.length})
        </Text>
        <Spacing direction="row">
          {columns.map(column => (
            <SpacingItem flex="0 20%" key={column.accessor}>
              <Spacing size="s">
                {data.referredUsers.map(referredUser =>
                  renderFields(referredUser, column)
                )}
              </Spacing>
            </SpacingItem>
          ))}
        </Spacing>
      </Spacing>
    )
  }

  const getFraudStatusProp = prop =>
    path(['payoutUser', 'fraudStatus', prop], data)

  const FraudStatus = () =>
    data.payoutUser.fraudStatus && data.payoutUser.ipAddress ? (
      <Spacing size="l">
        <Text t3 color="dark">
          IP QualityScore Fraud Status
        </Text>
        <Spacing>
          {[
            {
              label: 'Fraud Score',
              text: getFraudStatusProp('ipFraudScore')
            },
            { label: 'ISP', text: getFraudStatusProp('ipIsp') },
            { label: 'Location', text: getFraudStatusProp('ipLocation') },
            {
              label: 'Using Proxy?',
              evil: getFraudStatusProp('ipIsProxy')
            },
            {
              label: 'Reported for Abuse?',
              evil: getFraudStatusProp('ipReportedAbuse')
            },
            { label: 'Is a Bot?', evil: getFraudStatusProp('ipIsBot') }
          ].map(({ label, text, evil }) => (
            <Spacing direction="row" key={label}>
              <SpacingItem flex="0 20%">
                <Text t3 color="dark">
                  {label}
                </Text>
              </SpacingItem>
              {text ? (
                <Text h6>{text}</Text>
              ) : (
                <Text h6 color={evil ? 'red' : 'green'}>
                  {evil ? 'Yes' : 'No'}
                </Text>
              )}
            </Spacing>
          ))}
        </Spacing>
      </Spacing>
    ) : null

  return (
    <Container>
      <Spacing size="xl">
        <HeadlineWrap>
          <Spacing direction="row" size="xl" align="center">
            <Back backRouteName={backRouteName} />
            <Text h3 bold>
              User details: {data.payoutUser.username}
            </Text>
          </Spacing>
        </HeadlineWrap>
        <Spacing size="xxl">
          <Spacing direction="row">
            <SpacingItem flex="1">
              <Spacing size="xxl">
                <PayoutUserDetails />
                <ReffererUserDetails />
                <RefferedUsersDetails />
                <FraudStatus />
              </Spacing>
            </SpacingItem>
            <SpacingItem flex="0 20%">
              <PayoutRequestsList
                queryRenderParams={queryRenderParams}
                data={data}
              />
            </SpacingItem>
          </Spacing>

          <Spacing direction="row" justify="space-between" align="flex-end">
            <SpacingItem flex="0 80%">
              <TransactionsList
                transactionColumns={transactionColumns}
                transactions={prepareTransactions(data.transactions)}
              />
            </SpacingItem>
            <SpacingItem flex="1">
              <Spacing size="l">
                {terminatedUser && (
                  <TextWrap h6>
                    User terminated
                    <TextWrap t3 style={{ paddingLeft: 3 }}>
                      for violating Terms of Service on
                      <TextWrap t3 style={{ paddingLeft: 3 }}>
                        {parseDateAsString(data.payoutUser.terminatedAt)}
                      </TextWrap>
                    </TextWrap>
                  </TextWrap>
                )}
                <Button
                  block
                  themeColor="red"
                  disabled={!!terminatedUser}
                  onClick={() => onBlockPayout(id)}
                >
                  Block user
                </Button>
              </Spacing>
            </SpacingItem>
          </Spacing>
        </Spacing>
      </Spacing>
    </Container>
  )
}
export const UserPayoutDetailsCard = pipe(
  withMutation('deactivateMemberAccount', {
    showErrorNotifications: true
  })
)(UserPayoutDetailsCardInner)
