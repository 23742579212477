import React from 'react'
import styled from 'styled-components/macro'
import { Spacing, SpacingItem, Text, Box } from '../'

const GraphicWrapper = styled(SpacingItem)`
  margin-left: ${({ theme }) => theme.padding.xl};
`

const StyledBox = styled(Box)`
  min-height: 190px;
  display: flex;
`

export const EmptyState = ({ rightContent, text, title, graphic }) => (
  <StyledBox size="xl">
    <Spacing
      direction="row"
      align="center"
      justify="center"
      size="xxl"
      stretched
    >
      <GraphicWrapper>{graphic}</GraphicWrapper>
      <SpacingItem style={{ flex: 3 }}>
        <Spacing size="s">
          {title && <Text h3>{title}</Text>}
          <Text t1={!title} t3={title}>
            {text}
          </Text>
        </Spacing>
      </SpacingItem>
      {rightContent && (
        <SpacingItem style={{ flex: 1 }}>{rightContent}</SpacingItem>
      )}
    </Spacing>
  </StyledBox>
)
