import React from 'react'
import styled from 'styled-components/macro'
import { Graphic } from '../Graphic'

export const Container = styled.div`
  margin: ${({ theme, size }) =>
    `${theme.padding[size]} ${theme.padding[size]}`};
  min-height: 80%;
  margin-bottom: 400px;
  display: flex;
`

const GraphicWrap = styled(Graphic)`
  align-self: center;
  justify-content: center;
  flex: 1;
`

export const ContainerSpinner = ({ size = 'xxl' }) => (
  <Container size={size}>
    <GraphicWrap name="Spinner" size={100} themeColor="main" />
  </Container>
)
