import React from 'react'
import { pipe, equals } from 'ramda'
import { graphql } from 'react-apollo'

import { Button, Text, Spacing, InlineField } from '../../../../components/ui'
import { withMutation } from '../../../../components/wrappers'
import { StudyReviewBlock } from '../../../../components/blocks'
import { TotalSummary } from '../../../../components/blocks/StudyDetails/components'
import { CurrentUserQuery } from '../../../../state/queries'

const StudyReachPricing = ({
  opportunity,
  updateOpportunity,
  failedValidations,
  data: {
    currentUser: { currentRole }
  }
}) => {
  const isAdmin = equals(currentRole, 'admin')
  return (
    <StudyReviewBlock>
      <StudyReviewBlock.Header
        title="Reach and pricing"
        rightContent={
          <Button
            to={`/studies/builder/${opportunity.id}/1`}
            link
            transparent
            themeColor="secondary"
          >
            Change audience
          </Button>
        }
      />
      <Spacing size="xl">
        <Spacing>
          <Text h6>Target Audience Size</Text>
          <Text h2>~{opportunity.reach.maxPermitted}</Text>
          <Text t3 color="grey">
            Audience reach is the sum of quotas of all audience buckets. This
            number can vary depending on the overlap of criteria in audience
            buckets.
          </Text>
        </Spacing>

        <Text h6>Payout to members</Text>
        {isAdmin && (
          <Spacing direction="row">
            {[
              { label: 'Cash', field: 'cash' },
              { label: 'UBDI Coins', field: 'firstTime' }
            ].map(({ label, field }) => (
              <InlineField
                key={field}
                initialValue={opportunity.fees ? opportunity.fees[field] : 0}
                onUpdate={value =>
                  updateOpportunity({
                    variables: {
                      id: opportunity.id,
                      fees: {
                        ...(opportunity.fees || {}),
                        [field]: parseInt(value)
                      }
                    }
                  })
                }
                label={label}
                type="number"
                inlineError={failedValidations[field]}
                subscription={{ active: true }}
                updateConditions={({ active }) => !active}
              />
            ))}
          </Spacing>
        )}
      </Spacing>
      <StudyReviewBlock.Footer>
        <TotalSummary opportunity={opportunity} direction="row" excludeFirst />
      </StudyReviewBlock.Footer>
    </StudyReviewBlock>
  )
}

export const StudyReachPricingReview = pipe(
  graphql(CurrentUserQuery),
  withMutation('updateOpportunity')
)(StudyReachPricing)
