import React from 'react'
import styled from 'styled-components/macro'
import { includes } from 'ramda'
import { TickCheckbox } from '../components'
import { Text, Box, Spacing, Graphic } from '../../../ui'
import { useModal } from '../../../../hooks'

const StyledGroupBox = styled(Box)`
  overflow: hidden;
  cursor: pointer;
  border: 2px solid
    ${({ theme, checked }) =>
      checked ? theme.color.main : theme.color.purpleL3};
`

const GroupDetails = styled.div`
  text-align: right;
  cursor: pointer;
`

export const AudienceGroup = ({
  id,
  name,
  membersCount,
  logoUrl,
  selectedGroups,
  toggleSelectedGroup
}) => {
  const checked = includes(id, selectedGroups)
  const { openModal } = useModal()

  return (
    <Spacing size="s">
      <StyledGroupBox
        onClick={() => toggleSelectedGroup(id)}
        noShadow
        padding={20}
        backgroundColor="purpleL4"
        checked={checked}
      >
        <Spacing size="s">
          <Spacing direction="row" size="xs" justify="space-between">
            <Graphic size={36} src={logoUrl} png />
            <TickCheckbox checked={checked} />
          </Spacing>
          <Spacing size="xs">
            <Text title={name} h4 noWrap>
              {name}
            </Text>
            <Text t3>{membersCount} people</Text>
          </Spacing>
        </Spacing>
      </StyledGroupBox>
      <GroupDetails onClick={() => openModal('GroupDetails', { id, name })}>
        <Spacing size="xs" direction="row" align="center" justify="flex-end">
          <Graphic size={14} color="primary" name="Info" />
          <Text t3>Details</Text>
        </Spacing>
      </GroupDetails>
    </Spacing>
  )
}
