import gql from 'graphql-tag'

export const TransactionsQuery = gql`
  query Transactions(
    $first: Int
    $after: Int
    $orderBy: String
    $sort: String
  ) {
    allTransactions(
      first: $first
      after: $after
      orderBy: $orderBy
      sort: $sort
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          createdAt
          name
          totalUsd
        }
      }
    }
  }
`
