import gql from 'graphql-tag'

export const verifyCompanyEmail = gql`
  mutation VerifyCompanyEmail($code: String!) {
    verifyCompanyEmail(code: $code) {
      query {
        currentUser {
          id
          verified
        }
      }
      errors {
        key
        message
      }
    }
  }
`

export const sendVerificationEmail = gql`
  mutation SendVerificationEmail {
    sendVerificationEmail {
      success
      errors {
        key
        message
      }
    }
  }
`
