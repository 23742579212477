import React from 'react'
import styled from 'styled-components/macro'
import { Text } from '..'
import { Graphic } from '../Graphic'
import { Spacing } from '../Spacing'

const LabelWrapper = styled.label`
  cursor: pointer;
`

const Circle = styled.div`
  width: 26px;
  height: 26px;
  vertical-align: middle;
  display: inline-block;
  margin-right: ${props => props.theme.padding.s};
  border-radius: 50%;
  background-color: ${props =>
    props.checked ? props.theme.color.main : props.theme.color.transparent};

  border: 2px solid
    ${props =>
      props.checked ? props.theme.color.white : props.theme.color.purpleL1};
  transition: background-color ${props => props.theme.animationSpeed.normal};
  display: flex;
  align-items: center;
  justify-content: center;
`

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

export const Radio = ({ label, input, meta }) => (
  <LabelWrapper>
    <Spacing direction="row" size="s" align="center">
      <RadioInput {...input} type="radio" />
      <Circle checked={input.checked}>
        {input.checked && <Graphic name="Tick" size={12} themeColor="white" />}
      </Circle>
      <Text t2 inline color="dark">
        {label}
      </Text>
    </Spacing>
  </LabelWrapper>
)
