import gql from 'graphql-tag'

export const createTypeform = gql`
  mutation CreateTypeform($id: String!) {
    createTypeform(id: $id) {
      typeformId
      query {
        currentUser {
          id
          company {
            id
            connectedTypeform
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
`
