import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import * as styles from './styles'

export function SliderRail({ getRailProps }) {
  return (
    <Fragment>
      <div style={styles.railOuterStyle} {...getRailProps()} />
      <div style={styles.railInnerStyle} />
    </Fragment>
  )
}

SliderRail.propTypes = {
  getRailProps: PropTypes.func.isRequired
}

export function KeyboardHandle({
  domain: [min, max],
  handle: { id, value, percent },
  disabled,
  getHandleProps,
  hideValue
}) {
  return (
    <button
      role="slider"
      type="button"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={styles.getHandleStyles(percent, disabled)}
      {...getHandleProps(id)}
    >
      {hideValue ? '' : value}
    </button>
  )
}

KeyboardHandle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

KeyboardHandle.defaultProps = {
  disabled: false
}

export function Track({ getTrackProps, ...props }) {
  return <div style={styles.getTrackStyles(props)} {...getTrackProps()} />
}

Track.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  target: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  getTrackProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

Track.defaultProps = {
  disabled: false
}
