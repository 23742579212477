import React from 'react'
import styled from 'styled-components/macro'
import { format } from 'date-fns'

import {
  Modal,
  Button,
  Spacing,
  Input,
  TextArea,
  Notice,
  Text
} from '../../components/ui'
import { withMutation } from '../../components/wrappers'
import { Form, Field } from 'react-final-form'
import Formats from '../../util/Formats'

const ModalWrap = styled.div`
  width: 650px;
  min-height: 300px;
  position: relative;
`

const SendNotificationView = ({ onDismiss, params = {}, sendNotification }) => {
  const { studyId, sentNotifications } = params
  if (!studyId) return null

  console.log({ sentNotifications })
  const onSend = ({ title, message }) => {
    return sendNotification({
      variables: {
        studyId,
        title,
        message
      }
    }).then(({ userErrors, errors }) => {
      return userErrors || errors
    })
  }

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title="Send Notification"
    >
      {({ onDismiss }) => (
        <Form onSubmit={onSend}>
          {({
            handleSubmit,
            submitError,
            submitSucceeded,
            submitting,
            form
          }) => (
            <ModalWrap>
              <Spacing size="l">
                <Text t2>
                  Dispatching a Silent Matching notification will send a
                  background request for local PrivateMatching with this study.
                  Silent notification will go out to everyone, but it will show
                  up only to the people who matched with one of the Target
                  Audience Buckets.
                </Text>
                <Spacing>
                  {submitError && <Notice error>{submitError}</Notice>}
                  {submitSucceeded ? (
                    <Notice success>
                      Notification was successfully sent! It might take a while,
                      though, to reach all the matched users.
                    </Notice>
                  ) : (
                    <>
                      <Field
                        component={Input}
                        block
                        name="title"
                        label="Title"
                      />
                      <Field
                        component={TextArea}
                        block
                        name="message"
                        label="Message"
                      />
                    </>
                  )}
                  <>
                    <Button disabled={submitting} onClick={handleSubmit}>
                      Send
                    </Button>
                  </>
                </Spacing>

                {sentNotifications && sentNotifications.length > 0 && (
                  <Spacing>
                    <Text h4>Previously sent notifications</Text>
                    <Spacing separated>
                      {sentNotifications.map(
                        ({
                          id,
                          timestamp,
                          notification: { title, message }
                        }) => (
                          <div key={id}>
                            <Text>{title}</Text>
                            <Text t3>{message}</Text>

                            <Text t3 color="grey">
                              {format(parseInt(timestamp), Formats.date.full)}
                            </Text>
                          </div>
                        )
                      )}
                    </Spacing>
                  </Spacing>
                )}
              </Spacing>

              <Modal.Footer>
                <Button onClick={onDismiss} outline>
                  Cancel
                </Button>
              </Modal.Footer>
            </ModalWrap>
          )}
        </Form>
      )}
    </Modal>
  )
}

export const SendNotification = withMutation('sendNotification')(
  SendNotificationView
)
