const isValidPassword = password => {
  if (!password) return 'Password is mandatory'
  if (password.length < 6) return 'Password must be at least 6 characters'
  return true
}

const isSamePassword = (password, repeatPassword) => {
  if (password !== repeatPassword) return 'Passwords must match'
  return true
}

export const validatorPassword = (passwordField = 'password') => values => {
  const isValid = isValidPassword(values[passwordField])
  const isSame = isSamePassword(values[passwordField], values.repeatPassword)
  if (isValid !== true) return { [passwordField]: isValid }
  if (isSame !== true) return { repeatPassword: isSame }

  return {}
}
