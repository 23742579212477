import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import theme from '../../../theme'
import { useContextualHover } from '../../../hooks'

const getBackgroundColor = ({
  input: { value },
  selected,
  white,
  theme,
  color
}) => {
  if (color) return theme.color[color]
  if (selected || value) return theme.color.main
  return white ? theme.color.white : theme.color.purpleL3
}

const getTextColor = ({ input: { value }, selected }) => {
  return selected || value ? theme.color.white : theme.color.main
}

const TagWrap = styled.div`
  ${props => {
    const backgroundColor = getBackgroundColor(props)
    const color = getTextColor(props)
    const hover =
      (props.removable || props.onSelect) &&
      `
      background-color: ${
        props.removable ? props.theme.color.red : props.theme.color.main
      };
      color: ${props.theme.color.white};
      transition: background-color ${props.theme.animationSpeed.normal};
      transition: color ${props.theme.animationSpeed.normal};
      cursor: pointer;
    `

    return css`
      background: ${backgroundColor};
      color: ${color};
      border-radius: ${props.theme.borderRadius.base}
      padding: ${props.theme.padding.xs} ${props.theme.padding.m}

      ${props.input && props.input.onChange && `cursor: pointer;`}
      display: inline-block;

      ${props.theme.text.h7}
      line-height: 1.4;

      &:hover {
        ${hover}
      }
    `
  }}
`

export const Tag = props => {
  const { label, input, removable, extra, onRemove, onSelect } = props

  const { onMouseOver } = useContextualHover()

  const toggleSelected = () => {
    input.onChange && input.onChange(!input.value)
  }

  const handleOnClick = (...args) => {
    if (removable) {
      return onRemove(...args)
    }

    if (onSelect) {
      return onSelect(...args)
    }

    return toggleSelected(...args)
  }

  return (
    <TagWrap
      {...props}
      onClick={handleOnClick}
      title={removable && 'Remove tag'}
      onMouseOver={() => onMouseOver && onMouseOver({ label, extra })}
    >
      {label}
    </TagWrap>
  )
}

const propOptions = {
  colors: Object.keys(theme.color)
}

Tag.propTypes = {
  white: PropTypes.bool,
  color: PropTypes.oneOf(propOptions.colors)
}

Tag.defaultProps = {
  input: {
    value: false
  }
}
