import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'

const { REACT_APP_API_URL, NODE_ENV } = process.env

const EXCLUDED_QUERIES_FROM_AUTH = ['SignUpCompany']
const AUTH_ERRORS = ['U01', 'U02'] // U01 NotAuthorized, U02 TokenInvalid
const SCHEMA_ERRORS = ['GQ01']

export const onApolloError = errors => {
  const { graphQLErrors, networkError, operation } = errors

  // example of error handling code
  if (graphQLErrors) {
    // Handle top level errors
    graphQLErrors.map(err => {
      const { code, message } = err

      // Auth
      if (AUTH_ERRORS.includes(code)) {
        if (!EXCLUDED_QUERIES_FROM_AUTH.includes(operation.operationName)) {
          return handleInvalidOrMissingToken()
        }
        return false
      }

      // Schema
      if (SCHEMA_ERRORS.includes(code)) {
        if (NODE_ENV === 'development') {
          console.warn('GraphQL Error', message)
        }
      }

      console.log(`[GraphQL error]: Code: ${code}, Message: ${message}`)
      return false
    })
  } else if (networkError) {
    console.warn('There seems to be an issue with your network connectivity.')
    console.log(`[Network error]:  ${networkError}`)
  }
}

const handleInvalidOrMissingToken = () => {
  localStorage.removeItem('token')
  window.location = '/login'
}

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(onApolloError),
    authLink,
    new HttpLink({
      uri: REACT_APP_API_URL
    })
  ]),
  cache: new InMemoryCache()
})
