import React, { useState } from 'react'
import { equals, slice, pathOr, pipe } from 'ramda'

import { Spacing, Text, Button } from '../../../../components/ui'
import { withMutation } from '../../../../components/wrappers'
import { UserQuery } from '../../../../state/queries'
import { parseDateAsString } from '../../../../util/date'
import styled from 'styled-components'

const TextStyle = styled(Text)`
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.color.purpleL1};
  }
`

export const PayoutRequestsInner = ({
  data,
  confirmPayout,
  rejectPayout,
  queryRenderParams
}) => {
  const allUsersPayoutRequests = pathOr(
    [],
    ['payoutUser', 'member', 'payoutRequests'],
    data
  )
  const [isExpandedPayouts, setExpandedPayouts] = useState(false)

  const payoutRequestFinal =
    allUsersPayoutRequests.length > 5 && !isExpandedPayouts
      ? slice(0, 5, allUsersPayoutRequests)
      : allUsersPayoutRequests

  const onConfirmPayout = id =>
    confirmPayout({
      variables: { id },
      refetchQueries: [
        {
          query: UserQuery,
          variables: queryRenderParams.variables
        }
      ]
    }).then(({ userErrors }) => {
      if (userErrors) return userErrors
    })

  const onRejectPayout = id => {
    if (window.confirm('Are you sure?')) {
      rejectPayout({
        variables: { id },
        refetchQueries: [
          {
            query: UserQuery,
            variables: queryRenderParams.variables
          }
        ]
      }).then(({ userErrors }) => {
        if (userErrors) return userErrors
      })
    }
  }

  const renderButtons = payoutRequest => {
    return (
      <Spacing direction="row" stretchChildren>
        <Button
          outline
          block
          themeColor="red"
          onClick={() => onRejectPayout(payoutRequest.id)}
        >
          Reject
        </Button>
        <Button
          block
          graphic="Tick"
          onClick={() => onConfirmPayout(payoutRequest.id)}
        >
          Confirm
        </Button>
      </Spacing>
    )
  }

  return (
    <Spacing size="l">
      <Text t1 color="dark">
        Payout requests
      </Text>

      <Spacing size="l">
        {payoutRequestFinal.length === 0 && (
          <Text t3 color="dark">
            User does not have payout requests
          </Text>
        )}
        {payoutRequestFinal.map((payoutRequest, index) => {
          return (
            <Spacing size="l" key={index}>
              <Spacing direction="row" size="xs">
                <Text h6 color="dark" transform="capitalize">
                  {payoutRequest.status}
                </Text>
                <Text t3 color="dark">
                  request for
                </Text>
                <Text h6 color="dark">
                  ${payoutRequest.amount}
                </Text>
                <Text t3 color="dark">
                  on {parseDateAsString(payoutRequest.createdAt)}
                </Text>
              </Spacing>
              {equals(payoutRequest.status, 'pending') &&
                renderButtons(payoutRequest)}
            </Spacing>
          )
        })}
        {allUsersPayoutRequests.length > 5 && (
          <TextStyle
            t3
            color="main"
            onClick={() => setExpandedPayouts(!isExpandedPayouts)}
          >
            {isExpandedPayouts ? 'Hide' : 'Show'} more
          </TextStyle>
        )}
      </Spacing>
    </Spacing>
  )
}
export const PayoutRequestsList = pipe(
  withMutation('confirmPayout', {
    showErrorNotifications: true
  }),
  withMutation('rejectPayout', {
    showErrorNotifications: true
  })
)(PayoutRequestsInner)
