import React, { createContext, useState } from 'react'

export const HoverContext = createContext([])

export const ContextualHoverProvider = ({ children }) => {
  const [hovering, setHovering] = useState(null)

  const onMouseOver = value => setHovering(value)
  const onMouseLeave = () => setHovering(null)

  return (
    <HoverContext.Provider
      value={{
        hovering,
        onMouseOver,
        onMouseLeave
      }}
    >
      {children}
    </HoverContext.Provider>
  )
}
