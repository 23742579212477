import gql from 'graphql-tag'
import { PayoutRequestFragment } from '../fragments'

export const PayoutRequestsQuery = gql`
  query allPayoutRequests(
    $status: String
    $after: Int
    $first: Int = 10
    $username: String
  ) {
    allPayoutRequests(
      status: $status
      first: $first
      after: $after
      username: $username
    ) {
      totalCount
      edges {
        node {
          id
          ...PayoutRequest
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${PayoutRequestFragment}
`
