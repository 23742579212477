import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'

import {
  Spacing,
  ControlledInlineModal,
  SubmitError,
  Button,
  Text
} from '../../../../../components/ui'
import { Field } from 'react-final-form'
import { QueriesBuilder } from '../../../../../components/blocks'
import { useEffect } from 'react'
import { isEmpty } from 'ramda'

const ClickableText = styled(Text)`
  cursor: pointer;
  opacity: 0.5;
`

export const AddDataInsights = ({
  opportunity,
  submitError,
  form,
  ...props
}) => {
  const [showBuilder, setShowBuilder] = useState(
    !isEmpty(opportunity.inputDataSources)
  )

  const formInputDataSources = form.getState().values.inputDataSources

  useEffect(() => {
    if (isEmpty(formInputDataSources)) setShowBuilder(false)
  }, [formInputDataSources.length])

  return (
    <ControlledInlineModal {...props} title="Get custom insigths">
      {() =>
        !showBuilder ? (
          <ClickableText
            color="dark"
            t1
            onClick={() => {
              setShowBuilder(true)
            }}
          >
            + Filter data by:
          </ClickableText>
        ) : (
          <Spacing>
            {submitError && <SubmitError submitError={submitError} />}

            <FieldArray name="inputDataSources" mutators={arrayMutators}>
              {({ fields }) => (
                <Spacing size="l">
                  <Spacing size="xxl" separated>
                    {fields.map((name, index) => (
                      <Field
                        key={name}
                        name={name}
                        component={QueriesBuilder}
                        singleQuery={true}
                        initialSelectFieldValue="*"
                        onRemoveQueriesFormField={() => {
                          fields.remove(index)
                        }}
                      />
                    ))}
                  </Spacing>
                  <Button outline block onClick={() => fields.push({})}>
                    + Add a new Insight Query
                  </Button>
                </Spacing>
              )}
            </FieldArray>
          </Spacing>
        )
      }
    </ControlledInlineModal>
  )
}
