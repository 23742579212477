import React from 'react'
import { OpportunitiesOverview } from '../components/OpportunitiesOverview'
import {
  COMPANY_STUDIES_FILTERS,
  INITIAL_STUDIES_FILTERS,
  CREATE_STUDY_LINK,
  CREATE_STUDY_TEXT,
  STUDIES_SIDEBAR_TEXT
} from '../constants'
import { StudiesQueryVariables } from '../../../state/queries'

export const CompanyStudiesScreen = () => (
  <OpportunitiesOverview
    allStates={StudiesQueryVariables.allStudyStates}
    filtersList={COMPANY_STUDIES_FILTERS}
    initialFilters={INITIAL_STUDIES_FILTERS}
    buttonLink={CREATE_STUDY_LINK}
    buttonText={CREATE_STUDY_TEXT}
    sidebarTitle={STUDIES_SIDEBAR_TEXT}
    category="Study"
    userRole="company"
  />
)
