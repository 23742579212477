import React, { useEffect } from 'react'
import { Select } from '../../../ui'

export const DataSourceSelect = ({
  filterByDataSource,
  input,
  meta,
  selectOptions
}) => {
  useEffect(() => {
    filterByDataSource(input.value)
  }, [input.value])

  return (
    <Select input={input} meta={meta} options={selectOptions} label="Source" />
  )
}
