import React from 'react'
import styled from 'styled-components/macro'
import { Form, Field } from 'react-final-form'

import {
  Graphic,
  Text,
  Spacing,
  Button,
  Input,
  SubmitButton
} from '../components/ui'
import { withMutation } from '../components/wrappers'

import onboardingGraphic from './Onboarding/onboarding.svg'

// Styles
const Wrap = styled.div`
  display: flex;
  height: 100%;
`

const Bg = styled.div`
  flex: 1;
  background-image: url(${onboardingGraphic});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const StepWrap = styled.div`
  flex: 1;
`

const Step = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
`

const onSubmit = ({ logIn, push }) => values =>
  logIn({ variables: values }).then(async ({ errors, userErrors, data }) => {
    if (!userErrors && data && data.logIn) {
      localStorage.setItem('token', data.logIn.token)
      push('/')
    }

    return userErrors || errors
  })

const Login = ({ logIn, history: { push } }) => (
  <Wrap>
    <StepWrap>
      <Step>
        <Spacing size="xxxxl">
          <Graphic name="Logo" png size={80} />
          <Form onSubmit={onSubmit({ logIn, push })}>
            {({ handleSubmit, ...formProps }) => (
              <form onSubmit={handleSubmit}>
                <Spacing size="xl">
                  <Text h0>Log In</Text>

                  <Spacing>
                    <Field component={Input} name="email" label="Email" />
                    <Field
                      component={Input}
                      name="password"
                      label="Password"
                      type="password"
                    />
                  </Spacing>

                  <Spacing direction="row">
                    <SubmitButton {...formProps}>Log In</SubmitButton>
                    <Button
                      transparent
                      themeColor="grey"
                      onClick={() => push('/onboarding')}
                    >
                      Create Account
                    </Button>
                  </Spacing>
                </Spacing>
              </form>
            )}
          </Form>
        </Spacing>
      </Step>
    </StepWrap>
    <Bg />
  </Wrap>
)

export const LoginScreen = withMutation('logIn')(Login)
