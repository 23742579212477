import React, { useEffect, useState } from 'react'
import { parse, format } from 'date-fns'
import {
  pipe,
  map,
  flatten,
  equals,
  keys,
  isEmpty,
  is,
  uniqBy,
  prop
} from 'ramda'
import { Form, Field } from 'react-final-form'

import {
  Text,
  Table,
  Spacing,
  Graphic,
  Notice,
  ToggleChevron,
  Box
} from '../../../ui'
import styled from 'styled-components/macro'
import { DataSourceSelect } from './'
import { Search } from '../../../ui/Search'
import { useToggle } from '../../../../hooks'

const TableWrap = styled.div`
  width: 95vw;
  overflow-x: auto;
  overflow-y: hidden;
  tbody tr {
    td {
      max-width: 150px;
      vertical-align: top;
    }
    &:hover {
      cursor: default;
      td div {
        color: ${({ theme }) => theme.color.main};
      }
    }
  }
`

const DataSourceWrap = styled.div`
  min-width: 300px;
`

const StyledTableHeader = styled.th`
  cursor: pointer;
`

const EmptyToggle = styled.div`
  width: 20px;
  height: 20px;
`

export const TableView = ({
  insightResults = [],
  opportunity,
  total,
  requestOptions,
  setRequestOptions,
  rowsPerPage,
  shortId,
  loading,
  error
}) => {
  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState(null)
  const [isDesc, toggleIsDesc] = useToggle(false)

  const dateColumnIndex = keys(insightResults[0]).findIndex(
    equals('_createddate')
  )

  const durationColumnIndex = keys(insightResults[0]).findIndex(
    equals('_duration')
  )

  const setSortProps = column => {
    setPage(1)
    setOrderBy(column)

    // if the same column toggle asc/desc
    if (equals(orderBy, column)) {
      toggleIsDesc(!isDesc)
    } else {
      toggleIsDesc(true)
    }
  }

  const filterByDataSource = tableName => {
    setRequestOptions({
      ...requestOptions,
      tableName,
      offset: 0
    })
    setPage(1)
  }

  useEffect(() => {
    if (!orderBy) return

    setRequestOptions({
      ...requestOptions,
      orderBy: '_' + orderBy,
      sort: isDesc ? 'DESC' : 'ASC',
      offset: 0
    })
  }, [isDesc, orderBy])

  const getPreviousPage = () => {
    setPage(page - 1)
  }

  const getNextPage = () => {
    if (page * rowsPerPage >= insightResults.length) {
      setPage(page + 1)
      setRequestOptions({
        ...requestOptions,
        offset: insightResults.length
      })
    } else {
      setPage(page + 1)
    }
  }

  const parseDateAnswer = answer => {
    const date = /^[0-9]*$/.test(answer) ? parseInt(answer) : answer
    return format(parse(date), 'YYYY-MM-DD')
  }

  const millisecondsToMinutesAndSeconds = milliseconds => {
    const minutes = Math.floor(milliseconds / 60000)
    const seconds = ((milliseconds % 60000) / 1000).toFixed(0)
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
  }

  const onSearch = ({ search }) => {
    setRequestOptions({
      ...requestOptions,
      searchTerm: search,
      offset: 0
    })
    setPage(1)
  }

  const queries = pipe(
    map(({ query: { dataSources, objectType } }) => {
      return dataSources.map(dataSource => ({
        label: objectType.name,
        value: `insights_` + objectType.id
      }))
    }),
    flatten
  )(opportunity.inputDataSources)

  const selectOptions = [
    opportunity.survey
      ? {
          label: 'Survey Answers',
          value: `survey_answers_${opportunity.id}`
        }
      : '',
    ...queries
  ].filter(Boolean)

  const renderTable = () => {
    if (loading)
      return (
        <Box size="xxxxxl">
          <Spacing size="l" align="center">
            <Graphic name="Spinner" width={150} height={70} themeColor="main" />
            <Text color="grey">Loading...</Text>
          </Spacing>
        </Box>
      )

    if (isEmpty(insightResults)) {
      if (opportunity.state === 'published') {
        return (
          <Box size="xxxxxl">
            <Spacing size="l" align="center">
              <Graphic
                name="Loading"
                width={150}
                height={70}
                themeColor="main"
              />
              <Text color="grey">
                We're currently collecting the data for this study, come back in
                a bit!
              </Text>
            </Spacing>
          </Box>
        )
      }

      return <Notice>No results.</Notice>
    }

    if (error) {
      return <Notice>We had an unexpected error</Notice>
    }

    const isDateRow = equals(dateColumnIndex)
    const isDuration = equals(durationColumnIndex)

    const formatRow = (row, index) => {
      if (isDateRow(index)) {
        return parseDateAnswer(row)
      }

      if (isDuration(index)) {
        return millisecondsToMinutesAndSeconds(row)
      }

      if (is(Boolean, row)) {
        return row ? 'True' : 'False'
      }

      return row
    }

    return (
      <TableWrap>
        <Table
          showPagination={insightResults.length ? true : false}
          page={page}
          numberOfPages={Math.ceil(total / rowsPerPage)}
          previousPage={getPreviousPage}
          nextPage={getNextPage}
          horizontalScroll={true}
          tableHead={keys(insightResults[0]).map((column, n) => (
            <StyledTableHeader
              key={`${column}-${n}`}
              onClick={() => {
                setSortProps(column)
              }}
            >
              <Spacing
                size="xxs"
                direction="row"
                justify="space-between"
                align="center"
                stretched
              >
                <Text t3 color="dark">
                  {column}
                </Text>

                {orderBy === column ? (
                  <ToggleChevron
                    toggled={!isDesc}
                    onToggle={() => toggleIsDesc()}
                    small
                  />
                ) : (
                  <EmptyToggle />
                )}
              </Spacing>
            </StyledTableHeader>
          ))}
        >
          {insightResults
            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
            .map((result, index) => {
              return (
                <tr key={index}>
                  {keys(insightResults[0]).map((column, n) => (
                    <td key={n}>
                      <Text t3>{formatRow(result[column], n)}</Text>
                    </td>
                  ))}
                </tr>
              )
            })}
        </Table>
      </TableWrap>
    )
  }

  if (isEmpty(selectOptions)) {
    return <Notice>No data is available at the moment</Notice>
  }

  return (
    <Spacing size="l">
      <Form
        onSubmit={onSearch}
        initialValues={{
          dataSource: selectOptions[0].value,
          search: shortId
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Spacing justify="space-between" direction="row" stretched>
              <DataSourceWrap>
                <Field
                  name="dataSource"
                  component={DataSourceSelect}
                  filterByDataSource={filterByDataSource}
                  selectOptions={uniqBy(prop('value'), selectOptions)} // remove uniq once we handle the same tableName issue
                />
              </DataSourceWrap>
              <Search
                name="search"
                label="Search text"
                onSearch={handleSubmit}
              />
            </Spacing>
          </form>
        )}
      </Form>

      {renderTable()}
    </Spacing>
  )
}
