import { pick, without, split, match, head, pipe, last, omit } from 'ramda'

export const onRemoveCleanUpDataSourceId = onRemoveQueriesFormField => () => {
  onRemoveQueriesFormField()
}

export const onRemoveCleanUpFieldAnd = (
  parentInput,
  input,
  onRemoveSentenceWherePiece,
  onRemoveWhereRow,
  onUpdateFormField
) => ({
  invalidateObjectType = false,
  invalidateWhereRowFromCurrent = false,
  invalidateWhereRow = false,
  invalidateWhereAll = false
}) => {
  const fieldIndex = getFieldIndexFromName(input.name)

  // if first element in where last(and only) row
  if (
    invalidateObjectType &&
    (fieldIndex === 0 && parentInput.value.where.length === 1)
  ) {
    const otherFieldsParent = omit(['objectTypeId'], parentInput.value)
    parentInput.onChange({ ...otherFieldsParent }) // remove objectTypeId from parent
  }

  // remove all other values in row
  invalidateWhereRowFromCurrent && onUpdateFormField(fieldIndex, {})

  invalidateWhereRow && onRemoveWhereRow()
  // if last element in array, remove where
  if (
    invalidateWhereAll &&
    (fieldIndex === 0 && parentInput.value.where.length === 1)
  ) {
    const prevFields = pick(['dataSourceIds'], parentInput.value)
    parentInput.onChange({ ...prevFields, where: null })
    onRemoveSentenceWherePiece()
  }
}

export const onRemoveCleanUpComparisonAnd = (input, onUpdateFormField) => ({
  invalidateWhereRowFromCurrent = false
}) => {
  const fieldIndex = getFieldIndexFromName(input.name)

  // remove all other values in row
  invalidateWhereRowFromCurrent &&
    onUpdateFormField(fieldIndex, {
      condition: pick(['field'], input.value.condition)
    })
}

export const onRemoveCleanUpValueAnd = (input, onUpdateFormField) => ({
  invalidateWhereRowFromCurrent = false
}) => {
  const fieldIndex = getFieldIndexFromName(input.name)

  // remove all other values in row
  invalidateWhereRowFromCurrent &&
    onUpdateFormField(fieldIndex, {
      condition: pick(['field', 'comparison'], input.value.condition)
    })
}

export const onCompleteCleanUpConjunctionAnd = (
  parentInput,
  input,
  onCompleteWhereAndCondition,
  { totalConditions, currentContitionIndex }
) => value => {
  // if not last
  if (currentContitionIndex !== totalConditions) return

  if (value === 'AND') return onCompleteWhereAndCondition() // add another condition

  // extract this condition and wrap it with where
  const transformatedQuery = {
    ...parentInput.value,
    where: [
      ...without([input.value], parentInput.value.where),
      {
        groups: [
          {
            ...input.value,
            conjunction: 'OR'
          },
          {}
        ]
      }
    ]
  }
  parentInput.onChange(transformatedQuery)
}

export const onRemoveCleanUpFieldOr = (
  parentInput,
  input,
  onRemoveSentenceWherePiece,
  onRemoveWhereRow,
  onUpdateFormField,
  currentGroup
) => ({
  invalidateObjectType = false,
  invalidateWhereRowFromCurrent = false,
  invalidateWhereRow = false,
  invalidateWhereAll = false
}) => {
  const fieldIndex = getFieldIndexFromName(input.name)

  // if first element in where last(and only) row
  if (
    invalidateObjectType &&
    fieldIndex === 0 &&
    parentInput.value.where.length === 1 &&
    currentGroup.groups.length === 1
  ) {
    const otherFieldsParent = omit(['objectTypeId'], parentInput.value)

    parentInput.onChange({ ...otherFieldsParent }) // remove objectTypeId from parent
  }
  // remove all other values in row
  invalidateWhereRowFromCurrent && onUpdateFormField(fieldIndex, {})

  invalidateWhereRow && onRemoveWhereRow()

  if (
    invalidateWhereAll &&
    (fieldIndex === 0 &&
      parentInput.value.where.length === 1 &&
      currentGroup.groups.length === 1)
  ) {
    const prevFields = pick(['dataSourceIds'], parentInput.value)
    parentInput.onChange({ ...prevFields, where: null })
    onRemoveSentenceWherePiece()
  }
}

export const onRemoveCleanUpComparisonOr = (input, onUpdateFormField) => ({
  invalidateWhereRowFromCurrent = false
}) => {
  const fieldIndex = getFieldIndexFromName(input.name)

  // remove all other values in row
  invalidateWhereRowFromCurrent &&
    onUpdateFormField(fieldIndex, {
      condition: pick(['field'], input.value.condition)
    })
}

export const onRemoveCleanUpValueOr = (input, onUpdateFormField) => ({
  invalidateWhereRowFromCurrent = false
}) => {
  const fieldIndex = getFieldIndexFromName(input.name)

  // remove all other values in row
  invalidateWhereRowFromCurrent &&
    onUpdateFormField(fieldIndex, {
      condition: pick(['field', 'comparison'], input.value.condition)
    })
}

export const onCompleteCleanUpConjunctionOr = (
  onCompleteWhereOrCondition,
  onCompleteWhereAndCondition,
  { totalConditions, currentContitionIndex }
) => value => {
  // if not last
  if (currentContitionIndex !== totalConditions) return

  if (value === 'OR') return onCompleteWhereOrCondition() // add another condition to groups

  return onCompleteWhereAndCondition()
}

export const getFieldIndexFromName = pipe(
  split('.'),
  last,
  match(/\d+/gi),
  head,
  i => parseInt(i, 10)
)
