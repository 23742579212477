import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { pipe } from 'ramda'
import DropIn from 'braintree-web-drop-in-react'
import { Form } from 'react-final-form'

import { Modal, Button, Spacing, SubmitError } from '../../components/ui'
import { withMutation } from '../../components/wrappers'

const ModalWrap = styled.div`
  height: 400px;
  position: relative;
`

const ConnectBraintreeView = ({
  onDismiss,
  generateBraintreeClientToken,
  createBraintreePaymentToken
}) => {
  const [clientToken, setClientToken] = useState(null)
  const [dropinInstance, setDropinInstance] = useState(null)

  useEffect(() => {
    generateBraintreeClientToken().then(({ errors, userErrors, data }) => {
      if (!userErrors && !errors && data && data.generateBraintreeClientToken) {
        setClientToken(data.generateBraintreeClientToken.clientToken)
      }

      return errors || userErrors
    })
  }, [])

  if (!clientToken) return null

  const onSubmit = onDismiss => async () => {
    const { nonce } = await dropinInstance.requestPaymentMethod()

    return createBraintreePaymentToken({ variables: { nonce } }).then(
      ({ errors, userErrors, data }) => {
        if (
          !userErrors &&
          !errors &&
          data &&
          data.createBraintreePaymentToken
        ) {
          onDismiss()
          return
        }

        return errors || userErrors
      }
    )
  }

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title="Connect Braintree"
    >
      {({ onDismiss }) => (
        <ModalWrap>
          <Form
            onSubmit={onSubmit(onDismiss)}
            render={({ handleSubmit, submitError, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Spacing size="xl">
                  {submitError && <SubmitError submitError={submitError} />}
                  <DropIn
                    options={{
                      authorization: clientToken
                    }}
                    onInstance={instance => setDropinInstance(instance)}
                  />
                  <div>
                    <Spacing direction="row" stretchChildren>
                      <Button
                        outline
                        block
                        disabled={submitting}
                        onClick={onDismiss}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        block
                        onClick={handleSubmit}
                        disabled={submitting}
                      >
                        Add Card
                      </Button>
                    </Spacing>
                  </div>
                </Spacing>
              </form>
            )}
          />
        </ModalWrap>
      )}
    </Modal>
  )
}

export const ConnectBraintree = pipe(
  withMutation('generateBraintreeClientToken'),
  withMutation('createBraintreePaymentToken')
)(ConnectBraintreeView)
