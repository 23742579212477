import React from 'react'

import { Text, Spacing } from '../../../../ui'
import { AdCard } from './AdCard'
import { isNil } from 'ramda'

export const AdPreview = ({ opportunity }) => {
  if (isNil(opportunity.ad)) return null

  return (
    <Spacing size="s">
      <Text h6>Ad preview</Text>
      <AdCard opportunity={opportunity} />
    </Spacing>
  )
}
