import { StudiesQueryVariables } from '../../../state/queries'

export const COMPANY_STUDIES_FILTERS = {
  'Active studies': StudiesQueryVariables.active,
  Drafts: StudiesQueryVariables.draft,
  'All studies': StudiesQueryVariables.allStudyStates
}

export const ADMIN_STUDIES_FILTERS = {
  'Active studies': StudiesQueryVariables.active,
  'In review studies': StudiesQueryVariables.inReview,
  'All studies': StudiesQueryVariables.allStudyStates
}

export const INITIAL_STUDIES_FILTERS = {
  state: 'Active studies',
  sort: 'desc',
  categories: ['Study']
}

export const CREATE_STUDY_LINK = '/studies/create'

export const CREATE_STUDY_TEXT = 'New Study'

export const STUDIES_SIDEBAR_TEXT = 'Studies'
