import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import { pipe, isNil } from 'ramda'
import { Form } from 'react-final-form'

import DropIn from 'braintree-web-drop-in-react'

import {
  Graphic,
  Text,
  Button,
  Spacing,
  InlineModal,
  SubmitError,
  ContainerSpinner
} from '../../../components/ui'
import { withMutation } from '../../../components/wrappers'
import { RenderFromQuery } from '../../../components/util'
import { SignUpCompanyQuery } from '../../../state/queries'
import { VerticalFade } from '../components/AnimatedWizardItems'

const InlineModalWrapper = styled.div`
  margin-right: ${({ theme }) => theme.padding.xxxxl};
`

export const StepCard = ({ onNextStep }) => (
  <Spacing size="xxxxl">
    <Graphic name="Logo" png size={80} />
    <Spacing size="xl">
      <Text h0>Sign Up</Text>
      <Text t1>
        You can add your payment method now, or first explore the Portal and
        then decide
      </Text>
      <InlineModalWrapper>
        <Spacing size="l">
          <InlineModalAddCard onNextStep={onNextStep} />
          <InlineModal title="Add Crypto Wallet" disabled />
        </Spacing>
      </InlineModalWrapper>
      <VerticalFade>
        <Button onClick={onNextStep} transparent themeColor="grey">
          Skip for now
        </Button>
      </VerticalFade>
    </Spacing>
  </Spacing>
)

export const InlineModalAddCard = props => (
  <RenderFromQuery
    query={SignUpCompanyQuery}
    renderLoading={() => <ContainerSpinner />}
    renderData={({ currentUser: { company } }) =>
      !isNil(company.paymentMethod) ? (
        <InlineModal title="Card successfully connected" disabled />
      ) : (
        <InlineModal title="Add credit card">
          {({ onCollapse }) => <CardForm {...props} onCollapse={onCollapse} />}
        </InlineModal>
      )
    }
  />
)

const CardFormInner = ({
  onCollapse,
  onNextStep,
  generateBraintreeClientToken,
  createBraintreePaymentToken
}) => {
  const [clientToken, setClientToken] = useState(null)
  const dropinInstance = useRef(null)

  useEffect(() => {
    generateBraintreeClientToken().then(({ errors, userErrors, data }) => {
      if (!userErrors && !errors && data && data.generateBraintreeClientToken) {
        setClientToken(data.generateBraintreeClientToken.clientToken)
      }

      return errors || userErrors
    })
  }, [])

  if (!clientToken) return null

  const onSubmit = async () => {
    const { nonce } = await dropinInstance.current.requestPaymentMethod()

    return createBraintreePaymentToken({ variables: { nonce } }).then(
      ({ errors, userErrors, data }) => {
        if (
          !userErrors &&
          !errors &&
          data &&
          data.createBraintreePaymentToken
        ) {
          onNextStep()
          return
        }

        return errors || userErrors
      }
    )
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitError, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Spacing size="xl">
            {submitError && <SubmitError submitError={submitError} />}
            <DropIn
              options={{
                authorization: clientToken
              }}
              onInstance={instance => (dropinInstance.current = instance)}
            />
            <div>
              <Spacing direction="row" stretchChildren>
                <Button
                  outline
                  block
                  onClick={onCollapse}
                  disabled={submitting}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  block
                  onClick={handleSubmit}
                  disabled={submitting}
                >
                  Add Card
                </Button>
              </Spacing>
            </div>
          </Spacing>
        </form>
      )}
    />
  )
}

export const CardForm = pipe(
  withMutation('generateBraintreeClientToken'),
  withMutation('createBraintreePaymentToken')
)(CardFormInner)
