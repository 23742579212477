import gql from 'graphql-tag'

export const getTableauToken = gql`
  mutation GetTableauToken {
    getTableauToken {
      token
      errors {
        key
        message
      }
    }
  }
`
