import React from 'react'
import { withRouter } from 'react-router-dom'

import { Button } from '../../ui'

const StudyContinueInner = ({ opportunity, history }) => {
  const onStudyContinue = () => {
    return history.push('/studies/followup/' + opportunity.id)
  }

  return <Button onClick={onStudyContinue}>Create a Follow-up Study</Button>
}

export const StudyContinue = withRouter(StudyContinueInner)
