import React from 'react'
import styled from 'styled-components/macro'
import { useField } from 'react-final-form'
import { values, pathOr } from 'ramda'
import { useFieldArray } from 'react-final-form-arrays'

import { InlinePicker, InlineTypeahead } from '../../../ui'
import { QueryBuilderValue } from '../'
import { useSentence } from '../../hooks'
import { buildHelpers } from '../../utils/buildHelper'
import {
  onRemoveCleanUpFieldAnd,
  onRemoveCleanUpComparisonAnd,
  onRemoveCleanUpValueAnd,
  onCompleteCleanUpConjunctionAnd
} from '../../utils/sentenceHelper'

const Wrap = styled.div`
  padding: ${({ theme }) => theme.padding.s} 0px;
`

export const QueryBuilderConditionAnd = ({
  input,
  parentInput,
  onCompleteWhereAndCondition,
  onComplete: onCompleteSenteceWherePiece,
  onRemoveWhereRow,
  onRemove: onRemoveSentenceWherePiece,
  onTerminate: onTerminateSenteceWherePiece,
  onUpdateFormField,
  dataSchema,
  dataTypesSchema,
  conditionInfo,
  querySentenceProps
}) => {
  const { name } = input
  const selectedObjectTypeId = pathOr(
    null,
    ['value', 'objectTypeId'],
    parentInput
  )
  const selectedDataSourceId = pathOr(
    null,
    ['value', 'dataSourceIds'],
    parentInput
  )

  const selectedFieldId = pathOr(null, ['value', 'condition', 'field'], input)
  const selectedComparisonId = pathOr(
    null,
    ['value', 'condition', 'comparison'],
    input
  )

  const {
    getFieldOptions,
    getComparisonOptions,
    getInputOptions
  } = buildHelpers(dataSchema, dataTypesSchema)

  const fieldOptions = getFieldOptions(
    selectedObjectTypeId,
    selectedDataSourceId
  )

  const comparisonOptions = getComparisonOptions(
    selectedObjectTypeId,
    selectedFieldId
  )

  const inputOptions = getInputOptions(
    selectedObjectTypeId,
    selectedFieldId,
    selectedComparisonId
  )

  const pieceDefinitions = {
    [`${name}.field`]: {
      field: useField(`${name}.condition.field`),
      render: ({ field, ...sentenceProps }) => (
        <InlinePicker
          options={fieldOptions}
          type="secondary"
          onCompleteCleanUp={objectTypeId =>
            parentInput.onChange({ ...parentInput.value, objectTypeId })
          }
          onRemoveCleanUp={onRemoveCleanUpFieldAnd(
            parentInput,
            input,
            onRemoveSentenceWherePiece,
            onRemoveWhereRow,
            onUpdateFormField
          )}
          {...field}
          {...sentenceProps}
        />
      )
    },
    [`${name}.comparison`]: {
      field: useField(`${name}.condition.comparison`),
      render: ({ field, ...sentenceProps }) => (
        <InlinePicker
          type="transparent"
          options={comparisonOptions}
          onRemoveCleanUp={onRemoveCleanUpComparisonAnd(
            input,
            onUpdateFormField
          )}
          {...field}
          {...sentenceProps}
        />
      )
    },
    [`${name}.value`]: {
      field: useFieldArray(`${name}.condition.value`),
      render: sentenceProps => (
        <QueryBuilderValue
          name={`${name}.condition.value`}
          key={`${name}.condition.value`}
          onRemoveCleanUp={onRemoveCleanUpValueAnd(input, onUpdateFormField)}
          inputOptions={inputOptions}
          {...sentenceProps}
        />
      )
    },
    [`${name}.conjunction`]: {
      field: useField(`${name}.conjunction`),
      render: ({ field, ...sentenceProps }) => (
        <InlineTypeahead
          onTerminateCleanUp={onCompleteSenteceWherePiece}
          onTerminateParent={onTerminateSenteceWherePiece}
          onCompleteCleanUp={onCompleteCleanUpConjunctionAnd(
            parentInput,
            input,
            onCompleteWhereAndCondition,
            conditionInfo
          )}
          options={[
            { value: '.', label: '.', terminate: true },
            { value: 'AND', label: 'AND' },
            { value: 'OR', label: 'OR' }
          ]}
          {...field}
          {...sentenceProps}
        />
      )
    }
  }

  const sentence = useSentence({
    sentenceOrder: [
      `${name}.field`,
      `${name}.comparison`,
      `${name}.value`,
      `${name}.conjunction`
    ],
    pieceDefinitions,
    querySentenceProps
  })

  return (
    <Wrap>
      {sentence.map(piece =>
        pieceDefinitions[piece.id].render({
          ...piece,
          field: pieceDefinitions[piece.id].field,
          fields: values(pieceDefinitions).reduce(
            (acc, { id, field }) => ({ ...acc, [id]: field }),
            {}
          )
        })
      )}
    </Wrap>
  )
}
