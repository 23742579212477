import React from 'react'
import styled from 'styled-components/macro'

import { Modal, Spacing, Text, Button } from '../../components/ui'

const ModalWrap = styled.div`
  width: 650px;
  position: relative;
`

export const Confirmation = ({ onDismiss, params = {} }) => {
  const { title, text, onConfirm } = params

  const onConfirmation = () => {
    onConfirm()
    onDismiss()
  }

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title={title}
    >
      {({ onDismiss }) => (
        <ModalWrap>
          <Text t2>{text}</Text>
          <Modal.Footer>
            <Spacing direction="row" justify="flex-end" align="center">
              <Button outline onClick={onDismiss}>
                Cancel
              </Button>
              <Button onClick={onConfirmation}>Confirm</Button>
            </Spacing>
          </Modal.Footer>
        </ModalWrap>
      )}
    </Modal>
  )
}
