import React from 'react'
import { Button } from '../../../ui'
import { withRouter } from 'react-router-dom'
import { DropdownItem } from './DropdownItem'

export const ContinueDraftButtonInner = ({ id, dropdownItem, history }) => {
  const url = `/studies/builder/${id}`
  const text = 'Continue Building'

  const clickHandler = () => history.push(url)

  if (dropdownItem) {
    return <DropdownItem text={text} onClick={clickHandler} />
  }
  return <Button to={url}>{text}</Button>
}

export const ContinueDraftButton = withRouter(ContinueDraftButtonInner)
