import React from 'react'
import { useField } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import { pipe, propOr, isNil, complement } from 'ramda'
import styled from 'styled-components/macro'

import { InlinePicker, InlineTypeahead } from '../../../ui'
import { QueryBuilderText } from '../QueryBuilderText'
import { useSentence } from '../../hooks'
import { QueryBuilderWhere } from '../../components'
import { buildHelpers } from '../../utils/buildHelper'
import { onRemoveCleanUpDataSourceId } from '../../utils/sentenceHelper'
import { useEffect } from 'react'

const Wrap = styled.div``

const SeparatorWrap = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => `${theme.padding.m} 0px`};
`

const HorizontalLine = styled.div`
  width: calc(100% - 80px);
  border-bottom: 1px solid ${({ theme }) => theme.color.greyL2};
  margin: auto;
`

const WhereWrap = styled.div`
  display: flex;
  flex-direction: row;
`

const WhereWrapVerticalLeft = styled.div`
  width: 6px;
  border-left: 1px solid ${({ theme }) => theme.color.greyL1};
  border-top: 1px solid ${({ theme }) => theme.color.greyL1};
  border-bottom: 1px solid ${({ theme }) => theme.color.greyL1};
`

const WhereWrapVerticalRight = styled.div`
  width: 6px;
  border-right: 1px solid ${({ theme }) => theme.color.greyL1};
  border-top: 1px solid ${({ theme }) => theme.color.greyL1};
  border-bottom: 1px solid ${({ theme }) => theme.color.greyL1};
`

export const QueryBuilderSource = ({
  dataSchema,
  dataTypesSchema,
  input,
  onRemoveQueriesFormField,
  onCompleteQuery,
  queriesInfo: { totalQueries, currentQueryIndex },
  querySentenceProps,
  singleQuery,
  initialSelectFieldValue
}) => {
  const { name, value } = input

  const { getSourceOptions } = buildHelpers(dataSchema, dataTypesSchema)

  const sourceOptions = getSourceOptions()

  const isIntroAnimationDisabled = pipe(
    propOr(null, 'dataSourceIds'),
    complement(isNil)
  )(value)

  const selectField = useField(`${name}.select`)
  useEffect(() => {
    selectField.input.onChange(initialSelectFieldValue)
  }, [])

  const pieceDefinitions = {
    [`${name}.intro`]: {
      permanent: true,
      render: ({ key, ref, ...sentenceProps }) => (
        <QueryBuilderText
          key={key}
          disabled={isIntroAnimationDisabled}
          {...sentenceProps}
        >
          {!singleQuery ? 'I want audience that has' : 'Filter data by:'}
        </QueryBuilderText>
      )
    },
    [`${name}.dataSourceIds`]: {
      field: useField(`${name}.dataSourceIds`),
      render: ({ field, ...sentenceProps }) => (
        <InlinePicker
          {...field}
          {...sentenceProps}
          onRemoveCleanUp={onRemoveCleanUpDataSourceId(
            onRemoveQueriesFormField
          )}
          key={`${name}.dataSourceIds`}
          options={sourceOptions}
        />
      )
    },

    [`${name}.where`]: {
      field: useFieldArray(`${name}.where`),
      render: ({ ref, ...sentenceProps }) => {
        if (sentenceProps.state === 'skipped') return null

        return (
          <>
            <QueryBuilderText>where</QueryBuilderText>
            <WhereWrap key={`${name}.where`}>
              <WhereWrapVerticalLeft />
              <div style={{ flex: 1 }}>
                <QueryBuilderWhere
                  parentInput={input}
                  dataSchema={dataSchema}
                  dataTypesSchema={dataTypesSchema}
                  {...sentenceProps}
                  querySentenceProps={querySentenceProps}
                  onRemoveQueriesFormField={onRemoveQueriesFormField}
                />
              </div>
              <WhereWrapVerticalRight />
            </WhereWrap>
          </>
        )
      }
    },
    [`${name}.conjuctionQ`]: {
      permanent: true,
      field: useField(`${name}.conjuctionQ`, {
        initialValue: currentQueryIndex < totalQueries && 'AND'
      }),
      render: ({ field, key, onTerminate, onContinue, ...sentenceProps }) => (
        <SeparatorWrap key={key}>
          <InlineTypeahead
            {...field}
            {...sentenceProps}
            onCompleteCleanUp={() => {
              // prevent adding new query except from last one
              if (currentQueryIndex === totalQueries) onCompleteQuery()
            }}
            options={[{ value: 'AND', label: 'AND' }]}
          />
          <HorizontalLine />
        </SeparatorWrap>
      )
    }
  }

  const sentenceOrder = [
    `${name}.intro`,
    `${name}.dataSourceIds`,
    `${name}.where`
  ]

  if (!singleQuery) sentenceOrder.push(`${name}.conjuctionQ`)

  const sentence = useSentence({
    sentenceOrder,
    pieceDefinitions,
    querySentenceProps
  })

  return (
    <Wrap>
      {sentence.map(piece =>
        pieceDefinitions[piece.id].render({
          ...piece,
          field: pieceDefinitions[piece.id].field
        })
      )}
    </Wrap>
  )
}
