import React from 'react'
import { Tag } from '../../ui'
import { OpportunityConstants } from '../../../constants'

export const StudyStateTag = ({ state }) => (
  <Tag
    selected
    label={OpportunityConstants.states[state].label}
    color={OpportunityConstants.states[state].color}
  />
)
