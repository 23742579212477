import React, { useState, useEffect } from 'react'

import styled from 'styled-components/macro'
import {
  Box,
  Graphic,
  Spacing,
  Text,
  Button,
  SpacingItem
} from '../../../../components/ui'
import { RenderFromQuery } from '../../../../components/util'
import { OpportunityDetailsQuery } from '../../../../state/queries'
import { TickCheckbox } from '../../../../components/blocks/AudienceGroupPicker/components'
import { StudyAudience } from '../Audience'
import { AudienceBucketSummarySimple } from '../../../../components/blocks/StudyDetails/components/AudienceBucketsSummary/components'

const StyledGroupBox = styled(Box)`
  overflow: hidden;
  cursor: pointer;
  border: 2px solid
    ${({ checked, theme }) =>
      checked ? theme.color.main : theme.color.purpleL3};
`

export const StudyFollowupAudience = ({
  opportunity,
  opportunity: { dependsOn, targetAudienceBuckets }
}) => {
  const [checked, setChecked] = useState(true)

  const [showStudyAudience, setShowStudyAudience] = useState(
    targetAudienceBuckets.length ? true : false
  )
  const [dependsOnOpportunity, setDependsOnOpportunity] = useState()
  useEffect(() => {
    setDependsOnOpportunity(dependsOn[0])
  }, [])

  const onCheckSection = () => {
    opportunity.dependsOn = !checked ? [dependsOnOpportunity] : []
    setChecked(!checked)
  }

  return dependsOnOpportunity ? (
    <Spacing size="l">
      <RenderFromQuery
        query={OpportunityDetailsQuery}
        variables={{ id: dependsOnOpportunity }}
        renderData={({ opportunity: { name, targetAudienceBuckets } }) => (
          <StyledGroupBox
            checked={checked}
            size="xl"
            onClick={() => onCheckSection()}
          >
            <Spacing direction="row">
              <Graphic name="Friends" width={33} themeColor="main" />
              <SpacingItem flex="0 1 95.5%">
                <Spacing size="l">
                  <Spacing
                    direction="row"
                    justify="space-between"
                    align="center"
                  >
                    <Spacing direction="row" wrap size="xs">
                      <Text h4>Follow-up audience from</Text>
                      <Text h4 color="main">
                        {name}
                      </Text>
                    </Spacing>
                    <TickCheckbox checked={checked} />
                  </Spacing>
                  <AudienceSummary
                    name={name}
                    targetAudienceBuckets={targetAudienceBuckets}
                  />
                </Spacing>
              </SpacingItem>
            </Spacing>
          </StyledGroupBox>
        )}
      />
      {showStudyAudience ? (
        <StudyAudience />
      ) : (
        <Button outline block onClick={() => setShowStudyAudience(true)}>
          Narrow down audience
        </Button>
      )}
    </Spacing>
  ) : null
}

const AudienceSummary = ({ targetAudienceBuckets }) => (
  <Spacing size="l">
    {targetAudienceBuckets.map(bucket => (
      <AudienceBucketSummarySimple key={bucket.id} audienceBucket={bucket} />
    ))}
  </Spacing>
)
