import React from 'react'
import { withMutation } from '../../../wrappers'
import { Button } from '../../../ui'
import { DropdownItem } from './DropdownItem'
import { StudiesRefetchQueries } from '../../../../state/queries'

const ApproveButtonInner = ({ id, approveOpportunity, dropdownItem }) => {
  const text = 'Approve'

  const clickHandler = () => {
    if (window.confirm('Are you sure?')) {
      approveOpportunity({
        variables: { id },
        refetchQueries: StudiesRefetchQueries
      })
    }
  }

  if (dropdownItem) {
    return <DropdownItem text={text} onClick={clickHandler} />
  }
  return (
    <Button onClick={clickHandler} outline>
      {text}
    </Button>
  )
}

export const ApproveButton = withMutation('approveOpportunity', {
  showErrorNotifications: true
})(ApproveButtonInner)
