import gql from 'graphql-tag'

export const favoriteOpportunity = gql`
  mutation FavoriteOpportunity($id: String!, $favorite: Boolean!) {
    favoriteOpportunity(id: $id, favorite: $favorite) {
      opportunity {
        id
        favorite
      }
      errors {
        key
        message
      }
    }
  }
`
