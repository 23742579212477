import { toLower } from 'ramda'

export const useTypeahead = ({
  options,
  filterSuggestionBy,
  typed = '',
  getSuggestionLabel = i => i
}) => {
  const getSuggestions = () =>
    options
      .filter(option =>
        toLower(getSuggestionLabel(option)).includes(
          toLower(filterSuggestionBy || typed)
        )
      )
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label)
      })

  const suggestions = getSuggestions(typed)

  const getTypeaheadSuggestion = () => {
    if (!typed || !suggestions.length) return

    const suggestionsStartingWith = suggestions.filter(suggestion =>
      toLower(getSuggestionLabel(suggestion)).startsWith(toLower(typed))
    )

    const [firstSuggestion] = suggestionsStartingWith
    if (!firstSuggestion) return

    return {
      ...firstSuggestion,
      cutoff: firstSuggestion.label.substr(typed.length)
    }
  }

  return {
    suggestions,
    typeaheadSuggestion: getTypeaheadSuggestion()
  }
}
