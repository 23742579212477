import gql from 'graphql-tag'

import { UserFragment } from '../fragments'

export const updateUser = gql`
  mutation UpdateUser(
    $username: String
    $oldPassword: String
    $newPassword: String
    $phoneNumber: String
    $email: String
    $fullName: String
    $country: String
    $notificationsEnabled: Boolean
    $tosAccepted: Boolean
  ) {
    updateUser(
      username: $username
      oldPassword: $oldPassword
      newPassword: $newPassword
      phoneNumber: $phoneNumber
      email: $email
      fullName: $fullName
      country: $country
      notificationsEnabled: $notificationsEnabled
      tosAccepted: $tosAccepted
    ) {
      currentUser {
        id
        ...User
      }
      errors {
        key
        message
      }
    }
  }
  ${UserFragment}
`
