import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Text, Spacing, SpacingItem } from '../ui'
import { RenderFromQuery } from '../util'
import { CurrentCompanyQuery } from '../../state/queries'
import { useLocalStorageState } from 'react-storage-hooks'
import { withMutation } from '../wrappers'

const HeaderWrap = styled.div`
  background-color: ${({ verified, theme }) =>
    verified ? theme.color.green : theme.color.red};
  padding: ${({ theme }) => theme.padding.s};
  justify-content: center;
`

const ClickableText = styled(Text)`
  padding-right: ${({ theme }) => theme.padding.l};
  &:hover {
    cursor: pointer;
  }
`

const DismissableVerificationBannerInner = ({ sendVerificationEmail }) => {
  const [isBannerDismissed, setIsBannerDismissed] = useLocalStorageState(
    'VerificationBanner',
    false
  )

  const [emailSent, setEmailSent] = useState(false)

  const resendVerificationCode = () => {
    setEmailSent(true)
    sendVerificationEmail()
  }

  const renderBannerContent = ({ verified, email }) => {
    if (verified && !isBannerDismissed) {
      return (
        <Spacing direction="row" justify="space-between" stretched>
          <SpacingItem flex="1 0 70%">
            <Text center h6 color="white">
              Your email {email} was successfully verified!
            </Text>
          </SpacingItem>
          <ClickableText
            h6
            color="white"
            onClick={() => setIsBannerDismissed(true)}
          >
            Dismiss
          </ClickableText>
        </Spacing>
      )
    }
    if (!verified) {
      return (
        <>
          {emailSent ? (
            <Spacing direction="row" size="xs" align="center" justify="center">
              <Text center h6 color="white">
                We've sent a code to {email}. Please check your inbox.
              </Text>
            </Spacing>
          ) : (
            <ClickableText
              center
              h6
              color="white"
              onClick={() => resendVerificationCode()}
            >
              You need to verify your email. In case you didn't get the code,
              you can resend it by clicking here
            </ClickableText>
          )}
        </>
      )
    }
  }

  return (
    <RenderFromQuery
      query={CurrentCompanyQuery}
      renderError={() => null}
      renderData={({ currentUser }) => (
        <>
          {!isBannerDismissed && (
            <HeaderWrap verified={currentUser.verified}>
              {renderBannerContent(currentUser)}
            </HeaderWrap>
          )}
        </>
      )}
    />
  )
}

export const DismissableVerificationBanner = withMutation(
  'sendVerificationEmail'
)(DismissableVerificationBannerInner)
