import React from 'react'
import { pipe } from 'ramda'
import { withRouter } from 'react-router-dom'
import { withMutation } from '../../../wrappers'
import { Button } from '../../../ui'
import { DropdownItem } from './DropdownItem'
import { StudiesRefetchQueries } from '../../../../state/queries'

const DeleteDraftInner = ({ id, deleteOpportunity, history, dropdownItem }) => {
  const text = 'Delete Draft'
  const clickHandler = () => {
    if (window.confirm('Are you sure?')) {
      deleteOpportunity({
        variables: { id },
        refetchQueries: StudiesRefetchQueries
      }).then(() => {
        return history.push('/studies')
      })
    }
  }

  if (dropdownItem) {
    return <DropdownItem text={text} onClick={clickHandler} />
  }
  return (
    <Button onClick={clickHandler} outline>
      {text}
    </Button>
  )
}

export const DeleteDraftButton = pipe(
  withRouter,
  withMutation('deleteOpportunity', {
    showErrorNotifications: true
  })
)(DeleteDraftInner)
