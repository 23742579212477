import React from 'react'
import styled from 'styled-components'

import { Text, Spacing } from '../../ui/'

import {
  AudienceBucketsSummary,
  QuestionsSummary,
  SurveySummary,
  AdSummary,
  AdPreview
} from './components'

import {
  isCompletedAudienceStep,
  isCompletedDataSourcesStep,
  isCompletedSurveyStep,
  isCompletedAdStep
} from '../../../util/validators'
import { isNil, isEmpty } from 'ramda'
import { AudienceBucketSummarySimple } from './components/AudienceBucketsSummary/components'

const AdPreviewWrapper = styled.div`
  max-width: 360px;
`

const RequestedQueryDescription = ({ opportunity }) => {
  if (isNil(opportunity.requestedQueryDescription)) return null

  return (
    <Spacing>
      <Text h6>Requested query description</Text>
      <Text t3>{opportunity.requestedQueryDescription}</Text>
    </Spacing>
  )
}

// this component is used in StudySummary screen and StudyBuilder screen Summary section
export const StudyDetails = ({
  opportunity,
  sidebar,
  opportunity: { ad, name }
}) => {
  const renderSummaryDetails = ({
    targetAudienceBuckets,
    inputDataSources,
    survey,
    dependsOn
  }) => (
    <Spacing size="xl">
      {opportunity.requestedQueryDescription && (
        <RequestedQueryDescription opportunity={opportunity} />
      )}
      {(isCompletedAudienceStep({ targetAudienceBuckets }) ||
        (!isNil(dependsOn) && !isEmpty(dependsOn))) && (
        <AudienceBucketsSummary
          opportunity={opportunity}
          AudienceBucketSummaryComponent={
            sidebar && AudienceBucketSummarySimple
          }
        />
      )}

      {isCompletedAdStep({ ad }) && (
        <Spacing size="xxl">
          <AdSummary name={name} ad={ad} rowWrap={!sidebar} />
          <AdPreviewWrapper>
            <AdPreview opportunity={opportunity} />
          </AdPreviewWrapper>
        </Spacing>
      )}

      {isCompletedDataSourcesStep({ inputDataSources }) && (
        <QuestionsSummary
          title="Questions"
          inputDataSources={inputDataSources}
          direction={sidebar ? 'column' : 'row'}
        />
      )}

      {isCompletedSurveyStep({ survey }) && (
        <SurveySummary survey={survey} rowWrap={!sidebar} />
      )}
    </Spacing>
  )

  return (
    <Spacing size="xl">
      <Text h3>Details</Text>
      {renderSummaryDetails(opportunity)}
    </Spacing>
  )
}
