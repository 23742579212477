import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { toLower, find, pathEq, pipe, propOr, isEmpty } from 'ramda'

import { Text, Graphic, Spacing, Checkbox } from '../../../../../components/ui'
import { SimpleQueriesBuilder } from '../../../../../components/blocks'

const Wrap = styled.div`
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  border: 2px solid ${({ theme }) => theme.color.purpleL2};
  padding: ${({ theme: { padding }, checked }) =>
    `${padding.l} ${padding.xl} ${padding[checked ? 'xl' : 'l']}`};
  ${({ checked, theme }) =>
    checked &&
    css`
      border-color: ${theme.color.purple};
    `}
`

const DataSourceWrap = styled.label`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ::before {
    display: block;
    content: '';
    border: 2px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.large};
    margin: -2px;
    transition: border ${({ theme }) => theme.animationSpeed.normal};
  }
`

const Title = styled(Spacing)`
  min-width: 30%;
`

const Category = styled(Text)`
  min-width: 20%;
`

const HorizontalLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.greyL2};
`

// UNUSED component

export const DataSourceCheckbox = ({ label, input, extra }) => {
  const dataSourceId = extra.node.id
  const graphicName = 'Source' + label.replace(/ /g, '')
  const checkboxFieldName = `${input.name}.dataSourceId`

  const inputDataSources = extra.form.getState().values.inputDataSources
  const currentSourceQueries = pipe(
    find(pathEq(['dataSource', 'id'], dataSourceId)),
    propOr([], 'queries')
  )(inputDataSources)

  useEffect(() => {
    if (!input.value) return

    if (isEmpty(currentSourceQueries)) {
      input.onChange(false)
    }
  }, [currentSourceQueries.length])

  return (
    <Wrap checked={input.value}>
      <Spacing size="l">
        <DataSourceWrap>
          <Title direction="row" justify="flex-start" size="l" align="center">
            <Graphic name={graphicName} size={38} />
            <Text h3>{label}</Text>
          </Title>
          <Category t1 color="grey">
            {toLower(extra.node.category.name)}
          </Category>
          <Checkbox
            input={{
              ...input,
              name: checkboxFieldName,
              checked: input.value
            }}
          />
        </DataSourceWrap>
        {input.value && (
          <Spacing size="xl">
            <HorizontalLine />
            <SimpleQueriesBuilder
              input={input}
              singleQuery={true}
              initialSelectFieldValue="*"
            />
          </Spacing>
        )}
      </Spacing>
    </Wrap>
  )
}
