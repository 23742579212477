import React from 'react'
import { pipe, range, map, last } from 'ramda'
import { getYear } from 'date-fns'
import { RangeSlider } from '../'

export const TagRangeSlider = ({ fields, form, ...props }) => {
  const currentYear = getYear(new Date())
  const input = {
    onChange: ([from, to]) => {
      form.change(
        fields.name,
        pipe(
          range,
          map(year => `${currentYear - year}`)
        )(from, to + 1)
      )
    },
    value: (() => {
      if (!fields.value) {
        return []
      }

      return map(year => currentYear - parseInt(year))([
        fields.value[0],
        last(fields.value)
      ])
    })()
  }

  return <RangeSlider {...props} input={input} />
}
