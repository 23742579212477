import React from 'react'
import { withMutation } from '../../../wrappers'
import { Button } from '../../../ui'
import { DropdownItem } from './DropdownItem'
import theme from '../../../../theme'
import { StudiesRefetchQueries } from '../../../../state/queries'

const ReturnToDraftButtonInner = ({
  id,
  returnToDraftOpportunity,
  dropdownItem
}) => {
  const text = 'Revert to Draft'

  const clickHandler = () => {
    if (window.confirm('Are you sure?')) {
      returnToDraftOpportunity({
        variables: { id },
        refetchQueries: StudiesRefetchQueries
      })
    }
  }
  if (dropdownItem) {
    return (
      <DropdownItem
        text={text}
        onClick={clickHandler}
        textColor={theme.color.red}
      />
    )
  }

  return (
    <Button themeColor="red" onClick={clickHandler} outline>
      {text}
    </Button>
  )
}

export const ReturnToDraftButton = withMutation('returnToDraftOpportunity', {
  showErrorNotifications: true
})(ReturnToDraftButtonInner)
