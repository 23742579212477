import React from 'react'
import { withMutation } from '../../../wrappers'
import { Button } from '../../../ui'
import { DropdownItem } from './DropdownItem'
import { StudiesRefetchQueries } from '../../../../state/queries'

const CancelStudyButtonInner = ({ id, completeOpportunity, dropdownItem }) => {
  const text = 'End Study'

  const clickHandler = () => {
    if (window.confirm('Are you sure?')) {
      completeOpportunity({
        variables: { id },
        refetchQueries: StudiesRefetchQueries
      })
    }
  }

  if (dropdownItem) {
    return <DropdownItem text={text} onClick={clickHandler} />
  }

  return (
    <Button onClick={clickHandler} outline>
      {text}
    </Button>
  )
}

export const CancelStudyButton = withMutation('completeOpportunity', {
  showErrorNotifications: true
})(CancelStudyButtonInner)
