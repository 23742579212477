import React from 'react'
import { Button } from '../../../ui'
import { useModal } from '../../../../hooks'
import { DropdownItem } from './DropdownItem'

export const SendNotificationButton = ({ id, dropdownItem, opportunity }) => {
  const { openModal } = useModal()
  const clickHandler = () => {
    openModal('SendNotification', {
      studyId: id,
      sentNotifications: opportunity.sentNotifications
    })
  }

  const text = 'Send Notification'

  if (dropdownItem) {
    return <DropdownItem text={text} onClick={clickHandler} />
  }

  return (
    <Button onClick={clickHandler} outline>
      {text}
    </Button>
  )
}
