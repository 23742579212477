import React from 'react'
import { Chart } from 'react-charts'
import { format } from 'date-fns'

export const DataSourceTrend = ({ trend, color }) => {
  const data = [
    {
      label: 'Users',
      data: trend
    }
  ]

  const series = {
    showPoints: false
  }

  const axes = [
    {
      primary: true,
      position: 'bottom',
      type: 'linear',
      show: false
    },
    { position: 'left', type: 'linear', show: false }
  ]

  const getSeriesStyle = () => ({
    color
  })

  const tooltip = React.useMemo(
    () => ({
      render: ({ focused }) => {
        return (
          focused && (
            <div>
              <b>{format(focused.xValue, 'MM/DD/YYYY')}</b> {focused.yValue}{' '}
              users
            </div>
          )
        )
      }
    }),
    []
  )

  return (
    <Chart
      data={data}
      axes={axes}
      series={series}
      getSeriesStyle={getSeriesStyle}
      tooltip={tooltip}
    />
  )
}
