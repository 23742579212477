import gql from 'graphql-tag'
import { pipe, values, map, flatten } from 'ramda'

import {
  OpportunityFragment,
  PaginatedOpportunityFragment,
  UserGroupFragment,
  AudienceSchemaItemFragment,
  DataFieldTypeFragment,
  DataSchemaFragment,
  OpportunityMinimalFragment
} from '../fragments'

export const AllDataSourcesQuery = gql`
  query AllDataSources {
    allDataSources {
      edges {
        node {
          id
          name
          digimeId
          category {
            id
            name
          }
          availableObjectTypes {
            id
            name
            description
          }
        }
      }
    }
  }
`

export const OpportunityDetailsQuery = gql`
  query OpportunityDetails($id: String!) {
    opportunity(id: $id) {
      id
      ...Opportunity
    }
  }
  ${OpportunityFragment}
`

export const OpportunityMinimalQuery = gql`
  query OpportunityDetails($id: String!) {
    opportunity(id: $id) {
      id
      ...Opportunity
    }
  }
  ${OpportunityMinimalFragment}
`

export const StudiesQuery = gql`
  query Studies(
    $after: Int
    $sort: String
    $orderBy: String
    $state: [OpportunityState]
    $contains: [String!]
    $favorite: Boolean
    $name: String
    $categories: [OpportunityCategory!]
  ) {
    allOpportunities(
      after: $after
      sort: $sort
      orderBy: $orderBy
      first: 10
      state: $state
      contains: $contains
      favorite: $favorite
      name: $name
      categories: $categories
    ) {
      ...PaginatedOpportunity
    }
  }
  ${PaginatedOpportunityFragment}
`

export const TargetAudienceBucketQuery = gql`
  query TargetAudienceBucket {
    allUserGroups {
      edges {
        node {
          id
          ...UserGroup
        }
      }
    }

    audienceSchema(includeAppManaged: true) {
      ...AudienceSchemaItem
      children {
        ...AudienceSchemaItem
        children {
          ...AudienceSchemaItem
        }
        subfields {
          ...AudienceSchemaItem
        }
      }
      subfields {
        ...AudienceSchemaItem
      }
    }

    dataSchema {
      ...DataSchema
    }
    dataTypesSchema {
      ...DataFieldType
    }
  }
  ${UserGroupFragment}
  ${AudienceSchemaItemFragment}
  ${DataSchemaFragment}
  ${DataFieldTypeFragment}
`

export const allStudyStates = [
  'draft',
  'ready',
  'inReview',
  'paused',
  'published',
  'completed'
]

export const allAdStates = ['draft', 'ready', 'inReview', 'published']

export const statesInProgress = [
  'draft',
  'ready',
  'inReview',
  'paused',
  'published'
]
export const statesCompleted = ['completed']

export const StudiesQueryVariables = {
  allStudyStates: { state: allStudyStates },
  active: { state: ['ready', 'published'] },
  draft: { state: ['draft'] },
  inReview: { state: ['inReview'] },
  inProgress: { state: statesInProgress },
  completed: { state: statesCompleted },
  insights: { contains: ['inputDataSources'] },
  surveys: { contains: ['survey'] },
  allAdStates: { state: allAdStates },
  paused: { state: ['paused'] }
}

export const StudiesRefetchQueries = pipe(
  values,
  map(variables =>
    [true, false].map(favorite => ({
      query: StudiesQuery,
      variables: { ...variables, favorite }
    }))
  ),
  flatten
)(StudiesQueryVariables)
