import React from 'react'
import { Field } from 'react-final-form'

import {
  Spacing,
  Box,
  Text,
  Input,
  SpacingItem,
  UploadPhoto,
  Touchable,
  Button,
  Radio
} from '../../../../components/ui'

import { StudyColorPicker } from '../../../../components/blocks'
import { omit, pathOr } from 'ramda'
import {
  isCompletedAdStep,
  validatorAdFilled
} from '../../../../util/validators'
import { FieldArray } from 'react-final-form-arrays'

const engagementDuration = [
  {
    label: '5 seconds',
    value: '5'
  },
  {
    label: '10 seconds',
    value: '10'
  },
  {
    label: '30 seconds',
    value: '30'
  }
]
export const StudyCreateAd = () => (
  <Box size="xl">
    <Spacing size="l">
      <Spacing direction="row" size="xl" align="center">
        <SpacingItem flex={3}>
          <Field
            name="name"
            component={Input}
            label="Public ad headline"
            block
          />
        </SpacingItem>
        <SpacingItem flex={2}>
          <Text t4>120 characters max</Text>
        </SpacingItem>
      </Spacing>
      <Spacing direction="row" size="xl" align="center">
        <SpacingItem flex={3}>
          <Field name="ad.fullUrl" component={Input} label="Ad link" block />
        </SpacingItem>
        <SpacingItem flex={2}>
          <Field name="ad.colorHex" component={StudyColorPicker} />
        </SpacingItem>
      </Spacing>
      <Spacing direction="row" size="xl" align="center">
        <SpacingItem flex={1}>
          <Field
            name="ad.budget"
            component={Input}
            label="Ad budget"
            block
            type="number"
          />
        </SpacingItem>
        <SpacingItem flex={3}>
          <Text t4>USD</Text>
        </SpacingItem>
      </Spacing>
      <Field name="ad.image" component={UploadPhoto} photoFor="Ad" />
      <Spacing size="l">
        <Text h6>Engagement duration</Text>
        <Spacing direction="row" size="xl">
          {engagementDuration.map(item => (
            <Field
              key={item.value}
              type="radio"
              name="ad.duration"
              component={Radio}
              label={item.label}
              value={item.value}
            />
          ))}
        </Spacing>
      </Spacing>
      <Text h6>Conversion events</Text>
      <ConversionEventsFieldArray />
    </Spacing>
  </Box>
)

const ConversionEventsFieldArray = () => (
  <FieldArray name={`ad.conversionEvents`}>
    {({ fields }) => (
      <Spacing stretched>
        {fields.map((name, index) => (
          <Spacing
            key={name}
            direction="row"
            justify="space-between"
            align="center"
          >
            <Field
              key={`${name}.name`}
              name={`${name}.name`}
              label={`name #${index + 1}`}
              component={Input}
            />
            <Field
              key={`${name}.url`}
              name={`${name}.url`}
              label={`url #${index + 1}`}
              component={Input}
            />
            <Touchable onClick={() => fields.remove(index)}>
              <Text t4 color="grey">
                remove
              </Text>
            </Touchable>
          </Spacing>
        ))}
        <Button transparent link onClick={() => fields.push()} prefix="+">
          Add more
        </Button>
      </Spacing>
    )}
  </FieldArray>
)

StudyCreateAd.format = ad => ({
  ...ad,
  colorHex: pathOr('#FFFFFF', ['colorHex'], ad),
  duration: ad && `${ad.duration}`
})

StudyCreateAd.parse = ad => ({
  ...omit(
    [
      'imageUrl',
      'hostUrl',
      'pricePerClick',
      'pricePerClickComponents',
      'interactions',
      'stats'
    ],
    ad
  ),
  budget: parseInt(ad.budget, 10),
  duration: parseInt(ad.duration)
})

StudyCreateAd.validate = validatorAdFilled
StudyCreateAd.isCompleted = isCompletedAdStep
