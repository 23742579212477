import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components/macro'

import { Graphic } from '..'
import { withFieldError } from '../../wrappers'

import {
  getBackgroundColor,
  getBorder,
  getHoveredBackgroundColor
} from '../../../util/ui'

const InputWrap = styled.div`
  display: inline-block;
  width: ${({ block }) => (block ? '100%' : '320px')};
  position: relative;
`

const InputText = styled.input`
  width: 100%;
  padding: ${({ theme, graphic }) =>
    `${theme.padding.m} ${theme.padding[graphic ? 'xxl' : 'l']} ${
      theme.padding.m
    } ${parseInt(theme.padding.l) - 1}px`};
  outline: none;

  border-radius: ${({ theme }) => theme.borderRadius.base};
  background-color: ${props => getBackgroundColor(props)};
  border: ${getBorder};
  color: ${({ theme }) => theme.color.dark};

  transition: all .2s ease-in-out;

  &:hover {
    background-color: ${getHoveredBackgroundColor};
  }

  ::placeholder {
    opacity: 0;
  }

  ${({ theme }) => theme.text.t2}

  ${props =>
    isLabelShrinked(props) &&
    css`
      padding-bottom: ${props.theme.padding.xs};
      padding-top: 25px;

      ::placeholder {
        opacity: 1;
        color: ${props.theme.color.purpleL2};
      }
    `}

  ${props => props.isErrored && `border: 1px solid ${props.theme.color.red}`}

  ${props => props.success && `border: 1px solid ${props.theme.color.green}`}

  ${props =>
    props.clean &&
    css`
      padding: 0;
      background-color: transparent;
      border: none;
      ${props.theme.text.h1}
    `}
`

const Label = styled.label`
  position: absolute;
  padding: ${({ theme }) => `${theme.padding.m} ${theme.padding.l}`};

  pointer-events: none;

  color: ${({ theme }) => theme.color.dark};
  ${({ theme }) => theme.text.h6};

  ${props =>
    isLabelShrinked(props) &&
    css`
      ${props.theme.text.label}
      padding-top: 5px;
    `}

  ${props =>
    (!!props.input.value || props.input.value === 0) &&
    `color: ${props.theme.color.main};`}
  ${props => props.isErrored && `color: ${props.theme.color.red}`}
  ${props => props.success && `color: ${props.theme.color.green}`}

  transition: all 0.1s ease-in-out;
`

const GraphicWrap = styled(Graphic)`
  position: absolute;
  right: ${({ theme }) => theme.padding.l};
  top: ${({ theme }) => theme.padding.m};
  pointer-events: none;
`

const InputView = forwardRef(
  (
    {
      label,
      graphic,
      graphicColor,
      graphicSize = 18,
      block,
      placeholder,
      ...props
    },
    ref
  ) => (
    <InputWrap block={block}>
      <Label {...props}>{label}</Label>
      <InputText
        type="text"
        {...props}
        {...props.input}
        ref={ref}
        graphic={!!graphic}
        placeholder={placeholder || 'type...'}
      />
      {graphic && (
        <GraphicWrap
          name={graphic}
          size={graphicSize}
          themeColor={graphicColor}
        />
      )}
    </InputWrap>
  )
)

export const Input = withFieldError()(InputView)

const isLabelShrinked = ({ meta, input }) => {
  return meta.active || !!input.value || input.value === 0
}
