import React from 'react'
import { Text, Spacing } from '../../../components/ui'
import styled from 'styled-components/macro'

const SurveyWrapper = styled.div`
  padding: ${({ theme }) => theme.padding.m};
  border: 1px solid ${({ theme }) => theme.color.greyL2};
`

export const SurveyAnswersView = ({ survey: { columns, answers } }) => {
  return (
    <Spacing size="l">
      <Text h5 color="dark">
        Survey Answers
      </Text>
      <SurveyWrapper>
        <Spacing size="m">
          {columns.map((column, n) => (
            <Spacing size="xs" key={`${n} - ${column}`}>
              <Text h6>{column}</Text>
              <Text t3>{answers[n]}</Text>
            </Spacing>
          ))}
        </Spacing>
      </SurveyWrapper>
    </Spacing>
  )
}
