import React from 'react'

import { Spacing, Select, InlineField } from '../../ui'
import { withMutation } from '../../wrappers'

const SELECT_PROPS_MOCK = {
  label: 'Duration',
  options: [
    {
      label: '1 week',
      value: 7
    },
    {
      label: '1 month',
      value: 30
    },
    {
      label: '2 months',
      value: 60
    },
    {
      label: '6 months',
      value: 180
    },
    {
      label: '1 year',
      value: 365
    }
  ]
}

const StudyDurationSection = ({
  opportunity,
  updateOpportunity,
  initialValue,
  inlineError
}) => {
  const onUpdate = duration => {
    // Since FromSpy subscription calls this function on initial load, this prevents the mutation
    return updateOpportunity({
      variables: {
        id: opportunity.id,
        duration: { lengthInDays: parseInt(duration) }
      }
    })
  }

  return (
    <Spacing size="s">
      <InlineField
        {...SELECT_PROPS_MOCK}
        component={Select}
        onUpdate={onUpdate}
        graphic="Calendar"
        graphicSize={20}
        graphicColor="main"
        label={SELECT_PROPS_MOCK.label}
        initialValue={initialValue}
        inlineError={inlineError}
      />
    </Spacing>
  )
}

export const StudyDuration = withMutation('updateOpportunity')(
  StudyDurationSection
)
