import { pipe, reduceBy } from 'ramda'
import { getYear } from 'date-fns'

export const parseAgeTagsOverview = values => {
  const groupAgeCount = (acc, { count }) => (acc += count)

  const toAgeLabel = ({ label }) => {
    const ageDiff = getYear(new Date()) - parseInt(label, 10)

    return ageDiff < 18
      ? '<18'
      : ageDiff < 30
      ? '18-29'
      : ageDiff < 40
      ? '30-39'
      : ageDiff < 50
      ? '40-49'
      : ageDiff < 60
      ? '50-59'
      : '60+'
  }

  return pipe(
    reduceBy(groupAgeCount, 0, toAgeLabel),
    reduced =>
      Object.entries(reduced).map(([label, count]) => ({
        label,
        count
      }))
  )(values)
}
