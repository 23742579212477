import React, { useEffect } from 'react'

import { Graphic, Text, Button, Spacing } from '../../../components/ui'

import { VerticalFade } from '../components/AnimatedWizardItems'
import { withMutation } from '../../../components/wrappers'

const StepDoneInner = ({ onNextStep, sendVerificationEmail }) => {
  useEffect(() => {
    sendVerificationEmail()
  }, [])

  return (
    <Spacing size="xxxxl">
      <Graphic name="Logo" png size={80} />
      <Spacing size="xl">
        <VerticalFade>
          <Text h0>You've signed up!</Text>
        </VerticalFade>

        <VerticalFade>
          <Text t1>
            You have successfully signed up, please verify your account. The
            link has been sent to your email address.{' '}
          </Text>
        </VerticalFade>

        <VerticalFade>
          <Button onClick={onNextStep} primary>
            Explore Dashboard
          </Button>
        </VerticalFade>
      </Spacing>
    </Spacing>
  )
}

export const StepDone = withMutation('sendVerificationEmail')(StepDoneInner)
