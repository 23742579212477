import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { Spacing, Text } from '../../ui'

const getBorderDirection = ({ direction }) =>
  direction === 'horizontal' ? 'bottom' : 'right'

const getBorderSize = ({ direction }) => (direction === 'horizontal' ? 2 : 5)

const TabsContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === 'horizontal' ? 'column' : 'row'};
`

const TabLink = styled.div`
  cursor: pointer;
  flex: 1;
  width: 100%;
  display: inline-block;
  padding: 20px;
  margin-bottom: -1px;
  div {
    color: ${({ theme }) => theme.color.dark};
    opacity: 0.5;
    transition: opacity ${({ theme }) => theme.animationSpeed.default};
  }

  ${props =>
    props.direction === 'vertical' &&
    css`
      margin-right: -10px;
    `}

  &:hover {
    div {
      opacity: 1;
    }
  }

  ${props =>
    props.active &&
    css`
      border-${getBorderDirection(props)}: 5px solid ${({ theme }) =>
      theme.color.purple};
      div {
        opacity: 1;
      }
    `}
`

const Header = styled.div`
  background-color: ${({ theme }) => theme.color.transparent};
  border-${getBorderDirection}: ${getBorderSize}px solid ${({ theme }) =>
  theme.color.greyL2};
  display: flex;
  align-items: top;
  text-align: center;
  flex: 1;
  margin-bottom: ${({ direction, theme }) =>
    direction === 'horizontal' ? theme.padding.xl : 0}};
  margin-right: ${({ direction, theme }) =>
    direction === 'vertical' ? theme.padding.xxl : 0}};
`

const TabContents = styled.div`
  flex: 5;
`

const Tab = ({ label, ...props }) => (
  <TabLink {...props}>
    <Text h4>{label}</Text>
  </TabLink>
)

export const Tabs = ({ children, direction = 'horizontal', rightContent }) => {
  const filteredChildren = [].concat(children).filter(Boolean)

  const initialTabIndex = filteredChildren.findIndex(
    ({ props }) => props.initial
  )
  const [currentTabIndex, setCurrentTabIndex] = useState(
    initialTabIndex >= 0 ? initialTabIndex : 0
  )

  return (
    <TabsContainer direction={direction}>
      <Header direction={direction}>
        <Spacing
          direction={direction === 'horizontal' ? 'row' : 'column'}
          stretched
        >
          {filteredChildren.map(({ props }, index) => (
            <Tab
              {...props}
              onClick={() => setCurrentTabIndex(index)}
              active={currentTabIndex === index}
              key={index}
              direction={direction}
            />
          ))}
        </Spacing>
        {rightContent}
      </Header>

      <TabContents>
        {filteredChildren[currentTabIndex] &&
          filteredChildren[currentTabIndex].props.children}
      </TabContents>
    </TabsContainer>
  )
}

Tabs.Tab = Tab
