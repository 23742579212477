import React from 'react'
import styled from 'styled-components/macro'
import { pipe } from 'ramda'
import { NavLink, withRouter } from 'react-router-dom'

import { Text, Graphic, Spacing, Button } from '../ui'

import { UserMenu } from './components'

const links = [
  {
    to: '/',
    title: 'Overview',
    exact: true
  },
  {
    to: '/studies/',
    title: 'Studies'
  },
  { to: '/ads/', title: 'Ads' },
  process.env.REACT_APP_PLAYGROUND_OPPORTUNITY_ID && {
    to: '/playground/',
    title: 'Playground'
  }
].filter(Boolean)

const HeaderWrap = styled.div`
  background-image: ${({ theme }) => theme.gradient.headerGradient};
  padding: 0 ${({ theme }) => theme.padding.xl};
  display: flex;
  align-items: center;
  text-align: center;
`

const LogoWrap = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: ${({ theme }) => theme.padding.xl};
`
const HeaderLink = styled(NavLink)`
  display: inline-block;
  padding: ${({ theme }) => theme.padding.l};
  border-bottom: 5px solid transparent;

  &.active {
    border-bottom: 5px solid #fff;
  }
`

const TextWrap = styled(Text)`
  line-height: 18px;
`

const NewStudyButton = styled(Button)`
  border-radius: ${({ theme }) => theme.borderRadius.base};
  padding: ${({ theme }) => `4px ${theme.padding.l} 7px`};
`

const handleClickNewStudy = props => {
  props.history.push('/studies/create/')
}

const Header = props => (
  <HeaderWrap>
    <Spacing direction="row" justify="space-between" align="center" stretched>
      <LogoWrap>
        <Graphic name="UbdiLogo" width={82} />
      </LogoWrap>
      <Spacing direction="row" size="xs">
        {links.map(({ title, ...navProps }) => (
          <HeaderLink key={title} {...navProps}>
            <TextWrap h6 color="white">
              {title}
            </TextWrap>
          </HeaderLink>
        ))}
      </Spacing>
      <Spacing direction="row" size="l" align="center">
        <NewStudyButton onClick={() => handleClickNewStudy(props)}>
          <Spacing direction="row" align="center" size="s">
            <Text h7 color="white">
              +
            </Text>
            <Text h7 color="white">
              New Study
            </Text>
          </Spacing>
        </NewStudyButton>
        <UserMenu />
      </Spacing>
    </Spacing>
  </HeaderWrap>
)

export const AppHeader = pipe(withRouter)(Header)
