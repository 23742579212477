import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Spacing, Text, Graphic } from '../'

const getBackgroundColor = (success, error) => {
  if (success) return 'green'
  if (error) return 'red'
  return 'purpleL3'
}

const getTitleColor = (success, error) => {
  if (success || error) return 'white'
  return 'main'
}

const getTextColor = (success, error) => {
  if (success || error) return 'white'
  return 'dark'
}

const CloseButton = styled.div`
  cursor: pointer;
`

const renderChildren = ({
  className,
  title,
  onClose,
  children,
  success,
  error
}) => (
  <div className={className}>
    <Spacing direction="row" justify="space-between">
      <Spacing size="s">
        {title && (
          <Text color={getTitleColor(success, error)} h6>
            {title}
          </Text>
        )}
        {children && (
          <Text t2 color={getTextColor(success, error)}>
            {children}
          </Text>
        )}
      </Spacing>
      {onClose && (
        <CloseButton onClick={onClose}>
          <Graphic
            name="X"
            size="14"
            themeColor={getTitleColor(success, error)}
          />
        </CloseButton>
      )}
    </Spacing>
  </div>
)

export const Notice = styled(renderChildren)`
  ${({ theme, success, error }) => css`
    background-color: ${theme.color[getBackgroundColor(success, error)]};
    padding: ${theme.padding.l};
    border-radius: ${theme.borderRadius.base};
  `}
`
