import React from 'react'
import { graphql } from 'react-apollo'
import {
  omit,
  forEach,
  values,
  pipe,
  reduce,
  keys,
  head,
  path,
  isEmpty,
  dissoc
} from 'ramda'
import hoistNonReactStatic from 'hoist-non-react-statics'
import { FORM_ERROR } from 'final-form'

import { deepOmit } from '../../util'

import * as Mutations from '../../state/mutations'
import { useToast } from '../../hooks'

const defaultConfig = {
  showErrorNotifications: false
}

// Wrapper around a mutation promise
const _mutationPromise = (mutate, mutationConfig, renderErrors) => args => {
  return new Promise((resolve, reject) =>
    mutate({
      ...args,
      variables: deepOmit('__typename', args.variables)
    })
      .then(
        pipe(
          transformErrorsFromResponse(mutationConfig, renderErrors),
          resolve
        )
      )
      // these are caught in apollo onError
      .catch(errors => resolve({ errors }))
  )
}

const getErrorKey = key => (key === '_general' ? FORM_ERROR : key)

const getAndTransformErrors = pipe(
  reduce(
    (errors, err) => ({
      ...errors,
      [getErrorKey(err.key)]: err.message
    }),
    {}
  ),
  errors => (isEmpty(errors) ? null : errors)
)

const transformErrorsFromResponse = (mutationConfig, renderErrors) => ({
  data
}) => {
  const { showErrorNotifications } = mutationConfig

  const mutationName = firstKey(data)
  const errorsFromResponse = path([mutationName, 'errors'], data)

  // if data has errors
  if (errorsFromResponse) {
    const errors = getAndTransformErrors(errorsFromResponse)

    if (showErrorNotifications) {
      // show Notification for errors if not FormField errors
      renderErrors(errors)
    }

    const withoutErrorsInData = dissoc('errors', data[mutationName])
    return {
      data: {
        [mutationName]: {
          ...withoutErrorsInData
        }
      },
      errors,
      userErrors: errors
    }
  } else {
    return { data }
  }
}

const renderErrorNotifications = showToast =>
  pipe(
    values,
    forEach(text => showToast({ title: 'Error', text, type: 'error' }))
  )

export const withMutation = (
  mutationName,
  config = defaultConfig
) => Component => {
  const mutation = Mutations[mutationName]

  if (!mutation) {
    throw new Error(`Mutation ${mutationName} does not exist`)
  }

  const WithMutation = props => {
    const { showToast } = useToast()

    const mutationToPass = args =>
      _mutationPromise(
        props.mutate,
        config,
        renderErrorNotifications(showToast)
      )({
        update: mutation.update,
        refetchQueries: mutation.refetchQueries,
        ...args
      })

    mutationToPass.getOptimistic = mutation.getOptimistic

    const mutations = {
      ...omit(['mutate'], props),
      [mutationName]: mutationToPass
    }

    return <Component {...mutations} />
  }

  hoistNonReactStatic(WithMutation, Component)

  return graphql(mutation)(WithMutation)
}

const firstKey = pipe(
  keys,
  head
)
