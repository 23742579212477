import React from 'react'
import styled from 'styled-components/macro'
import { Graphic } from '../../../ui'

const LabelWrapper = styled.label`
  cursor: pointer;
  display: flex;
`

const Box = styled.div`
  width: 26px;
  height: 26px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.theme.borderRadius.large};
  background-color: ${props =>
    props.checked ? props.theme.color.main : props.theme.color.purpleL2};
  ${({ theme, checked }) =>
    !checked && `border: 2px solid ${theme.color.purpleL1}`};
  transition: background-color ${props => props.theme.animationSpeed.normal};
`

export const TickCheckbox = ({ checked }) => (
  <LabelWrapper>
    <Box checked={checked}>
      {checked && <Graphic name="Tick" size={12} themeColor="white" />}
    </Box>
  </LabelWrapper>
)
