import React, { forwardRef, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { InlinePicker } from '../../../ui'

const getTextAlignment = props => {
  if (props.center) return 'center'
  if (props.right) return 'right'
  return 'left'
}

const StyledInput = styled.input`
  ${props => {
    const { theme } = props

    const textAlignment = getTextAlignment(props)

    return css`
      ${theme.text.t2}
      text-align: ${textAlignment};

      width: 140px;
      position: relative;
      background: ${theme.color.purpleL3};
      border-radius: ${theme.borderRadius.pill};
      border: none;
      outline: none;
      padding: 7px ${theme.padding.m};
      color: ${theme.color.main};
      margin: 0 ${theme.padding.s} 0 0;
      
      ::placeholder {
        color: rgba(111, 98, 255, 0.4);
      }
    `
  }}
`

export const QueryBuilderInput = forwardRef(
  (
    {
      input,
      meta,
      onComplete,
      onRemove,
      state,
      inputType,
      onCommaAddField,
      fieldsInfo,
      suggestions,
      ...props
    },
    ref
  ) => {
    if (state === 'skipped') return null

    useEffect(() => {
      if (input.value && state === 'unmounted') {
        onComplete()
      }
    }, [])

    const autoFocus =
      fieldsInfo &&
      fieldsInfo.total > 1 &&
      fieldsInfo.current === fieldsInfo.total

    const onChanged = () => {
      // if completed then changed
      if (state === 'completed' && meta.dirty) {
        onRemove({
          keepCurrentSentencePieceForce: true,
          invalidateWhereRowFromCurrent: true
        })
      }

      return onComplete()
    }

    const handleKeyDown = e => {
      // backspace
      if (e.keyCode === 8 && !e.target.value.length) {
        e.preventDefault()

        onRemove({
          invalidateWhereRowFromCurrent: true
        }) // remove sentence where row maybe
      }

      // enter
      if (e.keyCode === 13 && e.target.value.length) {
        e.preventDefault()

        return onChanged()
      }

      // comma
      if (e.keyCode === 188 && onCommaAddField) {
        e.preventDefault()
        return onCommaAddField()
      }
    }

    if (suggestions) {
      const options = suggestions.map(sugg => ({
        label: sugg,
        value: sugg
      }))

      return (
        <InlinePicker
          input={input}
          meta={meta}
          type="purpleL3"
          options={options}
          ref={ref}
          onComplete={onComplete}
          onRemove={onRemove}
          state={state}
          onCommaAddField={onCommaAddField}
          acceptAnyValue={true}
        />
      )
    }

    return (
      <StyledInput
        key={input.name}
        onKeyDown={handleKeyDown}
        onChange={input.onChange}
        value={input.value}
        {...props}
        type={inputType}
        ref={ref}
        autoFocus={autoFocus} // temporary to autoFocus next on multiple value fields
      />
    )
  }
)

QueryBuilderInput.defaultProps = {
  left: true,
  placeholder: '',
  type: 'text',
  onComplete: () => {},
  onRemove: () => {}
}
