import React, { useState } from 'react'

import {
  Spacing,
  Button,
  Graphic,
  SpacingItem,
  SubmitError,
  InlineField,
  Input,
  Text,
  Box
} from '../../../../../components/ui'
import { TypeformEditor } from '../../'
import { RenderFromQuery } from '../../../../../components/util'
import { CurrentCompanyQuery } from '../../../../../state/queries'

import { StudyBuilderBlock } from '../../../../../components/blocks'

import { useModal } from '../../../../../hooks'
import { SurveyBuilder } from '../../SurveyBuilder'
import { FieldArray } from 'react-final-form-arrays'
import { getEmptyQuestion } from '../../../../../util/survey'
import styled from 'styled-components'

const GraphicWrapper = styled(SpacingItem)`
  text-align: center;
`

const StyledBox = styled(Box)`
  height: 100%;
  display: flex;
`

const EmptyStateCreateSurvey = ({
  opportunity,
  updateOpportunity,
  showSurveyBuilder,
  setShowSurveyBuilder,
  survey,
  connectedTypeform,
  onDisconnectTypeform,
  openModal
}) => {
  const onUpdate = typeformId => {
    return updateOpportunity({
      variables: {
        id: opportunity.id,
        survey: {
          typeformId
        }
      }
    })
  }

  return (
    <Spacing direction="row" stretchChildren>
      <EmptySurveyState
        text="Create a Survey using our own survey tools, and we will serve it to your audience"
        title="Create an UBDI Survey"
        direction="column"
        graphic={<Graphic name="Survey" size={71} />}
        contentBelow={
          <FieldArray name="survey.inHouse.questions">
            {({ fields }) => (
              <Button
                block
                onClick={() => {
                  setShowSurveyBuilder(!showSurveyBuilder)
                  fields.push(getEmptyQuestion())
                }}
              >
                Create Survey
              </Button>
            )}
          </FieldArray>
        }
      />

      <EmptySurveyState
        text="Create a Survey using Typeform, and we will serve it to your audience"
        title="Create Survey"
        graphic={<Graphic name="Survey" size={71} />}
        contentBelow={
          <Spacing size="l">
            {!connectedTypeform && (
              <Button
                block
                outline
                onClick={() => openModal('ConnectTypeform')}
              >
                Use Typeform
              </Button>
            )}
            {connectedTypeform && (!survey || !survey.typeformId) && (
              <Spacing>
                <InlineField
                  component={Input}
                  onUpdate={onUpdate}
                  label="Typeform ID"
                  initialValue={
                    opportunity.survey && opportunity.survey.typeformId
                  }
                />
                <Button
                  transparent
                  themeColor="secondary"
                  link
                  onClick={onDisconnectTypeform}
                >
                  Disconnect Typeform
                </Button>
              </Spacing>
            )}
          </Spacing>
        }
      />
    </Spacing>
  )
}

export const AddSurvey = ({
  opportunity,
  submitError,
  updateCompany,
  updateOpportunity
}) => {
  const { openModal } = useModal()
  const { survey } = opportunity
  const [showSurveyBuilder, setShowSurveyBuilder] = useState(
    survey && survey.inHouse
  )

  const onDisconnectTypeform = () =>
    updateCompany({ variables: { typeformAccessToken: '' } })

  const onRemoveSurvey = () =>
    updateOpportunity({
      variables: { id: opportunity.id, survey: { typeformId: null } }
    })

  return (
    <RenderFromQuery
      query={CurrentCompanyQuery}
      renderData={({ currentUser: { company } }) => {
        if (!showSurveyBuilder) {
          if (survey && survey.typeformId) {
            return (
              <StudyBuilderBlock
                title="Ask questions"
                graphic="Survey"
                headerRightContent={() => {}}
              >
                <Spacing size="xl">
                  {submitError && <SubmitError submitError={submitError} />}

                  <TypeformEditor {...opportunity} />

                  <Spacing direction="row">
                    <Button
                      transparent
                      themeColor="secondary"
                      link
                      onClick={onDisconnectTypeform}
                    >
                      Disconnect Typeform
                    </Button>
                    <Button
                      transparent
                      themeColor="secondary"
                      link
                      onClick={onRemoveSurvey}
                    >
                      Remove survey
                    </Button>
                  </Spacing>
                </Spacing>
              </StudyBuilderBlock>
            )
          }
          return (
            <EmptyStateCreateSurvey
              opportunity={opportunity}
              showSurveyBuilder={showSurveyBuilder}
              setShowSurveyBuilder={setShowSurveyBuilder}
              updateOpportunity={updateOpportunity}
              survey={survey}
              openModal={openModal}
              connectedTypeform={company.connectedTypeform}
              onDisconnectTypeform={onDisconnectTypeform}
            />
          )
        }
        return <SurveyBuilder />
      }}
    />
  )
}

const EmptySurveyState = ({ contentBelow, title, text, graphic }) => {
  return (
    <StyledBox size="xl">
      <Spacing size="xl" stretched>
        <GraphicWrapper>{graphic}</GraphicWrapper>
        <Spacing size="s" justify="center" align="center">
          {title && <Text h3>{title}</Text>}
          <Text t1={!title} t3={title}>
            {text}
          </Text>
        </Spacing>
        {contentBelow}
      </Spacing>
    </StyledBox>
  )
}
