import gql from 'graphql-tag'

export const InputDataSourceFragment = gql`
  fragment InputDataSource on InputDataSource {
    query {
      id
      objectType {
        id
        name
      }
      dataSources {
        id
        name
      }
      objectTypeId
      dataSourceIds
      where {
        groups {
          condition {
            field
            comparison
            value
          }
          conjunction
        }
        condition {
          field
          comparison
          value
        }
        conjunction
      }
      sql
      nativeQueryDescription {
        aggregation
        breakout
        filter
      }
    }
    required
  }
`

export const OpportunityFragment = gql`
  fragment Opportunity on Opportunity {
    id
    createdAt
    category
    ad {
      imageUrl
      colorHex
      budget
      fullUrl
      hostUrl
      conversionEvents {
        name
        url
      }
    }
    name
    shortDescription
    internalName
    favorite
    state
    type
    reach {
      isUnlimited
      maxPermitted
      reached
    }
    fees {
      firstTime
      cash
    }
    dependsOn
    pricePerPerson
    inputDataSources {
      ...InputDataSource
    }
    outputDataQueries {
      id
      objectTypeId
      dataSourceIds
      sql
    }
    targetAudienceBuckets {
      id
      name
      quota
      responseCount
      tags {
        and {
          uniqueId
          values
        }
        not {
          uniqueId
          values
        }
      }
      queries {
        objectTypeId
        dataSourceIds
        nativeQueryDescription {
          aggregation
          breakout
          filter
        }
        where {
          groups {
            condition {
              field
              comparison
              value
            }
            conjunction
          }
          condition {
            field
            comparison
            value
          }
          conjunction
        }
        select
      }
      simpleQueries {
        dataSourceIds
        keywords
        queryMatch
        dateRange
        includeKeywords
      }
    }
    story {
      screens {
        id
        template
        thumbnail
      }
    }
    duration {
      lengthInDays
      startDate
      endDate
    }
    survey {
      typeformId
      totalQuestions
      inHouse {
        description
        questions {
          id
          required
          title
          type
          imageUrl {
            thumbnail
            default
          }
          videoUrl
          choices {
            label
          }
        }
      }
    }
    isMultipartStudy
    results {
      url
      label
    }
    company {
      photo {
        thumbnail
        default
        large
      }
    }
    showCompanyLogo
    responseConfig {
      additionalResponseTags
      requiredResponseTags
    }
    insightsDataType
    insightsStorageLocation
    requestedQueryDescription
    sentNotifications {
      id
      timestamp
      notification {
        title
        message
        forceShow
      }
    }
    ad {
      imageUrl
      colorHex
      fullUrl
      hostUrl
      duration
      pricePerClick
      pricePerClickComponents {
        id
        default {
          label
          amount
        }
        selected {
          label
          amount
        }
      }
      conversionEvents {
        name
        url
      }
      interactions {
        action
        tagCategories {
          name
          values {
            label
            count
          }
        }
        conversionUrl
      }
      stats {
        impressions
        visits
        conversions
        totalSpent
        timeSpent
      }
    }
    study {
      stats {
        impressions
        detailsOpen
      }
    }
    interactionsOverview {
      name
      values {
        label
        count
      }
    }
  }
  ${InputDataSourceFragment}
`

export const OpportunityMinimalFragment = gql`
  fragment Opportunity on Opportunity {
    id
    createdAt
    internalName
    name
    state
    type
    category
    favorite
    reach {
      maxPermitted
      reached
    }
    fees {
      firstTime
      cash
    }
    ad {
      stats {
        impressions
        visits
        conversions
        totalSpent
      }
    }
    survey {
      typeformId
      totalQuestions
      inHouse {
        description
      }
    }
    inputDataSources {
      ...InputDataSource
    }
  }
  ${InputDataSourceFragment}
`

export const PaginatedOpportunityFragment = gql`
  fragment PaginatedOpportunity on PaginatedOpportunity {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...Opportunity
      }
      cursor
    }
  }
  ${OpportunityMinimalFragment}
`
