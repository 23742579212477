import React, { useState } from 'react'
import { RenderFromQuery } from '../../../../components/util'
import { StatisticsQuery } from '../../../../state/queries'

import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup
} from 'react-simple-maps'
import { scaleLinear } from 'd3-scale'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import { Text } from '../../../../components/ui'
import { equals, prop } from 'ramda'

const geoUrl =
  'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json'

const tooltipStyle = {
  backgroundColor: '#201553',
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 10,
  paddingRight: 10,
  borderRadius: 30
}

export const WorldMap = () => {
  const [content, setContent] = useState('')

  return (
    <RenderFromQuery
      query={StatisticsQuery}
      renderData={({
        statistics: {
          member: { totalPerCountry }
        }
      }) => (
        <>
          <ComposableMap
            width={950}
            height={500}
            projectionConfig={{
              rotate: [-11.4, 9.5, 0],
              scale: 203.9
            }}
          >
            <ZoomableGroup>
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map(geo => {
                    const country = totalPerCountry.find(country =>
                      equals(country.country, geo.properties.ISO_A2)
                    )

                    const max = Math.max(...totalPerCountry.map(prop('total')))

                    const colorScale = scaleLinear()
                      .domain([0, max])
                      .range(['rgb(255,255,255,0.5)', 'rgb(255,255,255,1)'])

                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        onMouseEnter={() => {
                          const { NAME_LONG } = geo.properties
                          setContent(
                            `${NAME_LONG} - ${country ? country.total : 0}`
                          )
                        }}
                        onMouseLeave={() => {
                          setContent('')
                        }}
                        fill={
                          country
                            ? colorScale(country.total)
                            : 'rgb(255,255,255,0.4)'
                        }
                        style={{
                          hover: {
                            fill: '#201553',
                            outline: 'none'
                          }
                        }}
                      />
                    )
                  })
                }
              </Geographies>
            </ZoomableGroup>
          </ComposableMap>
          {content && (
            <MouseTooltip offsetX={15} offsetY={10}>
              <Text t5 color="white" style={tooltipStyle}>
                {content}
              </Text>
            </MouseTooltip>
          )}
        </>
      )}
    />
  )
}
