import React, { useEffect, forwardRef } from 'react'
import { isEmpty, propOr } from 'ramda'
import { Field } from 'react-final-form'

import { QueryBuilderInput } from '../'
import { getInputType } from '../../utils/buildHelper'

export const QueryBuilderValue = forwardRef(
  (
    { name, inputOptions, field: { fields, ...fieldsRest }, ...sentenceProps },
    ref
  ) => {
    const defaultSubfieldsLabels = [{ label: 'Value' }]
    const options = inputOptions.subfields || defaultSubfieldsLabels

    const type = getInputType(inputOptions.type) || 'text'

    useInitialOptionsFilling({ fields, options })
    useNoValueSkip({ sentenceProps, inputOptions })

    const onRemove = onRemoveProps => {
      if (fields.length > 1) {
        fields.pop()
      } else {
        sentenceProps.onRemove(onRemoveProps)
        if (sentenceProps.onRemoveCleanUp) {
          sentenceProps.onRemoveCleanUp(onRemoveProps)
        }
      }
    }

    const onCommaAddField = () => fields.push('')

    const maybeMultipleValuesProps = {
      onCommaAddField: inputOptions.multipleValues && onCommaAddField
    }

    return fields.map((name, index) => (
      <Field
        name={name}
        key={name}
        render={props => <QueryBuilderInput ref={ref} {...props} />}
        placeholder={propOr('Value', 'label', options[index])}
        inputType={type}
        {...sentenceProps}
        {...fieldsRest}
        {...maybeMultipleValuesProps}
        onRemove={onRemove}
        fieldsInfo={{ total: fields.length, current: index + 1 }}
        suggestions={inputOptions.suggestions}
      />
    ))
  }
)

const useInitialOptionsFilling = ({ fields, options }) => {
  // Filling empty initial values per schema
  useEffect(() => {
    if (!fields.value || !fields.value.length) {
      options.forEach(() => fields.push(''))
    }
  }, [fields.length])
}

const useNoValueSkip = ({ sentenceProps, inputOptions }) => {
  // When no value is needed, just mark the field as completed
  useEffect(() => {
    if (
      isEmpty(inputOptions.subfields) &&
      sentenceProps.state === 'unmounted'
    ) {
      sentenceProps.onComplete()
    }
  }, [inputOptions])
}
