import React from 'react'

import {
  Box,
  Text,
  Spacing,
  GradientWrapper,
  NumberBox
} from '../../../../components/ui'
import { RenderFromQuery } from '../../../../components/util'
import { StatisticsQuery } from '../../../../state/queries'
import { WorldMap } from '../WorldMap'

export const UserStats = () => {
  return (
    <Spacing size="xl">
      <Text h2>Users</Text>
      <RenderFromQuery
        query={StatisticsQuery}
        renderData={({ statistics: { member } }) => (
          <Spacing size="s">
            <Box as={GradientWrapper} size="l" gradient="purpleGradient">
              <Spacing size="l">
                <Spacing direction="row" justify="space-between" align="center">
                  <Text h2 color="white">
                    {member.totalUsers}
                  </Text>
                  <Text t3 color="white">
                    Ubdi users
                  </Text>
                </Spacing>
                <WorldMap />
              </Spacing>
            </Box>
            <Spacing direction="row" size="s" stretchChildren>
              <NumberBox gradient="purpleGradient" title="Live Users">
                {member.liveUsers}
              </NumberBox>
              <NumberBox gradient="purpleGradient" title="Countries">
                {member.distinctCountries}
              </NumberBox>
            </Spacing>
          </Spacing>
        )}
      />
    </Spacing>
  )
}
