import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import {
  Modal,
  Button,
  Spacing,
  SubmitButton,
  SubmitError,
  Text,
  Number
} from '../../components/ui'
import { RenderFromQuery } from '../../components/util'
import { AudienceSchemaQuery, StatisticsQuery } from '../../state/queries'
import { withMutation } from '../../components/wrappers'

import { TagFilters } from '../../components/blocks'
import { TagQuestions } from '../../components/blocks/TagFilters/TagQuestions'
import { ContextualHoverProvider } from '../../state/contexts'
import { useContextualHover } from '../../hooks'
import { flattenAudienceSchema } from '../../util'
import { keys } from 'ramda'

const Wrap = styled.div`
  display: flex;
  height: 100%;
`

const ModalWrap = styled.div`
  width: 830px;
  height: 640px;
  position: relative;
`

const TagFiltersWrap = styled.div`
  flex: 1;
  overflow: scroll;
`

const Sidebar = styled.div`
  width: 200px;
  height: 95%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${({ theme }) => theme.padding.xl};
`

const Actions = styled.div`
  bottom: 30px;
  left: 100%;
  width: 200px;
`

const EstimateBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.color.purpleL3};
    padding: ${theme.padding.m};
    border-radius: ${theme.borderRadius.base};
  `}
`

const roundToNearestTen = n => Math.ceil(n / 10) * 10

const DefineAudienceView = ({ onDismiss, params = {} }) => {
  const { onSave, tags } = params

  const onSubmit = values => {
    onSave && onSave({ tags: values })
    onDismiss()
  }

  const { hovering } = useContextualHover()

  const getMaximumReach = ({ selected = {}, audienceSchema, totalUsers }) => {
    const flatSchema = flattenAudienceSchema(audienceSchema)
    const selectedKeys = keys(selected).reduce(
      (acc, k) => [...acc, ...selected[k]],
      []
    )
    const selectedCounts = selectedKeys.map(key =>
      flatSchema[key] ? flatSchema[key].userCount : 0
    )
    const maxTagCount = Math.max(...selectedCounts)

    if (maxTagCount > 0) {
      return roundToNearestTen(maxTagCount)
    }

    return totalUsers
  }

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title="Define Audience"
    >
      {({ onDismiss }) => (
        <Form mutators={arrayMutators} onSubmit={onSubmit} initialValues={tags}>
          {({ handleSubmit, submitError, form, values, ...formProps }) => (
            <ModalWrap>
              <RenderFromQuery
                query={AudienceSchemaQuery}
                renderData={({ audienceSchema }) => (
                  <Wrap>
                    <TagFiltersWrap>
                      <TagFilters
                        categories={audienceSchema}
                        renderFilterComponent={props => (
                          <Field
                            name="and"
                            component={TagQuestions}
                            form={form}
                            {...props}
                          />
                        )}
                      />
                    </TagFiltersWrap>
                    <Sidebar>
                      <Spacing size="l">
                        <Spacing size="xs">
                          <Text t4>Total UBDI Users</Text>
                          <Text h2>
                            <RenderFromQuery
                              query={StatisticsQuery}
                              renderData={({
                                statistics: {
                                  member: { totalUsers }
                                }
                              }) => <Number>{totalUsers}</Number>}
                              renderLoading={() => '...'}
                            />
                          </Text>
                        </Spacing>
                        <Spacing size="xs">
                          <Text t4>Maximum Reach</Text>
                          <Text h2>
                            <RenderFromQuery
                              query={StatisticsQuery}
                              renderData={({
                                statistics: {
                                  member: { totalUsers }
                                }
                              }) => (
                                <Number>
                                  {getMaximumReach({
                                    selected: values.and,
                                    audienceSchema,
                                    totalUsers
                                  })}
                                </Number>
                              )}
                              renderLoading={() => '...'}
                            />
                          </Text>
                        </Spacing>
                        {hovering && (
                          <EstimateBox>
                            <Spacing>
                              <Text color="main" h7>
                                {hovering.label}
                              </Text>
                              <Spacing size="xs">
                                <Text t4>Total Users</Text>
                                <Text h2>
                                  {hovering.extra.userCount > 0 ? (
                                    <Number>
                                      {roundToNearestTen(
                                        hovering.extra.userCount
                                      )}
                                    </Number>
                                  ) : (
                                    <>
                                      <Spacing direction="row" align="center">
                                        ~<Text t3>not enough data</Text>
                                      </Spacing>
                                    </>
                                  )}
                                </Text>
                              </Spacing>
                            </Spacing>
                          </EstimateBox>
                        )}
                        <Text t4>
                          We are showing a maximum reach instead of an estimated
                          reach because we do not keep demographic info about
                          our users in the system. We just keep the counts of
                          individual tags!
                        </Text>
                      </Spacing>
                      <Actions>
                        <Spacing size="l">
                          {submitError && (
                            <SubmitError submitError={submitError} />
                          )}
                          <Button
                            onClick={() => {
                              form.reset()
                              onDismiss()
                            }}
                            block
                            outline
                          >
                            Cancel
                          </Button>
                          <SubmitButton
                            onClick={handleSubmit}
                            {...formProps}
                            block
                          >
                            Save Tags
                          </SubmitButton>
                        </Spacing>
                      </Actions>
                    </Sidebar>
                  </Wrap>
                )}
              />
            </ModalWrap>
          )}
        </Form>
      )}
    </Modal>
  )
}

const DefineAudienceWithContext = props => (
  <ContextualHoverProvider>
    <DefineAudienceView {...props} />
  </ContextualHoverProvider>
)

export const DefineAudience = withMutation('updateOpportunity')(
  DefineAudienceWithContext
)
