import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import posed from 'react-pose'

import { Text, Spacing } from '../'
import theme from '../../../theme'

const Wrap = styled.div`
  padding: ${({ theme }) => theme.padding.xl};
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.borderRadius.large};

  overflow: hidden;
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.color.purpleL3}

  transition: all ${({ theme }) => theme.animationSpeed.slow};

  ${({ disabled, expanded }) =>
    !disabled &&
    css`
      &:hover {
        background-color: #fff;
        ${!expanded &&
          css`
            border-color: ${({ theme }) => theme.color.main};
          `}
      }
    `}

  ${({ error, theme }) =>
    error &&
    css`
      border-color: ${theme.color.redNegative};
    `}

  ${({ expanded }) =>
    expanded
      ? css`
          background-color: #fff;
          box-shadow: ${theme.boxShadow.large};
        `
      : css`
          cursor: pointer;
          background-color: ${theme.color.purpleL3};
        `}

  ${({ disabled }) => disabled && `cursor: not-allowed;`}
`

const AnimatedWrap = posed(Wrap)()

const TitleWrap = styled(Text)`
  display: flex;
  justify-content: space-between;
`

const AnimatedTextWrap = ({ persistentTitle, ...props }) =>
  persistentTitle ? (
    <AnimatedPersistentText {...props} />
  ) : (
    <AnimatedText {...props} />
  )

const AnimatedPersistentText = posed(
  styled(TitleWrap)`
    border-bottom: 1px solid transparent;
  `
)({
  collapsed: {
    marginBottom: 0,
    paddingBottom: 0,
    borderBottomColor: 'rgba(0, 0, 0, 0)'
  },
  expanded: {
    marginBottom: 30,
    paddingBottom: 30,
    borderBottomColor: theme.color.greyL2
  }
})

const AnimatedText = posed(TitleWrap)({
  collapsed: {
    height: 'auto',
    opacity: 1,
    transition: {
      opacity: { duration: 300 }
    }
  },
  expanded: {
    height: 0,
    opacity: ({ persistentTitle }) => (persistentTitle ? 1 : 0),
    transition: {
      opacity: { duration: 100 }
    }
  }
})

export const AnimatedChildren = posed.div({
  expanded: {
    applyAtStart: { display: 'block' },
    height: 'auto',
    opacity: 1,
    transition: {
      opacity: {
        delay: 100,
        duration: 100
      },
      default: {
        type: 'spring',
        damping: 50,
        duration: 500,
        stiffness: 200
      }
    }
  },
  collapsed: {
    applyAtEnd: { display: 'none' },
    height: 0,
    opacity: 0,
    transition: {
      type: 'spring',
      damping: 50,
      duration: 500,
      stiffness: 200
    }
  }
})

const stopPropagation = cb => e => {
  e && e.nativeEvent instanceof Event && e.stopPropagation()
  cb(e)
}

export const ControlledInlineModal = ({
  children,
  disabled,
  persistentTitle,
  summary,
  title,
  onExpand,
  onCollapse,
  expanded,
  error
}) => (
  <AnimatedWrap
    pose={expanded ? 'expanded' : 'collapsed'}
    onClick={!expanded ? onExpand : () => {}}
    expanded={expanded}
    disabled={disabled}
    error={error}
  >
    <AnimatedTextWrap
      h4
      color={disabled ? 'purpleL2' : 'main'}
      persistentTitle={persistentTitle}
    >
      <Spacing direction="row" align="center">
        + {title}
      </Spacing>
      {summary && summary({ expanded })}
    </AnimatedTextWrap>
    <AnimatedChildren persistentTitle={persistentTitle}>
      {children &&
        children({
          onCollapse: stopPropagation(onCollapse),
          expanded
        })}
    </AnimatedChildren>
  </AnimatedWrap>
)

export class InlineModal extends Component {
  state = {
    expanded: false
  }

  expand = () => {
    if (this.props.disabled) return
    this.setState({ expanded: true })
  }

  collapse = stopPropagation(() => this.setState({ expanded: false }))

  render() {
    return (
      <ControlledInlineModal
        expanded={this.state.expanded}
        onCollapse={this.collapse}
        onExpand={this.expand}
        {...this.props}
      />
    )
  }
}

InlineModal.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}
