import React, { useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { symmetricDifference, reduce, values, flatten } from 'ramda'

import {
  Modal,
  Button,
  Spacing,
  Text,
  SubmitButton,
  SubmitError
} from '../../components/ui'
import { RenderFromQuery } from '../../components/util'
import { AudienceSchemaQuery } from '../../state/queries'
import { withMutation } from '../../components/wrappers'
import { TagFilters } from '../../components/blocks'
import { ResponseTagQuestions } from '../../components/blocks/TagFilters/ResponseTagQuestions'
import {
  getAudienceCategoriesFromRequirements,
  getAdditionalResponseTags,
  getRequiredResponseTags
} from '../../util'
import { validatorEnoughAdditionalTags } from '../../util/validators'

const Wrap = styled.div`
  display: flex;
`

const ModalWrap = styled.div`
  width: 830px;
  position: relative;
`

const TagFiltersWrap = styled.div`
  flex: 1;
`

const Sidebar = styled.div`
  width: 200px;
  height: 100%;
  position: sticky;
  top: 100px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${({ theme }) => theme.padding.xl};
`

const Actions = styled.div`
  bottom: 30px;
  left: 100%;
  width: 200px;
  position: sticky;
`

const DefineAdditionalResponseTagsView = ({
  onDismiss,
  params = {},
  updateOpportunity
}) => {
  const { opportunity } = params
  const [totalQuestions, setTotalQuestions] = useState(0)
  const initialValues = {
    tags: {
      additionalResponseTags: flatten(
        values(getAdditionalResponseTags(opportunity))
      ),
      requiredResponseTags: getRequiredResponseTags(opportunity)
    }
  }

  // selected audienceRequirements in step 1.
  const audienceRequirementsCategories = useMemo(
    () =>
      getAudienceCategoriesFromRequirements(opportunity.audienceRequirements),
    [opportunity.audienceRequirements]
  )
  // exclude ones from audienceRequirements (just additionalResponseTags)
  const filterResponseTags = allSelectedTags =>
    symmetricDifference(allSelectedTags, audienceRequirementsCategories)

  const onSubmit = ({
    tags: { additionalResponseTags, requiredResponseTags }
  }) => {
    const filteredResponseTags = filterResponseTags(additionalResponseTags)

    return updateOpportunity({
      variables: {
        id: opportunity.id,
        responseConfig: {
          additionalResponseTags: filteredResponseTags,
          requiredResponseTags
        }
      }
    }).then(({ userErrors, errors }) => {
      if (!userErrors && !errors) return onDismiss()

      return userErrors || errors
    })
  }

  const calculateTotalQuestionsCount = (audienceSchema = []) => {
    const total = reduce(
      (total, item) => {
        total += (item.children && item.children.length) || 0
        return total
      },
      0,
      audienceSchema
    )

    setTotalQuestions(total)
  }

  const getSelectedQuestionsCount = (allQuestions = []) =>
    filterResponseTags(allQuestions).length

  const validator = ({ tags: { additionalResponseTags } }) => {
    const filteredResponseTags = filterResponseTags(additionalResponseTags)

    return validatorEnoughAdditionalTags({
      additionalResponseTags: filteredResponseTags
    })
  }

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title="Find out more about your audience"
    >
      {({ onDismiss }) => (
        <Form
          mutators={arrayMutators}
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validator}
        >
          {({ handleSubmit, submitError, form, values, ...formProps }) => (
            <ModalWrap>
              <RenderFromQuery
                query={AudienceSchemaQuery}
                renderData={({ audienceSchema }) => {
                  if (totalQuestions === 0)
                    calculateTotalQuestionsCount(audienceSchema)

                  const selectedQuestions = getSelectedQuestionsCount(
                    values.tags.additionalResponseTags
                  )

                  return (
                    <Wrap>
                      <TagFiltersWrap>
                        <TagFilters
                          categories={audienceSchema}
                          renderFilterComponent={props => (
                            <Field
                              name="tags"
                              component={ResponseTagQuestions}
                              audienceRequirementsCategories={
                                audienceRequirementsCategories
                              }
                              {...props}
                            />
                          )}
                        />
                      </TagFiltersWrap>
                      <Sidebar>
                        <Spacing size="xl">
                          <Spacing size="s">
                            <Text t3>Total questions</Text>
                            <Text h2>{totalQuestions}</Text>
                          </Spacing>
                          <Spacing size="s">
                            <Text t3>Selected questions</Text>
                            <Text h2>{selectedQuestions}</Text>
                          </Spacing>
                        </Spacing>
                      </Sidebar>
                    </Wrap>
                  )
                }}
              />
              <Actions>
                <Spacing size="l">
                  {submitError && <SubmitError submitError={submitError} />}
                  <Button
                    onClick={() => {
                      form.reset()
                      onDismiss()
                    }}
                    block
                    outline
                  >
                    Cancel
                  </Button>
                  <SubmitButton onClick={handleSubmit} {...formProps} block>
                    Save Questions
                  </SubmitButton>
                </Spacing>
              </Actions>
            </ModalWrap>
          )}
        </Form>
      )}
    </Modal>
  )
}

export const DefineAdditionalResponseTags = withMutation('updateOpportunity')(
  DefineAdditionalResponseTagsView
)
