import React from 'react'
import { pipe, equals, filter, take, slice, head } from 'ramda'
import { graphql } from 'react-apollo'

import { Spacing } from '../../ui'

import { CurrentUserQuery } from '../../../state/queries'
import {
  PauseStudyButton,
  CancelStudyButton,
  EditPublishedButton,
  ExportResultsButton,
  ContinueStudyButton,
  ContinueDraftButton,
  ReturnToDraftButton,
  ApproveButton,
  DeleteDraftButton,
  SendNotificationButton,
  StudyManipulationDropdown,
  SyncTypeformButton
} from './components'

export const StudyManipulationInner = ({
  opportunity,
  data: {
    currentUser: { currentRole }
  }
}) => {
  const { id, state } = opportunity
  const isAdmin = equals(currentRole, 'admin')

  const EditInReviewButton = props => (
    <EditPublishedButton {...props} text="Review" />
  )

  const getButtonsForRender = () => {
    switch (state) {
      case 'published':
        return [
          PauseStudyButton,
          CancelStudyButton,
          isAdmin && EditPublishedButton,
          ExportResultsButton,
          isAdmin && SendNotificationButton,
          SyncTypeformButton
        ]
      case 'paused':
        return [ContinueStudyButton, CancelStudyButton, ExportResultsButton]
      case 'draft':
        return [DeleteDraftButton, ContinueDraftButton]
      case 'inReview':
        return [
          isAdmin && EditInReviewButton,
          isAdmin && ApproveButton,
          ReturnToDraftButton
        ]
      case 'completed':
        return [
          ExportResultsButton,
          isAdmin && ReturnToDraftButton,
          SyncTypeformButton
        ]
      default:
        return []
    }
  }

  const buttonsForRender = getButtonsForRender()
  const shouldRenderWithDropdown = buttonsForRender.filter(Boolean).length > 2

  const renderButtons = () =>
    buttonsForRender
      .filter(Boolean)
      .map(Component => (
        <Component key={id} id={id} opportunity={opportunity} />
      ))

  const renderDropdownActions = () => {
    const FirstAction = pipe(
      filter(Boolean),
      take(1),
      head
    )(buttonsForRender)

    const dropdownItems = pipe(
      filter(Boolean),
      slice(1, buttonsForRender.length)
    )(buttonsForRender)

    return (
      <Spacing direction="row" size="l">
        <FirstAction id={id} />
        <StudyManipulationDropdown
          dropdownItems={dropdownItems}
          opportunity={opportunity}
          id={id}
        />
      </Spacing>
    )
  }

  return (
    <Spacing direction="row" size="l">
      {shouldRenderWithDropdown ? renderDropdownActions() : renderButtons()}
    </Spacing>
  )
}

export const StudyManipulation = pipe(graphql(CurrentUserQuery))(
  StudyManipulationInner
)
