import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import { map, find, pipe, prop } from 'ramda'

import { Text } from '..'
import { Graphic } from '../Graphic'
import { withFieldError } from '../../wrappers'
import {
  getBackgroundColor,
  getBorder,
  getHoveredBackgroundColor
} from '../../../util/ui'

const InputWrap = styled.div`
  width: 100%;
  position: relative;
`

const DefaultSelect = styled.select`
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
`

const CustomSelect = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  padding: 0 ${({ theme }) => theme.padding.l};

  display: flex;
  flex-direction: row;

  background: ${getBackgroundColor};
  border: ${getBorder};

  ${({ isErrored, theme }) => isErrored && `border-color: ${theme.color.red}`}
  border-radius: ${({ theme }) => theme.borderRadius.base};
  outline: none;

  ${({ theme }) => theme.text.t2}

  align-items: center;
  transition: background-color ${({ theme }) => theme.animationSpeed.slow};

  ${InputWrap}:hover & {
    background: ${getHoveredBackgroundColor};
  }
`

const Label = styled.label`
  color: ${({ theme }) => theme.color.main};
  ${({ theme }) => theme.text.h6};
  ${({ theme, input }) => !!input.value && theme.text.label};

  ${({ isErrored, theme }) => isErrored && `color: ${theme.color.red}`}

  transition: all 0.1s ease-in-out;
`

const Value = styled(Text)`
  color: ${({ color }) => color};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const TextWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme, graphic }) =>
    graphic &&
    css`
      padding-left: ${theme.padding.l};
    `}

  pointer-events: none;
`

const GraphicWrap = styled(Graphic)`
  pointer-events: none;
`

const SelectView = props => {
  const {
    label,
    options,
    graphic,
    graphicSize = 16,
    graphicColor,
    emptySelectionPossible,
    emptySelectionText = 'No preference'
  } = props

  const renderArrow = () =>
    props.meta.active ? (
      <GraphicWrap name="ArrowUp" size={10} />
    ) : (
      <GraphicWrap name="ArrowDown" size={10} />
    )

  const renderOptions = map(item => (
    <option key={item.value} value={item.value}>
      {item.label}
    </option>
  ))(options)

  const getLabelByValue = selectedValue =>
    pipe(
      find(({ value }) => selectedValue.toString() === value.toString()),
      prop('label')
    )(options)

  return (
    <InputWrap>
      <DefaultSelect {...props.input}>
        <option disabled={!emptySelectionPossible} value="">
          {emptySelectionPossible ? emptySelectionText : 'Please select...'}
        </option>
        {renderOptions}
      </DefaultSelect>
      <CustomSelect {...props}>
        {graphic && (
          <GraphicWrap
            name={graphic}
            size={graphicSize}
            themeColor={graphicColor}
          />
        )}
        <TextWrap {...props}>
          <Label {...props}>{label}</Label>
          {!!props.input.value ? (
            <Value h6 color={props.textColor}>
              {getLabelByValue(props.input.value)}
            </Value>
          ) : (
            emptySelectionPossible && (
              <Value h6 color={props.textColor}>
                {emptySelectionText}
              </Value>
            )
          )}
        </TextWrap>
        {renderArrow()}
      </CustomSelect>
    </InputWrap>
  )
}

export const Select = withFieldError()(SelectView)

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  label: PropTypes.string,
  emptySelectionPossible: PropTypes.bool
}

Select.defaultProps = {
  options: [],
  emptySelectionPossible: false
}
