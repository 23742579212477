import React from 'react'

import { Text, Spacing, SpacingItem } from '../../../../ui'

export const TabledDataSummary = ({ label, value, injectHtml = false }) => (
  <Spacing direction="row" key={value}>
    <SpacingItem flex="0 1 40%">
      <Text t3>{label}</Text>
    </SpacingItem>
    <SpacingItem flex="0 1 60%">
      <Text t3 color="main">
        {injectHtml ? (
          <div dangerouslySetInnerHTML={{ __html: value }} />
        ) : (
          value
        )}
      </Text>
    </SpacingItem>
  </Spacing>
)
