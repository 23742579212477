import React from 'react'
import { graphql } from 'react-apollo'

import {
  Spacing,
  Container,
  ContainerSpinner,
  Text,
  Notice,
  Tabs
} from '../../components/ui'
import { OpportunityMinimalQuery } from '../../state/queries/index.js'
import { DataViewer } from '../../components/blocks/DataViewer'

import { Demographics } from './components'

export const Playground = ({ data: { opportunity } }) => {
  if (!opportunity) {
    return <ContainerSpinner />
  }

  return (
    <Container size="xxl">
      <Spacing stretched>
        <Text h1>Playground</Text>
        <Tabs size="xxl">
          <Tabs.Tab label="Data">
            <Spacing>
              <Notice title="Take a look at our sample data">
                Once you run your own studies the data collected will look like
                this.
              </Notice>
              <DataViewer opportunity={opportunity} demo />
            </Spacing>
          </Tabs.Tab>
          <Tabs.Tab label="Demographics">
            <Demographics />
          </Tabs.Tab>
        </Tabs>
      </Spacing>
    </Container>
  )
}

export const PlaygroundScreen = graphql(OpportunityMinimalQuery, {
  options: () => ({
    variables: {
      id: process.env.REACT_APP_PLAYGROUND_OPPORTUNITY_ID
    }
  })
})(Playground)
