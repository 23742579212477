import gql from 'graphql-tag'

export const DashboardUserStatsQuery = gql`
  query DashboardUserStats {
    stats {
      dashboard {
        users {
          liveUsers
          totalUsers
          distinctCountries
        }
      }
    }
  }
`
export const DashboardAudienceTagsQuery = gql`
  query DashboardAudienceTags {
    stats {
      dashboard {
        audienceTags {
          audienceTag {
            tag
            total
          }
        }
      }
    }
  }
`

export const DashboardDatasourceQuery = gql`
  query DashboardDatasource {
    stats {
      dashboard {
        dataSources {
          all {
            dataSource {
              name
            }
            linkedUsersHistory {
              date
              total
            }
            dataPoints
          }
        }
      }
    }
  }
`

export const UsersCountriesQuery = gql`
  query UsersCountries {
    stats {
      dashboard {
        users {
          usersCountries
        }
      }
    }
  }
`
