import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { Spacing, Text, Number } from '../../../../ui'
import { equals } from 'ramda'

const Wrapper = styled.div`
  padding-top: ${({ theme, ad }) => !ad && theme.padding.l};
  border-top: ${({ ad, theme }) =>
    !ad ? `1px solid ${theme.color.greyL2}` : 'none'};
`
const TotalReachWrap = styled.div`
  padding-bottom: ${({ theme, ad }) =>
    ad ? theme.padding.l : theme.padding.s};
`

const StudyPriceWrap = styled.div`
  padding-top: ${({ theme, ad }) => ad && theme.padding.l};
  border-top: ${({ ad, theme }) =>
    ad ? `1px solid ${theme.color.greyL2}` : 'none'};
`

const AdPriceWrap = styled.div`
  padding-top: ${({ theme, ad }) => ad && theme.padding.l};
  border-top: ${({ ad, theme }) =>
    ad ? `1px solid ${theme.color.greyL2}` : 'none'};
`

const defaultRenderItem = ({ label, value }) => (
  <Spacing direction="row" justify="space-between" stretched>
    <Text h6 color="grey">
      {label}
    </Text>
    <Text h3>{value}</Text>
  </Spacing>
)

const renderAdItem = ({ label, value, sameValues }) => (
  <Spacing direction="row" justify="space-between" stretched>
    <Text t3 color="grey" decoration={sameValues && 'line-through'}>
      + {label}
    </Text>
    <Text
      t3={sameValues}
      h6={!sameValues}
      decoration={sameValues && 'line-through'}
    >
      {value}
    </Text>
  </Spacing>
)

export const TotalSummary = ({
  opportunity,
  opportunity: { ad },
  direction,
  renderItem = defaultRenderItem,
  renderAdPriceItem = renderAdItem,
  excludeFirst = false
}) => {
  const { reach, pricePerPerson } = opportunity

  const maxReach = reach.maxPermitted
  const total = maxReach * pricePerPerson

  const { isUnlimited } = reach

  return (
    <Spacing direction={direction} size="l" wrap stretchChildren>
      {
        // TODO: This one is temporary until we redesign TotalSummary too
        // we need to distinguish TotalSummary possible states
        // there are three states for now on new design
        // DefineAudience modal, DurationPriceWrap in StudyBuilder and Publish screen
      }
      <Wrapper ad={ad}>
        <TotalReachWrap ad={ad}>
          {!excludeFirst &&
            !isUnlimited &&
            renderItem({
              label: 'Total reach:',
              value: <Number prefix="~">{maxReach}</Number>
            })}
        </TotalReachWrap>
        {!ad ? (
          <StudyPriceWrap ad={ad}>
            <Spacing size="s">
              {renderItem({
                label: 'Estimated price per person:',
                value: <Number currency="USD">{pricePerPerson}</Number>
              })}
              {!isUnlimited &&
                renderItem({
                  label: 'Total price:',
                  value: <Number currency="USD">{total}</Number>
                })}
              {isUnlimited && (
                <Text t2 color="grey">
                  To get a rate you first need to set a limit on your audience
                  buckets.
                </Text>
              )}
            </Spacing>
          </StudyPriceWrap>
        ) : (
          <AdPriceWrap>
            <Spacing>
              {renderItem({
                label: 'Price breakdown'
              })}
              <Spacing size="l">
                {ad.pricePerClickComponents.map(item => (
                  <Spacing size="s" key={item.id}>
                    {renderAdPriceItem({
                      label: item.default.label,
                      value: (
                        <Number currency="USD">{item.default.amount}</Number>
                      ),
                      sameValues: !equals(
                        item.selected.label,
                        item.default.label
                      )
                    })}
                    {!equals(item.selected.label, item.default.label) &&
                      renderAdPriceItem({
                        label: item.selected.label,
                        value: (
                          <Number currency="USD">{item.selected.amount}</Number>
                        )
                      })}
                  </Spacing>
                ))}

                {renderAdPriceItem({
                  label: 'UBDI Cut (🦠COVID-19 Discount)',
                  value: <Number currency="USD">{0}</Number>
                })}
                {renderItem({
                  label: 'Total Cost Per Click',
                  value: <Number currency="USD">{ad.pricePerClick}</Number>
                })}
              </Spacing>
            </Spacing>
          </AdPriceWrap>
        )}
      </Wrapper>
    </Spacing>
  )
}

const propOptions = {
  directions: ['row', 'row-reverse', 'column', 'column-reverse']
}

TotalSummary.defaultProps = {
  direction: 'column'
}

TotalSummary.propTypes = {
  opportunity: PropTypes.object,
  direction: PropTypes.oneOf(propOptions.directions)
}
