import React from 'react'
import styled from 'styled-components/macro'
import { equals, isNil } from 'ramda'
import { Spacing, Text, Box } from '../../../../components/ui'

const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/

const VideoPlayerContainer = styled(Box)`
  height: 216px;
  background-color: ${({ theme }) => theme.color.purpleL3};
  padding: ${({ theme }) => theme.padding.l};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  border: 1px solid ${({ theme }) => theme.color.purpleL1};
  display: flex;
  align-items: center;
  justify-content: center;
`

const VideoPlayerStyle = styled.iframe`
  width: 304px;
  height: 176px;
  border: 0;
`

const extractVideoID = url => {
  if (!isNil(url)) {
    const match = url.match(regExp)
    if (match && equals(match[7].length, 11)) {
      return `https://www.youtube.com/embed/` + match[7]
    }
  }
}

export const VideoPlayer = ({ videoUrl, text = 'Video preview' }) => (
  <Spacing size="l">
    <Text h7>{text}</Text>
    <VideoPlayerContainer>
      <VideoPlayerStyle
        src={extractVideoID(videoUrl)}
        frameborder="0"
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
      />
    </VideoPlayerContainer>
  </Spacing>
)
