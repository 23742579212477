import React from 'react'
import { withMutation } from '../../../wrappers'
import { Button } from '../../../ui'
import { DropdownItem } from './DropdownItem'
import { StudiesRefetchQueries } from '../../../../state/queries'

const ContinueStudyButtonInner = ({
  id,
  continueOpportunity,
  dropdownItem
}) => {
  const text = 'Continue Study'

  const clickHandler = () =>
    continueOpportunity({
      variables: { id },
      refetchQueries: StudiesRefetchQueries
    })

  if (dropdownItem) {
    return <DropdownItem text={text} onClick={clickHandler} />
  }

  return (
    <Button onClick={clickHandler} outline>
      {text}
    </Button>
  )
}

export const ContinueStudyButton = withMutation('continueOpportunity', {
  showErrorNotifications: true
})(ContinueStudyButtonInner)
