// Reasonable doubt by uncle that this file is not used anymore

import React, { useState } from 'react'
import { RenderFromQuery } from '../../../../util'
import { OpportunityResponsesQuery } from '../../../../../state/queries/OpportunityResponsesQueries'
import { slice } from 'ramda'
import {
  Table,
  Spacing,
  EmptyState,
  Graphic,
  Text,
  ToggleChevron
} from '../../../../ui'
import styled from 'styled-components/macro'
import { useModal, useToggle } from '../../../../../hooks'

const ROWS_PER_PAGE = 10

const columns = [
  {
    title: 'ID',
    accessor: 'shortId'
  },
  {
    title: 'Matched Tags',
    accessor: 'matchedTagsLength'
  },
  {
    title: 'Insights',
    accessor: 'insightsLength'
  },
  {
    title: 'Survey Answers',
    accessor: 'surveyAnswersLength'
  }
]

const TableWrap = styled.div`
  flex: 1;
  tbody {
    tr {
      td:first-child div {
        color: ${({ theme }) => theme.color.main};
      }
    }
  }
`

const StyledTableHeader = styled.th`
  cursor: pointer;
`

const EmptyToggle = styled.div`
  width: 20px;
  height: 20px;
`

const OpportunityResponseRow = ({ opportunityResponse, columns, ...rest }) => (
  <tr {...rest}>
    {columns.map((column, index) => {
      return (
        <td key={index}>
          <Text t3 right={index > 2}>
            {opportunityResponse[column.accessor]}
          </Text>
        </td>
      )
    })}
  </tr>
)

export const OpportunityResponsesTable = ({
  opportunity,
  setShortId,
  openDataTab
}) => {
  const [page, setPage] = useState(1)
  const [isDesc, toggleIsDesc] = useToggle(true)
  const [orderBy, setOrderBy] = useState(null)
  const { openModal } = useModal()
  const setSortProps = accessor => {
    setPage(1)

    // if the same column toggle asc/desc
    if (orderBy === accessor) return toggleIsDesc()
    setOrderBy(accessor)
  }

  const fetchMore = (allOpportunityResponses, queryRenderParams) => {
    const name = 'allOpportunityResponses'
    queryRenderParams.fetchMore({
      variables: {
        after: allOpportunityResponses.pageInfo.endCursor,
        sort: isDesc ? 'desc' : 'asc',
        orderBy
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return {
          [name]: {
            ...fetchMoreResult[name],
            edges: prev[name].edges.concat(fetchMoreResult[name].edges)
          }
        }
      }
    })
  }

  const getPreviousPage = () => {
    setPage(page - 1)
  }

  const getNextPage = (allOpportunityResponses, queryRenderParams) => {
    if (allOpportunityResponses.edges.length) {
      fetchMore(allOpportunityResponses, queryRenderParams)
    }
    setPage(page + 1)
  }
  const openDetails = rowData => {
    openModal('StudyResultsViewer', {
      ...rowData,
      opportunity,
      openDataTab,
      setShortId
    })
  }
  return (
    <RenderFromQuery
      query={OpportunityResponsesQuery}
      variables={{
        opportunity: opportunity.id,
        after: null,
        first: ROWS_PER_PAGE,
        orderBy,
        sort: isDesc ? 'desc' : 'asc'
      }}
      renderEmpty={() => (
        <EmptyState
          title="Waiting for Insights"
          text="Insights from your Study will appear here as soon as people enter and respond"
          graphic={
            <Graphic name="Loading" width={145} height={60} themeColor="main" />
          }
        />
      )}
      renderData={({ allOpportunityResponses }, queryRenderParams) => {
        const rows = slice(
          (page - 1) * ROWS_PER_PAGE,
          page * ROWS_PER_PAGE,
          allOpportunityResponses.edges
        )

        return (
          <TableWrap>
            <Table
              showPagination
              page={page}
              numberOfPages={Math.ceil(
                allOpportunityResponses.totalCount / ROWS_PER_PAGE
              )}
              previousPage={getPreviousPage}
              nextPage={() =>
                getNextPage(allOpportunityResponses, queryRenderParams)
              }
              tableHead={columns.map((column, index) => (
                <StyledTableHeader
                  key={index}
                  onClick={() => {
                    setSortProps(column.accessor)
                  }}
                >
                  <Spacing
                    size="xxs"
                    direction="row"
                    justify="space-between"
                    align="center"
                    stretched
                  >
                    <Text t3 color="dark" right={index > 0}>
                      {column.title}
                    </Text>
                    {orderBy === column.accessor ? (
                      <ToggleChevron
                        toggled={!isDesc}
                        onToggle={() => toggleIsDesc()}
                        small
                      />
                    ) : (
                      <EmptyToggle />
                    )}
                  </Spacing>
                </StyledTableHeader>
              ))}
            >
              {rows.map(({ node }, index) => (
                <OpportunityResponseRow
                  key={index}
                  onClick={() => openDetails(node)}
                  columns={columns}
                  opportunityResponse={node}
                />
              ))}
            </Table>
          </TableWrap>
        )
      }}
    />
  )
}
