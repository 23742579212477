import React from 'react'
import styled from 'styled-components/macro'
import { Box, Text, Spacing } from '../../../../components/ui'

const GraphicWrap = styled.div`
  background-color: ${({ theme }) => theme.color.purpleL2};
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
`

// could extract this component in future
const PlaygroundCard = ({ children, thumbnail, title }) => (
  <Box size="m" backgroundColor="purpleL3" noShadow link>
    <Spacing direction="row" align="center" size="l">
      <GraphicWrap
        style={thumbnail && { backgroundImage: `url(${thumbnail})` }}
      />
      <Spacing size="xs" justify="space-around">
        <Text h5>{title}</Text>
        <Text t2 color="grey">
          {children}
        </Text>
      </Spacing>
    </Spacing>
  </Box>
)

export const PlaygroundList = () => {
  return (
    <Box backgroundColor="white" size="xl">
      <Spacing size="xl">
        <Text h2>Playground</Text>
        <Spacing size="s">
          <a
            href={'https://youtu.be/Rb_AQOoHgOM'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <PlaygroundCard
              title="How do I create a simple study?"
              thumbnail="https://i3.ytimg.com/vi/Rb_AQOoHgOM/hqdefault.jpg"
            >
              Check out this tutorial that explains how to build your first
              study
            </PlaygroundCard>
          </a>

          <a href="/playground">
            <PlaygroundCard
              title="Play with sample data"
              thumbnail="https://ubdi-static.s3.amazonaws.com/images/playground-thumbnail.jpg"
            >
              Dig into our sample data to get a better picture of what to expect
              from studies
            </PlaygroundCard>
          </a>

          <a href="https://help.typeform.com/hc/en-us/articles/360029112492-My-first-typeform">
            <PlaygroundCard
              title="Intro into Surveys"
              thumbnail="https://ubdi-static.s3.amazonaws.com/images/typeform-thumbnail.jpg"
            >
              Learn about how to build surveys with our integrated tool Typeform
            </PlaygroundCard>
          </a>

          <PlaygroundCard title="How to analyse the results from your finished study?">
            Explore what you can do with our integration with Tableau
          </PlaygroundCard>
        </Spacing>
      </Spacing>
    </Box>
  )
}
