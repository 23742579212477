import gql from 'graphql-tag'

export const UserGroupFragment = gql`
  fragment UserGroup on UserGroup {
    id
    name
    membersLimit
    membersCount
    brandLogosUrls
    logoUrl
  }
`
