import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../../../theme'
import { Text } from '../../../ui'

const ItemWrap = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => `${theme.padding.m} ${theme.padding.l}`};
  border-bottom: 1px solid ${({ theme }) => theme.color.greyL2};
  color: ${({ theme }) => theme.color.dark};
  overflow: hidden;
  text-align: left;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.purpleL3};
    transition: background-color ${({ theme }) => theme.animationSpeed.normal};
  }
`

export const DropdownItem = ({
  text,
  onClick,
  disabled,
  textColor = theme.color.main
}) => {
  return (
    <ItemWrap onClick={onClick} disabled={disabled}>
      <Text h6 bold style={{ color: textColor }}>
        {text}
      </Text>
    </ItemWrap>
  )
}
