import React from 'react'
import styled from 'styled-components/macro'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'

import {
  Spacing,
  Text,
  Tag,
  CheckGroup,
  Touchable,
  Button,
  Input,
  LocationSelector,
  TagRangeSlider
} from '../../../components/ui'

const TagsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -${({ theme }) => theme.padding.s};
  margin-bottom: -${({ theme }) => theme.padding.s};
`

const PaddedTag = styled(Tag)`
  margin-right: ${({ theme }) => theme.padding.s};
  margin-bottom: ${({ theme }) => theme.padding.s};
`

const PaddedLocation = styled.div`
  width: 100%;
`

const SUPPORTED_CUSTOM_UI = ['text', 'age_picker', 'location_finder']

export const TagQuestions = ({ questions = [], input, form }) => (
  <Spacing size="xl">
    {questions.map(({ uniqueId, label, children, custom, multi }, i) => {
      if (custom && !SUPPORTED_CUSTOM_UI.includes(custom)) {
        return null
      }

      return (
        <Spacing key={uniqueId} size="l">
          <Text h6>{label}</Text>
          <TagsWrap>
            {renderQuestionControl({
              input,
              uniqueId,
              children,
              custom,
              multi,
              form,
              label
            })}
          </TagsWrap>
        </Spacing>
      )
    })}
  </Spacing>
)

const renderQuestionControl = ({
  input,
  uniqueId,
  children,
  custom,
  multi,
  form,
  label
}) => {
  const singleFieldProps = {
    format: values => values && values[0],
    parse: value => [value]
  }

  if (custom) {
    switch (custom) {
      case 'age_picker': {
        return (
          <FieldArray
            component={TagRangeSlider}
            name={`${input.name}.general_age`}
            form={form}
            domain={[14, 99]}
            multirange
          />
        )
      }

      case 'location_finder': {
        return (
          <PaddedLocation>
            <Spacing>
              <Text h7>City</Text>
              <FieldArray
                name={`${input.name}.general_location_city`}
                component={LocationSelector}
                target="locality"
                placeholder="Choose city"
              />
              <Text h7>Country</Text>
              <FieldArray
                name={`${input.name}.general_location_country`}
                component={LocationSelector}
                target="country"
                placeholder="Choose country"
              />
              <Text h7>State</Text>
              <FieldArray
                name={`${input.name}.general_location_state`}
                component={LocationSelector}
                target="administrative_area_level_1"
                placeholder="Choose state"
              />
              <Text h7>Zip code</Text>
              <FieldArray
                name={`${input.name}.general_location_zip`}
                component={LocationSelector}
                target="postal_code"
                placeholder="Choose ZIP"
              />
            </Spacing>
          </PaddedLocation>
        )
      }

      case 'text': {
        if (!multi) {
          return (
            <Field
              name={uniqueId}
              label="Custom text"
              component={Input}
              {...singleFieldProps}
            />
          )
        }

        return (
          <InputFieldArray input={input} uniqueId={uniqueId} label={label} />
        )
      }
      default: {
        return null
      }
    }
  }

  return (
    <FieldArray
      name={`${input.name}.${uniqueId}`}
      render={CheckGroup}
      renderCheck={props => <PaddedTag {...props} />}
      options={children.map(({ uniqueId, label, userCount }) => ({
        value: uniqueId,
        label,
        userCount
      }))}
    />
  )
}

const InputFieldArray = ({ input, uniqueId, label }) => {
  return (
    <FieldArray name={`${input.name}.${uniqueId}`}>
      {({ fields }) => (
        <Spacing stretched>
          {fields.map((name, index) => (
            <Spacing
              key={name}
              direction="row"
              justify="space-between"
              align="center"
            >
              <Field
                key={name}
                name={name}
                label={`${label} #${index + 1}`}
                component={Input}
              />
              <Touchable onClick={() => fields.remove(index)}>
                <Text t4 color="grey">
                  remove
                </Text>
              </Touchable>
            </Spacing>
          ))}
          <Button transparent link onClick={() => fields.push()} prefix="+">
            Add more
          </Button>
        </Spacing>
      )}
    </FieldArray>
  )
}
