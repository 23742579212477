import React from 'react'
import { Button } from '../../../ui'
import { withRouter } from 'react-router-dom'
import { DropdownItem } from './DropdownItem'

const EditPublishedButtonInner = ({
  id,
  dropdownItem,
  history,
  text = 'Edit'
}) => {
  const url = `/studies/builder/${id}`

  const clickHandler = () => history.push(url)

  if (dropdownItem) {
    return <DropdownItem text={text} onClick={clickHandler} />
  }
  return <Button to={url}>{text}</Button>
}

export const EditPublishedButton = withRouter(EditPublishedButtonInner)
