import gql from 'graphql-tag'

import { UserFragment } from '../fragments'

export const signUp = gql`
  mutation SignUp(
    $username: String
    $email: String!
    $phoneNumber: String
    $password: String
    $fullName: String
    $country: String
    $bonusCode: String
    $role: String
    $companyCode: String
  ) {
    signUp(
      username: $username
      email: $email
      phoneNumber: $phoneNumber
      password: $password
      fullName: $fullName
      country: $country
      bonusCode: $bonusCode
      role: $role
      companyCode: $companyCode
    ) {
      token
      user {
        id
        ...User
      }
      errors {
        key
        message
      }
    }
  }
  ${UserFragment}
`
