import { SURVEY_QUESTION_TYPES } from '../screens/StudyBuilder/components/SurveyBuilder/constants'

export const getEmptyQuestion = () => ({
  type: SURVEY_QUESTION_TYPES.singleChoice,
  required: false,
  title: '',
  image: null,
  videoUrl: '',
  choices: [getSingleChoiceOption({})]
})

export const getSingleChoiceOption = () => ({
  label: ''
})
