import React, { useState } from 'react'
import { omit, isNil } from 'ramda'
import { Form, Field, FormSpy } from 'react-final-form'

import {
  Text,
  Button,
  Spacing,
  SpacingItem,
  Container,
  ContainerSpinner,
  Select,
  Notice
} from '../../../../components/ui'
import { RenderFromQuery } from '../../../../components/util'
import { StudiesQuery } from '../../../../state/queries'
import { StudiesList } from '../../components/List'
import { OpportunityWelcomeScreen } from '../../Company/Welcome'
import { Search } from '../../../../components/ui/Search'
import { OpportunitiesFilters } from '../OpportunitiesFilters'
import styled from 'styled-components'

const ORDER_BY = {
  'Newest to oldest': 'desc',
  'Oldest to newest': 'asc'
}

const SidebarWrap = styled.div`
  width: 200px;
`
export const OpportunitiesOverview = ({
  filtersList,
  initialFilters,
  buttonLink,
  buttonText,
  sidebarTitle,
  category,
  allStates,
  userRole
}) => {
  const [filters, setFilters] = useState(initialFilters)
  const [studiesCount, setStudiesCount] = useState(0)

  const onChangeSubmit = variables => {
    const newFilters =
      !isNil(filters.name) && isNil(variables.name) // entered then deleted
        ? { ...omit(['name'])(filters) }
        : filters

    setFilters({
      categories: [category],
      ...newFilters,
      ...omit(['state'])(variables)
    })
  }

  const onStateChange = state => {
    setFilters({
      ...filters,
      state
    })
  }

  const queryParams = {
    ...omit(['state'])(filters),
    ...filtersList[filters.state]
  }

  return (
    <RenderFromQuery
      query={StudiesQuery}
      variables={{ ...allStates, categories: [category] }}
      renderEmpty={() => <OpportunityWelcomeScreen category={category} />}
      renderData={() => (
        <Form onSubmit={onChangeSubmit} initialValues={initialFilters}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Container size="xxl">
                <Spacing size="xxl" direction="row" stretched>
                  <SidebarWrap>
                    <Spacing size="xxl">
                      <Text h1>{sidebarTitle}</Text>
                      <Spacing size="l">
                        <OpportunitiesFilters
                          filters={filtersList}
                          selected={filters.state}
                          onSelect={onStateChange}
                          category={category}
                        />
                      </Spacing>
                      <Spacing>
                        <Button to={buttonLink}>{buttonText}</Button>
                        {userRole === 'admin' && (
                          <Button to="/stories/create">
                            New Story/Insight
                          </Button>
                        )}
                      </Spacing>
                    </Spacing>
                  </SidebarWrap>
                  <SpacingItem style={{ flex: 1 }}>
                    <Spacing size="xl" stretched>
                      <Spacing direction="row" justify="space-between">
                        <Spacing direction="row" size="xl" align="center">
                          <Search
                            name="name"
                            label="Name"
                            onSearch={handleSubmit}
                          />
                          <Text t3 color="grey">
                            {studiesCount} results
                          </Text>
                        </Spacing>
                        <FormSpy
                          subscription={{ dirtyFields: true }}
                          onChange={handleSubmit}
                        />
                        <div style={{ width: 320 }}>
                          <Field
                            name="sort"
                            component={Select}
                            label="Order by"
                            options={Object.keys(ORDER_BY).map(item => ({
                              label: item,
                              value: ORDER_BY[item]
                            }))}
                          />
                        </div>
                      </Spacing>
                      <RenderFromQuery
                        query={StudiesQuery}
                        variables={{
                          ...queryParams
                        }}
                        renderLoading={() => <ContainerSpinner />}
                        renderEmpty={() => {
                          setStudiesCount(0)

                          return <Notice>No results</Notice>
                        }}
                        renderData={(
                          { allOpportunities },
                          queryRenderParams
                        ) => {
                          setStudiesCount(allOpportunities.totalCount)

                          return (
                            <StudiesList
                              studies={allOpportunities}
                              queryRenderParams={queryRenderParams}
                              category={category}
                            />
                          )
                        }}
                      />
                    </Spacing>
                  </SpacingItem>
                </Spacing>
              </Container>
            </form>
          )}
        </Form>
      )}
    />
  )
}
