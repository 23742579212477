import React from 'react'
import { Spacing, Text } from '../../../../../../components/ui'
import styled from 'styled-components'

const ClickableText = styled(Text)`
  &:hover {
    cursor: pointer;
  }
`

export const ImageActions = ({ replace, remove }) => (
  <Spacing direction="row" justify="center">
    <ClickableText h7 color="grey" onClick={remove}>
      Delete
    </ClickableText>
    <Text h7 color="grey">
      ·
    </Text>
    <ClickableText h7 color="grey" onClick={replace}>
      Replace
    </ClickableText>
  </Spacing>
)
