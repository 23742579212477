import React from 'react'
import {
  Spacing,
  Text,
  ContainerSpinner,
  Button
} from '../../../../components/ui'
import { DataSourceStatsCard } from '../'
import { RenderFromQuery } from '../../../../components/util'
import { DashboardDatasourceQuery } from '../../../../state/queries'

export const DataSourcesStats = ({ limit }) => {
  return (
    <Spacing size="xl">
      <Spacing direction="row" align="center">
        <Text h2>Data Sources</Text>
        <Button transparent link themeColor="greyL1" to="/data-sources">
          See all {'>'}
        </Button>
      </Spacing>
      <RenderFromQuery
        query={DashboardDatasourceQuery}
        renderLoading={() => <ContainerSpinner />}
        renderData={({ stats: { dashboard } }) => (
          <Spacing size="s">
            {dashboard.dataSources.all.slice(0, limit).map((data, n) => (
              <DataSourceStatsCard key={n} data={data} />
            ))}
          </Spacing>
        )}
      />
    </Spacing>
  )
}
