import React from 'react'
import styled from 'styled-components/macro'

import { Graphic } from '../'

const SpinnerWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: ${({ theme }) => theme.padding.xxxl};
`

const GraphicWrap = styled(Graphic)`
  align-self: center;
  justify-content: center;
  flex: 1;
`
export const CenteredSpinner = () => (
  <SpinnerWrapper>
    <GraphicWrap name="Spinner" size={100} themeColor="main" />
  </SpinnerWrapper>
)
