import React from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'ramda'
import theme from '../../../theme'
import { Box, Text, Number, Spacing, GradientWrapper, Tag } from '../'

const Wrapper = ({ backgroundColor, children, ...props }) =>
  backgroundColor ? (
    <Box backgroundColor={backgroundColor} {...props}>
      {children}
    </Box>
  ) : (
    <Box as={GradientWrapper} {...props}>
      {children}
    </Box>
  )

const renderNumber = (prefix, currency, children) => (
  <Number prefix={prefix} currency={currency}>
    {children}
  </Number>
)

const NumberBoxTag = ({
  backgroundColor,
  title,
  children,
  prefix,
  currency
}) => (
  <Wrapper backgroundColor={backgroundColor} size="m" noShadow>
    <Spacing size="s">
      <Tag label={title} color="white" />
      <Text h3 color="dark">
        {renderNumber(prefix, currency, children)}
      </Text>
    </Spacing>
  </Wrapper>
)

const NumberBoxNormal = ({
  backgroundColor,
  gradient,
  title,
  children,
  prefix,
  currency
}) => (
  <Wrapper backgroundColor={backgroundColor} gradient={gradient} size="l">
    <Spacing size="l">
      <Text h6 color="white">
        {title}
      </Text>
      <Text h1 color="white">
        {renderNumber(prefix, currency, children)}
      </Text>
    </Spacing>
  </Wrapper>
)

const NumberBoxSmall = ({
  backgroundColor,
  gradient,
  title,
  children,
  prefix,
  currency
}) => (
  <Wrapper
    backgroundColor={backgroundColor}
    gradient={gradient}
    size="l"
    noShadow
  >
    <Spacing size="s">
      <Text t3 color="white">
        {title}
      </Text>
      <Text h3 color="white">
        {renderNumber(prefix, currency, children)}
      </Text>
    </Spacing>
  </Wrapper>
)

const NumberBoxExtraSmall = ({
  backgroundColor,
  gradient,
  title,
  children,
  prefix,
  currency
}) => (
  <Wrapper
    backgroundColor={backgroundColor}
    gradient={gradient}
    size="m"
    noShadow
  >
    <Spacing size="s">
      <Text h7 color="white">
        {title}
      </Text>
      <Text h3 color="white">
        {renderNumber(prefix, currency, children)}
      </Text>
    </Spacing>
  </Wrapper>
)

export const NumberBox = props => {
  if (isNil(props.children)) return null
  if (props.tag) return <NumberBoxTag {...props} />

  switch (props.size) {
    case 's':
      return <NumberBoxSmall {...props} />
    case 'xs':
      return <NumberBoxExtraSmall {...props} />
    default:
      return <NumberBoxNormal {...props} />
  }
}

const propOptions = {
  color: Object.keys(theme.color),
  gradient: Object.keys(theme.gradient),
  size: ['m', 's', 'xs']
}

NumberBox.defaultProps = {
  tag: false
}

NumberBox.propTypes = {
  title: PropTypes.string,
  prefix: PropTypes.string,
  currency: PropTypes.string,
  backgroundColor: PropTypes.oneOf(propOptions.color),
  gradient: PropTypes.oneOf(propOptions.gradient),
  size: PropTypes.oneOf(propOptions.size),
  tag: PropTypes.bool
}
