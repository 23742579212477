import { StudiesQueryVariables } from '../../../state/queries'

export const COMPANY_ADS_FILTERS = {
  'Active ads': StudiesQueryVariables.active,
  Drafts: StudiesQueryVariables.draft,
  'Completed ads': StudiesQueryVariables.completed,
  'Paused ads': StudiesQueryVariables.paused,
  'All ads': StudiesQueryVariables.allAdStates
}

export const ADMIN_ADS_FILTERS = {
  'Active ads': StudiesQueryVariables.active,
  Drafts: StudiesQueryVariables.draft,
  'Completed ads': StudiesQueryVariables.completed,
  'In review ads': StudiesQueryVariables.inReview,
  'All ads': StudiesQueryVariables.allAdStates
}

export const INITIAL_ADS_FILTERS = {
  state: 'Active ads',
  sort: 'desc',
  categories: ['Ad']
}

export const CREATE_AD_LINK = '/ads/create'

export const CREATE_AD_TEXT = 'New Ad'

export const ADS_SIDEBAR_TEXT = 'Ads'
