import React from 'react'

import { Text, Spacing, Line } from '../../../../ui'
import {
  AudienceBucketSummary,
  AudienceBucketSummarySimple
} from './components'
import { isNil, isEmpty } from 'ramda'
import { RenderFromQuery } from '../../../../util'
import { OpportunityDetailsQuery } from '../../../../../state/queries'

export const AudienceBucketsSummary = ({
  opportunity,
  opportunity: { targetAudienceBuckets, dependsOn },
  AudienceBucketSummaryComponent = AudienceBucketSummary
}) => {
  const isFollowUpStudy = !isNil(dependsOn) && !isEmpty(dependsOn)
  return (
    <Spacing size="s">
      {!isFollowUpStudy ? (
        <Text h6>Audience</Text>
      ) : (
        <FollowupAudienceSummarySidebar opportunity={opportunity} />
      )}
      <Line themeColor="purpleL2" />
      {targetAudienceBuckets.map((bucket, index) => (
        <AudienceBucketSummaryComponent
          key={`bucket_summary_${index}`}
          audienceBucket={bucket}
        />
      ))}
    </Spacing>
  )
}

const FollowupAudienceSummarySidebar = ({ opportunity }) => {
  return (
    <RenderFromQuery
      query={OpportunityDetailsQuery}
      variables={{ id: opportunity.dependsOn[0] }}
      renderData={({
        opportunity: { name, targetAudienceBuckets, category }
      }) => (
        <Spacing size="s">
          <Spacing direction="row" justify="space-between" align="center">
            <Text h6>
              {`Audience who participated in ${category} `}
              <Text h6 color="main" inline>
                {name}
              </Text>
            </Text>
          </Spacing>
          <Line themeColor="purpleL2" />
          <Spacing>
            {targetAudienceBuckets.map(bucket => (
              <AudienceBucketSummarySimple
                key={bucket.id}
                audienceBucket={bucket}
              />
            ))}
          </Spacing>
        </Spacing>
      )}
    />
  )
}
