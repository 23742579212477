import React from 'react'
import { keys } from 'ramda'
import { Text, Spacing, Touchable } from '../../../../components/ui'
import { StudiesQuery } from '../../../../state/queries'
import { RenderFromQuery } from '../../../../components/util'

export const OpportunitiesFilters = ({
  filters,
  selected,
  onSelect,
  category
}) => {
  const renderFilterItem = (label, count) => (
    <Touchable key={label} onClick={() => onSelect(label)}>
      <Spacing direction="row" justify="space-between" align="center">
        <Text h6 color={label === selected ? 'main' : 'dark'}>
          {label}
        </Text>
        <Text t3 color="grey">
          {count}
        </Text>
      </Spacing>
    </Touchable>
  )

  return (
    <Spacing direction="column" size="l">
      {keys(filters).map(item => (
        <RenderFromQuery
          key={item}
          query={StudiesQuery}
          variables={{ state: filters[item].state, categories: [category] }}
          renderLoading={() => renderFilterItem(item, 0)}
          renderEmpty={() => renderFilterItem(item, 0)}
          renderData={({ allOpportunities: { totalCount } }) =>
            renderFilterItem(item, totalCount)
          }
        />
      ))}
    </Spacing>
  )
}
