import React, { useState, useRef } from 'react'
import {
  Spacing,
  SubmitError,
  Button,
  Select,
  TextArea,
  Tag,
  Text,
  Line
} from '../../../../components/ui'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { Field, useField } from 'react-final-form'
import { RenderFromQuery } from '../../../../components/util'
import { DataSchemaQuery } from '../../../../state/queries'
import styled from 'styled-components/macro'
import { parseQuery, formatQuery } from '../../../../util'
import { map } from 'ramda'

const PaddedTag = styled(Tag)`
  margin-bottom: ${({ theme }) => theme.padding.s};
  margin-right: ${({ theme }) => theme.padding.s};
`

export const StoryQueryBuilder = ({ submitError }) => {
  return (
    <Spacing>
      {submitError && <SubmitError submitError={submitError} />}
      <RenderFromQuery
        query={DataSchemaQuery}
        renderData={({ dataSchema, dataTypesSchema }) => (
          <Spacing>
            <Text h3>Input queries</Text>
            <SimpleQueryBuilder
              dataSchema={dataSchema}
              dataTypesSchema={dataTypesSchema}
              fieldName="inputDataSources"
              queryFieldNameSuffix=".query"
            />
            <Line themeColor="purpleL2" />
            <Text h3>Output queries</Text>
            <SimpleQueryBuilder
              dataSchema={dataSchema}
              dataTypesSchema={dataTypesSchema}
              fieldName="outputDataQueries"
            />
          </Spacing>
        )}
      />
    </Spacing>
  )
}

export const SimpleQueryBuilder = ({
  dataSchema,
  dataTypesSchema,
  fieldName,
  queryFieldNameSuffix
}) => {
  return (
    <FieldArray name={fieldName} mutators={arrayMutators}>
      {({ fields }) => (
        <Spacing size="l">
          <Spacing size="xxl" separated>
            {fields.map((name, index) => (
              <Field
                key={name}
                name={`${name}${queryFieldNameSuffix || ''}`}
                component={SimpleQueryRow}
                singleQuery
                initialSelectFieldValue="*"
                onRemoveQueriesFormField={() => {
                  fields.remove(index)
                }}
                dataSchema={dataSchema}
                dataTypesSchema={dataTypesSchema}
              />
            ))}
          </Spacing>
          <Button
            outline
            block
            onClick={() => fields.push({ query: { sql: 'SELECT * FROM ?' } })}
          >
            + Add a new Query
          </Button>
        </Spacing>
      )}
    </FieldArray>
  )
}

const SimpleQueryRow = ({ input, dataSchema }) => {
  const objectTypesWithSources = dataSchema.objectTypes.map(({ name, id }) => {
    const dataSources = dataSchema.services
      .filter(({ availableObjectTypes }) => availableObjectTypes.includes(id))
      .map(({ name }) => name)

    return {
      value: id,
      label: `${name} (${dataSources.join(', ')})`
    }
  })

  const [lastCursorPosition, setLastCursorPosition] = useState(0)
  const sqlField = useField(`${input.name}.sql`)
  const sqlFieldRef = useRef()

  const insertField = name => {
    const {
      input: { value }
    } = sqlField

    const insertedText =
      value.substring(0, lastCursorPosition) +
      name +
      value.substring(lastCursorPosition)

    sqlField.input.onChange(insertedText)
    sqlFieldRef.current.focus()

    setTimeout(() => {
      sqlFieldRef.current.setSelectionRange(
        lastCursorPosition + name.length,
        lastCursorPosition + name.length
      )
    }, 10)
  }

  return (
    <Spacing>
      <Field
        name={`${input.name}.objectTypeId`}
        render={({ input: objectTypeInput, meta }) => {
          return (
            <Spacing>
              <Tag label={input.value.id || 'New query'} />
              <Select
                input={objectTypeInput}
                meta={meta}
                options={objectTypesWithSources}
                label="Object to extract"
              />
              {objectTypeInput.value && (
                <div>
                  {dataSchema.objectTypes
                    .find(
                      ({ id }) =>
                        parseInt(id) === parseInt(objectTypeInput.value)
                    )
                    .fieldMappings.map(({ displayName, name }) => (
                      <PaddedTag
                        key={name}
                        label={displayName}
                        onSelect={() => insertField(name)}
                      />
                    ))}
                </div>
              )}
            </Spacing>
          )
        }}
      />
      <TextArea
        {...sqlField}
        block
        ref={sqlFieldRef}
        placeholder="Enter SQL query"
        label="SQL Query"
        onClick={e => setLastCursorPosition(e.target.selectionStart)}
        onChange={e => setLastCursorPosition(e.target.selectionStart)}
        monospace
      />
    </Spacing>
  )
}

StoryQueryBuilder.parse = map(parseQuery)
StoryQueryBuilder.format = map(formatQuery)
