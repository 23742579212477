import React from 'react'
import { Form, Field } from 'react-final-form'

import {
  Graphic,
  Text,
  Spacing,
  Input,
  SubmitButton
} from '../../../components/ui'
import { validatorRequired } from '../../../util/validators'
import { withMutation } from '../../../components/wrappers'
import { RenderFromQuery } from '../../../components/util'
import { SignUpCompanyQuery } from '../../../state/queries'

import { VerticalFade } from '../components/AnimatedWizardItems'

const onSubmit = ({ onNextStep, updateUser }) => values =>
  updateUser({ variables: values }).then(({ errors, userErrors, data }) => {
    if (!userErrors && data && data.updateUser) {
      onNextStep()
    }

    return userErrors || errors
  })

const Step = props => (
  <Spacing size="xxxxl">
    <Graphic name="Logo" png size={80} />
    <RenderFromQuery
      query={SignUpCompanyQuery}
      renderLoading={() => <NameForm {...props} />}
      renderData={({ currentUser }) => (
        <NameForm {...props} currentUser={currentUser} />
      )}
    />
  </Spacing>
)

const NameForm = ({ location, onNextStep, updateUser, currentUser }) => (
  <Form
    initialValues={currentUser}
    onSubmit={onSubmit({ onNextStep, updateUser })}
    validate={validatorRequired('fullName', 'Company Name')}
  >
    {({ handleSubmit, ...formProps }) => (
      <form onSubmit={handleSubmit}>
        <Spacing size="xl">
          <Text h0>Sign Up</Text>

          <VerticalFade>
            <Text t1>What’s your company name?</Text>
          </VerticalFade>
          <VerticalFade>
            <Field component={Input} name="fullName" label="Name" autoFocus />
          </VerticalFade>

          <VerticalFade noEnter>
            <SubmitButton {...formProps}>Continue</SubmitButton>
          </VerticalFade>
        </Spacing>
      </form>
    )}
  </Form>
)

export const StepName = withMutation('updateUser')(Step)
