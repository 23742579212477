import React, { useState } from 'react'
import { dropLast, append } from 'ramda'

import ModalsContext from './context'
import * as AllModals from './_all'

export const ModalsProvider = ({ children }) => {
  const [nowOpen, setOpen] = useState([])

  return (
    <ModalsContext.Provider
      value={{
        nowOpen: nowOpen,
        openModal: (id, params) => setOpen(append({ id, params })),
        closeModal: () => setOpen(dropLast(1))
      }}
    >
      {children}
    </ModalsContext.Provider>
  )
}

export const Modals = () => {
  return (
    <ModalsContext.Consumer>
      {({ nowOpen, closeModal }) =>
        nowOpen.map(({ id, params }) => {
          const ModalComponent = AllModals[id]
          return (
            <ModalComponent key={id} params={params} onDismiss={closeModal} />
          )
        })
      }
    </ModalsContext.Consumer>
  )
}
