import React from 'react'
import styled, { css } from 'styled-components/macro'
import posed from 'react-pose'

const AnimatedButton = posed.button({
  pressable: true,
  init: {
    scale: 1
  },
  press: {
    scale: 0.98
  }
})

const TouchableButton = styled(AnimatedButton)`
  padding: 0;
  margin: 0;
  border: none;
  display: block;
  width: 100%;
  cursor: pointer;
  outline: none;
  background-color: transparent;

  ${props =>
    !props.noHover &&
    css`
      transition: opacity ${({ theme }) => theme.animationSpeed.normal};
      &:hover {
        opacity: 0.9;
      }
    `};
`

export const Touchable = props => <TouchableButton type="button" {...props} />
