import { pipe, contains, path } from 'ramda'
import { withRouter } from 'react-router-dom'
import { withMutation } from '../../../components/wrappers'

import {
  StudiesQuery,
  StudiesQueryVariables,
  CurrentCompanyQuery
} from '../../../state/queries'
import { useEffect } from 'react'
import { graphql } from 'react-apollo'

const refetchQueries = [
  {
    query: StudiesQuery,
    variables: StudiesQueryVariables.inProgress
  }
]

const StudyCreate = props => {
  const {
    history,
    createOpportunity,
    location: { pathname },
    data
  } = props

  const connectedPaymentMethod = path(
    ['currentUser', 'company', 'paymentMethod', 'cardType'],
    data
  )

  useEffect(() => {
    const isFollowupStudy = contains('followup', pathname)
    const categoryRoute = pathname.split('/')[1]
    const getCategoryDependendProps = categoryRoute => {
      switch (categoryRoute) {
        case 'ads':
          return { ad: {} }
        case 'stories':
          return { story: { screens: [] } }
        default:
          return {}
      }
    }

    const dependsOn = isFollowupStudy
      ? [props.match.params.opportunityId]
      : null

    // if (process.env.NODE_ENV !== 'development' && !connectedPaymentMethod) {
    //   console.log('NO CONNECTED PAYMENT')
    //   history.replace(
    //     `/connect-payment-method?redirect=/${categoryRoute}/create`
    //   )
    //   return
    // }

    createOpportunity({
      variables: { dependsOn, ...getCategoryDependendProps(categoryRoute) },
      refetchQueries
    }).then(({ errors, userErrors, data }) => {
      if (!userErrors && data && data.createOpportunity.opportunity.id) {
        history.replace(
          `/${categoryRoute}/builder/${data.createOpportunity.opportunity.id}`
        )
      }

      return userErrors || errors
    })
  }, [connectedPaymentMethod])

  return null
}

export const StudyCreateScreen = pipe(
  withRouter,
  withMutation('createOpportunity'),
  graphql(CurrentCompanyQuery)
)(StudyCreate)
