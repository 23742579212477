import React, { Fragment } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components/macro'
import { ApolloProvider } from 'react-apollo'
import * as Sentry from '@sentry/browser'
import Intercom from 'react-intercom'

import { hasToken } from '../../util/session'

// Stying
import 'normalize.css'
import theme from '../../theme'

// Components
import { AppHeader, GlobalStyles, ErrorBoundary } from '../'

// Screens
import {
  OverviewScreen,
  CompanyStudiesScreen,
  AdminStudiesScreen,
  StudyBuilderScreen,
  StudyPublishScreen,
  StudySummaryScreen,
  OnboardingScreen,
  LoginScreen,
  ConnectTypeform,
  PayoutsScreen,
  PlaygroundScreen,
  ConnectCardScreen,
  DataSourcesStatsScreen
} from '../../screens'
import { StudyLaunchedScreen } from '../../screens/StudyBuilder/components'
import { StudyCreateScreen } from '../../screens/Studies/Create'

// Modals
import { Modals, ModalsProvider } from '../../modals'

// Toasts
import { Toasts, ToastsProvider } from '../../state/contexts'

// State
import { client } from '../../state/apollo'
import { CurrentUserQuery } from '../../state/queries'
import { RenderFromQuery } from '../util'
import { UserPayoutDetails } from '../../screens/Payouts/components'
import { CompanyAdsScreen } from '../../screens/Ads/Company'
import { AdminAdsScreen } from '../../screens/Ads/Admin'
import { VerifyEmail } from '../../screens/VerifyEmail'
import { DismissableVerificationBanner } from '../DismissableVerificationBanner'

// Error reporting tool
const environment = process.env.REACT_APP_ENV
const sentryEnabled = environment === 'Production' || environment === 'Staging'

if (sentryEnabled) {
  Sentry.init({
    dsn: 'https://f09b5270c9964f339a1e0f0b795207b0@sentry.io/1510424',
    environment,
    release: process.env.REACT_APP_SENTRY_RELEASE_NAME
  })
}

const App = () => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <GlobalStyles />
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <ModalsProvider>
            <ToastsProvider>
              <Router>
                <Switch>
                  <Route path="/onboarding" component={OnboardingScreen} />
                  <Route path="/login" component={LoginScreen} />
                  <Route
                    path="/"
                    render={props =>
                      hasToken() ? (
                        <RenderFromQuery
                          query={CurrentUserQuery}
                          renderData={({ currentUser }) => {
                            if (sentryEnabled) {
                              Sentry.configureScope(scope => {
                                scope.setUser({
                                  id: currentUser.id,
                                  email: currentUser.email
                                })
                              })
                            }

                            return (
                              <>
                                <Intercom
                                  appID="us60l91z"
                                  name={currentUser.fullName}
                                  email={currentUser.email}
                                  Source="Portal"
                                />
                                <Main
                                  {...props}
                                  role={currentUser.currentRole}
                                />
                              </>
                            )
                          }}
                        />
                      ) : (
                        <Redirect to="/login" />
                      )
                    }
                  />
                </Switch>
                <Modals />
                <Toasts />
              </Router>
            </ToastsProvider>
          </ModalsProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </React.Fragment>
  </ThemeProvider>
)

// Authenticated state
const Main = props => (
  <Fragment>
    <AppHeader />
    <DismissableVerificationBanner />
    <Route path="/" exact component={OverviewScreen} />
    <Switch>
      <Route path="/playground/" component={PlaygroundScreen} />
      <Route
        path="/(studies|ads|stories)/launched"
        component={StudyLaunchedScreen}
      />
      <Route
        path="/(studies|ads|stories)/create"
        component={StudyCreateScreen}
      />
      <Route
        path="/studies/followup/:opportunityId?"
        component={StudyCreateScreen}
      />
      <Route
        path="/(studies|ads|stories)/builder/:opportunityId/:step?"
        component={StudyBuilderScreen}
      />
      <Route
        path="/(studies|ads|stories)/publish/:opportunityId?"
        component={StudyPublishScreen}
      />
      <Route
        path="/(studies|ads|stories)/summary/:opportunityId?"
        component={StudySummaryScreen}
      />
      <Route
        path="/studies"
        component={
          props.role === 'company' ? CompanyStudiesScreen : AdminStudiesScreen
        }
      />
      <Route
        path="/ads"
        component={props.role === 'company' ? CompanyAdsScreen : AdminAdsScreen}
      />

      <Route
        path="/payouts/:id"
        component={props.role === 'admin' ? UserPayoutDetails : null}
      />
      <Route
        path="/payouts"
        component={props.role === 'admin' ? PayoutsScreen : null}
      />

      <Route path="/connect-payment-method" component={ConnectCardScreen} />
      {/* <Route path="/settings" component={SettingsScreen} /> */}
      <Route path="/oauth/connect" component={ConnectTypeform} />
      <Route path="/verify-email" component={VerifyEmail} />
      <Route path="/data-sources" component={DataSourcesStatsScreen} />
    </Switch>
  </Fragment>
)
export default App
