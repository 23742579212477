import React from 'react'

import { Text, Tabs, Spacing } from '../../../ui'

import { CategoriesChart } from './CategoriesChart'

const TABS = [
  { title: 'Visit', interaction: 'visit' },
  { title: 'Conversion', interaction: 'conversion' }
]

export const AdInteractionsOverview = ({ interactions }) => (
  <Spacing>
    <Text h3>Demographics</Text>
    <Tabs size="l">
      {TABS.filter(
        ({ interaction }) =>
          !!interactions.find(({ action }) => action === interaction)
      ).map(({ title, interaction }) => {
        const { tagCategories, conversionUrl } = interactions.find(
          ({ action }) => action === interaction
        )

        return (
          <Tabs.Tab key={title} label={title}>
            <Spacing>
              <Text h4>
                {conversionUrl && (
                  <Text t3 color="main">{`${conversionUrl}`}</Text>
                )}
              </Text>
              <CategoriesChart tagCategories={tagCategories} />
            </Spacing>
          </Tabs.Tab>
        )
      })}
    </Tabs>
  </Spacing>
)
