import React from 'react'
import { slice, isEmpty } from 'ramda'
import {
  Spacing,
  Box,
  Text,
  Button,
  ContainerSpinner
} from '../../../../components/ui'
import { StudyCardMini } from '../'
import { RenderFromQuery } from '../../../../components/util'

import { StudiesQuery, StudiesQueryVariables } from '../../../../state/queries'

export const StudiesListMini = ({ limit }) => (
  <Box size="xl" backgroundColor="white">
    <Spacing size="xl">
      <Spacing direction="row" justify="space-between" align="center">
        <Text h2>Studies</Text>
        <Button transparent link themeColor="greyL1" to="/studies">
          See all >
        </Button>
      </Spacing>
      <RenderFromQuery
        query={StudiesQuery}
        variables={StudiesQueryVariables.active}
        renderLoading={() => <ContainerSpinner />}
        renderData={({ allOpportunities }) => {
          const trimmedStudies = slice(0, limit, allOpportunities.edges)

          if (isEmpty(trimmedStudies))
            return <Text t2>You have no active studies.</Text>

          return (
            <Spacing size="l">
              {trimmedStudies.map(({ node }) => (
                <StudyCardMini key={node.id} opportunity={node} />
              ))}
            </Spacing>
          )
        }}
      />
      <Spacing align="center">
        <Button
          center
          transparent
          link
          themeColor="secondary"
          to="/studies/create/"
          prefix="+"
        >
          Create new Study
        </Button>
      </Spacing>
    </Spacing>
  </Box>
)
