import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import { useDropzone } from 'react-dropzone'
import { Text, Spacing } from '../../../../components/ui'
import { isNil, isEmpty } from 'ramda'
import { ImageActions } from '../../../../screens/StudyBuilder/components/SurveyBuilder/components/ImageActions'

const UploadImageContainer = styled.div`
  height: 300px;
  background-color: ${({ theme }) => theme.color.purpleL3};
  padding: ${({ theme }) => theme.padding.l};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  border: 1px solid ${({ theme }) => theme.color.purpleL1};
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
  }
`

const ImagePreview = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ImagePreviewContainer = styled.div`
  display: flex;
  height: 260px;
  max-width: 500px;
`

const Image = styled.img`
  display: block;
  width: auto;
  height: 100%;
`

const UploadImageInput = styled.input`
  visibility: hidden;
  height: 0;
`

const renderImagePreview = image => (
  <ImagePreviewContainer>
    <Image src={image.src} />
  </ImagePreviewContainer>
)

export const ImageUpload = ({ onSetImage, uploadedImage }) => {
  const [image, setImage] = useState(uploadedImage)

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: acceptedFiles => {
      const imageFile = acceptedFiles[0]

      const reader = new FileReader()

      reader.onload = event => {
        setImage(
          Object.assign(imageFile, {
            src: event.target.result
          })
        )
      }
      reader.readAsDataURL(imageFile)
    }
  })

  useEffect(() => {
    onSetImage(image)
  }, [image])

  return (
    <Spacing size="l">
      <UploadImageContainer {...getRootProps({ className: 'dropzone' })}>
        <UploadImageInput {...getInputProps()} />
        {isNil(image) || isEmpty(image.src) ? (
          <Spacing size="s" align="center">
            <Text h3>Drag and drop an image, or click to upload</Text>
            <Text t3>
              Supported formats are JPG and PNG, maximum size 500kb and
              1080x1080px
            </Text>
          </Spacing>
        ) : (
          <ImagePreview>{renderImagePreview(image)}</ImagePreview>
        )}
      </UploadImageContainer>

      {!isNil(image) && (
        <ImageActions remove={() => setImage(null)} replace={open} />
      )}
    </Spacing>
  )
}
