import React from 'react'
import { Box, Spacing, Text, Graphic } from '../../ui'

export const StudyBuilderBlock = ({
  title,
  graphic,
  headerRightContent,
  children
}) => {
  return (
    <Box size="xl">
      <Spacing size="xl">
        <Spacing direction="row" justify="space-between">
          <Spacing direction="row" align="center" size="xl">
            {graphic && <Graphic name={graphic} size={23} themeColor="main" />}
            {title && <Text h4>{title}</Text>}
          </Spacing>
          {headerRightContent}
        </Spacing>
        {children}
      </Spacing>
    </Box>
  )
}
