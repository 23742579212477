import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import theme from '../../../theme'

export const Box = styled.div`
  border-radius: ${props => props.theme.borderRadius.large};
  padding: ${props => props.theme.padding[props.size]};

  background-color: ${props => props.theme.color[props.backgroundColor]};

  ${props =>
    !props.noShadow &&
    css`
      box-shadow: ${props.theme.boxShadow.large};
    `};

  ${props =>
    props.link &&
    css`
      cursor: pointer;
    `};
`

export const propOptions = {
  size: Object.keys(theme.padding),
  backgroundColor: Object.keys(theme.color)
}

Box.defaultProps = {
  size: 'l',
  backgroundColor: 'white',
  noShadow: false
}

Box.propTypes = {
  size: PropTypes.oneOf(propOptions.size),
  backgroundColor: PropTypes.oneOf(propOptions.backgroundColor),
  noShadow: PropTypes.bool
}
