import React from 'react'
import { Container, ContainerSpinner, Spacing, Text } from '../../components/ui'
import { DashboardDatasourceQuery } from '../../state/queries'
import { DataSourceListItem } from '../Overview/components'
import { RenderFromQuery } from '../../components/util'

export const DataSourcesStatsScreen = () => (
  <RenderFromQuery
    query={DashboardDatasourceQuery}
    renderLoading={() => <ContainerSpinner />}
    renderData={({ stats: { dashboard } }) => (
      <Container size="xxl">
        <Spacing stretched>
          <Text h2>All Data Sources</Text>
          <Spacing size="s" stretched>
            {dashboard.dataSources.all.map((data, n) => (
              <DataSourceListItem key={n} data={data} />
            ))}
          </Spacing>
        </Spacing>
      </Container>
    )}
  />
)
