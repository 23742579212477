import gql from 'graphql-tag'
import { DataSchemaFragment, DataFieldTypeFragment } from '../fragments'

export const DataSchemaQuery = gql`
  query DataSchemaQuery {
    dataSchema {
      ...DataSchema
    }
    dataTypesSchema {
      ...DataFieldType
    }
  }
  ${DataSchemaFragment}
  ${DataFieldTypeFragment}
`
