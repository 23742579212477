import gql from 'graphql-tag'

export const connectTypeform = gql`
  mutation ConnectTypeform($code: String!) {
    connectTypeform(code: $code) {
      query {
        currentUser {
          id
          company {
            id
            connectedTypeform
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
`
