import React from 'react'
import styled from 'styled-components/macro'

import {
  Text,
  Spacing,
  Graphic,
  SpacingItem,
  ProgressBar
} from '../../../../../ui'
import { RenderFromQuery } from '../../../../../util'
import {
  flattenAudienceSchema,
  getBucketsTagsSummary,
  getQueriesSummary
} from '../../../../../../util'
import { TargetAudienceBucketQuery } from '../../../../../../state/queries'

import {
  anyAudienceRequirementFilled,
  anyAudienceTagFilled
} from '../../../../../../util/validators'

const BoxOuter = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.large};
  background: ${({ theme }) => theme.color.white};
  overflow: hidden;
`

const BoxWrapper = styled.div`
  padding: ${({ theme }) => theme.padding.l};
`

export const AudienceBucketSummary = ({
  audienceBucket: { name, quota, responseCount, tags, queries }
}) => (
  <RenderFromQuery
    query={TargetAudienceBucketQuery}
    renderData={({ allUserGroups, audienceSchema, dataSchema }) => (
      <BoxOuter>
        <BoxWrapper>
          <Spacing size="s">
            <Spacing direction="row" justify="space-between" align="center">
              <Text h6>{name}</Text>
              <Graphic
                name="Loading"
                width={50}
                height={20}
                themeColor="main"
              />
            </Spacing>
            <Spacing direction="row" wrap>
              <SpacingItem flex="1 0 50%">
                <Text t3 color="main">
                  {renderTagsRequirementsText({
                    tags,
                    audienceSchema,
                    allUserGroups
                  })}
                </Text>
              </SpacingItem>
              <Spacing direction="row" size="xs">
                <Text t2>{responseCount}</Text>
                <Text t2 color="greyL1">
                  /
                </Text>
                <Text t2>{quota || 'unlimited'}</Text>
              </Spacing>
            </Spacing>
            <Spacing size="l">
              <Text t3>
                {renderQueriesRequirementsText({ queries, dataSchema })}
              </Text>
            </Spacing>
          </Spacing>
        </BoxWrapper>
        {quota && <ProgressBar total={quota} current={responseCount} />}
      </BoxOuter>
    )}
  />
)

export const AudienceBucketSummarySimple = ({
  audienceBucket: { name, quota, responseCount, tags, queries }
}) => (
  <RenderFromQuery
    query={TargetAudienceBucketQuery}
    renderData={({ allUserGroups, audienceSchema, dataSchema }) => (
      <Spacing size="s">
        <Text h6>{name}</Text>
        <Spacing direction="row" wrap>
          <SpacingItem flex="1 0 50%">
            <Text t3 color="main">
              {renderTagsRequirementsText({
                tags,
                audienceSchema,
                allUserGroups
              })}
            </Text>
          </SpacingItem>
          <Text t2 title="Quota">
            {quota || 'unlimited'}
          </Text>
        </Spacing>
        <Spacing size="l">
          <Text t3>
            {renderQueriesRequirementsText({ queries, dataSchema })}
          </Text>
        </Spacing>
      </Spacing>
    )}
  />
)

const renderTagsRequirementsText = ({ tags, audienceSchema, allUserGroups }) =>
  anyAudienceTagFilled(tags) &&
  getBucketsTagsSummary(
    flattenAudienceSchema(audienceSchema),
    allUserGroups,
    tags
  )

const renderQueriesRequirementsText = ({ queries, dataSchema }) =>
  anyAudienceRequirementFilled(queries) && (
    <div
      dangerouslySetInnerHTML={{
        __html: getQueriesSummary(dataSchema.services, queries)
      }}
    />
  )
