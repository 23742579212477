import gql from 'graphql-tag'

export const createOpportunity = gql`
  mutation CreateOpportunity(
    $dependsOn: [String!]
    $ad: AdInput
    $story: StoryInput
  ) {
    createOpportunity(dependsOn: $dependsOn, ad: $ad, story: $story) {
      opportunity {
        id
      }
      errors {
        key
        message
      }
    }
  }
`
