import React from 'react'
import { isEmpty } from 'ramda'
import { Spacing } from '../../../components/ui'
import { AdInteractionsOverview } from '../../../components/blocks'
import { SummaryCard } from './SummaryCard'
import { calculateCTR } from '../../../util'
import { formatDuration } from '../../../util/date'

export const AdStats = ({ ad }) => {
  const { budget = 0, interactions } = ad

  return (
    <Spacing size="xl">
      <Spacing direction="row" stretchChildren>
        <SummaryCard title="Ad Budget" value={`$${budget}`} />
        <SummaryCard title="Total spent" value={`$${ad.stats.totalSpent}`} />
        <SummaryCard title="Price Per Click" value={`$${ad.pricePerClick}`} />
      </Spacing>
      <Spacing direction="row" stretchChildren>
        <SummaryCard title="Impressions" value={ad.stats.impressions} />
        <SummaryCard title="Visits" value={ad.stats.visits} />
        <SummaryCard
          title="Time Spent"
          value={formatDuration(ad.stats.timeSpent)}
        />
        <SummaryCard title="CTR" value={calculateCTR(ad.stats)} />
        <SummaryCard title="Conversions" value={ad.stats.conversions} />
      </Spacing>
      {!isEmpty(interactions) && (
        <AdInteractionsOverview interactions={interactions} />
      )}
    </Spacing>
  )
}
