import React from 'react'
import styled from 'styled-components/macro'
import { pipe, equals, isEmpty, isNil } from 'ramda'
import { graphql } from 'react-apollo'
import camelCase from 'camelcase'

import {
  Spacing,
  InlineField,
  Checkbox,
  SpacingItem
} from '../../../../components/ui'
import { withMutation } from '../../../../components/wrappers'
import {
  StudyReviewBlock,
  StudyAttribute,
  StudyDuration,
  StudyStartDate
} from '../../../../components/blocks'

import { formatDate } from '../../../../util/date'

import { CurrentUserQuery } from '../../../../state/queries'

const CompanyLogoCheckWrap = styled.div`
  margin-left: ${({ theme }) => theme.padding.xl};
  width: 30%;
`

const StudyPublishingInfo = ({
  opportunity,
  updateOpportunity,
  failedValidations,
  data: {
    currentUser: { currentRole }
  }
}) => {
  const isAdmin = equals(currentRole, 'admin')
  const studyTypeOptions =
    opportunity.category === 'Study' ? ['Earn'] : ['Story', 'Tribe']

  return (
    <StudyReviewBlock>
      <StudyReviewBlock.Header title="Publishing information" />
      <Spacing size="l">
        {isAdmin && (
          <Spacing size="xxs" direction="row" strethChildren>
            <SpacingItem flex="0 1 60%">
              <StudyAttribute
                initialValue={opportunity.type}
                opportunity={opportunity}
                inlineError={failedValidations.type}
                options={studyTypeOptions.map(value => ({
                  label: value,
                  value
                }))}
                label="Study category"
                selectKey="type"
              />
            </SpacingItem>
          </Spacing>
        )}
        {isAdmin && (
          <Spacing size="xxs" direction="row" strethChildren>
            <SpacingItem flex="0 1 60%">
              <StudyAttribute
                initialValue={opportunity.insightsDataType}
                opportunity={opportunity}
                inlineError={failedValidations.insightsDataType}
                options={['Anonymized', ' Stripped of PII', 'Raw Data'].map(
                  value => ({
                    label: value,
                    value: camelCase(value)
                  })
                )}
                label="Insights data type"
                selectKey="insightsDataType"
              />
            </SpacingItem>
          </Spacing>
        )}
        {isAdmin && (
          <Spacing size="xxs" direction="row" strethChildren>
            <SpacingItem flex="0 1 60%">
              <StudyAttribute
                initialValue={opportunity.insightsStorageLocation}
                opportunity={opportunity}
                inlineError={failedValidations.insightsStorageLocation}
                options={['UBDI Server', 'Company Server'].map(value => ({
                  label: value,
                  value: camelCase(value)
                }))}
                label="Insights storage location"
                selectKey="insightsStorageLocation"
              />
            </SpacingItem>
          </Spacing>
        )}

        <Spacing size="xxs" direction="row">
          <SpacingItem flex="0 1 60%">
            <InlineField
              initialValue={opportunity.name}
              onUpdate={name =>
                updateOpportunity({
                  variables: {
                    id: opportunity.id,
                    name
                  }
                })
              }
              label="Public Name"
              inlineError={failedValidations.name}
              subscription={{ active: true }}
              updateConditions={({ active }) => !active}
            />
          </SpacingItem>
        </Spacing>
        <Spacing size="xxs" direction="row" align="center" strethChildren>
          <SpacingItem flex="0 1 60%">
            <InlineField
              initialValue={opportunity.shortDescription}
              onUpdate={shortDescription =>
                updateOpportunity({
                  variables: {
                    id: opportunity.id,
                    shortDescription
                  }
                })
              }
              label="Short Description"
              inlineError={failedValidations.shortDescription}
              subscription={{ active: true }}
              updateConditions={({ active }) => !active}
            />
          </SpacingItem>
          <SpacingItem as={CompanyLogoCheckWrap}>
            <InlineField
              initialValue={opportunity.showCompanyLogo}
              onUpdate={showCompanyLogo =>
                updateOpportunity({
                  variables: {
                    id: opportunity.id,
                    showCompanyLogo
                  }
                })
              }
              label="Show Company logo"
              component={Checkbox}
              type="checkbox"
            />
          </SpacingItem>
        </Spacing>
        <Spacing size="xxs" direction="row">
          <SpacingItem flex="0 1 60%">
            <StudyStartDate
              initialValue={
                opportunity.duration && opportunity.duration.startDate
                  ? formatDate(opportunity.duration.startDate)
                  : null
              }
              opportunity={opportunity}
            />
          </SpacingItem>
        </Spacing>
        <Spacing size="xxs" direction="row" align="center" strethChildren>
          <SpacingItem flex="0 1 60%">
            <StudyDuration
              initialValue={
                opportunity.duration && opportunity.duration.lengthInDays
                  ? opportunity.duration.lengthInDays
                  : null
              }
              opportunity={opportunity}
            />
          </SpacingItem>
          {!isNil(opportunity.dependsOn) && !isEmpty(opportunity.dependsOn) && (
            <SpacingItem as={CompanyLogoCheckWrap}>
              <InlineField
                initialValue={opportunity.isMultipartStudy}
                onUpdate={isMultipartStudy =>
                  updateOpportunity({
                    variables: {
                      id: opportunity.id,
                      isMultipartStudy
                    }
                  })
                }
                label="Multipart Study"
                component={Checkbox}
                type="checkbox"
              />
            </SpacingItem>
          )}
        </Spacing>
      </Spacing>
    </StudyReviewBlock>
  )
}

export const StudyPublishingInfoReview = pipe(
  graphql(CurrentUserQuery),
  withMutation('updateOpportunity')
)(StudyPublishingInfo)
