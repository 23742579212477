import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import theme from '../../../theme'

export const GradientWrapper = styled.div`
  background: ${props => props.theme.gradient[props.gradient]};
`

export const propOptions = { gradients: Object.keys(theme.gradient) }

GradientWrapper.propTypes = {
  gradient: PropTypes.oneOf(propOptions.gradients).isRequired
}
