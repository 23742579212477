import theme from '../../../theme'
import { clamp } from 'ramda'

export const railOuterStyle = {
  position: 'absolute',
  width: '100%',
  height: 42,
  transform: 'translate(0%, -50%)',
  borderRadius: 10,
  cursor: 'pointer'
}

export const railInnerStyle = {
  position: 'absolute',
  width: '100%',
  height: 14,
  transform: 'translate(0%, -50%)',
  borderRadius: 10,
  pointerEvents: 'none',
  backgroundColor: theme.color.purpleL3
}

export const getHandleStyles = (percent, disabled) => ({
  left: `${clamp(3, 97, percent)}%`,
  cursor: 'grab',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  borderRadius: 10,
  backgroundColor: disabled ? '#666' : theme.color.purpleL1,
  color: '#fff',
  fontSize: 12,
  padding: theme.padding.xs + ' ' + theme.padding.s,
  fontFamily: 'Rubik',
  outline: 'none',
  border: 'none',
  minHeight: 20
})

export const getTrackStyles = ({ source, target, disabled }) => ({
  position: 'absolute',
  transform: 'translate(0%, -50%)',
  height: 14,
  backgroundColor: disabled ? '#999' : theme.color.main,
  borderRadius: 10,
  cursor: 'pointer',
  left: `${source.percent + 1}%`,
  width: `${target.percent - source.percent - 2}%`
})
