import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components/macro'

import { Graphic } from '..'
import { withFieldError } from '../../wrappers'

import { getBackgroundColor, getBorder } from '../../../util/ui'

const InputWrap = styled.div`
  position: relative;
  display: inline-block;
  width: ${({ block }) => (block ? '100%' : '320px')};
  border-radius: ${({ theme }) => theme.borderRadius.base};
  background-color: ${getBackgroundColor};
  border: ${getBorder};

  ${props => props.isErrored && `border: 1px solid ${props.theme.color.red}`}

  ${props => props.success && `border: 1px solid ${props.theme.color.green}`}

  ${props =>
    props.clean &&
    css`
      padding: 0;
      background-color: transparent;
      border: none;
      ${props.theme.text.h1}
    `}
`

const TextAreaEl = styled.textarea`
  border: none;
  width: 100%;
  padding: ${({ theme, graphic }) =>
    `0 ${theme.padding.l} ${parseInt(theme.padding.l) - 1}px`};
  outline: none;
  background-color: ${getBackgroundColor};
  color: ${({ theme }) => theme.color.dark};
  resize: vertical;

  transition: all 0.2s ease-in-out;

  ::placeholder {
    opacity: 0;
  }

  ${({ theme }) => theme.text.t2}
  ${({ monospace }) => monospace && `font-family: monospace;`}
`

const Label = styled.div`
  padding: ${({ theme }) => `${theme.padding.m} ${theme.padding.l}}`};
  pointer-events: none;

  color: ${({ theme }) => theme.color.dark};
  ${({ theme }) => theme.text.h6};

  ${props => props.isErrored && `color: ${props.theme.color.red}`}
  ${props =>
    props.success &&
    `color: ${props.theme.color.green}`}

transition: all 0.2s ease-in-out;
`

const GraphicWrap = styled(Graphic)`
  position: absolute;
  right: ${({ theme }) => theme.padding.l};
  top: ${({ theme }) => theme.padding.m};
  pointer-events: none;
`

const TextAreaView = forwardRef(
  (
    {
      label,
      graphic,
      graphicColor,
      graphicSize = 18,
      block,
      placeholder,
      ...props
    },
    ref
  ) => (
    <InputWrap block={block} {...props}>
      <Label {...props}>{label}</Label>
      <TextAreaEl
        type="text"
        {...props}
        {...props.input}
        ref={ref}
        graphic={!!graphic}
        placeholder={placeholder || 'type...'}
      />
      {graphic && (
        <GraphicWrap
          name={graphic}
          size={graphicSize}
          themeColor={graphicColor}
        />
      )}
    </InputWrap>
  )
)

export const TextArea = withFieldError()(TextAreaView)
