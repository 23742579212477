import React from 'react'
import { equals } from 'ramda'

import { Spacing, Text, SpacingItem } from '../../../../components/ui'

export const TransactionsList = ({ transactions, transactionColumns }) => {
  return (
    <Spacing size="l">
      <Text t1 color="dark">
        Transactions
      </Text>
      <Spacing direction="row">
        {transactions.length === 0 && (
          <Text t3 color="dark">
            User does not have transactions
          </Text>
        )}
        {transactions.length > 0 &&
          transactionColumns.map((column, columnIndex) => {
            return (
              <SpacingItem
                flex="0 1 40%"
                key={column.accessor + '-' + columnIndex}
              >
                <Spacing size="s">
                  <Text t3 color="dark">
                    {column.title}
                  </Text>
                  <Spacing size="s">
                    {transactions.map(transaction => {
                      if (equals(column.accessor, 'totalUsd'))
                        return (
                          <Text
                            h6
                            color={transaction.totalUsd > 0 ? 'green' : 'red'}
                            key={transaction.id}
                          >
                            {transaction.totalUsd} $
                          </Text>
                        )

                      return (
                        <Text h6 color="dark" key={transaction.id}>
                          {transaction[column.accessor]}
                        </Text>
                      )
                    })}
                  </Spacing>
                </Spacing>
              </SpacingItem>
            )
          })}
      </Spacing>
    </Spacing>
  )
}
