import React from 'react'
import styled, { css } from 'styled-components/macro'
import PropTypes from 'prop-types'

const getSize = ({ size, direction, multiplier = 1 }) => {
  if (direction === 'row' || direction === 'row-reverse') {
    return `0 ${(multiplier * parseInt(size)) / 2}px`
  } else {
    return `${(multiplier * parseInt(size)) / 2}px 0`
  }
}

export const SpacingItem = styled.div`
  max-width: 100%;

  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}

  ${({ display }) =>
    display &&
    css`
      display: ${display};
    `}

  ${({ alignSelf }) =>
    alignSelf &&
    css`
      align-self: ${alignSelf};
    `}
`

const renderSpacingItem = (child, index) => {
  if (child) {
    const children = [].concat(child).filter(el => el)
    return children.map(child => {
      if (child.type === SpacingItem) {
        return child
      } else {
        const key = child.key || `${index}`
        return <SpacingItem key={key}>{child}</SpacingItem>
      }
    })
  }

  return null
}

const SpacingChildren = ({ children, className }) => (
  <div className={className}>
    {[]
      .concat(children)
      .filter(Boolean)
      .map(renderSpacingItem)}
  </div>
)

export const Spacing = styled(SpacingChildren)`
  display: flex;

  ${props => css`
    flex-direction: ${props.direction};
    ${props.stretched &&
      css`
        flex: 1;
      `}
    margin: ${getSize({
      size: props.theme.padding[props.size],
      direction: props.direction,
      multiplier: -1
    })};
    justify-content: ${props.justify};
    align-items: ${props.align};

    ${props.wrap &&
      css`
        flex-wrap: wrap;
      `}

    > ${SpacingItem} {
      padding: ${getSize({
        size: props.theme.padding[props.size],
        direction: props.direction
      })};

      ${props.separated &&
        css`
          + ${SpacingItem} {
            ${['row', 'row-reverse'].includes(props.direction)
              ? css`
                  border-left: 1px solid ${props.theme.color.greyL2};
                `
              : css`
                  border-top: 1px solid ${props.theme.color.greyL2};
                `}
          }
        `}

      ${props.stretchChildren &&
        css`
          flex: 1;
        `}

      ${props.wrap &&
        css`
          overflow: hidden;
        `}
    }
  `}
`

Spacing.defaultProps = {
  direction: 'column',
  size: 'm',
  stretchChildren: false,
  justify: 'flex-start',
  align: 'stretch',
  wrap: false,
  separated: false,
  stretched: false
}

export const propOptions = {
  directions: ['row', 'row-reverse', 'column', 'column-reverse'],
  sizes: ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl', 'xxxxl', 'xxxxxl'],
  justifications: [
    'inherit',
    'flex-start',
    'center',
    'flex-end',
    'space-between'
  ],
  alignments: [
    'inherit',
    'baseline',
    'flex-start',
    'center',
    'flex-end',
    'stretch'
  ]
}

Spacing.propTypes = {
  direction: PropTypes.oneOf(propOptions.directions),
  size: PropTypes.oneOf(propOptions.sizes),
  justify: PropTypes.oneOf(propOptions.justifications),
  align: PropTypes.oneOf(propOptions.alignments),
  wrap: PropTypes.bool,
  stretchChildren: PropTypes.bool,
  stretched: PropTypes.bool
}
