import gql from 'graphql-tag'

export const completeCardSetup = gql`
  mutation CompleteCardSetup($paymentMethod: String!) {
    completeCardSetup(paymentMethod: $paymentMethod) {
      success
      errors {
        key
        message
      }
    }
  }
`
