import React, { PureComponent } from 'react'
import styled from 'styled-components/macro'
import { Route, Switch } from 'react-router-dom'
import posed, { PoseGroup } from 'react-pose'
import { head, last, pipe, split } from 'ramda'

import { StepLoading } from './components/StepLoading'

import onboardingGraphic from './onboarding.svg'

import * as StepsComponents from './steps'

// Styles
const Wrap = styled.div`
  display: flex;
  height: 100%;
`

const Bg = styled.div`
  flex: 1;
  background-image: url(${onboardingGraphic});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const StepWrap = styled.div`
  flex: 1;
`

const Step = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
`

const StepLoadingWrap = styled.div`
  margin-top: ${({ theme }) => theme.padding.xxxl};
`

// Animations
const RouteContainer = posed.div()

// Steps
const steps = [
  ['email', 'StepEmail'],
  ['name', 'StepName'],
  ['password', 'StepPassword'],
  ['avatar', 'StepAvatar'],
  ['done', 'StepDone']
]

export class OnboardingScreen extends PureComponent {
  static defaultProps = {
    steps
  }

  componentDidMount() {
    const { location, history } = this.props

    if (!this._stepIds.includes(getRouteKey(location.pathname)))
      history.replace(this._getStepUrl(head(this._stepIds)))
  }

  render() {
    const { location, steps } = this.props

    return (
      <Wrap>
        <StepWrap>
          <Step>
            <PoseGroup preEnterPose="preEnter">
              <RouteContainer key={location.pathname}>
                <Switch location={location}>
                  {this._renderSteps()}
                  <Route render={() => <div>Invalid step</div>} />
                </Switch>
              </RouteContainer>
            </PoseGroup>
            <StepLoadingWrap>
              <StepLoading
                total={steps.length}
                current={this.currentStepIndex}
                onStepChange={this._goToStepIndex}
              />
            </StepLoadingWrap>
          </Step>
        </StepWrap>
        <Bg />
      </Wrap>
    )
  }

  _renderSteps() {
    const wizardProps = {
      onNextStep: this._onNextStep,
      onPrevStep: this._onPrevStep
    }

    const { match, steps } = this.props
    return steps.map(([stepId, stepComponent]) => {
      const StepComponent = StepsComponents[stepComponent]
      return (
        <Route
          path={`${match.url}/${stepId}`}
          render={props => <StepComponent {...props} {...wizardProps} />}
          key={stepId}
        />
      )
    })
  }

  _goToStep = step => {
    const stepIndex = this._stepIds.indexOf(step)

    return this._goToStepIndex(stepIndex)
  }

  _goToStepIndex = stepIndex => () => {
    const {
      steps,
      history: { push }
    } = this.props

    if (stepIndex < 0) return
    if (stepIndex === steps.length) return this._onComplete()

    push(this._getStepUrl(steps[stepIndex][0]))
  }

  _onPrevStep = () => this._goToStepIndex(this.prevStepIndex)()
  _onNextStep = () => this._goToStepIndex(this.nextStepIndex)()

  _getStepUrl = stepId => `${this.props.match.url}/${stepId}`

  _onComplete = () => this.props.history.push('/')

  get _stepIds() {
    return this.props.steps.map(([id, component]) => id)
  }

  get nextStepIndex() {
    return this.currentStepIndex + 1
  }

  get prevStepIndex() {
    return this.currentStepIndex - 1
  }

  get currentStepIndex() {
    const { location } = this.props
    return this._stepIds.indexOf(getRouteKey(location.pathname))
  }
}

const getRouteKey = pipe(
  split('/'),
  last
)
