import React from 'react'

import {
  Container,
  Spacing,
  SpacingItem,
  Input,
  Select
} from '../../components/ui'

import { PayoutRequestsTable } from './components'
import { Field, Form, FormSpy } from 'react-final-form'
import { useState } from 'react'

import debounce from 'lodash.debounce'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'

const FindUserQuery = gql`
  query FindUser($username: String) {
    user(username: $username) {
      payoutUser {
        id
      }
    }
  }
`

export const PayoutsScreen = withApollo(({ client, history }) => {
  const statusOptions = ['Pending', 'Confirmed', 'Rejected'].map(value => ({
    label: value,
    value: value.toLowerCase()
  }))

  const initialStatus = statusOptions[0].value
  const [params, setParams] = useState({ status: initialStatus })

  const submitHandler = debounce(setParams, 500)

  const goToUserHandler = async ({ username }) => {
    try {
      const {
        data: {
          user: { payoutUser }
        }
      } = await client.query({
        query: FindUserQuery,
        variables: { username }
      })

      history.push(`/payouts/${payoutUser.id}`)
    } catch {}
  }

  return (
    <Container size="xxl">
      <Spacing stretched>
        <Spacing stretched direction="row" justify="space-between">
          <SpacingItem flex="0 0 60%">
            <Form
              onSubmit={submitHandler}
              initialValues={{ status: initialStatus, username: null }}
            >
              {() => (
                <>
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={({ values }) => submitHandler(values)}
                  />
                  <Spacing stretched direction="row" justify="space-between">
                    <SpacingItem flex="0 0 60%">
                      <Field
                        name="status"
                        label="Payout status"
                        component={Select}
                        options={statusOptions}
                      />
                    </SpacingItem>
                    <Field
                      name="username"
                      component={Input}
                      label="Search by username"
                    />
                  </Spacing>
                </>
              )}
            </Form>
          </SpacingItem>
          <Form onSubmit={goToUserHandler}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field name="username" component={Input} label="Go to user" />
              </form>
            )}
          </Form>
        </Spacing>

        <PayoutRequestsTable {...params} />
      </Spacing>
    </Container>
  )
})
