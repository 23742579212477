import { useState } from 'react'

export const useSteps = ({
  initialStep,
  handleStepChange,
  steps,
  onCompleted = () => {}
}) => {
  const [currentStep, setCurrentStep] = useState(initialStep ? initialStep : 0)

  const nextStep = () => {
    goToStep({ shouldHandleStepChange: true })(currentStep + 1)
  }

  const goToStep = config => step => {
    const { shouldHandleStepChange } = config
    if (step >= steps.length && onCompleted) return onCompleted()

    setCurrentStep(step)
    if (shouldHandleStepChange) handleStepChange(step)
  }

  return {
    currentStep,
    nextStep,
    goToStep
  }
}
