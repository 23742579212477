import React from 'react'
import styled from 'styled-components/macro'

import { Box, Spacing, Text } from '../../ui'

const StyledHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.greyL2};
  padding-bottom: ${({ theme }) => theme.padding.l};
`

const StudyReviewBlockFooter = styled.div`
  background: ${({ theme }) => theme.color.purpleL4};
  padding-top: ${({ theme }) => theme.padding.xl};
  border-top: 1px solid ${({ theme }) => theme.color.greyL2};
  position: sticky;
  bottom: 0px;
`

const StudyReviewBlockHeader = ({ title, rightContent = null }) => {
  return (
    <StyledHeader>
      <Spacing direction="row" justify="space-between" align="center">
        <Text h5>{title}</Text>
        {rightContent}
      </Spacing>
    </StyledHeader>
  )
}

export const StudyReviewBlock = ({ children }) => {
  return (
    <Box size="xl" backgroundColor="purpleL4" noShadow>
      <Spacing size="xl">{children}</Spacing>
    </Box>
  )
}

StudyReviewBlock.Header = StudyReviewBlockHeader
StudyReviewBlock.Footer = StudyReviewBlockFooter
