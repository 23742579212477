import React from 'react'
import { isEmpty } from 'ramda'
import { Spacing, Text } from '../../../components/ui'
import { RenderFromQuery } from '../../../components/util'
import { TargetAudienceBucketQuery } from '../../../state/queries'
import {
  flattenAudienceSchema,
  getTagsSummary,
  getTagQuestionFromProfileSchema
} from '../../../util'

export const MatchedTagsView = ({ matchedTags }) =>
  !isEmpty(matchedTags) ? (
    <RenderFromQuery
      query={TargetAudienceBucketQuery}
      renderData={({ allUserGroups, audienceSchema, dataSchema }) => {
        const flatConfig = flattenAudienceSchema(audienceSchema)

        return (
          <Spacing>
            <Text h5>Matched Tags</Text>
            <Spacing>
              {matchedTags.map(({ name: uniqueId, values }, index) => {
                const question = getTagQuestionFromProfileSchema(
                  audienceSchema,
                  uniqueId
                )

                // Tribes are not defined in schema but they are in matchedTags
                if (!question && uniqueId !== 'system_groups') return null

                const tagValues = getTagsSummary({
                  flatConfig,
                  allUserGroups
                })([{ uniqueId, values }])

                return (
                  <React.Fragment key={index}>
                    <b>{(question && question.label) || 'Tribes'}</b>:{' '}
                    {tagValues}
                  </React.Fragment>
                )
              })}
            </Spacing>
          </Spacing>
        )
      }}
    />
  ) : null
