import React, { useMemo, useState, useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { filter, contains, symmetricDifference, values, flatten } from 'ramda'

import { StudyReviewBlock, TagFiltersOverview } from '../../blocks'
import { ResponseTagQuestionsOverview } from '../TagFiltersOverview/ResponseTagQuestionsOverview'
import { Text, Button, Spacing, SubmitError } from '../../ui'
import { RenderFromQuery } from '../../util'
import { withMutation } from '../../wrappers'
import { AudienceSchemaQuery } from '../../../state/queries'

import {
  getAudienceCategoriesFromRequirements,
  getAdditionalResponseTags,
  getRequiredResponseTags
} from '../../../util'

import { useModal } from '../../../hooks'

const StudyAdditionalResponseTagsView = ({
  opportunity,
  updateOpportunity
}) => {
  const { openModal } = useModal()

  const [initialValues, setInitialValues] = useState({
    additionalResponseTags: [],
    requiredResponseTags: []
  })

  useEffect(() => {
    setInitialValues({
      additionalResponseTags: flatten(
        values(getAdditionalResponseTags(opportunity))
      ),
      requiredResponseTags: getRequiredResponseTags(opportunity)
    })
  }, [opportunity])

  const onAddQuestion = () => {
    openModal('DefineAdditionalResponseTags', { opportunity })
  }

  // Remove all from category except ones from audienceRequirements
  const onRemoveCategory = (uniqueId, input) =>
    filter(
      childUniqueId =>
        contains(childUniqueId, audienceRequirementsCategories) ||
        !contains(uniqueId, childUniqueId),
      input.value
    )

  const audienceRequirementsCategories = useMemo(
    () =>
      getAudienceCategoriesFromRequirements(opportunity.audienceRequirements),
    [opportunity.audienceRequirements]
  )

  const onSubmit = ({ additionalResponseTags, requiredResponseTags }) => {
    // exclude ones from audienceRequirements
    const filteredResponseTags = symmetricDifference(
      additionalResponseTags,
      audienceRequirementsCategories
    )

    return updateOpportunity({
      variables: {
        id: opportunity.id,
        responseConfig: {
          additionalResponseTags: filteredResponseTags,
          requiredResponseTags
        }
      }
    }).then(({ userErrors, errors }) => {
      return userErrors || errors
    })
  }

  const fieldName = 'additionalResponseTags'

  return (
    <StudyReviewBlock>
      <StudyReviewBlock.Header title="Find out more about your audience" />
      <Spacing size="xl" stretched>
        <Text t2>
          Get the know the audience that participated in your study through the
          specified characteristics.
        </Text>
        {initialValues.additionalResponseTags.length && (
          <Form onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, submitError, dirty }) => (
              <RenderFromQuery
                query={AudienceSchemaQuery}
                renderData={({ audienceSchema }) => (
                  <Spacing size="l">
                    <OnChange name={fieldName}>
                      {() => !!dirty && handleSubmit()}
                    </OnChange>
                    {submitError && <SubmitError submitError={submitError} />}
                    <Field
                      name={fieldName}
                      component={TagFiltersOverview}
                      categories={audienceSchema}
                      onRemoveCategory={onRemoveCategory}
                      renderFilterComponent={props => (
                        <ResponseTagQuestionsOverview
                          audienceRequirementsCategories={
                            audienceRequirementsCategories
                          }
                          requiredResponseTags={
                            opportunity.responseConfig.requiredResponseTags
                          }
                          {...props}
                        />
                      )}
                    />
                  </Spacing>
                )}
              />
            )}
          </Form>
        )}
        <Button block outline onClick={onAddQuestion}>
          + Ask for more tags
        </Button>
      </Spacing>
    </StudyReviewBlock>
  )
}

export const StudyAdditionalResponseTags = withMutation('updateOpportunity')(
  StudyAdditionalResponseTagsView
)
