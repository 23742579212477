export const OpportunityConstants = {
  states: {
    draft: {
      raw: 'draft',
      label: 'draft',
      color: 'secondary'
    },
    inReview: {
      raw: 'inReview',
      label: 'in review'
    },
    ready: {
      raw: 'ready',
      label: 'ready'
    },
    paused: {
      raw: 'paused',
      label: 'paused',
      color: 'yellow'
    },
    published: {
      raw: 'published',
      label: 'active'
    },
    processing: {
      raw: 'processing',
      label: 'processing results'
    },
    completed: {
      raw: 'completed',
      label: 'ended',
      color: 'green'
    }
  },
  minimumAudience: 10
}
