import React from 'react'
import { Form, FormSpy, Field } from 'react-final-form'
import { isNil } from 'ramda'

import { Spacing, Input, SubmitError } from '../'

const FIELD_NAME = 'inlineField'

// TODO: This file is very similar to `./Duration`
// if some other variants of this are needed, better extract and use this as base component
export const InlineField = ({
  initialValue,
  label,
  component = Input,
  onUpdate,
  updateConditions = ({ dirty }) => !!dirty,
  subscription = { dirty: true },
  ...inputProps
}) => {
  const onSubmit = values => {
    const value = values[FIELD_NAME]

    if (isNil(value) || value === initialValue) return
    return onUpdate(value).then(({ errors }) => {
      if (errors) {
        return errors
      }
    })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ [FIELD_NAME]: initialValue }}
      render={({ handleSubmit, submitError }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            subscription={subscription}
            onChange={changed => updateConditions(changed) && handleSubmit()}
          />
          <Spacing>
            {submitError && <SubmitError submitError={submitError} />}
            <Field
              component={component}
              name={FIELD_NAME}
              label={label}
              block
              {...inputProps}
            />
          </Spacing>
        </form>
      )}
    />
  )
}
