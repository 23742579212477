import React from 'react'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'

import { Spacing, Button, Text } from '../../../../components/ui'

import {
  parseAudienceRequirements,
  formatAudienceRequirements,
  getEmptyAudienceBucket
} from '../../../../util'
import {
  isCompletedAudienceStep,
  validatorAllRequirementQueriesFilled
} from '../../../../util/validators'
import { renderLocalStorageNotice } from '../../util'

import { LocalStorage } from '../../../../constants'

import { AudienceBucket } from './components'
import { RenderFromQuery } from '../../../../components/util'
import { AllUserGroupsQuery } from '../../../../state/queries'

export const StudyAudience = props => {
  const noticeId = LocalStorage.audienceNotice

  return (
    <Spacing size="xl">
      {renderLocalStorageNotice(noticeId)}
      <RenderFromQuery
        query={AllUserGroupsQuery}
        renderData={({ allUserGroups: { edges: groups } }) => (
          <FieldArray name="targetAudienceBuckets" mutators={arrayMutators}>
            {({ fields }) => (
              <Spacing size="l">
                <Spacing size="l">
                  {fields.map((name, index) => (
                    <Spacing size="xl">
                      <Field
                        key={name}
                        name={name}
                        component={AudienceBucket}
                        bucketsInfo={{
                          currentBucket: index + 1,
                          totalBuckets: fields.length
                        }}
                        groups={groups}
                        onRemove={() => fields.remove(index)}
                        onUpdate={values => fields.update(index, values)}
                        {...props}
                      />
                      {index < fields.length - 1 && (
                        <Text color="grey" bold center>
                          OR
                        </Text>
                      )}
                    </Spacing>
                  ))}
                </Spacing>
                <Button
                  outline
                  block
                  onClick={() =>
                    fields.push(getEmptyAudienceBucket(fields.length))
                  }
                >
                  + Create a new bucket
                </Button>
              </Spacing>
            )}
          </FieldArray>
        )}
      />
    </Spacing>
  )
}

StudyAudience.format = formatAudienceRequirements
StudyAudience.parse = parseAudienceRequirements
StudyAudience.isCompleted = isCompletedAudienceStep
StudyAudience.validate = validatorAllRequirementQueriesFilled
