import React from 'react'
import styled from 'styled-components/macro'
import { isNil } from 'ramda'

import { Text } from '../../../../../ui'
import { DataSchemaQuery } from '../../../../../../state/queries'
import { RenderFromQuery } from '../../../../../util'
import { buildHelpers } from '../../../../../ui/QueryRow/utils'

const ContainerWrap = styled.div`
  padding-left: ${({ theme }) => theme.padding.l};
`

const Conjuction = styled.span`
  color: ${({ theme, isAnd }) =>
    isAnd ? theme.color.secondary : theme.color.main};
`

// !! unused component
export const AudienceDataRequirementsSummary = ({ audienceRequirements }) => {
  const { queries } = audienceRequirements

  return (
    <ContainerWrap>
      <RenderFromQuery
        query={DataSchemaQuery}
        renderData={({ dataSchema }) => {
          const {
            getSelectedSource,
            getSelectedColumn,
            getSelectedCondition
          } = buildHelpers(dataSchema)

          return queries
            .map((query, n) => {
              const { source, value, conjunction, column } = query
              if (isNil(source) || isNil(column)) return null

              const isAnd = conjunction === 'AND'
              const isLast = n === queries.length - 1

              const selectedSource = getSelectedSource(query)

              // Temporary fix while we rework audience requirement queries
              if (!selectedSource) {
                return null
              }

              const { label: sourceName } = selectedSource
              const { displayName: columnName } = getSelectedColumn(query)
              const { label: conditionName } = getSelectedCondition(query)

              const label = [
                sourceName,
                columnName,
                conditionName,
                value ? value.join('-') : null
              ]
                .filter(Boolean)
                .join('; ')

              return (
                <Text t3>
                  - {label}
                  {!isLast && (
                    <Conjuction isAnd={isAnd}> {conjunction}</Conjuction>
                  )}
                </Text>
              )
            })
            .filter(Boolean)
        }}
      />
    </ContainerWrap>
  )
}
