import React from 'react'
import styled, { css } from 'styled-components/macro'
import posed from 'react-pose'
import { tween } from 'popmotion'
import { interpolate } from 'flubber'

const Toggle = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.purpleL3};
  border-radius: ${({ theme }) => theme.borderRadius.base};
  cursor: pointer;

  ${({ small }) =>
    small &&
    css`
      width: 20px;
      height: 20px;
    `}
`

export const ToggleChevron = ({ toggled, onToggle, ...props }) => (
  <Toggle onClick={onToggle} {...props}>
    <svg
      viewBox="0 0 20 13"
      width={props.small ? '10' : '20'}
      height={props.small ? '7.5' : '13'}
    >
      <Icon
        pose={toggled ? 'up' : 'down'}
        stroke="#6F62FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Toggle>
)

const paths = {
  up: 'M2.121 10L10 2.121M10 2.121L17.879 10',
  down: 'M2.121 3L10 10.879M10 10.879L17.879 3'
}

const pathIds = Object.keys(paths)

const Icon = posed.path(
  pathIds.reduce((config, id) => {
    config[id] = {
      d: paths[id],
      transition: morphTransition
    }

    return config
  }, {})
)

const morphTransition = ({ from, to }) =>
  tween({
    from: 0,
    to: 1
  }).pipe(interpolate(from, to))
