import gql from 'graphql-tag'

export const sendNotification = gql`
  mutation SendNotification(
    $studyId: String!
    $title: String!
    $message: String!
  ) {
    sendNotification(studyId: $studyId, title: $title, message: $message) {
      opportunity {
        id
        sentNotifications {
          id
          timestamp
          notification {
            title
            message
            forceShow
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
`
