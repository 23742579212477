import gql from 'graphql-tag'

export const DataSchemaFragment = gql`
  fragment DataSchema on DataSchema {
    serviceGroups {
      id
      name
    }
    services {
      id
      name
      serviceGroup {
        id
        name
      }
      availableObjectTypes
    }
    objectTypes {
      id
      name
      fieldMappings {
        sourceField
        type
        name
        displayName
        suggestions
        comparisonsMap
      }
    }
  }
`
