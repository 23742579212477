import React from 'react'
import styled from 'styled-components/macro'

import { Container, Text, Spacing } from '../ui'

const LargeGraphic = styled.div`
  flex: 1;
  background: ${props => `url(${props.graphic}) no-repeat center`};
`

const ContentWrap = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  padding-right: ${({ theme }) => theme.padding.xl};
`

const HorizontalLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.greyL2};
`

const ButtonWrap = styled.div`
  display: flex;
  flexdirection: row;
`

export const StatePage = ({ headline, children, buttons, graphic }) => {
  return (
    <Container size="xxxl">
      <ContentWrap>
        <Spacing size="xl">
          <Spacing size="l">
            <Text h0>{headline}</Text>
            {children}
          </Spacing>
          <HorizontalLine />
          <ButtonWrap>{buttons}</ButtonWrap>
        </Spacing>
      </ContentWrap>
      <LargeGraphic graphic={graphic} />
    </Container>
  )
}
