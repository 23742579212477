import React from 'react'
import { Form, Field } from 'react-final-form'
import styled from 'styled-components/macro'

import {
  Graphic,
  Text,
  Button,
  Spacing,
  InlineModal,
  Avatar,
  UploadPhoto,
  SubmitButton
} from '../../../components/ui'
import { validatorRequired } from '../../../util/validators'
import { withMutation } from '../../../components/wrappers'
import { RenderFromQuery } from '../../../components/util'
import { SignUpCompanyQuery } from '../../../state/queries'

import { VerticalFade } from '../components/AnimatedWizardItems'

const UploaderWrap = styled.div({
  width: 450
})

const onSubmit = ({ onNextStep, updateCompany }) => values =>
  updateCompany({ variables: values }).then(({ errors, userErrors, data }) => {
    if (!userErrors && data && data.updateCompany) {
      onNextStep()
    }

    return userErrors || errors
  })

const AvatarPending = () => (
  <Spacing size="xl">
    <VerticalFade>
      <Text t1>Please choose a photo for your company</Text>
    </VerticalFade>
    <VerticalFade>
      <UploaderWrap>
        <InlineModal title="Add photo">
          {({ onCollapse }) => (
            <Spacing>
              <Field
                name="photo"
                component={UploadPhoto}
                onModalCollapse={onCollapse}
              />
            </Spacing>
          )}
        </InlineModal>
      </UploaderWrap>
    </VerticalFade>
  </Spacing>
)

const AvatarUploaded = ({ user }) => (
  <Spacing size="xl">
    <VerticalFade>
      <Text t1>You have already uploaded your photo</Text>
    </VerticalFade>
    <VerticalFade>
      <Avatar user={user} size="default" />
    </VerticalFade>
  </Spacing>
)

const AvatarForm = ({ onNextStep, updateCompany }) => (
  <Spacing size="xxxxl">
    <Graphic name="Logo" png size={80} />
    <RenderFromQuery
      query={SignUpCompanyQuery}
      renderData={({ currentUser }) => (
        <Form
          onSubmit={
            currentUser.company.photo
              ? onNextStep
              : onSubmit({ onNextStep, updateCompany })
          }
          validate={!currentUser.company.photo && validatorRequired('photo')}
        >
          {({ handleSubmit, ...formProps }) => (
            <form onSubmit={handleSubmit}>
              <Spacing size="xl">
                <Text h0>Sign Up</Text>

                {currentUser.company.photo ? (
                  <AvatarUploaded user={currentUser} />
                ) : (
                  <AvatarPending />
                )}

                <VerticalFade>
                  <Spacing direction="row">
                    <SubmitButton {...formProps}>Continue</SubmitButton>
                    <Button onClick={onNextStep} transparent themeColor="grey">
                      Skip for now
                    </Button>
                  </Spacing>
                </VerticalFade>
              </Spacing>
            </form>
          )}
        </Form>
      )}
    />
  </Spacing>
)

export const StepAvatar = withMutation('updateCompany')(AvatarForm)
