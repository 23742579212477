import pluralize from 'pluralize'
import {
  pipe,
  pathOr,
  map,
  filter,
  prepend,
  find,
  prop,
  propEq,
  propOr,
  flatten,
  uniq,
  contains
} from 'ramda'

export const buildHelpers = (dataSchema, dataTypesSchema) => {
  const _formatOptions = (value, label) => ({ value, label })
  const _formatDescription = (id, name) => ({ description: { id, name } })

  const _formatObjectType = ({
    id: objectTypeId,
    name: objectTypeName,
    fieldMappings
  }) =>
    map(
      ({ name, displayName }) => ({
        ..._formatOptions(name, displayName),
        ..._formatDescription(objectTypeId, objectTypeName)
      }),
      fieldMappings
    )

  // gets available objectTypes for selected dataSource (or all of them for All Data Sources)
  const _findAvailableObjectTypes = dataSourceIds =>
    pipe(
      pathOr([], ['services']),
      filter(
        item =>
          contains(-1, dataSourceIds) ||
          pipe(
            prop('id'),
            id => contains(id, dataSourceIds)
          )(item)
      ),
      map(propOr([], ['availableObjectTypes'])),
      flatten,
      uniq
    )(dataSchema)

  const _findObjectType = objectTypeId =>
    pipe(
      pathOr([], ['objectTypes']),
      find(propEq('id', objectTypeId)),
      objectType => {
        if (!objectType) {
          throw new Error(
            `Object type ${objectType} does not exist in dataSchema`
          )
        }

        return objectType
      }
    )(dataSchema)

  const _findConditionByComparisonType = type =>
    pipe(
      find(propEq('name', type)),
      prop('conditions'),
      map(({ id, ...rest }) => ({ value: id, ...rest }))
    )(dataTypesSchema)

  // gets fieldMappings from objectType for given dataSourceIds
  const _getFormatedObjectTypeFields = map(
    pipe(
      _findObjectType,
      _formatObjectType
    )
  )

  const _getObjectTypeFields = pipe(
    _findObjectType,
    propOr([], ['fieldMappings'])
  )

  // gets all objectType options
  const getObjectTypeOptions = () =>
    pipe(
      pathOr([], ['objectTypes']),
      map(({ id, name }) => _formatOptions(id, pluralize(name)))
    )(dataSchema)

  // gets whole objectType object for given objectTypeId
  const getSelectedObjectType = objectTypeId => {
    if (!objectTypeId) return null

    return _findObjectType(objectTypeId)
  }

  // gets source options
  const getSourceOptions = () => {
    return pipe(
      pathOr([], ['services']),
      map(
        ({
          id: sourceId,
          name: sourceName,
          serviceGroup: { id: serviceId, name: serviceName }
        }) => ({
          ..._formatOptions([sourceId], sourceName),
          ..._formatDescription(serviceId, serviceName)
        })
      ),
      prepend(_formatOptions([-1], 'All sources')) // !!! -1 insted of null, so builder can distinguish these two state
    )(dataSchema)
  }

  const getSelectedSource = (objectTypeId, dataSourceIds) => {
    if (!objectTypeId || !dataSourceIds) return null

    const sourceOptions = getSourceOptions(objectTypeId)

    return find(propEq('value', dataSourceIds))(sourceOptions)
  }

  // gets field options for selected objectTypeId
  const getFieldOptions = (objectTypeId, dataSourceIds) => {
    if (!objectTypeId) {
      if (!dataSourceIds) return []

      return pipe(
        _findAvailableObjectTypes,

        _getFormatedObjectTypeFields,
        flatten
      )(dataSourceIds)
    }

    return pipe(
      _getFormatedObjectTypeFields,
      flatten
    )([objectTypeId])
  }

  // gets comparison options for selected objectTypeId and fieldId
  const getComparisonOptions = (objectTypeId, fieldId) => {
    if (!objectTypeId || !fieldId) return []

    return pipe(
      _getObjectTypeFields,
      find(propEq('name', fieldId)),
      ({ type, comparisonsMap }) =>
        comparisonsMap
          ? map(comparison =>
              pipe(
                () => _findConditionByComparisonType(type),
                find(propEq('value', comparison))
              )()
            )(comparisonsMap)
          : _findConditionByComparisonType(type)
    )(objectTypeId)
  }

  // gets input options for selected objectTypeId, fieldId and comparisonId
  const getInputOptions = (objectTypeId, fieldId, comparisonId) => {
    if (!objectTypeId || !fieldId || !comparisonId) return {}

    const selectedField = pipe(
      _getObjectTypeFields,
      find(propEq('name', fieldId))
    )(objectTypeId)

    const { type, suggestions } = selectedField
    if (!type) return {}

    const conditionType = pipe(
      () => _findConditionByComparisonType(type),
      find(propEq('value', comparisonId))
    )()

    return { type, suggestions, ...conditionType }
  }

  return {
    getObjectTypeOptions,
    getSourceOptions,
    getFieldOptions,
    getComparisonOptions,
    getInputOptions,
    getSelectedObjectType,
    getSelectedSource
  }
}

export const getInputType = field =>
  ['integer', 'double', 'date'].includes(field) ? 'number' : 'text'
