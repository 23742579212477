import React from 'react'
import styled from 'styled-components/macro'
import { equals } from 'ramda'

import { Text, Spacing, Button, SpacingItem } from '../../../../components/ui'
import {
  STUDY_COLUMNS,
  AD_COLUMNS,
  AD_HEADER_DESCRIPTION,
  STUDY_HEADER_DESCRIPTION,
  AD_HEADER,
  STUDY_HEADER
} from './constants'

const WelcomeScreenWrap = styled.div`
  padding: ${({ theme }) => `${theme.padding.xxl} ${theme.padding.xxxxl}`};
`

const ColumnWrapper = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.purpleL3};
  padding: ${({ theme }) => theme.padding.xl};
  display: flex;
  flex: 1;
  height: 100%;
`

const renderHeader = category => {
  const { title, buttonLink, buttonText } = equals(category, 'Ad')
    ? AD_HEADER
    : STUDY_HEADER
  return (
    <Spacing direction="row" justify="space-between" stretched>
      <SpacingItem flex="0 1 65%">
        <Spacing size="l">
          <Text h0>{title}</Text>
          <Text>
            {equals(category, 'Ad')
              ? AD_HEADER_DESCRIPTION
              : STUDY_HEADER_DESCRIPTION}
          </Text>
        </Spacing>
      </SpacingItem>
      <Button to={buttonLink}>
        <Text h6 color="white">
          {buttonText}
        </Text>
      </Button>
    </Spacing>
  )
}

const renderBody = category => {
  const columns = equals(category, 'Ad') ? AD_COLUMNS : STUDY_COLUMNS

  return (
    <Spacing direction="row" size="l" stretchChildren>
      {columns.map((column, index) => {
        return (
          <ColumnWrapper key={index}>
            <Spacing size="xl">
              <Text h3>{column.title}</Text>
              <Text t3>
                <div dangerouslySetInnerHTML={{ __html: column.description }} />
              </Text>
            </Spacing>
          </ColumnWrapper>
        )
      })}
    </Spacing>
  )
}

export const OpportunityWelcomeScreen = ({ category }) => (
  <WelcomeScreenWrap>
    <Spacing size="xl">
      {renderHeader(category)}
      {renderBody(category)}
    </Spacing>
  </WelcomeScreenWrap>
)
