import React from 'react'
import styled from 'styled-components/macro'

import { Text, Spacing } from '../../../../components/ui'
import { StudyStats, StudyStateTag } from '../../../../components/blocks'
import { getOpportunityType } from '../../../../util'
import { format } from 'date-fns'
import Formats from '../../../../util/Formats'

const DotSeparator = styled.span`
  color: ${({ theme }) => theme.color.dark};

  ::before {
    content: '·';
  }
`

const renderStudyInfo = opportunity => {
  const StudyType = <Text t3>{getOpportunityType(opportunity)}</Text>
  const StudyCreatedAt = (
    <Text t3>
      created at {format(parseInt(opportunity.createdAt), Formats.date.full)}
    </Text>
  )

  if (opportunity.state === 'draft') return StudyCreatedAt

  return (
    <Spacing direction="row" size="s">
      {StudyType}
      <DotSeparator />
      {StudyCreatedAt}
    </Spacing>
  )
}

export const StudyCardDetails = ({ opportunity }) => (
  <Spacing direction="row" justify="space-between" stretched stretchChildren>
    <Spacing size="xl">
      <Text h2>{opportunity.name}</Text>
      <Spacing direction="row" size="l" align="center">
        <StudyStateTag state={opportunity.state} />
        {renderStudyInfo(opportunity)}
      </Spacing>
    </Spacing>
    <StudyStats direction="row" opportunity={opportunity} size="s" />
  </Spacing>
)

export const StoryCardDetails = ({ opportunity }) => (
  <Spacing direction="row" justify="space-between" stretched stretchChildren>
    <Spacing size="xl">
      <Text h2>{opportunity.name}</Text>
      <Spacing direction="row" size="l" align="center">
        <StudyStateTag state={opportunity.state} />
        {renderStudyInfo(opportunity)}
      </Spacing>
    </Spacing>
  </Spacing>
)
