import React from 'react'
import { format } from 'date-fns'
import styled from 'styled-components'

import {
  Container,
  Tabs,
  Spacing,
  NumberBox,
  Button,
  Text,
  Box,
  Table,
  Number,
  LoadMore
} from '../../components/ui'
import { RenderFromQuery } from '../../components/util'
import { CurrentCompanyQuery } from '../../state/queries'
import { useModal } from '../../hooks'

import { TransactionsQuery } from '../../state/queries/TransactionsQueries'
import Formats from '../../util/Formats'

const DotSeparator = styled.span`
  color: ${({ theme }) => theme.color.white};

  ::before {
    content: '·';
  }
`

const NoMethodFlow = () => {
  const { openModal } = useModal()

  return (
    <Spacing>
      To start add your preferred payment method.
      <Button onClick={() => openModal('ConnectBraintree')}>
        Add payment method
      </Button>
    </Spacing>
  )
}

export const SettingsScreen = () => {
  const { openModal } = useModal()

  return (
    <RenderFromQuery
      query={CurrentCompanyQuery}
      renderData={({
        currentUser: {
          company: { paymentBalance, paymentMethod }
        }
      }) => (
        <Container size="xxl">
          <Spacing stretched>
            <Tabs direction="vertical">
              <Tabs.Tab label="Billing">
                <Spacing size="xl">
                  <Text h2>Billing</Text>

                  {!paymentMethod ? (
                    <NoMethodFlow />
                  ) : (
                    <>
                      <Spacing direction="row" stretchChildren>
                        <NumberBox
                          title="Current Balance"
                          gradient="gradient1"
                          currency="USD"
                          size="s"
                        >
                          {paymentBalance}
                        </NumberBox>
                        <Box
                          title="Card Number"
                          backgroundColor="secondary"
                          size="l"
                        >
                          <Spacing>
                            <Text t3 color="white">
                              Connected Payment Method
                            </Text>
                            <Spacing direction="row" justify="space-between">
                              <Spacing size="m" align="center" direction="row">
                                <Text h6 color="white">
                                  {paymentMethod.cardType}
                                </Text>
                                <DotSeparator />
                                <Text t2 color="white">
                                  {paymentMethod.cardMaskedNumber}
                                </Text>
                              </Spacing>
                              <Button
                                onClick={() => openModal('ConnectBraintree')}
                                link
                                transparent
                                themeColor="white"
                              >
                                Edit
                              </Button>
                            </Spacing>
                          </Spacing>
                        </Box>
                      </Spacing>
                    </>
                  )}
                  <Text h4>Transactions history</Text>
                  <RenderFromQuery
                    query={TransactionsQuery}
                    variables={{
                      first: 10,
                      after: null,
                      orderBy: 'createdAt',
                      sort: 'desc'
                    }}
                    renderData={({ allTransactions }, queryRenderParams) => (
                      <>
                        <Table
                          tableHead={
                            <>
                              <th style={{ width: '20%' }}>
                                <Text h6>Date</Text>
                              </th>
                              <th>
                                <Text h6>Transaction Name</Text>
                              </th>
                              <th>
                                <Text h6 right>
                                  Total USD
                                </Text>
                              </th>
                            </>
                          }
                        >
                          {allTransactions.edges.map(({ node }) => (
                            <tr>
                              <td>
                                {format(
                                  parseInt(node.createdAt),
                                  Formats.date.full
                                )}
                              </td>
                              <td>{node.name}</td>
                              <td>
                                <Text right>
                                  <Number currency="USD">
                                    {node.totalUsd}
                                  </Number>
                                </Text>
                              </td>
                            </tr>
                          ))}
                        </Table>
                        <LoadMore
                          name="allTransactions"
                          data={allTransactions}
                          {...queryRenderParams}
                        />
                      </>
                    )}
                  />
                </Spacing>
              </Tabs.Tab>
            </Tabs>
          </Spacing>
        </Container>
      )}
    />
  )
}
