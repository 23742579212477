import React from 'react'

import { Spacing, LoadMore, Graphic } from '../../../../components/ui'

import { StudyCardDetails, StoryCardDetails } from '../StudyCardDetails'
import { AdCardDetails } from '../../../Ads/components'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const ContentBox = styled(Link)`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  box-shadow: none;
  overflow: hidden;

  transition: all ${({ theme }) => theme.animationSpeed.normal};

  &:hover {
    border-color: ${({ theme }) => theme.color.purpleL1};
    box-shadow: ${props => props.theme.boxShadow.default};
  }
`

const FavoriteWrapper = styled.div`
  padding: ${({ theme }) => `0px ${theme.padding.m}`};
  background: ${({ theme, favorite }) =>
    favorite ? theme.color.purpleL3 : theme.color.purpleL4};

  display: flex;
  align-items: center;
`

const ContentWrapper = styled.div`
  padding: ${({ theme: { padding } }) =>
    `${padding.l} ${padding.l} ${padding.l} ${padding.xl}`};
  display: flex;
  flex: 1;
`

export const StudiesList = ({ studies, queryRenderParams, category }) => {
  const renderCardDetails = node => {
    switch (node.category) {
      case 'Ad':
        return <AdCardDetails opportunity={node} />
      case 'Study':
        return <StudyCardDetails opportunity={node} />
      case 'Story':
        return <StoryCardDetails opportunity={node} />
      default:
        return null
    }
  }

  const categoryRoute = category === 'Ad' ? 'ads' : 'studies'

  return (
    <>
      <Spacing size="l">
        {studies.edges.map(({ node }, n) => (
          <ContentBox to={`/${categoryRoute}/summary/${node.id}`} key={node.id}>
            <FavoriteWrapper favorite={node.favorite}>
              <Graphic
                name="Star"
                size={24}
                themeColor={node.favorite ? 'main' : 'transparent'}
              />
            </FavoriteWrapper>
            <ContentWrapper>{renderCardDetails(node)}</ContentWrapper>
          </ContentBox>
        ))}
      </Spacing>
      <LoadMore name="allOpportunities" data={studies} {...queryRenderParams} />
    </>
  )
}
