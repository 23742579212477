import gql from 'graphql-tag'

export const SurveySchemaQuery = gql`
  query SurveySchemaQuery {
    surveySchema {
      supportedTypes {
        displayName
        name
        validation {
          key
          value
        }
      }
    }
  }
`
