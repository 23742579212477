import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { slice, pathOr, map, uniq } from 'ramda'

import {
  Table,
  Spacing,
  Text,
  EmptyState,
  Graphic
} from '../../../components/ui'
import { RenderFromQuery } from '../../../components/util'
import { PayoutRequestsQuery } from '../../../state/queries'
import { ContainerSpinner } from '../../../components/ui'
import { PayoutRequestsTableRow } from './PayoutRequestsTableRow'

const ROWS_PER_PAGE = 10

const columns = [
  {
    title: 'Payout User',
    accessor: 'payoutUser'
  },
  {
    title: 'Referrer User',
    accessor: 'referrerUser'
  },
  {
    title: 'Referred Users',
    accessor: 'referredUsers'
  },
  {
    title: 'Amount',
    accessor: 'amount'
  }
]

const TableWrap = styled.div`
  flex: 1;
`

const StyledTableHeader = styled.th`
  cursor: pointer;
`

const renderTableHeader = columns =>
  columns.map((column, index) => (
    <StyledTableHeader key={index}>
      <Spacing
        size="xxs"
        direction="row"
        justify="space-between"
        align="center"
        stretched
      >
        <Text h6 right={index > 0}>
          {column.title}
        </Text>
      </Spacing>
    </StyledTableHeader>
  ))

const renderTableRows = ({ rows, ...rest }) =>
  rows.map(({ node }, index) => {
    const payoutRequest = preparePayoutRequestData(node)
    const id = pathOr(null, ['payoutUser', 'id'], node)
    return (
      <PayoutRequestsTableRow
        key={index}
        columns={columns}
        id={id}
        payoutRequest={payoutRequest}
        {...rest}
      />
    )
  })

export const PayoutRequestsTable = ({ status, username }) => {
  const [page, setPage] = useState(1)

  const fetchMore = (allPayoutRequests, queryRenderParams) => {
    const name = 'allPayoutRequests'
    queryRenderParams.fetchMore({
      variables: {
        after: allPayoutRequests.pageInfo.endCursor
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return {
          [name]: {
            ...fetchMoreResult[name],
            edges: uniq(prev[name].edges.concat(fetchMoreResult[name].edges))
          }
        }
      }
    })
  }

  const getPreviousPage = () => {
    setPage(page - 1)
  }

  const getNextPage = (allPayoutRequests, queryRenderParams) => {
    if (allPayoutRequests.edges.length) {
      fetchMore(allPayoutRequests, queryRenderParams)
    }
    setPage(page + 1)
  }

  return (
    <RenderFromQuery
      query={PayoutRequestsQuery}
      variables={{
        after: null,
        first: ROWS_PER_PAGE,
        status,
        username
      }}
      renderLoading={() => (
        <TableWrap>
          <ContainerSpinner />
        </TableWrap>
      )}
      renderEmpty={() => (
        <TableWrap>
          <EmptyState
            title="Waiting for Payout Requests"
            text="Payout Requests will appear here as soon as people initiate any payouts."
            graphic={
              <Graphic
                name="Loading"
                width={145}
                height={60}
                themeColor="main"
              />
            }
          />
        </TableWrap>
      )}
      renderData={({ allPayoutRequests }, queryRenderParams) => {
        const rows = slice(
          (page - 1) * ROWS_PER_PAGE,
          page * ROWS_PER_PAGE,
          allPayoutRequests.edges
        )

        return (
          <TableWrap>
            <Table
              showPagination
              page={page}
              numberOfPages={Math.ceil(
                allPayoutRequests.totalCount / ROWS_PER_PAGE
              )}
              previousPage={getPreviousPage}
              nextPage={() => getNextPage(allPayoutRequests, queryRenderParams)}
              tableHead={renderTableHeader(columns)}
            >
              {renderTableRows({
                rows,
                queryRenderParams,
                fetchMore: () => fetchMore(allPayoutRequests, queryRenderParams)
              })}
            </Table>
          </TableWrap>
        )
      }}
    />
  )
}

const preparePayoutRequestData = ({
  id,
  amount,
  payoutUser,
  referrerUser,
  referredUsers
}) => {
  const extractFieldValue = (fieldName, object) => {
    const value = pathOr(null, [fieldName], object)

    if (fieldName === 'username') return value
    return value ? `${fieldName}: ${value}` : ''
  }

  return {
    id,
    amount: '$' + amount,
    payoutUser: [
      payoutUser
        ? [
            extractFieldValue('username', payoutUser),
            extractFieldValue('email', payoutUser)
          ]
        : ['Deleted account']
    ],
    referrerUser: [
      referrerUser
        ? [
            extractFieldValue('username', referrerUser),
            extractFieldValue('email', referrerUser)
          ]
        : ['Deleted account']
    ],
    referredUsers: map(user =>
      user
        ? [
            extractFieldValue('username', user),
            extractFieldValue('email', user)
          ]
        : ['Deleted account']
    )(referredUsers)
  }
}
