import React from 'react'
import { FieldArray } from 'react-final-form-arrays'

import { DataSchemaQuery } from '../../../state/queries'
import { RenderFromQuery } from '../../util'
import { useQuerySentences } from '../../QueryBuilder/hooks'

import { Spacing } from '../../ui'
import { Field } from 'react-final-form'
import { QueryBuilderSource } from '../../QueryBuilder/components'

export const QueriesBuilder = ({
  input,
  singleQuery = false,
  initialSelectFieldValue,
  ...props
}) => {
  const querySentenceProps = useQuerySentences()
  const fieldName = singleQuery
    ? `${input.name}.query`
    : `${input.name}.queries`

  return (
    <RenderFromQuery
      query={DataSchemaQuery}
      renderData={({ dataSchema, dataTypesSchema }) => {
        if (singleQuery)
          return (
            <Field
              name={fieldName}
              component={QueryBuilderSource}
              queriesInfo={{
                totalQueries: 0,
                currentQueryIndex: 0
              }}
              dataSchema={dataSchema}
              dataTypesSchema={dataTypesSchema}
              querySentenceProps={querySentenceProps}
              singleQuery={singleQuery}
              initialSelectFieldValue={initialSelectFieldValue}
              {...props}
            />
          )

        return (
          <FieldArray
            name={fieldName}
            dataSchema={dataSchema}
            dataTypesSchema={dataTypesSchema}
            render={({ fields, ...rest }) => (
              <Spacing size="xl">
                <Spacing size="l">
                  {fields.map((name, index) => (
                    <Spacing key={`${name}.${index}`}>
                      <Field
                        name={name}
                        component={QueryBuilderSource}
                        onCompleteQuery={() => fields.push({})}
                        queriesInfo={{
                          totalQueries: fields.length,
                          currentQueryIndex: index + 1
                        }}
                        onRemoveQueriesFormField={() => fields.remove(index)}
                        querySentenceProps={querySentenceProps}
                        singleQuery={singleQuery}
                        initialSelectFieldValue={initialSelectFieldValue}
                        {...props}
                        {...rest}
                      />
                    </Spacing>
                  ))}
                </Spacing>
              </Spacing>
            )}
          />
        )
      }}
    />
  )
}
