import { createGlobalStyle } from 'styled-components/macro'

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
  }

  a {
    text-decoration: none;
  }

  @import url('https://fonts.googleapis.com/css?family=Poppins:600,700|Rubik');
`
