import gql from 'graphql-tag'

export const MemberFragment = gql`
  fragment Member on Member {
    id
    ipAddress
    deviceModel
    hyperwallet {
      id
      kycHash
      verificationStatus
    }
    payoutRequests {
      id
      amount
      createdAt
      status
    }
  }
`
