import React, { createRef, useState } from 'react'
import { contains, includes } from 'ramda'

import Geosuggest from 'react-geosuggest'

import { Text, Spacing, Button, SpacingItem, Graphic, TextArea } from '../'
import { Touchable } from '../Touchable'
import { Form, Field } from 'react-final-form'

export const LocationSelector = ({ fields, target, placeholder }) => {
  const _geoSuggest = createRef()

  const onUpdate = suggest => {
    if (!suggest) return

    _geoSuggest.current.clear()
    _geoSuggest.current.focus()
    _geoSuggest.current.blur()
    _geoSuggest.current.focus()

    return fields.push(
      suggest.gmaps.address_components.find(({ types }) =>
        contains(target, types)
      ).long_name
    )
  }

  const [isToggled, setIsToggled] = useState(false)

  return (
    <Spacing size="s">
      {fields.value ? (
        <Spacing size="xs">
          {fields.value.map((item, n) => (
            <Spacing
              key={item}
              direction="row"
              justify="space-between"
              align="center"
            >
              <Text t3>{item}</Text>
              <Touchable onClick={() => fields.remove(n)}>
                <Text t4 color="grey">
                  remove
                </Text>
              </Touchable>
            </Spacing>
          ))}
        </Spacing>
      ) : null}

      <Spacing direction="row">
        <SpacingItem flex={1}>
          <Geosuggest
            style={geosugestStyle}
            placeholder={placeholder}
            autoActivateFirstSuggest={true}
            ignoreTab={true}
            onSuggestSelect={onUpdate}
            onSelect={onUpdate}
            types={['(regions)']}
            skipSuggest={suggest => {
              return !includes(target, suggest.types)
            }}
            ref={_geoSuggest}
          />
        </SpacingItem>
        <Button onClick={() => setIsToggled(!isToggled)} outline>
          <Graphic name="longInput" color="white" size={10} />
        </Button>
      </Spacing>
      {isToggled && (
        <Form
          onSubmit={({ multiValues }) => {
            multiValues.split('\n').map(fields.push)
            setIsToggled(false)
          }}
        >
          {({ handleSubmit, form }) => (
            <Spacing>
              <Field name="multiValues" component={TextArea} block />
              <SpacingItem alignSelf="flex-end">
                <Button
                  onClick={() => {
                    handleSubmit()
                    form.reset()
                  }}
                >
                  Add to list
                </Button>
              </SpacingItem>
            </Spacing>
          )}
        </Form>
      )}
    </Spacing>
  )
}

const geosugestStyle = {
  input: {
    width: '100%',
    border: 'none',
    borderColor: 'transparent',
    borderRadius: 7,
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: '#F5F4FF',
    color: '#494949',
    fontSize: 12,
    fontFamily: 'Rubik',
    outline: 'none'
  },
  suggestItem: {
    listStyleType: 'none',
    cursor: 'pointer',
    fontSize: 17,
    fontFamily: 'Rubik',
    lineHeight: 1.2,
    paddingTop: 5
  }
}
