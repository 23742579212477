import { pipe, mergeAll, map, is } from 'ramda'

// Utility for merging validator fns
export const mergeValidators = validators => data =>
  pipe(
    map(validator => validator(data)),
    mergeAll
  )(validators)

// Utility for checking existence of field
export const validatorRequired = (fieldName, label, message) => values => {
  const value = values[fieldName]
  if (!value || (is(Array, value) && value.length === 0)) {
    return { [fieldName]: message || `${label} is mandatory` }
  }

  return {}
}

// Utility for checking existence of at least one of the fields
export const validatorAtLeastOneRequired = (
  fieldNames,
  labels,
  message
) => values => {
  const allValues = fieldNames.map(fieldName => values[fieldName])

  const missingFields = allValues.filter(
    value => !value || (is(Array, value) && value.length === 0)
  )

  if (missingFields.length === fieldNames.length) {
    return {
      _general: message || `One of either ${labels.join(', ')} is mandatory`
    }
  }

  return {}
}
