import React from 'react'
import {
  Spacing,
  SubmitError,
  Button,
  Touchable,
  Text,
  Input
} from '../../../../components/ui'
import { FieldArray } from 'react-final-form-arrays'
import { Field } from 'react-final-form'
import { pick } from 'ramda'

export const StoryScreenTemplates = ({ opportunity, submitError }) => {
  return (
    <Spacing>
      {submitError && <SubmitError submitError={submitError} />}
      <Field
        name="story.screens"
        component={StoryScreenInput}
        label="Screen Template"
      />
    </Spacing>
  )
}

const StoryScreenInput = ({ input, label }) => {
  return (
    <FieldArray name={`${input.name}`}>
      {({ fields }) => (
        <Spacing stretched>
          {fields.map((name, index) => (
            <Spacing key={name} direction="row" align="center">
              <Field
                key={name}
                name={`${name}.template`}
                label={`${label} #${index + 1}`}
                component={Input}
              />
              <Field
                key={name}
                name={`${name}.thumbnail`}
                label={`Thumbnail URL #${index + 1}`}
                component={Input}
              />
              <Touchable onClick={() => fields.remove(index)}>
                <Text t3>remove</Text>
              </Touchable>
            </Spacing>
          ))}
          <Button transparent link onClick={() => fields.push()} prefix="+">
            Add more
          </Button>
        </Spacing>
      )}
    </FieldArray>
  )
}

StoryScreenTemplates.parse = story => ({
  screens: story.screens.map(pick(['template', 'thumbnail']))
})
