import React from 'react'

import { Spacing, Text } from '../../../ui'
import { parseAgeTagsOverview } from '../utils'

import { HorizontalBarChart } from './HorizontalBarChart'

export const CategoriesChart = ({ tagCategories }) => (
  <Spacing size="s">
    {tagCategories.map((tags, tagIndex) => {
      const tagsOverview =
        tags.name === 'Age'
          ? {
              ...tags,
              values: parseAgeTagsOverview(tags.values)
            }
          : tags

      return (
        <Spacing size="s" key={`tags-category-${tagIndex}`}>
          <Text h6>{tags.name}</Text>
          <HorizontalBarChart tags={tagsOverview} />
        </Spacing>
      )
    })}
  </Spacing>
)
