import gql from 'graphql-tag'
import { AudienceSchemaItemFragment } from '../fragments'

export const AudienceSchemaQuery = gql`
  query AudienceSchemaQuery {
    audienceSchema(includeAppManaged: true) {
      ...AudienceSchemaItem
      children {
        ...AudienceSchemaItem
        children {
          ...AudienceSchemaItem
          userCount
        }
        subfields {
          ...AudienceSchemaItem
        }
      }
      subfields {
        ...AudienceSchemaItem
      }
    }
  }
  ${AudienceSchemaItemFragment}
`

export const AudienceDataVerifiedTagsQuery = gql`
  query AudienceDataVerifiedTagsQuery($tags: [String!]) {
    audienceDataVerifiedTags(tags: $tags) {
      uniqueId
      queries {
        nativeQueryDescription {
          filter
        }
        sql
        objectTypeId
        dataSourceIds
      }
    }
  }
`
