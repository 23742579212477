import React from 'react'
import styled from 'styled-components/macro'
import { FieldArray } from 'react-final-form-arrays'
import { keys, omit, contains } from 'ramda'

import {
  Spacing,
  SpacingItem,
  Text,
  Tag,
  CheckGroup,
  Touchable
} from '../../../components/ui'

const TagsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -${({ theme }) => theme.padding.s};
`

const Wrap = styled.div`
  margin-top: ${({ theme }) => theme.padding.s};
  margin-bottom: ${({ theme }) => theme.padding.s};
`

const QuestionTagWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.padding.s};
`
export const hasSubfieldTag = uniqueId => parentUniqueId =>
  contains(uniqueId, parentUniqueId)

export const TagQuestionsOverview = ({ input, questions = [] }) => (
  <Wrap>
    <Spacing size="m">
      {questions
        .reduce((allQuestions, question) => {
          if (question.subfields) {
            return allQuestions.concat(question).concat(question.subfields)
          }
          return allQuestions.concat(question).concat(question.subfields || [])
        }, [])
        .filter(({ uniqueId }) => keys(input.value).includes(uniqueId))
        .map(renderQuestionRow({ input }))}
    </Spacing>
  </Wrap>
)

const renderQuestionRow = ({ input }) => ({
  uniqueId,
  label,
  children,
  renderTags = renderQuestionTags,
  custom
}) => {
  const onQuestionRemove = () => {
    return input.onChange(omit([uniqueId], input.value))
  }

  const childrenToRender = custom
    ? input.value[uniqueId].map(value => ({ uniqueId: value, label: value }))
    : children

  return (
    <Spacing key={uniqueId} size="l" direction="row" stretchChildren>
      <Text h6>{label}</Text>
      <SpacingItem style={{ flex: 2 }}>
        {renderTags({
          input,
          uniqueId,
          children: childrenToRender,
          onQuestionRemove
        })}
      </SpacingItem>
      <SpacingItem style={{ flex: 0 }}>
        <Touchable onClick={onQuestionRemove}>
          <Text h7 color="grey">
            remove
          </Text>
        </Touchable>
      </SpacingItem>
    </Spacing>
  )
}

const renderQuestionTags = ({
  input,
  uniqueId,
  children,
  onQuestionRemove
}) => (
  <TagsWrap>
    <FieldArray
      name={`${input.name}.${uniqueId}`}
      render={props => (
        <CheckGroup
          {...props}
          render={({ children }) => (
            <Spacing direction="row" size="s" wrap>
              {children.map(
                child =>
                  child && (
                    <SpacingItem as={QuestionTagWrapper} key={child.key}>
                      {child}
                    </SpacingItem>
                  )
              )}
            </Spacing>
          )}
        />
      )}
      onGroupRemove={onQuestionRemove}
      renderCheck={({ input: { checked }, ...props }) =>
        checked && <Tag {...props} removable />
      }
      options={children.map(({ uniqueId, label }) => ({
        value: uniqueId,
        label
      }))}
    />
  </TagsWrap>
)
