import React, { useState } from 'react'
import { pipe, equals } from 'ramda'
import { graphql } from 'react-apollo'

import { OpportunityDetailsQuery } from '../../state/queries'

import { Container, ContainerSpinner, Spacing, Tabs } from '../../components/ui'
import { StudyName, StudyManipulation } from '../../components/blocks'
import SummaryTab from './SummaryTab'
import ResultsTab from './ResultsTab'
import { DataViewer } from '../../components/blocks/DataViewer'
import { OpportunitySpecifications } from './components'

const isAd = opportunity => equals(opportunity.category, 'Ad')

const StudySummary = ({ data: { opportunity } }) => {
  if (!opportunity) {
    return <ContainerSpinner />
  }

  const [shortId, setShortId] = useState('')

  const renderAllTabs = () => (
    <>
      <Tabs
        size="xl"
        rightContent={<OpportunitySpecifications opportunity={opportunity} />}
      >
        <Tabs.Tab label="Summary">
          <SummaryTab
            opportunity={opportunity}
            openDataTab={() => {}}
            setShortId={setShortId}
          />
        </Tabs.Tab>
        <Tabs.Tab label="Data">
          <DataViewer shortId={shortId} opportunity={opportunity} />
        </Tabs.Tab>
        <Tabs.Tab label="Results">
          <ResultsTab opportunity={opportunity} />
        </Tabs.Tab>
      </Tabs>
    </>
  )

  const renderSummaryTab = () => (
    <Tabs size="xl">
      <Tabs.Tab
        label="Summary"
        rightContent={<OpportunitySpecifications opportunity={opportunity} />}
      >
        <SummaryTab
          opportunity={opportunity}
          openDataTab={() => {}}
          setShortId={setShortId}
          isAd={isAd(opportunity)}
        />
      </Tabs.Tab>
    </Tabs>
  )

  return (
    <Container size="xxl">
      <Spacing size="xl" stretched>
        <Spacing direction="row" justify="space-between">
          <StudyName
            opportunity={opportunity}
            editable={false}
            backRouteName="/studies"
          />
          <StudyManipulation opportunity={opportunity} />
        </Spacing>

        {isAd(opportunity) ? renderSummaryTab() : renderAllTabs()}
      </Spacing>
    </Container>
  )
}

export const StudySummaryScreen = pipe(
  graphql(OpportunityDetailsQuery, {
    options: props => ({
      variables: {
        id: props.match.params.opportunityId
      }
    })
  })
)(StudySummary)
