import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { withMutation } from '../../components/wrappers'

const ConnectionState = ({ connection }) => {
  if (connection === 'connecting') {
    return 'Connecting...'
  }

  if (connection === 'failed') {
    return 'Error while connecting.'
  }

  return 'Connected!'
}

const Container = styled.div`
  color: ${props => props.theme.color.dark};
  text-align: center;
  padding-top: 100px;
  ${props => props.theme.text.h1};
`

const ConnectTypeformInner = ({
  connectTypeform,
  location: { search: code }
}) => {
  const [connection, setConnection] = useState('connecting')

  useEffect(() => {
    connectTypeform({ variables: { code } }).then(({ userErrors, errors }) => {
      console.log({ userErrors, errors })
      if (userErrors || errors) {
        setConnection('failed')
        return
      }

      setConnection('connected')
    })
  }, [])

  return (
    <Container>
      <ConnectionState connection={connection} />
    </Container>
  )
}

export const ConnectTypeform = withMutation('connectTypeform')(
  ConnectTypeformInner
)
