import React from 'react'

import { Text, Spacing, Box, SpacingItem } from '../../../../ui'
import styled from 'styled-components'

const AdCardContainer = styled(Box)`
  background-color: ${({ color }) => color};
`

const UrlStyle = styled.a`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #fff;
  }
`

const AdImage = ({ ad }) => (
  <img src={ad.imageUrl} width={76} alt={ad.hostUrl} />
)

export const AdCard = ({ opportunity: { name, ad } }) => {
  const textColor = ad.colorHex === '#FFFFFF' ? 'dark' : 'white'

  return (
    <AdCardContainer color={ad.colorHex}>
      <Spacing direction="row">
        <SpacingItem flex="1 0 60%">
          <Spacing size="l" flex={1}>
            <Text h6 color={textColor}>
              {name}
            </Text>
            <Spacing direction="row" size="s">
              <Text t3 color={textColor}>
                Ad
              </Text>
              <Text t3 color={textColor}>
                ·
              </Text>
              <UrlStyle href={ad.fullUrl} target="_blank">
                <Text t3 color={textColor}>
                  {ad.hostUrl}
                </Text>
              </UrlStyle>
            </Spacing>
          </Spacing>
        </SpacingItem>
        {ad.imageUrl && <AdImage ad={ad} />}
      </Spacing>
    </AdCardContainer>
  )
}
