import { isNil } from 'ramda'

// if image is not obtained from s3 (imageUrl), return the one from local url
export const parseImageUrl = ({ imageUrl, image }) => {
  if (!isNil(image)) {
    return image
  }

  if (!isNil(imageUrl)) {
    return { src: imageUrl.default }
  }

  return null
}
