import React from 'react'

import { Spacing, InlineField } from '../../ui'
import { withMutation } from '../../wrappers'
import { DatePicker } from '../../ui'

const StudyStartDateSection = ({
  opportunity,
  updateOpportunity,
  initialValue,
  inlineError
}) => {
  const onUpdate = startDate =>
    updateOpportunity({
      variables: {
        id: opportunity.id,
        duration: { startDate }
      }
    })

  return (
    <Spacing size="s">
      <InlineField
        component={DatePicker}
        onUpdate={onUpdate}
        graphic="Calendar"
        graphicSize={20}
        graphicColor="main"
        label="Start date"
        initialValue={initialValue}
        inlineError={inlineError}
      />
    </Spacing>
  )
}

export const StudyStartDate = withMutation('updateOpportunity')(
  StudyStartDateSection
)
