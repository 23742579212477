import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { withMutation } from '../../components/wrappers'
import { slice } from 'ramda'
import { useHistory } from 'react-router-dom'

const ConnectionState = ({ connection }) => {
  if (connection === 'connecting') {
    return 'Connecting...'
  }

  if (connection === 'failed') {
    return 'An error has occured. Please resend the code and try again.'
  }
}

const Container = styled.div`
  color: ${props => props.theme.color.dark};
  text-align: center;
  padding-top: 100px;
  ${props => props.theme.text.h1};
`

const VerifyEmailInner = ({
  verifyCompanyEmail,
  location: { search: verificationCode }
}) => {
  const [connection, setConnection] = useState('connecting')
  const { push } = useHistory()

  const code = slice(6, Infinity, verificationCode)

  useEffect(() => {
    verifyCompanyEmail({ variables: { code } }).then(
      ({ userErrors, errors }) => {
        if (userErrors || errors) {
          setConnection('failed')
          return
        }
        push('/')
      }
    )
  }, [code])

  return (
    <Container>
      <ConnectionState connection={connection} />
    </Container>
  )
}

export const VerifyEmail = withMutation('verifyCompanyEmail')(VerifyEmailInner)
