import React from 'react'
import styled from 'styled-components/macro'
import { useLocalStorageState } from 'react-storage-hooks'

import { Notice as NoticeText } from '../../../constants'
import { Notice, Spacing, Box, Graphic, Text } from '../../../components/ui'
import { isCompletedAudienceStep } from '../../../util/validators'
import { map, pipe, intersection, flatten, length } from 'ramda'

const MEDICAL_SOURCES = ['16', '20', '30', '421']

const TextWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.padding.xxl};
`

export const renderLocalStorageNotice = noticeId => {
  const [isNoticeDismissed, setIsNoticeDismissed] = useLocalStorageState(
    noticeId,
    null
  )

  if (isNoticeDismissed) return null

  return (
    <Notice
      title={NoticeText[noticeId].title}
      onClose={() => {
        setIsNoticeDismissed(true)
      }}
    >
      {NoticeText[noticeId].text}
    </Notice>
  )
}

export const renderCollectingDataNotice = opportunity => {
  if (!isCompletedAudienceStep(opportunity)) return null

  return (
    <Box size="xxl">
      <Spacing size="xl" align="center">
        <Graphic name="Loading" width={145} height={60} themeColor="main" />
        <Spacing size="l">
          <Text h3 center>
            We are now counting the eligible members in the UBDI community based
            on your requirements
          </Text>
          <TextWrapper>
            <Text t2 center>
              You will be able to review the audience size of your study prior
              to launching your study. In the meantime you can continue building
              your study and we will notify you when the count is finished.
            </Text>
          </TextWrapper>
        </Spacing>
      </Spacing>
    </Box>
  )
}

const checkIfMedicalSourceSelected = pipe(
  map(bucket =>
    map(query => intersection(query.dataSourceIds, MEDICAL_SOURCES))(
      bucket.queries || []
    )
  ),
  flatten,
  length
)

export const renderMedicalSourcesNotice = opportunity => {
  if (!checkIfMedicalSourceSelected(opportunity.targetAudienceBuckets))
    return null

  return (
    <Notice>
      Since you’ve selected medical sources in your audience buckets your study
      will undergo detailed review. We’ll follow up with you shortly if we have
      questions.
    </Notice>
  )
}
