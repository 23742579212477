import React from 'react'
import styled from 'styled-components/macro'

import {
  StudiesListMini,
  ResultsListMini,
  PlaygroundList,
  UserStats,
  DataSourcesStats
} from './components'
import { Box, Spacing, SpacingItem } from '../../components/ui'

const FirstRowWrapper = styled.div`
  display: grid;
  padding: ${({ theme }) => theme.padding.xl};
  grid-template-columns: 66% auto;
  grid-gap: ${({ theme }) => theme.padding.xl};
  height: auto;
`

const SecondRowWrapper = styled.div`
  display: grid;
  padding: ${({ theme }) => theme.padding.xl};
  grid-template-columns: 50% auto;
  grid-gap: ${({ theme }) => theme.padding.xl};
  height: auto;
`

const ContentWrapper = styled.div`
  flex: 1;
  height: 100%;
  max-width: 50%;
`

export const OverviewScreen = () => {
  return (
    <>
      <FirstRowWrapper>
        <Box backgroundColor="white" size="xl">
          <Spacing direction="row" size="l" stretchChildren>
            <SpacingItem as={ContentWrapper}>
              <DataSourcesStats limit={5} />
            </SpacingItem>
            <SpacingItem as={ContentWrapper}>
              <UserStats />
            </SpacingItem>
          </Spacing>
        </Box>
        <StudiesListMini limit={2} />
      </FirstRowWrapper>
      <SecondRowWrapper>
        <ResultsListMini limit={2} />
        {/* <AudienceStats limit={10} /> */}
        <PlaygroundList />
      </SecondRowWrapper>
    </>
  )
}
