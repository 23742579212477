import React, { useState, useEffect } from 'react'
import { isNil } from 'ramda'
import styled, { css } from 'styled-components/macro'
import { Field } from 'react-final-form'
import { Text, Spacing, Checkbox } from '../../ui'
import { QueryBuilderInput } from '../../QueryBuilder/components'

const QuotaWrapper = styled.div`
  ${props => {
    const { disabled } = props
    const opacity = disabled ? 0.5 : 1

    return css`
      border: 0px;
      opacity: ${opacity};
    `
  }}
`

export const AudienceQuota = ({ input, text }) => {
  const [isDisabled, setDisabled] = useState(true)

  useEffect(() => {
    if (!isNil(input.value.quota)) setDisabled(false)
  }, [])

  const toggleDisabled = input => {
    if (!isDisabled) {
      input.onChange(null)
    }
    setDisabled(!isDisabled)
  }

  return (
    <Field
      name={`${input.name}.quota`}
      render={({ input }) => {
        return (
          <Spacing direction="row" justify="space-between" align="center">
            <QuotaWrapper disabled={isDisabled}>
              <Spacing direction="row" size="xs" align="center">
                <Text t1 color="dark">
                  {text[0]}
                </Text>
                <Field
                  name={input.name}
                  component={QueryBuilderInput}
                  placeholder="number"
                  inputType="number"
                  center
                  min={1}
                  disabled={isDisabled}
                  {...input}
                />
                <Text t1>{text[1]}</Text>
              </Spacing>
            </QuotaWrapper>
            <Checkbox
              label="Set the limit of people"
              input={{
                onChange: () => toggleDisabled(input),
                checked: !isDisabled
              }}
            />
          </Spacing>
        )
      }}
    />
  )
}
