import React from 'react'
import { Spacing } from '../../../../../../components/ui'
import styled from 'styled-components'
import { ImageActions } from '../ImageActions'

const ImagePreviewContainer = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => theme.padding.l};
`

const Image = styled.img`
  max-width: 100%;
`

export const ImagePreview = ({ image, replace, remove }) => (
  <ImagePreviewContainer>
    <Spacing>
      <Image src={image.src} />
      <ImageActions replace={replace} remove={remove} />
    </Spacing>
  </ImagePreviewContainer>
)
