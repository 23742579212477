import React, { useState } from 'react'
import styled from 'styled-components'
import { includes } from 'ramda'
import { FieldArray } from 'react-final-form-arrays'

import { DataSchemaQuery } from '../../../state/queries'
import { RenderFromQuery } from '../../util'

import { Spacing, Button, Text, Select, Tag, Checkbox } from '../../ui'
import { Field } from 'react-final-form'
import { getPadding, getColor } from '../../../util/styled'
import { getEmptySimpleQuery } from '../../../util'

const DataSourceGroup = styled.div`
  width: 200px;
  margin-bottom: ${getPadding('m')};
`

const RANGE_OPTIONS = [
  {
    label: '1 week',
    value: 7
  },
  {
    label: '1 month',
    value: 30
  },
  {
    label: '2 months',
    value: 60
  },
  {
    label: '6 months',
    value: 180
  },
  {
    label: '1 year',
    value: 365
  }
]

const LabelWrapper = styled.label`
  cursor: pointer;
  display: flex;
`

const Box = styled.div`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  display: inline-block;
  margin-right: ${props => props.theme.padding.s};
  border-radius: ${props => props.theme.borderRadius.small};
  background-color: ${props =>
    props.checked ? props.theme.color.main : props.theme.color.purpleL2};

  transition: background-color ${props => props.theme.animationSpeed.normal};
`

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

const DotSeparator = styled.span`
  color: ${getColor('grey')};

  ::before {
    content: '·';
  }
`

// TODO: Extract
const DataSourcePicker = ({ input, meta, dataSchema }) => {
  const [edit, setEdit] = useState(false)

  const isSelected = id => includes(id, input.value || [])

  const handleToggle = id => () => {
    if (isSelected(id)) {
      input.onChange(input.value.filter(value => value !== id))
    } else {
      input.onChange([...(input.value || []), id])
    }
  }

  const handleSelectAll = () => {
    input.onChange(
      dataSchema.services
        .filter(({ availableObjectTypes }) => availableObjectTypes.length > 0)
        .map(({ id }) => id)
    )
  }

  const handleUnselectAll = () => {
    input.onChange([])
  }

  if (!edit) {
    const selectedSources = dataSchema.services.filter(({ id }) =>
      isSelected(id)
    )

    return (
      <Spacing direction="column">
        <Spacing direction="row">
          {selectedSources.length === 0
            ? ''
            : selectedSources.map(({ name }) => <Tag selected label={name} />)}
        </Spacing>
        <DotSeparator />
        <Button onClick={() => setEdit(true)}>Add sources</Button>
      </Spacing>
    )
  }

  return (
    <Spacing>
      <Text t2>
        By selecting more than one data source, your opportunity will be matched
        with people who have EITHER of these sources. Ex. if you select Twitter
        + Finance your opportunity will show to people who have either Twitter
        OR Finance. If you want your opportunity to show to people who have BOTH
        data sources, adding an additional query will give you the AND
        functionality
      </Text>
      <Spacing direction="row" wrap>
        {dataSchema.serviceGroups.map(serviceGroup => (
          <DataSourceGroup>
            <Text h4 color="gray">
              {serviceGroup.name}
            </Text>
            <br />
            <Spacing size="xs">
              {dataSchema.services
                .filter(
                  ({ serviceGroup: { id }, availableObjectTypes }) =>
                    serviceGroup.id === id && availableObjectTypes.length > 0
                )
                .map(service => (
                  <LabelWrapper>
                    <div>
                      <CheckboxInput
                        onChange={handleToggle(service.id)}
                        type="checkbox"
                      />
                      <Box checked={isSelected(service.id)} />
                    </div>
                    <Text
                      inline
                      color={isSelected(service.id) ? 'main' : 'dark'}
                    >
                      {service.name}
                    </Text>
                  </LabelWrapper>
                ))}
            </Spacing>
          </DataSourceGroup>
        ))}
      </Spacing>
      <Spacing direction="row" align="center" justify="flex-end">
        <Button link transparent onClick={handleSelectAll}>
          select all
        </Button>
        <DotSeparator />
        <Button link transparent onClick={handleUnselectAll}>
          unselect all
        </Button>
        <Button onClick={() => setEdit(false)}>done</Button>
      </Spacing>
    </Spacing>
  )
}

const CustomTextarea = styled.textarea`
  outline: none;
  width: 500px;
  height: 100px;
  outline: none;
  padding: ${getPadding('m')};
  border: 1px solid ${getColor('grey')};
`

const KeywordArea = ({ input, meta }) => {
  const handleChange = event => {
    input.onChange(event.target.value.split('\n'))
  }

  return (
    <CustomTextarea onChange={handleChange}>
      {input.value.join('\n')}
    </CustomTextarea>
  )
}

// TODO: Extract
const SimpleQueryBuilderQuery = ({ dataSchema, input, includeKeywords }) => {
  const { name } = input

  return (
    <>
      <Spacing size="xl">
        <Spacing>
          <Spacing size="xxs">
            <Text h5>Include or Exclude</Text>
            <Text t2 color="grey">
              Define if you want to include people matching this query or
              exclude
            </Text>
          </Spacing>
          <Field
            name={`${name}.queryMatch`}
            label="Include"
            component={Checkbox}
            type="checkbox"
          />
        </Spacing>
        <Spacing>
          <Spacing size="xxs">
            <Text h5>Read from data sources:</Text>
            <Text t2 color="grey">
              Select sources you'd like to read from for your query
            </Text>
          </Spacing>
          <Field
            name={`${name}.dataSourceIds`}
            component={DataSourcePicker}
            dataSchema={dataSchema}
          />
        </Spacing>

        <Spacing>
          <Spacing size="xxs">
            <Text h5>Limit by date range:</Text>
            <Text t2 color="grey">
              Limit your query by a date range
            </Text>
          </Spacing>
          <div style={{ width: 500 }}>
            <Field
              name={`${name}.dateRange`}
              component={Select}
              graphic="Calendar"
              graphicSize={20}
              graphicColor="main"
              label="Date Range"
              emptySelectionPossible
              emptySelectionText="All Time"
              options={RANGE_OPTIONS}
            />
          </div>
        </Spacing>

        {includeKeywords && (
          <>
            <Spacing>
              <Spacing size="xxs">
                <Text h5>Filter by keywords:</Text>
                <Text t2 color="grey">
                  Search data for any specified keyword (separated by new line)
                </Text>
              </Spacing>
              <Field name={`${name}.keywords`} component={KeywordArea} />
            </Spacing>
          </>
        )}
      </Spacing>
    </>
  )
}

const AndSeparator = styled.div`
  background-color: ${getColor('purpleL3')};
  padding: ${getPadding('m')} 0;
`

export const SimpleQueriesBuilder = ({
  input,
  singleQuery = false,
  initialSelectFieldValue,
  includeKeywords,
  ...props
}) => {
  const fieldName = singleQuery
    ? `${input.name}.simpleQuery`
    : `${input.name}.simpleQueries`

  return (
    <RenderFromQuery
      query={DataSchemaQuery}
      renderData={({ dataSchema }) => (
        <FieldArray
          name={fieldName}
          render={({ fields, ...rest }) => {
            const fieldsSize = fields.value.reduce((total, name, index) => {
              if (fields.value[index].includeKeywords !== !!includeKeywords) {
                return total
              }

              return total + 1
            }, 0)

            let realIndex = 0

            return (
              <Spacing size="xl">
                <Spacing size="xl">
                  {fields.map((name, index) => {
                    if (
                      fields.value[index].includeKeywords !== !!includeKeywords
                    ) {
                      return null
                    }

                    realIndex++

                    return (
                      <Spacing key={`${name}.${index}`}>
                        <Spacing direction="row" justify="space-between">
                          <Tag selected label={`Query #${realIndex}`} />
                          <Button
                            onClick={() => {
                              if (window.confirm('Are you sure?')) {
                                fields.remove(index)
                              }
                            }}
                            link
                            transparent
                            color="red"
                          >
                            delete query
                          </Button>
                        </Spacing>
                        <Field
                          name={name}
                          component={SimpleQueryBuilderQuery}
                          dataSchema={dataSchema}
                          includeKeywords={includeKeywords}
                        />
                        {realIndex < fieldsSize && (
                          <AndSeparator>
                            <Text color="dark" bold center>
                              AND
                            </Text>
                          </AndSeparator>
                        )}
                      </Spacing>
                    )
                  })}
                  <Button
                    block
                    outline
                    onClick={() =>
                      fields.push(getEmptySimpleQuery({ includeKeywords }))
                    }
                    prefix="+"
                  >
                    Add more
                  </Button>
                </Spacing>
              </Spacing>
            )
          }}
        />
      )}
    />
  )
}
