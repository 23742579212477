import React from 'react'
import { toUpper } from 'ramda'
import { lighten } from 'polished'
import styled, { css } from 'styled-components'

import { Text, Spacing } from '../'

const Wrap = styled.div`
  box-shadow: rgba(9, 30, 66, 0.31) 0px 0px 1px,
    rgba(9, 30, 66, 0.25) 0px 4px 8px -2px;
  background: rgb(255, 255, 255);
  border: 1px solid ${({ theme, type }) => theme.color[type]};
  border-radius: 5px;
  list-style-type: none;
  margin-left: 15px;
  overflow: scroll;
  max-height: 450px;
`

const getBackgroundColor = ({ theme, type, isHighlighted }) => {
  if (type === 'transparent' || type === 'purpleL3') {
    return isHighlighted ? theme.color.grey : theme.color.white
  }

  return isHighlighted
    ? theme.color.white
    : lighten(0.2, `${theme.color[type]}`)
}

const SuggestionWrap = styled.span`
  ${props => {
    const { theme, type } = props
    const backgroundColor = getBackgroundColor(props)

    return css`
      margin: 0;
      list-style-type: none;
      background-color: ${backgroundColor}
      display: flex;
      cursor: pointer;
      border: 0.5px solid ${theme.color[type]};
      padding: 5px 10px;
      min-width: 100%;
    `
  }}
`

export const SuggestionList = ({ children, containerProps, ...props }) => {
  if (!children) return null
  return (
    <Wrap {...containerProps} {...props}>
      {children}
    </Wrap>
  )
}

export const Suggestion = ({ label, value, description, ...props }) => (
  <SuggestionWrap {...props}>
    <Spacing direction="row" justify="space-between" align="center" stretched>
      <Text t3 color="dark" noWrap>
        {label}
      </Text>
      {description && (
        <Text formLabel color={props.type} right noWrap>
          {toUpper(description.name)}
        </Text>
      )}
    </Spacing>
  </SuggestionWrap>
)
