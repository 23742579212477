import React, { useState } from 'react'
import { map, pipe, isEmpty } from 'ramda'
import { withApollo, graphql } from 'react-apollo'

import { Spacing, Button } from '../../../../components/ui'

import {
  validatorContainsQueries,
  isCompletedDataSourcesStep
} from '../../../../util/validators'
import { parseQuery, formatQuery } from '../../../../util'
import {
  renderLocalStorageNotice,
  renderMedicalSourcesNotice
} from '../../util'

import { LocalStorage } from '../../../../constants'

import { StudyAdditionalResponseTags } from '../../../../components/blocks'
import { AddSimpleDataInsights, AddDataInsights } from './components'
import { CurrentUserQuery } from '../../../../state/queries'

const StudyDataSourcesView = ({
  opportunity,
  registerSubmitHandler,
  onModifiedFields,
  data: {
    currentUser: { currentRole, id }
  },
  ...props
}) => {
  const [inAdvancedMode, setInAdvancedMode] = useState(false)
  const noticeId = LocalStorage.dataSourcesNotice

  return (
    <Spacing size="xl">
      {['Study', 'Story'].includes(opportunity.category) && (
        <Spacing size="xl">
          {renderLocalStorageNotice(noticeId)}
          {renderMedicalSourcesNotice(opportunity)}
          <Spacing direction="row-reverse">
            <Button
              outline={!inAdvancedMode}
              onClick={() => setInAdvancedMode(true)}
            >
              Advanced Mode
            </Button>
            <Button
              outline={inAdvancedMode}
              onClick={() => setInAdvancedMode(false)}
            >
              Simple Mode
            </Button>
          </Spacing>
          {inAdvancedMode ? (
            <AddDataInsights opportunity={opportunity} {...props} />
          ) : (
            <AddSimpleDataInsights opportunity={opportunity} {...props} />
          )}
        </Spacing>
      )}
      <StudyAdditionalResponseTags opportunity={opportunity} />
    </Spacing>
  )
}

export const StudyDataSources = pipe(
  withApollo,
  graphql(CurrentUserQuery)
)(StudyDataSourcesView)

StudyDataSources.parse = inputDataSources => {
  if (isEmpty(inputDataSources)) return []

  return map(({ query = {} }) => ({
    required: true, // TODO should be configurable via UI, aBd
    query: parseQuery(query)
  }))(inputDataSources)
}

StudyDataSources.format = inputDataSources => {
  if (isEmpty(inputDataSources)) return []

  return map(({ query = {}, ...props }) => ({
    ...props,
    query: formatQuery(query)
  }))(inputDataSources)
}

StudyDataSources.isCompleted = isCompletedDataSourcesStep
StudyDataSources.validate = validatorContainsQueries
