import React from 'react'
import { is, slice } from 'ramda'

import { Spacing, Text } from '../../../components/ui'
import { useHistory } from 'react-router-dom'

export const PayoutRequestsTableRow = ({
  payoutRequest,
  columns,
  queryRenderParams,
  id,
  verifiedUser,
  ...rest
}) => {
  const { push } = useHistory()
  return (
    <tr {...rest} onClick={() => id && push(`/payouts/${id}`)}>
      {columns.map((column, index) => {
        const tableFieldData = payoutRequest[column.accessor]

        if (!is(Array, tableFieldData))
          return (
            <td key={column + '-' + index}>
              <Text t3 key={column + '-' + index} center>
                {tableFieldData}
              </Text>
            </td>
          )

        const tableFieldDataFinal =
          tableFieldData.length > 3
            ? slice(0, 3, tableFieldData)
            : tableFieldData

        return (
          <td key={column + '-' + index}>
            <Spacing size="xs">
              {tableFieldDataFinal.map((dataRow, i) => (
                <Spacing size="xxs" key={column + '-' + index + '-' + i}>
                  {dataRow.map(data => (
                    <Text t3 key={column + '-' + index + '-' + i}>
                      {data}
                    </Text>
                  ))}
                </Spacing>
              ))}
            </Spacing>
          </td>
        )
      })}
    </tr>
  )
}
