import gql from 'graphql-tag'

export const approveOpportunity = gql`
  mutation ApproveOpportunity($id: String!) {
    approveOpportunity(id: $id) {
      opportunity {
        id
        state
      }
      errors {
        key
        message
      }
    }
  }
`
