import { format, parse } from 'date-fns'

const DEFAULT_FORMAT = 'MM/DD/YYYY'

/**
 * @name parseDate
 * @description Converts a unix timestamp to an instance of Date
 */
export const parseDate = unixTimestamp => new Date(parseInt(unixTimestamp))

/**
 * @name formatDate
 * @description Converts a unix timestamp to a properly formatted date
 */
export const formatDate = date => format(parseInt(date), DEFAULT_FORMAT)

export const parseDateAsString = timestamp => {
  const date = /^[0-9]*$/.test(timestamp) ? parseInt(timestamp) : timestamp
  return format(parse(date), 'YYYY-MM-DD')
}

/**
 * @name formatDuration
 * @description Accepts seconds and parses them as hours, minutes and seconds
 */
export const formatDuration = totalSeconds => {
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds - hours * 3600 - minutes * 60

  return [`${hours}h`, `${minutes}m`, `${seconds}s`]
    .filter(item => item[0] !== '0')
    .join(' ')
}
