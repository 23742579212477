import { useState, useEffect, useRef } from 'react'
import {
  last,
  map,
  path,
  assoc,
  pipe,
  slice,
  all,
  when,
  propEq,
  either,
  split,
  dropLast,
  join,
  prop
} from 'ramda'

export const useSentence = ({
  pieceDefinitions,
  sentenceOrder,
  querySentenceProps: {
    currentQuerySentence,
    setCurrentQuerySentence,
    addQuerySentence,
    removeQuerySentence,
    removeQuerySentenceChildren,
    completeCurrentGroupQuerySentence,
    forceCurrentSentence
  }
}) => {
  useEffect(() => {
    addQuerySentence(sentenceOrder[0])
  }, [])

  const [sentence, setSentence] = useState(
    sentenceOrder.map(id => ({
      id,
      state: 'unmounted'
    }))
  )

  const refs = useRef(
    sentenceOrder.reduce((acc, id) => ({ ...acc, [id]: useRef() }), {})
  )

  // user hitting backspace on an already empty input
  const destroyPiece = (pieceId, pieceIndex) => keepCurrentForce => {
    setSentence(s => {
      // if thats permanent set it as last visible and invalidate all others -> unmounted
      if (pieceDefinitions[pieceId].permanent || keepCurrentForce) {
        return s.map((piece, i) => {
          if (i < pieceIndex) return piece
          if (i === pieceIndex) return { ...piece, state: 'editing' }
          return { ...piece, state: 'unmounted' }
        })
      }

      // set previous one as last visible and invalidate all others -> unmounted
      return s.map((piece, i) => {
        if (i < pieceIndex - 1) return piece
        if (i === pieceIndex - 1) return { ...piece, state: 'editing' }
        return { ...piece, state: 'unmounted' }
      })
    })
  }

  const completePiece = pieceId => {
    return setSentence(
      map(when(propEq('id', pieceId), assoc('state', 'completed')))
    )
  }

  // all previous -> completed, after -> skipped, last -> stays unmounted
  const terminatePiece = pieceIndex => {
    return setSentence(s =>
      s.map((piece, i) => {
        if (i <= pieceIndex) return { ...piece, state: 'completed' }
        if (i === s.length - 1) return piece
        return { ...piece, state: 'skipped' }
      })
    )
  }

  const unmountSkippedPieces = pieceIndex => {
    return setSentence(s =>
      s.map((piece, i) => {
        if (i < pieceIndex) return piece
        if (i === pieceIndex) return { ...piece, state: 'completed' }
        return { ...piece, state: 'unmounted' }
      })
    )
  }

  // gets either
  const allPreviousCompleted = index =>
    pipe(
      slice(0, index),
      all(either(propEq('state', 'completed'), propEq('state', 'skipped')))
    )(sentence)

  const visibleWords = sentence
    .filter((word, i) => allPreviousCompleted(i))
    .map((piece, pieceIndex) => ({
      ...piece,
      key: piece.id + pieceIndex,
      ref: prop(piece.id, refs.current),
      onRemove: ({
        keepCurrentSentencePieceForce = false,
        keepCurrentQuerySentenceForce = false
      } = {}) => {
        destroyPiece(piece.id, pieceIndex)(keepCurrentSentencePieceForce)

        if (pieceIndex === 0 && !keepCurrentQuerySentenceForce) {
          return removeQuerySentence(sentenceOrder[0])
        }

        setCurrentQuerySentence(sentenceOrder[0], { trimPieceId: true })
      },

      onComplete: () => completePiece(piece.id, pieceIndex),
      onTerminate: (forceTerminateComplete = false) => {
        terminatePiece(pieceIndex)
        if (!forceCurrentSentence || forceTerminateComplete)
          completeCurrentGroupQuerySentence(piece.id)
      },
      onContinue: () => unmountSkippedPieces(pieceIndex)
    }))

  const visibleWordsStates = JSON.stringify(visibleWords.map(prop('state')))

  // auto focus on last field
  const lastVisiblePiece = last(visibleWords)
  useEffect(() => {
    if (
      !currentQuerySentence ||
      currentQuerySentence === getSenteceName(sentenceOrder[0])
    ) {
      waitForAndFocus(refs, lastVisiblePiece.id)
      setCurrentQuerySentence(lastVisiblePiece.id, { trimPieceId: true })
    }
  }, [visibleWordsStates, currentQuerySentence])

  return visibleWords
}

const waitForAndFocus = (refs, refId, maxRetries = 300) => {
  const element = path(['current', refId, 'current'], refs)
  if (element) {
    element.focus()
  } else if (maxRetries > 0) {
    setTimeout(() => waitForAndFocus(refs, refId, maxRetries - 1))
  }
}

const getSenteceName = pipe(
  split('.'),
  dropLast(1),
  join('.')
)
