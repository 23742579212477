import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Spacing, Text, Box, Graphic } from '../../components/ui'
import { withMutation } from '../../components/wrappers'

const TableauReport = styled.iframe`
  width: 100%;
  height: 700px;
  border: none;
`

const ResultsTabInner = ({ opportunity, getTableauToken }) => {
  const hasResults = opportunity.results.length > 0

  const [token, setToken] = useState()
  const [hasErrored, setHasErrored] = useState()

  useEffect(() => {
    if (hasResults) {
      getTableauToken().then(({ data, errors }) => {
        if (errors) {
          setHasErrored(true)
        }

        if (data) {
          setToken(data.getTableauToken.token)
        }
      })
    }
  }, [])

  if (hasErrored) {
    return (
      <Box size="xxxxxl">
        <Spacing size="l" align="center">
          <Text color="grey">
            There was an error while loading your charts. Our team is looking
            into it!
          </Text>
        </Spacing>
      </Box>
    )
  }

  return (
    <Spacing>
      {hasResults ? (
        <Spacing>
          {opportunity.results.map(result => {
            const embedUrl = `${process.env.REACT_APP_TABLEAU_URL}/trusted/${token}/${result.url}`
            return (
              <Box size="xl">
                <Spacing size="xxl">
                  <Text h3>{result.label}</Text>
                  <TableauReport src={embedUrl} />
                </Spacing>
              </Box>
            )
          })}
        </Spacing>
      ) : (
        <Box size="xxxxxl">
          <Spacing size="l" align="center">
            <Graphic name="Loading" width={150} height={70} themeColor="main" />
            <Text color="grey">
              We're analyzing your data! Come back for some cool charts soon ;)
            </Text>
          </Spacing>
        </Box>
      )}
    </Spacing>
  )
}

const ResultsTab = withMutation('getTableauToken')(ResultsTabInner)

export default ResultsTab
