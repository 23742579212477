import React from 'react'
import styled from 'styled-components/macro'

import { Modal, Button, Spacing, Text } from '../../components/ui'
import { RenderFromQuery } from '../../components/util'
import { withMutation } from '../../components/wrappers'
import { CurrentCompanyQuery } from '../../state/queries'

const ModalWrap = styled.div`
  height: 1000px;
  position: relative;
`

const ConnectTypeformView = ({ onDismiss }) => {
  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title="Connect Typeform"
    >
      {({ onDismiss }) => (
        <ModalWrap>
          <RenderFromQuery
            query={CurrentCompanyQuery}
            renderData={({ currentUser: { company } }) => (
              <Spacing size="xxl" separated>
                <Spacing>
                  <Text h3>First Step: Authorize</Text>
                  <Text t3>
                    Press the Connect button to proceed to the Typeform
                    authorization page. On the bottom of that page, press the
                    Accept button.
                  </Text>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={company.typeformConnectUrl}
                  >
                    <Button>Connect</Button>
                  </a>
                </Spacing>
                <Spacing>
                  <Text h3>Second Step: Reload this page</Text>
                  <Text t3>
                    Once you get to the "Connected" message, come here and
                    reload this page.
                  </Text>
                  <Button outline onClick={() => window.location.reload()}>
                    Reload
                  </Button>
                </Spacing>
              </Spacing>
            )}
          />
        </ModalWrap>
      )}
    </Modal>
  )
}

export const ConnectTypeform = withMutation('connectTypeform')(
  ConnectTypeformView
)
