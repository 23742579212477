import React from 'react'
import { Button, Spacing, Text } from '.'
import styled from 'styled-components/macro'

const TableWrap = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  thead tr {
    background: ${({ theme }) => theme.color.purpleL3};
    th {
      padding: ${({ theme }) => `${theme.padding.l} ${theme.padding.m}`};
    }
  }
  tbody {
    tr {
      background-color: ${({ theme }) => theme.color.transparent};
      &:nth-child(even) {
        background-color: ${({ theme }) => theme.color.purpleL4};
      }

      &:hover {
        cursor: pointer;
      }

      td {
        padding: ${({ theme }) => theme.padding.m};
      }
    }
  }
`
export const Table = ({
  nextPage,
  previousPage,
  showPagination = false,
  page,
  numberOfPages,
  previousLabel = '<',
  nextLabel = '>',
  tableHead,
  children,
  horizontalScroll = false
}) => {
  return (
    <Spacing size="m">
      <TableWrap horizontalScroll={horizontalScroll}>
        <thead>
          <tr>{tableHead}</tr>
        </thead>
        <tbody>{children}</tbody>
      </TableWrap>
      {showPagination && (
        <Spacing
          size="xxl"
          display="flex"
          direction="row"
          justify="center"
          align="center"
        >
          <Button block outline onClick={previousPage} disabled={page === 1}>
            {previousLabel}
          </Button>
          <Text>
            {page} of {numberOfPages}
          </Text>
          <Button
            block
            outline
            onClick={nextPage}
            disabled={page === numberOfPages}
          >
            {nextLabel}
          </Button>
        </Spacing>
      )}
    </Spacing>
  )
}
