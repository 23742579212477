import gql from 'graphql-tag'
import { UserFragment, TransactionFragment } from '../fragments'

export const CurrentUserQuery = gql`
  query CurrentUser {
    currentUser {
      id
      fullName
      email
      currentRole
      availableRoles
    }
  }
`

export const UserQuery = gql`
  query user($id: String!) {
    user(id: $id) {
      payoutUser {
        id
        ...User
      }
      referrerUser {
        id
        ...User
      }
      referredUsers {
        id
        ...User
      }
      transactions {
        id
        ...Transaction
      }
    }
  }
  ${UserFragment}
  ${TransactionFragment}
`

export const UserDetailsQuery = gql`
  query user($id: String!) {
    user(id: $id) {
      payoutUser {
        id
        fraudStatus {
          ipFraudScore
          ipIsp
          ipLocation
          ipIsProxy
          ipReportedAbuse
          ipIsBot
        }
        ...User
      }
      referrerUser {
        id
        ...User
      }
      referredUsers {
        id
        ...User
      }
      transactions {
        id
        ...Transaction
      }
    }
  }
  ${UserFragment}
  ${TransactionFragment}
`
