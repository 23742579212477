import React from 'react'
import { Button } from '../../../ui'
import { DropdownItem } from './DropdownItem'
import { withMutation } from '../../../wrappers'
import { StudiesRefetchQueries } from '../../../../state/queries'

const PauseStudyButtonInner = ({ id, pauseOpportunity, dropdownItem }) => {
  const text = 'Pause Study'

  const clickHandler = () =>
    pauseOpportunity({
      variables: { id },
      refetchQueries: StudiesRefetchQueries
    })

  if (dropdownItem) {
    return <DropdownItem text={text} onClick={clickHandler} />
  }
  return (
    <Button onClick={clickHandler} outline>
      {text}
    </Button>
  )
}

export const PauseStudyButton = withMutation('pauseOpportunity', {
  showErrorNotification: true
})(PauseStudyButtonInner)
