export const STUDY_COLUMNS = [
  {
    title:
      'Traditional survey based research with Instant Micro-Targeted Audiences',
    description:
      'Make any combitionation of an audience you desire with data across hundreds of sources in less than 5 minutes and send them a survey with multiple choice, short/long answer, ranking, image & product testing, video feedback and more!<br/><br/> For example reach the: <br/><br/>The Health Conscious Millennial that runs multiple times a week (<b>Apple Watch/Google Fit</b>), purchases food from health conscious stores (<b>Finance</b>), engages with health food recipes & meal prep (<b>Pinterest</b>)<br/><br/> Or <br/><br/> The Subscription Lover that buys FabFit Fun boxes and Rent the Runway (<b>Finance</b>), and is also a podcast subscriber (<b>Apple</b>), who has a fitness subscription (<b>Microsoft</b>), & is a Amazon prime member (<b>Amazon</b>)'
  },
  {
    title:
      'Hyper-targeted data collection research for analysis of 100’s of verified & permissioned sources',
    description:
      'Whether you are an brand, academic, or non-profit doing research, access permissioned datasets for the robust insights you need across 100’s of sources <br/><br/>For example: Analyze the shift of time spent across the last three years against political, national, and global events by seeing how 1000 nationally representative people watch netflix, spend time on apps, play video games, shop online, listen to music, watch youtube… the list goes on and so do the possibilities!'
  },
  {
    title: 'Event Based Research',
    description:
      'Collect information around a time-frame that interests you. <br/><br/>For example: Extract Youtube watch history, Google searches, and financial transaction history for the 2 weeks before and the 2 weeks after Black Friday Sales of 500 males and 500 females.'
  }
]

export const AD_COLUMNS = [
  {
    title: 'Deeply Targeted',
    description:
      'UBDI ads are more deeply targeted than any other platform.<br/><br/> Here are some examples of the data you can use to find the perfect audience to send your ads: <br/><br/> <ul><li>Spends more than $200 a week at your competitor</li><li>Bikes multiple times a week </li><li>Watches shows in certain genres on Netflix</li><li> Is currently prescribed an ADD medication </li><li>Heavily invests in cryptocurrencies on Coinbase</li> <li>Buys a product substitute on Amazon</ul>'
  },
  {
    title: 'Confirmed Engagement',
    description:
      'Users are paid only after they view your link from your ad or offer for 10 or 30 seconds. This ensures they took the time to familiarize themselves with what you’re offering.'
  },
  {
    title: 'Follow Up Ability',
    description:
      'We provide you the opportunity to follow up and ask questions as to why they made certain decisions or ask to extract data like their financial purchases to verify if they shopped at your store or searches to see if they looked you up later --closing the advertising loop! Additionally, our ads will provide you a holistic look at both the customers who engage and do not engage with your ads.'
  }
]

export const AD_HEADER_DESCRIPTION =
  'Here, you can send ads and offers to a data verified audience of your choosing, like your customers or your competitor’s customers,  all while receiving deep insights into the audience of your choosing.'

export const STUDY_HEADER_DESCRIPTION =
  'Here, you can instantly match with a verified audience of your choosing, send surveys or request to access data across 100’s of sources for the actionable insights you need.'

export const AD_HEADER = {
  title: 'Welcome to UBDI Ads',
  buttonLink: '/ads/create/',
  buttonText: 'Create your first ad'
}

export const STUDY_HEADER = {
  title: 'Welcome to UBDI Studies',
  buttonLink: '/studies/create/',
  buttonText: 'Create your first study'
}
