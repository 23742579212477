import React, { useState } from 'react'
import { findIndex, append, remove, equals, not, includes } from 'ramda'

import { AudienceGroup } from './components'
import { Text, Spacing, SpacingItem, Graphic, Button } from '../../ui'
import styled from 'styled-components/macro'

const WrapForVerticallySpacedGroups = styled.div`
  margin-bottom: -${({ theme }) => theme.padding.l};
`

const VerticallySpacedGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.padding.l};
`

export const AudienceGroupPicker = ({
  groups,
  input,
  onRemoveSystemGroups
}) => {
  const toggleSelectedGroup = groupId => {
    const index = findIndex(equals(groupId))(input.value || [])

    if (index === -1) input.onChange(append(groupId, input.value))
    else {
      if (input.value.length === 1) return onRemoveSystemGroups()
      input.onChange(remove(index, 1, input.value))
    }
  }

  const [showGroups, setShowGroups] = useState(false)

  const groupsToShow = groups.filter(group => {
    if (showGroups) {
      return true
    }

    return includes(group.node.id, input.value)
  })

  return (
    <Spacing>
      <Spacing direction="row" justify="space-between" align="center">
        <Spacing direction="row" align="center">
          <Graphic size={40} name="Users" themeColor="main" />
          <Text t1 color="dark">
            Target by tribe
          </Text>
        </Spacing>

        <Button outline block onClick={() => setShowGroups(not)}>
          {!showGroups ? 'Select tribe(s)' : 'Hide'}
        </Button>
      </Spacing>
      {groupsToShow.length > 0 && (
        <WrapForVerticallySpacedGroups>
          <Spacing size="l" direction="row" align="center" wrap>
            {groupsToShow.map(({ node: group }) => (
              <SpacingItem key={group.id} flex="0 1 33.3%">
                <VerticallySpacedGroup>
                  <AudienceGroup
                    {...group}
                    selectedGroups={input.value}
                    toggleSelectedGroup={toggleSelectedGroup}
                  />
                </VerticallySpacedGroup>
              </SpacingItem>
            ))}
          </Spacing>
        </WrapForVerticallySpacedGroups>
      )}
    </Spacing>
  )
}
