import React from 'react'
import styled from 'styled-components/macro'
import { filter, prop, pipe, reduce, omit } from 'ramda'

import { Spacing, Text, Touchable, Graphic } from '../../../components/ui'

const TagCategoryOverviewWrap = styled.div`
  border-radius: ${props => props.theme.borderRadius.large};

  background-color: white;
  border: 1px solid ${({ theme }) => theme.color.greyL2};

  padding: ${({ theme }) => theme.padding.s} ${({ theme }) => theme.padding.xl};
`

const TagQuestionsOverviewWrap = styled.div`
  margin-left: 60px;
`

export const TagCategoryOverview = ({
  uniqueId,
  input,
  children,
  label,
  questions,
  onRemoveCategory = (uniqueId, input) =>
    omit(questionsOfCategory(uniqueId)(questions), input.value)
}) => {
  const onRemove = () => {
    return input.onChange(onRemoveCategory(uniqueId, input))
  }

  return (
    <TagCategoryOverviewWrap>
      <Spacing size="l" separated>
        <Spacing direction="row" justify="space-between" align="center">
          <Spacing direction="row" size="l" align="center">
            <Graphic
              name={`Audience_${uniqueId}`}
              size={40}
              themeColor="main"
            />
            <Text h4>{label}</Text>
          </Spacing>
          <Touchable onClick={onRemove}>
            <Text h7 color="grey">
              remove all
            </Text>
          </Touchable>
        </Spacing>
        <TagQuestionsOverviewWrap>{children}</TagQuestionsOverviewWrap>
      </Spacing>
    </TagCategoryOverviewWrap>
  )
}

const questionsOfCategory = categoryId =>
  pipe(
    reduce(
      (acc, q) => [
        ...acc,
        q.uniqueId,
        ...(q.subfields || []).map(prop('uniqueId'))
      ],
      []
    ),
    filter(uniqueId => uniqueId.split('_')[0] === categoryId)
  )
