import React, { useState, forwardRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Autosuggest from 'react-autosuggest'
import {
  prop,
  propEq,
  find,
  propOr,
  pipe,
  split,
  join,
  length,
  contains
} from 'ramda'

import { Text } from '../'
import { Suggestion, SuggestionList } from './SuggestionList'
import { useTypeahead } from '../../../hooks'

import './autosuggestStyles.css'

const getTextColor = (theme, type) => {
  if (type === 'transparent') return theme.color.purple
  if (type === 'purpleL3') return theme.color.main

  return theme.color.white
}

const getPlaceholderTextColor = (theme, type) => {
  if (type === 'purpleL3') return `rgba(111, 98, 255, 0.4)`

  return `rgba(255, 255, 255, 0.7)`
}

const Wrap = styled.div`
  position: relative;
  margin: 0 10px;
  display: inline-block;

  ${({ theme, type }) => {
    if (type !== 'transparent')
      return css`
        background-color: ${theme.color[type]};
        padding: 7px ${theme.padding.m};
        border-radius: ${theme.borderRadius.pill};
      `
  }}
`

const NiceInput = styled.input`
  ${({ theme, type }) => {
    const color = getTextColor(theme, type)
    const placeholderColor = getPlaceholderTextColor(theme, type)

    return css`
      ${theme.text.t2};
      position: relative;
      background: transparent;
      border: none;
      outline: none;
      padding: 0;
      line-height: 1.4;
      z-index: 20;
      color: ${color}
      
      ${type === 'transparent' && `text-decoration: underline;`}
  
  ::placeholder {
    color: ${placeholderColor};
    `
  }}

  
  }
`

const TypeaheadSuggestion = styled(Text)`
  position: absolute;
  left: 10;
  top: 10;
  z-index: 10;
`

// Name is probably off
export const InlinePicker = forwardRef(
  (
    {
      input,
      meta,
      placeholder,
      options,
      onRemoveCleanUp,
      onCompleteCleanUp,
      onComplete: onCompleteSentecePiece,
      onRemove: onRemoveSentencePiece,
      state,
      onCommaAddField,
      acceptAnyValue = false,
      ...props
    },
    parentRef
  ) => {
    if (state === 'skipped') return null

    const initialState = input.value
      ? acceptAnyValue
        ? input.value
        : pipe(
            find(propEq('value', input.value)),
            propOr('', ['label'])
          )(options)
      : ''

    if (initialState && state === 'unmounted') return onCompleteSentecePiece()

    const [textInputValue, setTextInputValue] = useState(initialState)

    useEffect(() => {
      setTextInputValue(initialState)
    }, [initialState])

    const { typeaheadSuggestion, suggestions } = useTypeahead({
      options,
      filterSuggestionsBy: input.value,
      typed: textInputValue,
      getSuggestionLabel: prop('label')
    })

    const getCharsLength = pipe(
      split(' '),
      join(''),
      length
    )

    const calculatedWidth =
      Math.max(
        props.type === 'transparent' ? 2 : 10,
        getCharsLength(textInputValue),
        getCharsLength(placeholder),
        getCharsLength(propOr('', 'label', typeaheadSuggestion)) + 0.5
      ) + 'ch'

    const onSelect = (e, { suggestion }) => {
      e.preventDefault()

      // if completed then changed
      if (state === 'completed' && input.value !== suggestion.value) {
        input.onChange('') // remove current field
        onRemoveSentencePiece({
          keepCurrentSentencePieceForce: true
        }) // remove current sentence piece

        if (onRemoveCleanUp)
          onRemoveCleanUp({
            invalidateWhereRowFromCurrent: true,
            invalidateWhereRow: true
          }) // remove sentence where row maybe
      }

      onCompleteCleanUp && onCompleteCleanUp(suggestion.description.id)
      input.onChange(suggestion.value) // set current field
      return onCompleteSentecePiece() // set pieceId complete
    }

    const handleKeyDown = e => {
      // backspace
      if (e.keyCode === 8) {
        // maybe remove objectType from query
        if (textInputValue.length === 1 && contains('field', input.name)) {
          // remove other fields after
          onRemoveSentencePiece({
            keepCurrentSentencePieceForce: true,
            keepCurrentQuerySentenceForce: true
          })

          onRemoveCleanUp &&
            onRemoveCleanUp({
              invalidateObjectType: true,
              invalidateWhereRowFromCurrent: true
            }) // remove sentence where row maybe
          return
        }

        if (!textInputValue.length) {
          e.preventDefault()
          input.onChange('') // remove current field
          if (onRemoveCleanUp)
            onRemoveCleanUp({
              invalidateWhereRow: true,
              invalidateWhereAll: true
            }) // remove sentence where row maybe
          return onRemoveSentencePiece({}) // remove current sentence piece}
        }
      }

      if (acceptAnyValue && e.keyCode === 13 && e.target.value.length) {
        e.preventDefault()

        return onSelect(e, { suggestion: { value: e.target.value } })
      }

      // comma
      if (e.keyCode === 188 && onCommaAddField) {
        e.preventDefault()
        return onCommaAddField()
      }
    }

    const renderInputComponent = ({ ref, ...inputProps }) => {
      return (
        <Wrap {...props}>
          {typeaheadSuggestion && (
            <TypeaheadSuggestion
              inline
              color={props.type === 'purpleL3' ? 'grey' : 'greyL1'}
              t2
            >
              {inputProps.value}
              {typeaheadSuggestion.cutoff}
            </TypeaheadSuggestion>
          )}
          <NiceInput
            {...inputProps}
            type={props.type}
            onKeyDown={e => {
              inputProps.onKeyDown(e)
              handleKeyDown(e)
            }}
            ref={el => {
              if (parentRef) parentRef.current = el

              if (typeof ref === 'function') ref(el)
              else ref.current = el
            }}
            placeholder={placeholder}
            style={{
              width: calculatedWidth
            }}
            autoComplete="off"
          />
        </Wrap>
      )
    }

    return (
      <Autosuggest
        suggestions={suggestions}
        highlightFirstSuggestion
        onSuggestionsFetchRequested={() => null}
        onSuggestionsClearRequested={() => null}
        onSuggestionSelected={onSelect}
        getSuggestionValue={prop('label')}
        renderSuggestion={(renderProps, { isHighlighted = false }) => {
          return (
            <Suggestion
              {...props}
              {...renderProps}
              isHighlighted={isHighlighted}
            />
          )
        }}
        renderSuggestionsContainer={renderProps => (
          <SuggestionList {...props} {...renderProps} />
        )}
        renderInputComponent={renderInputComponent}
        shouldRenderSuggestions={() => state !== 'completed'}
        inputProps={{
          ...input,
          value: textInputValue,
          onChange: (event, { newValue, method }) => {
            if (method === 'up' || method === 'down') return // to enable move through the options with arrow keys

            setTextInputValue(newValue)
          }
        }}
      />
    )
  }
)

InlinePicker.defaultProps = {
  type: 'purple',
  placeholder: '',
  options: [],
  onComplete: () => {},
  onRemove: () => {}
}
