import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { graphql } from 'react-apollo'
import { pipe, isEmpty, toLower } from 'ramda'
import { FORM_ERROR } from 'final-form'

import { Container, Spacing, Button, Notice } from '../../../components/ui'
import { StudySection, StudyName } from '../../../components/blocks'
import { withMutation } from '../../../components/wrappers'
import {
  OpportunityDetailsQuery,
  CurrentUserQuery
} from '../../../state/queries'
import { OpportunityConstants } from '../../../constants'
import { useValidation, useSteps } from '../../../hooks'
import { StudySteps } from '../components'
import { Preview } from '../../../components/blocks/StudyDetails/components'
import {
  StudySummaryReview,
  StudyReachPricingReview,
  StudyPublishingInfoReview
} from './components'
import { RenderFromQuery } from '../../../components/util'
import { ApproveButton } from '../../../components/blocks/StudyManipulation/components'

const DotSeparator = styled.span`
  color: ${({ theme }) => theme.color.greyL1};

  ::before {
    content: '·';
  }
`

const HorizontalLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.greyL2};
`

const Summary = styled.div`
  width: 30%;
  height: 100%;
  min-height: calc(100vh - 170px);
  position: sticky;
  top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.purpleL4};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  margin-left: ${({ theme }) => theme.padding.xxl};
`

const StudyPublish = ({
  submitForReviewOpportunity,
  history,
  data: { opportunity }
}) => {
  if (!opportunity) return null

  const [loading, setLoading] = useState(false)
  const { failedValidations, setFailedValidations } = useValidation()

  const handleStepChange = currentStep =>
    history.push(`/studies/builder/${opportunity.id}/${currentStep + 1}`)

  const steps = StudySteps.getSteps(opportunity)

  const { currentStep, goToStep } = useSteps({
    initialStep: 3,
    handleStepChange,
    steps
  })

  const onLaunchStudy = () => {
    setLoading(true)

    return submitForReviewOpportunity({
      variables: { id: opportunity.id }
    }).then(async ({ userErrors, data }) => {
      setLoading(false)

      if (userErrors) {
        return setFailedValidations(userErrors)
      }

      if (
        data &&
        data.submitForReviewOpportunity.opportunity.state ===
          OpportunityConstants.states.inReview.raw
      ) {
        return history.push('/studies/launched')
      }
    })
  }

  return (
    <Container size="xxl">
      <Spacing size="xxl" stretched>
        <StudyName
          opportunity={opportunity}
          backRouteName={`/studies/builder/${opportunity.id}`}
        />
        <StudySteps
          opportunity={opportunity}
          currentStep={currentStep}
          goToStep={goToStep}
        />
        <Spacing size="l">
          <StudySummaryReview opportunity={opportunity} />
          <StudyReachPricingReview
            opportunity={opportunity}
            failedValidations={failedValidations}
          />
          {['Study', 'Story'].includes(opportunity.category) && (
            <StudyPublishingInfoReview
              opportunity={opportunity}
              failedValidations={failedValidations}
            />
          )}
        </Spacing>
        <HorizontalLine />
        {!isEmpty(failedValidations) && failedValidations[FORM_ERROR] && (
          <Notice error>{failedValidations[FORM_ERROR]}</Notice>
        )}
        <Spacing direction="row" align="center" justify="space-between">
          <Spacing direction="row" size="s" align="center">
            <Button
              to={`/studies/builder/${opportunity.id}/1`}
              link
              transparent
              themeColor="secondary"
            >
              Change audience
            </Button>
            {opportunity.category === 'Study' && <DotSeparator />}
            {opportunity.category === 'Study' && (
              <Button
                to={`/studies/builder/${opportunity.id}/3`}
                link
                transparent
                themeColor="secondary"
              >
                Add survey
              </Button>
            )}
            <DotSeparator />
            <Button to={`/studies`} link transparent themeColor="secondary">
              Cancel {toLower(opportunity.category)}
            </Button>
          </Spacing>
          {opportunity.state !== 'published' ? (
            <RenderFromQuery
              query={CurrentUserQuery}
              renderData={({ currentUser: { currentRole } }) => (
                <Spacing>
                  {currentRole === 'admin' && (
                    <ApproveButton
                      opportunity={opportunity}
                      id={opportunity.id}
                    />
                  )}
                  <Button onClick={onLaunchStudy} disabled={loading}>
                    Publish {toLower(opportunity.category)}
                  </Button>
                </Spacing>
              )}
            />
          ) : (
            <Button
              outline
              onClick={() => history.push(`/studies/summary/${opportunity.id}`)}
            >
              Go to summary
            </Button>
          )}
        </Spacing>
      </Spacing>
      <Summary>
        <StudySection title="Public Preview">
          <Spacing size="l">
            <Preview opportunity={opportunity} />
          </Spacing>
        </StudySection>
      </Summary>
    </Container>
  )
}

export const StudyPublishScreen = pipe(
  graphql(OpportunityDetailsQuery, {
    options: props => ({
      variables: {
        id: props.match.params.opportunityId
      }
    })
  }),
  withMutation('updateOpportunity'),
  withMutation('submitForReviewOpportunity')
)(StudyPublish)
