import gql from 'graphql-tag'
import { CompanyFragment } from '../fragments'

export const createBraintreePaymentToken = gql`
  mutation CreateBraintreePaymentToken($nonce: String!) {
    createBraintreePaymentToken(nonce: $nonce) {
      success
      query {
        currentUser {
          id
          email
          fullName
          verified
          company {
            id
            ...Company
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
  ${CompanyFragment}
`
