import React from 'react'
import { __, includes, isEmpty, isNil } from 'ramda'
import { Spacing, SpacingItem, Text } from '../../components/ui'
import {
  StudySection,
  StudyDetails,
  StudyStats,
  StudyStateTag,
  StudyContinue,
  StudyInteractionOverview
} from '../../components/blocks'
import { OpportunityResponsesTable } from '../../components/blocks/StudyDetails/components'
import { AdStats } from './components'

const FOLLOWUP_STATES = ['draft', 'published', 'completed']
const shouldAllowFollowup = includes(__, FOLLOWUP_STATES)

const SummaryTab = ({ opportunity, openDataTab, setShortId, isAd }) => {
  const { state, ad, interactionsOverview } = opportunity

  return (
    <Spacing direction="row" stretchChildren>
      <Spacing stretchChildren>
        {!isAd && (
          <StudySection
            title="Study Status"
            rightContent={<StudyStateTag state={state} />}
          >
            <StudyStats opportunity={opportunity} showImpressions />
          </StudySection>
        )}
        <SpacingItem display="flex">
          <StudySection>
            <StudyDetails opportunity={opportunity} />
          </StudySection>
        </SpacingItem>

        {shouldAllowFollowup(state) && (
          <StudySection title="Follow-up on this Study">
            <Spacing>
              <Text t3>
                You can create a follow-up study with either the same audience,
                or a subset of the audience that completed this study.
              </Text>
              <StudyContinue opportunity={opportunity} />
            </Spacing>
          </StudySection>
        )}
      </Spacing>
      {!isAd ? (
        <Spacing size="xxl">
          <OpportunityResponsesTable
            opportunity={opportunity}
            openDataTab={openDataTab}
            setShortId={setShortId}
          />
          {!isNil(interactionsOverview) && !isEmpty(interactionsOverview) && (
            <StudyInteractionOverview interactions={interactionsOverview} />
          )}
        </Spacing>
      ) : (
        <StudySection title="Stats">
          <AdStats ad={ad} />
        </StudySection>
      )}
    </Spacing>
  )
}

export default SummaryTab
