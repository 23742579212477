import { curry, is } from 'ramda'

export const deepOmit = curry((omitKey, object) => {
  if (!object) return object

  return Object.keys(object).reduce((res, key) => {
    if (key === omitKey) return res

    return {
      ...res,
      [key]: omitInValue(omitKey, object[key])
    }
  }, {})
})

const omitInValue = curry((omitKey, value) => {
  if (is(Array, value)) return value.map(omitInValue(omitKey))
  if (is(Object, value)) return deepOmit(omitKey, value)

  return value
})
