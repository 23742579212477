import gql from 'graphql-tag'

export const DataFieldTypeFragment = gql`
  fragment DataFieldType on DataFieldType {
    name
    conditions {
      id
      label
      multipleValues
      subfields {
        label
      }
    }
  }
`
