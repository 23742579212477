import React from 'react'
import { isNil, isEmpty } from 'ramda'

import { StudyStepsHeader } from '../../../../components/blocks'
import {
  StudyFollowupAudience,
  StudyAudience,
  StudyDataSources,
  StudySurvey,
  StudyCreateAd,
  StoryQueryBuilder,
  StoryScreenTemplates
} from '../'

const getSteps = opportunity => {
  const audienceSection =
    !isNil(opportunity.dependsOn) && !isEmpty(opportunity.dependsOn)
      ? ['Follow up audience', StudyFollowupAudience]
      : ['Build the audience', StudyAudience]

  switch (opportunity.category) {
    case 'Ad':
      return [
        audienceSection,
        ['Create Ad', StudyCreateAd],
        ['Get audience insights', StudyDataSources]
      ]

    case 'Story':
      return [
        ['Build Story', StoryQueryBuilder],
        ['Story Screens', StoryScreenTemplates]
      ]

    default:
      return [
        audienceSection,
        ['Create survey', StudySurvey],
        ['Get data insights', StudyDataSources]
      ]
  }
}

// Since StudyBuilder and StudyPublish are using StudyStepsHeader component
// this is middle component that decides which steps are going to be there and calculate completed steps
// moved from StudyBuilder to here so it can be used not just in StudyBuilder but in other places using useSteps hook
export const StudySteps = ({ opportunity, ...props }) => {
  const steps = getSteps(opportunity)

  const completedSteps = steps.map(([, StepComponent], i) =>
    StepComponent.isCompleted && StepComponent.isCompleted(opportunity) ? i : -1
  )

  return (
    <StudyStepsHeader
      completedSteps={completedSteps}
      steps={steps}
      {...props}
    />
  )
}

StudySteps.getSteps = getSteps
