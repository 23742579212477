import gql from 'graphql-tag'
import { MemberFragment } from './Member'

export const UserFragment = gql`
  fragment User on User {
    id
    username
    email
    fullName
    phoneNumber
    country
    verified
    notificationsEnabled
    tosAccepted
    terminated
    terminatedAt
    member {
      id
      ...Member
    }
  }
  ${MemberFragment}
`
