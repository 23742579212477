import styled, { css } from 'styled-components/macro'

const DEFAULT_VARIANT = 't1'
const DEFAULT_COLOR = 'dark'

const getTextAlignment = props => {
  if (props.center) return 'center'
  if (props.right) return 'right'
  return 'left'
}

const getVariant = props => {
  const variants = Object.keys(props.theme.text)
  return variants.find(variant => !!props[variant]) || DEFAULT_VARIANT
}

const getColor = props => props.theme.color[props.color || DEFAULT_COLOR]

export const Text = styled.div`
  ${props => {
    const color = getColor(props)
    const textAlignment = getTextAlignment(props)

    return css`
      display: ${props.inline ? 'inline-block' : 'block'};

      color: ${color};
      text-align: ${textAlignment};

      ${props.theme.text[getVariant(props)]}
      line-height: 1.4;

      word-wrap: break-word;

      transition: color ${props => props.theme.animationSpeed.normal};

      ${props.bold && `font-weight: bold;`}

      ${props.noWrap &&
        `
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `}

      ${props.transform &&
        css`
          text-transform: ${props.transform};
        `}

        ${props.decoration &&
          css`
            text-decoration: ${props.decoration};
          `}
    `
  }}
`
