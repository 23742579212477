import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { without, pipe } from 'ramda'

import { Text, Graphic, Avatar } from '../../../ui'
import { RenderFromQuery } from '../../../../components/util'
import { withMutation } from '../../../../components/wrappers'
import {
  CurrentUserQuery,
  CurrentCompanyQuery
} from '../../../../state/queries'
import { withRouter } from 'react-router-dom'

const logOut = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('_mbp')
  window.location.reload()
}

const Container = styled.div`
  position: relative;
  z-index: 1000;
`

const AvatarWrap = styled(Avatar)`
  width: 35px;
  border-radius: 50%;
`

const UserWrap = styled.div`
  position: relative;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.purpleL2};
  width: 35px;
  height: 35px;
  border-radius: 50%;
  align-self: center;
  outline: none;
  border: none;
`

const Menu = styled.div`
  position: absolute;
  width: 220px;
  top: 130%;
  right: 0;
  display: ${props => (props.visible ? 'block' : 'none')};
`

const MenuList = styled.div`
  box-shadow: ${props => props.theme.boxShadow.default};
  border-radius: ${props => props.theme.borderRadius.base};
  border: 1px solid ${props => props.theme.color.greyL2};
  overflow: hidden;
`

const MenuItem = styled.div`
  cursor: pointer;
  background-color: ${props => props.theme.color.white};
  padding: ${props => props.theme.padding.m} ${props => props.theme.padding.l};
  border-bottom: 1px solid ${props => props.theme.color.greyL2};
  color: ${props => props.theme.color.dark};
  overflow: hidden;
  text-align: left;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: ${props => props.theme.color.greyL2};
    transition: background-color ${props => props.theme.animationSpeed.normal};
  }
`

const GraphicWrap = styled(Graphic)`
  pointer-events: none;
  position: absolute;
  top: -11px;
  right: 8px;
`

const UserMenuInner = ({ switchRole, history }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => setMenuOpen(!isMenuOpen)

  const handleSwitch = role => async () => {
    const { data } = await switchRole({ variables: { role } })
    const { token } = data.switchRole

    if (token) {
      localStorage.setItem('token', token)
      window.location.reload()
    }
  }

  return (
    <Container>
      <UserWrap onClick={toggleMenu}>
        <RenderFromQuery
          query={CurrentCompanyQuery}
          renderError={() => null}
          renderData={({ currentUser }) => {
            return <AvatarWrap user={currentUser} size="thumbnail" />
          }}
        />
      </UserWrap>

      <Menu visible={isMenuOpen}>
        <GraphicWrap name="ArrowUp" size={16} color="white" />
        <MenuList>
          <RenderFromQuery
            query={CurrentUserQuery}
            renderData={({ currentUser }) => {
              const menuItems = without(
                [currentUser.currentRole],
                currentUser.availableRoles
              ).map(role => (
                <MenuItem onClick={handleSwitch(role)} key={role}>
                  Switch to{' '}
                  <Text t2 inline transform="capitalize">
                    {role}
                  </Text>
                </MenuItem>
              ))

              currentUser.currentRole === 'admin' &&
                menuItems.push(
                  <MenuItem
                    key="payout"
                    onClick={() => history.push('/payouts')}
                  >
                    <Text t2>Payout requests</Text>
                  </MenuItem>
                )

              // currentUser.currentRole === 'company' &&
              //   menuItems.push(
              //     <MenuItem
              //       key="payout"
              //       onClick={() => history.push('/settings')}
              //     >
              //       <Text t2>Settings</Text>
              //     </MenuItem>
              //   )

              menuItems.push(
                <MenuItem key="logout" onClick={logOut}>
                  <Text t2>Logout</Text>
                </MenuItem>
              )

              return menuItems
            }}
          />
        </MenuList>
      </Menu>
    </Container>
  )
}

export const UserMenu = pipe(
  withMutation('switchRole', {
    showErrorNotifications: true
  }),
  withRouter
)(UserMenuInner)
