import React from 'react'
import styled from 'styled-components/macro'
import {
  contains,
  pipe,
  findIndex,
  lte,
  equals,
  pathOr,
  complement,
  remove
} from 'ramda'
import { FieldArray } from 'react-final-form-arrays'

import { Spacing, Text, CheckGroup, Checkbox, SpacingItem } from '../../ui'

const ContentWrapper = styled.label`
  padding: ${({ theme }) => `${theme.padding.l} ${theme.padding.m}`};
  border-top: 1px solid ${({ theme }) => theme.color.greyL2};
  cursor: pointer;
  display: flex;

  ${({ input: { disabled } }) => disabled && `cursor: not-allowed`};
`

const QuestionCheckbox = ({ label, ...props }) => {
  const { input } = props
  return (
    <ContentWrapper {...props}>
      <Spacing direction="row" size="xl" align="center">
        <Checkbox {...props} />
        <Text h6 color={input.checked ? 'dark' : 'grey'}>
          {label}
        </Text>
      </Spacing>
    </ContentWrapper>
  )
}

const RequiredCheckbox = ({ label, ...props }) => {
  const { input } = props
  return (
    <ContentWrapper {...props}>
      <Spacing direction="row" size="xxs" align="center">
        <Checkbox {...props} />
        <Text t3 color={input.checked ? 'dark' : 'grey'}>
          required
        </Text>
      </Spacing>
    </ContentWrapper>
  )
}

export const ResponseTagQuestions = ({
  questions = [],
  uniqueId,
  audienceRequirementsCategories,
  input: parentInput
}) => {
  const isDisabled = questionUniqueId =>
    pipe(
      findIndex(contains(questionUniqueId)),
      lte(0)
    )(audienceRequirementsCategories)

  const checkIfTagExists = (uniqueId, tag) => {
    return pipe(
      pathOr([], [tag]),
      findIndex(equals(uniqueId)),
      complement(equals(-1))
    )(parentInput.value)
  }

  const addRequiredToAdditional = uniqueId => {
    if (
      !checkIfTagExists(uniqueId, 'requiredResponseTags') &&
      !checkIfTagExists(uniqueId, 'additionalResponseTags')
    ) {
      parentInput.onChange({
        ...parentInput.value,
        additionalResponseTags: [
          ...parentInput.value.additionalResponseTags,
          uniqueId
        ],
        requiredResponseTags: [
          ...parentInput.value.requiredResponseTags,
          uniqueId
        ]
      })
    }
  }

  const removeAdditionalFromRequired = uniqueId => {
    if (
      checkIfTagExists(uniqueId, 'requiredResponseTags') &&
      checkIfTagExists(uniqueId, 'additionalResponseTags')
    ) {
      const additionalIndex = findIndex(equals(uniqueId))(
        parentInput.value.additionalResponseTags
      )
      const requiredIndex = findIndex(equals(uniqueId))(
        parentInput.value.requiredResponseTags
      )

      parentInput.onChange({
        additionalResponseTags: remove(
          additionalIndex,
          1,
          parentInput.value.additionalResponseTags
        ),
        requiredResponseTags: remove(
          requiredIndex,
          1,
          parentInput.value.requiredResponseTags
        )
      })
    }
  }

  return (
    <Spacing key={uniqueId} size="xxs" direction="row" stretched>
      <SpacingItem flex={4}>
        <FieldArray
          name="tags.additionalResponseTags"
          render={CheckGroup}
          renderCheck={props => <QuestionCheckbox {...props} />}
          options={questions.map(({ uniqueId, label }) => ({
            value: uniqueId,
            onChange: () => removeAdditionalFromRequired(uniqueId),
            disabled: isDisabled(uniqueId),
            label // TODO: in future when audience.json is populated replace label with question
          }))}
        />
      </SpacingItem>
      <SpacingItem flex={1}>
        <FieldArray
          name="tags.requiredResponseTags"
          render={CheckGroup}
          renderCheck={props => <RequiredCheckbox {...props} />}
          options={questions.map(({ uniqueId }) => ({
            value: uniqueId,
            onChange: () => addRequiredToAdditional(uniqueId),
            disabled: isDisabled(uniqueId)
          }))}
        />
      </SpacingItem>
    </Spacing>
  )
}
