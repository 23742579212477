import React from 'react'
import { Chart } from 'react-charts'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  height: 20px;
`

export const HorizontalBarChart = ({ tags: { name, values } }) => {
  const data = React.useMemo(
    () =>
      values.map(({ label, count }) => ({
        label,
        data: [[name, count]]
      })),
    []
  )

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'left', show: false },
      { position: 'bottom', type: 'linear', stacked: true, show: false }
    ],
    []
  )

  const series = {
    type: 'bar'
  }

  return (
    <Wrapper>
      <Chart data={data} axes={axes} series={series} tooltip={true} />
    </Wrapper>
  )
}
