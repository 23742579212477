import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { default as ReactDatePicker } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.css'
import { Input } from '../'
import { formatDate } from '../../../util/date'

const StyledButton = styled.button`
  width: 100%;
  padding: 0;
  border: 0;
`

const StyledInput = styled(Input)`
  overflow: hidden;
  cursor: pointer;
`

export const DatePicker = ({ input, ...props }) => {
  const [startDate, setStartDate] = useState(+new Date())

  const ExampleCustomInput = ({ onClick }) => (
    <StyledButton onClick={onClick}>
      <StyledInput {...props} input={input} disabled />
    </StyledButton>
  )

  return (
    <ReactDatePicker
      selected={startDate}
      onChange={date => {
        const timestamp = new Date(date).getTime()

        input.onChange(formatDate(timestamp))
        setStartDate(date)
      }}
      customInput={<ExampleCustomInput />}
    />
  )
}
