import React, { useState } from 'react'
import { Graphic, Spacing, Text } from '../../../ui'
import theme from '../../../../theme'
import { lighten } from 'polished'
import styled from 'styled-components/macro'

const borderColor = lighten(0.2, theme.color.main)

const Container = styled.div`
  position: relative;
  z-index: 100;
`

const DropdownWrap = styled.div`
  position: relative;
  cursor: pointer;
  padding: ${({ theme }) => `${theme.padding.m} ${theme.padding.xl}`};
  border: 1px solid ${borderColor};
  border-radius: ${({ theme }) => theme.borderRadius.base};
  &:hover {
    border: 1px solid ${({ theme }) => theme.color.main};
  }
`

const Dropdown = styled.div`
  position: absolute;
  width: 220px;
  top: 100%;
  right: 0;
  display: ${props => (props.visible ? 'block' : 'none')};
`

const DropdownList = styled.div`
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  border-radius: ${({ theme }) => theme.borderRadius.base};
  border: 1px solid ${({ theme }) => theme.color.greyL2};
  overflow: hidden;
`

export const StudyManipulationDropdown = ({
  dropdownItems,
  opportunity,
  id
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen)
  }

  return (
    <Container>
      <DropdownWrap onClick={toggleMenu}>
        <Spacing direction="row" justify="space-between">
          <Text h6 bold color="main" inline>
            More actions
          </Text>
          <Graphic name="ArrowDown" size={10} color={theme.color.main} />
        </Spacing>
      </DropdownWrap>
      <Dropdown visible={isMenuOpen}>
        <DropdownList>
          {dropdownItems.map((Component, index) => (
            <Component
              key={index}
              id={id}
              opportunity={opportunity}
              dropdownItem
            />
          ))}
        </DropdownList>
      </Dropdown>
    </Container>
  )
}
