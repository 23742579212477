import React from 'react'
import styled from 'styled-components/macro'

import { Spacing, Text, ToggleChevron, Graphic } from '../../../components/ui'

const TagCategoryWrap = styled.div`
  border-radius: ${props => props.theme.borderRadius.large};

  transition: background-color ${({ theme }) => theme.animationSpeed.slow};
  background-color: ${({ theme, expanded }) =>
    theme.color[expanded ? 'white' : 'purpleL4']};
  border: 1px solid
    ${({ theme, expanded }) => (expanded ? theme.color.greyL2 : 'transparent')};
`

const Title = styled.div`
  cursor: pointer;
  padding: ${({ theme: { padding } }) => `${padding.l} ${padding.xl}`};
`

const ChildrenWrap = styled.div`
  padding: ${({ theme }) => `${theme.padding.xl}`};
  padding-top: ${({ theme }) => theme.padding.s};
`

export const TagCategory = ({
  uniqueId,
  expanded,
  label,
  onToggle,
  children
}) => (
  <TagCategoryWrap expanded={expanded}>
    <Title onClick={onToggle}>
      <Spacing direction="row" justify="space-between" align="center">
        <Spacing direction="row" size="l" align="center">
          <Graphic name={`Audience_${uniqueId}`} size={40} themeColor="main" />
          <Text h4>{label}</Text>
        </Spacing>
        <ToggleChevron toggled={expanded} />
      </Spacing>
    </Title>
    {expanded && <ChildrenWrap>{children}</ChildrenWrap>}
  </TagCategoryWrap>
)
