import React from 'react'
import { pipe } from 'ramda'

import { Spacing } from '../../../../components/ui/'
import { AddSurvey } from './components'
import { withMutation } from '../../../../components/wrappers'

import { renderLocalStorageNotice } from '../../util'
import { isCompletedSurveyStep } from '../../../../util/validators'

import { LocalStorage } from '../../../../constants'

export const StudySurveyView = props => {
  const noticeId = LocalStorage.surveyNotice

  return (
    <Spacing size="xl">
      {renderLocalStorageNotice(noticeId)}
      <AddSurvey {...props} />
    </Spacing>
  )
}

export const StudySurvey = pipe(
  withMutation('updateCompany'),
  withMutation('updateOpportunity')
)(StudySurveyView)

StudySurvey.parse = () => {}
StudySurvey.isCompleted = isCompletedSurveyStep
StudySurvey.validate = () => {}
