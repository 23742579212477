import React from 'react'

import { AnimatedText } from '../../../ui'

export const QueryBuilderText = ({ children, ...props }) => {
  return (
    <AnimatedText inline {...props}>
      {children}
    </AnimatedText>
  )
}
