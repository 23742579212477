import gql from 'graphql-tag'

export const initCardSetup = gql`
  mutation InitCardSetup {
    initCardSetup {
      clientSecret
      errors {
        key
        message
      }
    }
  }
`
