import React from 'react'
import { Form, Field } from 'react-final-form'

import {
  Graphic,
  Text,
  Spacing,
  Input,
  SubmitButton
} from '../../../components/ui'
import { validatorPassword } from '../../../util/validators'
import { withMutation } from '../../../components/wrappers'
import { RenderFromQuery } from '../../../components/util'
import { SignUpCompanyQuery } from '../../../state/queries'

import { VerticalFade } from '../components/AnimatedWizardItems'

const onSubmit = ({ onNextStep, updateUser }) => values =>
  updateUser({ variables: values }).then(
    async ({ errors, userErrors, data }) => {
      if (!userErrors && data && data.updateUser) {
        onNextStep()
      }

      return userErrors || errors
    }
  )

const hasPasswordInitialValues = {
  newPassword: '********',
  repeatPassword: '********'
}

export const Step = props => (
  <Spacing size="xxxxl">
    <Graphic name="Logo" png size={80} />
    <RenderFromQuery
      query={SignUpCompanyQuery}
      renderLoading={() => <PasswordForm {...props} />}
      renderData={({ currentUser: { hasPassword } }) => (
        <PasswordForm {...props} hasPassword={hasPassword} />
      )}
    />
  </Spacing>
)

const PasswordForm = ({ onNextStep, updateUser, hasPassword }) => (
  <Form
    initialValues={hasPassword && hasPasswordInitialValues}
    onSubmit={hasPassword ? onNextStep : onSubmit({ onNextStep, updateUser })}
    validate={validatorPassword('newPassword')}
  >
    {({ handleSubmit, ...formProps }) => (
      <form onSubmit={handleSubmit}>
        <Spacing size="xl">
          <VerticalFade noEnter>
            <Text h0>Sign Up</Text>
          </VerticalFade>
          <VerticalFade>
            <Text t1>
              {!hasPassword
                ? 'Set up your password'
                : 'You have already set the password'}
            </Text>
          </VerticalFade>
          <VerticalFade>
            <Field
              component={Input}
              name="newPassword"
              label="Password"
              type="password"
              disabled={hasPassword}
              autoFocus
            />
          </VerticalFade>
          <VerticalFade>
            <Field
              component={Input}
              name="repeatPassword"
              label="Repeat Password"
              type="password"
              disabled={hasPassword}
            />
          </VerticalFade>
          <VerticalFade>
            <SubmitButton {...formProps}>Continue</SubmitButton>
          </VerticalFade>
        </Spacing>
      </form>
    )}
  </Form>
)

export const StepPassword = withMutation('updateUser')(Step)
