import React from 'react'
import { Spacing, Modal, Button, Notice } from '../../components/ui'
import styled from 'styled-components/macro'
import { SurveyAnswersView } from './components/SurveyAnswersView'
import { RenderFromQuery } from '../../components/util'
import { OpportunityResponseQuery } from '../../state/queries/OpportunityResponsesQueries'
import { SummaryCard, MatchedTagsView, InsightsView } from './components'

const ModalWrap = styled.div`
  width: 1200px;
`

export const StudyResultsViewer = ({
  onDismiss,
  params: {
    shortId,
    insightsLength,
    surveyAnswersLength,
    matchedTagsLength,
    openDataTab,
    setShortId
  } = {}
}) => {
  const openInsightResults = () => {
    setShortId(shortId)
    openDataTab()
    onDismiss()
  }
  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title={`ID: ${shortId}`}
    >
      {() => (
        <ModalWrap>
          <Spacing size="l">
            <Spacing direction="row" size="m" stretchChildren>
              <SummaryCard
                title={'Total matched tags'}
                value={matchedTagsLength}
              />
              <SummaryCard title={'Total insights'} value={insightsLength} />
              <SummaryCard
                title={'Total survey answers'}
                value={surveyAnswersLength}
              />
            </Spacing>
            <RenderFromQuery
              query={OpportunityResponseQuery}
              variables={{
                shortId
              }}
              renderData={({
                opportunityResponse: { matchedTags, survey, insights = [] }
              }) => (
                <Spacing size="xl">
                  {matchedTags && <MatchedTagsView matchedTags={matchedTags} />}
                  {survey && <SurveyAnswersView survey={survey} />}
                  <Notice title="Future of data in Portal">
                    Results will live in the data tab from now on, and this
                    modal is marked for deprecation.
                  </Notice>
                  {insights.map((insight, index) => (
                    <InsightsView
                      shortId={shortId}
                      insight={insight}
                      key={index}
                    />
                  ))}
                </Spacing>
              )}
            />
          </Spacing>
          <Modal.Footer>
            <Spacing direction="row" size="m" justify="flex-end">
              <Button outline onClick={() => openInsightResults()}>
                See insight results
              </Button>
            </Spacing>
          </Modal.Footer>
        </ModalWrap>
      )}
    </Modal>
  )
}
