import gql from 'graphql-tag'

export const returnToDraftOpportunity = gql`
  mutation ReturnToDraftOpportunity($id: String!) {
    returnToDraftOpportunity(id: $id) {
      opportunity {
        id
        state
      }
      errors {
        key
        message
      }
    }
  }
`
