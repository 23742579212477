import {
  keys,
  pipe,
  map,
  pick,
  sort,
  uniq,
  subtract,
  last,
  flatten,
  prop,
  pathOr,
  isEmpty,
  filter,
  propOr,
  isNil,
  intersection,
  omit,
  pathEq,
  path,
  join,
  propEq,
  find,
  complement,
  equals,
  includes
} from 'ramda'
import { getYear } from 'date-fns'
import shortid from 'shortid'
import { isCompletedSurveyStep, isCompletedDataSourcesStep } from './validators'

export const getEmptyAudienceRequirementsQueryRow = ({ id } = {}) => ({
  id: id || shortid.generate(),
  source: '',
  column: '',
  condition: '',
  value: '',
  conjunction: 'AND'
})

export const getEmptyAudienceBucket = (
  index = 0,
  { includeKeywords = false, advanced = false, fromQueryBuilder = false } = {}
) => ({
  name: `Audience bucket #${index + 1}`,
  quota: null,
  tags: null,
  queries: fromQueryBuilder && advanced ? [{}] : [],
  simpleQueries:
    fromQueryBuilder && !advanced
      ? [getEmptySimpleQuery({ includeKeywords })]
      : []
})

export const getEmptySimpleQuery = ({ includeKeywords }) => ({
  dataSourceIds: [],
  keywords: [],
  queryMatch: true,
  dateRange: null,
  includeKeywords
})

// Accepts API-format (arrays), transforms it into final-form format, hash with [uniqueId] as key
export const formatAudienceRequirements = (targetAudienceBuckets = []) => {
  const buckets = map(bucket => {
    const { tags, queries, quota, simpleQueries, ...requirements } =
      bucket || {}
    const tagsFinal = {
      and:
        tags &&
        tags.and &&
        tags.and.reduce(
          (acc, [{ uniqueId, values }]) => ({
            ...acc,
            [uniqueId]: values
          }),
          {}
        )
    }

    return {
      ...requirements,
      quota: quota && quota.toString(),
      tags: tagsFinal,
      queries: queries && queries.length > 0 ? formatQueries(queries) : [{}],
      simpleQueries:
        simpleQueries && simpleQueries.length > 0
          ? formatSimpleQueries(simpleQueries)
          : [{}]
    }
  })(targetAudienceBuckets)

  if (isEmpty(buckets)) return [getEmptyAudienceBucket()]

  return buckets
}

// Turns UI format into API
export const parseAudienceRequirements = targetAudienceBuckets => {
  return pipe(
    map(
      ({
        tags: bucketTags,
        queries: bucketQueries,
        quota: bucketQuota,
        ...bucket
      }) => {
        const tagsParsed = {
          and: parseFilterTags(bucketTags && bucketTags.and)
        }

        const queriesParsed = parseQueries(
          filter(complement(isEmpty), bucketQueries || [])
        )

        const tags = !isEmpty(tagsParsed.and) ? tagsParsed : null
        const queries =
          !isEmpty(queriesParsed) && !isEmpty(queriesParsed[0])
            ? queriesParsed
            : null

        const quota = !isEmpty(bucketQuota) ? parseInt(bucketQuota, 10) : null

        return filter(Boolean)({
          tags,
          queries,
          quota,
          ...omit(['responseCount'], bucket),
          simpleQueries: bucket.simpleQueries.filter(item => !isEmpty(item))
        })
      }
    ),
    filter(
      ({ tags, queries, simpleQueries, quota }) =>
        !isNil(tags) ||
        !isNil(queries) ||
        !isNil(quota) ||
        !isNil(simpleQueries)
    )
  )(targetAudienceBuckets)
}

// Turns UI format into API
export const parseQuery = query => {
  const { where, ...rest } = pick(
    ['objectTypeId', 'dataSourceIds', 'sql', 'where', 'select', 'id'], // TODO: this should be more logical, this is too hidden
    query
  )

  // convert dataSourceIds to string
  const dataSourceIds = pathOr([], ['dataSourceIds'], rest)
    .map(ds => ds.toString())
    .filter(ds => ds !== '-1')

  const parseConjunction = conjunction => {
    if (isNil(conjunction)) return { conjunction: 'AND' } // if not set its top level conjunction => AND
    if (conjunction === '.') return { conjunction: null }

    return {}
  }

  // replace '.' with null
  const parseWhere = where => {
    const groups = propOr(null, 'groups', where)

    const groupsFinal = groups ? groups.map(item => parseWhere(item)) : null
    const conjunction = parseConjunction(where.conjunction)
    return { ...where, ...conjunction, groups: groupsFinal }
  }

  const newWhere = where ? where.map(item => parseWhere(item)) : []

  return {
    ...rest,
    dataSourceIds,
    where: newWhere,
    objectTypeId: parseInt(rest.objectTypeId)
  }
}

export const parseQueries = map(parseQuery)

export const formatSimpleQuery = query => {
  return {
    ...query
  }
}

// Accepts API-format (arrays), transforms it into final-form format
export const formatQuery = query => {
  const dataSourceIds =
    query.dataSourceIds && query.dataSourceIds.length === 1
      ? query.dataSourceIds
      : [-1]

  // replace null cunjunctions to '.' in UI format
  const formatWhere = where => {
    const groups = propOr(null, 'groups', where)

    if (!groups) {
      const newConjunction = isNil(where.conjunction)
        ? { ...where, conjunction: '.' }
        : where
      return { ...newConjunction }
    }

    const newGroups = groups.map(query => formatWhere(query))

    return { ...where, groups: newGroups }
  }

  const newWhere = query.where
    ? query.where.map(where => formatWhere(where))
    : null

  return { ...query, dataSourceIds, where: newWhere }
}

export const formatQueries = map(formatQuery)
const formatSimpleQueries = map(formatSimpleQuery)

export const parseFilterTag = tags => uniqueId => ({
  uniqueId,
  values: [].concat(tags[uniqueId])
})

// Accepts hash format from final-form, transforms it to API-compatible array
export const parseFilterTags = values =>
  keys(values).map(parseFilterTag(values))

/**
 * @name getAgeRange
 * @param years {[string]} Array of years in the range
 * @returns [from, to] {[number, number]} From and to age
 */
export const getAgeRange = pipe(
  map(Number),
  sort((a, b) => b - a), // ASC
  years => [years[0], last(years)],
  uniq,
  map(subtract(getYear(new Date())))
)

// Accepts audienceRequirements UI format and returns array of selected categoryUniqueIds
export const getAudienceCategoriesFromRequirements = audienceRequirements => {
  const safeAudienceRequirements = pathOr(
    [],
    ['filters', 'and'],
    audienceRequirements
  )

  return flatten(
    safeAudienceRequirements.map(filterGroup =>
      filterGroup.map(prop('uniqueId'))
    )
  )
}

// Accepts additionalResponseTags and extracts categories from audienceRequirements
// Returns merged array of uniqueIds of categories from both collections
export const getAdditionalResponseTags = opportunity => {
  const original = pathOr(
    [],
    ['responseConfig', 'additionalResponseTags'],
    opportunity
  )

  const fromRequirements = getAudienceCategoriesFromRequirements(
    opportunity.audienceRequirements
  )

  return {
    additionalResponseTags: uniq(flatten([fromRequirements, original]))
  }
}

export const getRequiredResponseTags = opportunity => {
  const original = pathOr(
    [],
    ['responseConfig', 'requiredResponseTags'],
    opportunity
  )

  const fromAdditional = getAdditionalResponseTags(opportunity)

  return intersection(fromAdditional.additionalResponseTags, original)
}

export const getTagsSummary = ({ allUserGroups, flatConfig }) =>
  map(({ uniqueId, values }) => {
    // Custom age handling
    if (uniqueId === 'general_age') {
      return `Age ${getAgeRange(values).join('-')}`
    }

    // User group handling
    if (uniqueId === 'system_groups') {
      const userGroups = pathOr([], ['edges'], allUserGroups)

      return pipe(
        map(value =>
          pipe(
            find(pathEq(['node', 'id'], value)),
            path(['node', 'name'])
          )(userGroups)
        ),
        join(', ')
      )(values)
    }

    // Custom entries handling
    if (uniqueId === 'custom_text' || includes('general_location', uniqueId))
      return values.join(', ')

    return values
      .map(tag => {
        const tagValue = getCaseInsensitiveFromObject(flatConfig, tag)
        return (tagValue && tagValue.label) || tag
      })
      .join(', ')
  })

const getCaseInsensitiveFromObject = (myObject, searchKey) =>
  myObject[
    Object.keys(myObject).find(
      key => key.toLowerCase() === searchKey.toLowerCase()
    )
  ]

export const getBucketsTagsSummary = (flatConfig, allUserGroups, tags) =>
  pipe(
    map(
      pipe(
        getTagsSummary({ flatConfig, allUserGroups }),
        join(', ')
      )
    ),
    join(', ')
  )(tags.and)

export const getQueriesSummary = (dataSources, queries) =>
  pipe(
    map(query => {
      // default case is that dataSourceIds=['1'] has one element in array
      // otherwise we assume that user selected `All sources` since API calculates dataSourceIds in case they are not provided ('All sources' case)
      const dataSourceName =
        query.dataSourceIds && query.dataSourceIds.length === 1
          ? pipe(
              find(propEq('id', query.dataSourceIds[0])),
              prop('name')
            )(dataSources)
          : 'All sources'

      const headerPart = `Has data from <b>${dataSourceName}</b> where <br>`
      const queryPart = pathOr('', ['nativeQueryDescription', 'filter'])(query)

      return [headerPart, queryPart].join(' ')
    }),
    join('<br> <br>')
  )(queries)

export const getOpportunityType = opportunity => {
  if (opportunity.category === 'Study') {
    return [
      isCompletedDataSourcesStep(opportunity) ? 'insight' : null,
      isCompletedSurveyStep(opportunity) ? 'survey' : null
    ]
      .filter(Boolean)
      .join(' + ')
  }

  return opportunity.category
}

export const getTagQuestionFromProfileSchema = (audienceSchema, tagId) =>
  pipe(
    map(category =>
      category.children.find(tag =>
        equals(tag.uniqueId, getQuestionLevelId(tagId))
      )
    ),
    find(category => !isNil(category))
  )(audienceSchema)

const getQuestionLevelId = uniqueId => {
  const [category, question] = uniqueId.split('_')
  return [category, question].join('_')
}
