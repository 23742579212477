import React, { Fragment } from 'react'
import styled, { css } from 'styled-components/macro'
import PropTypes from 'prop-types'
import { lighten } from 'polished'
import { Link } from 'react-router-dom'
import posed from 'react-pose'

import theme from '../../../theme'
import { Graphic, Text, Spacing } from '../../ui'

const DEFAULT_COLOR = 'main'

const getBackgroundColor = props => {
  if (props.outline) return theme.color.white
  if (props.transparent) return 'transparent'

  return theme.color[props.themeColor || DEFAULT_COLOR]
}

const getTextThemeColor = props => {
  if (props.outline || props.transparent)
    return props.themeColor || DEFAULT_COLOR

  return 'white'
}

const getBorderColor = (props, { hover = false } = {}) => {
  const currentColor = theme.color[props.themeColor || DEFAULT_COLOR]

  if (props.outline && !hover) return lighten(0.2, currentColor)
  if (props.transparent) return 'transparent'

  return currentColor
}

const getGraphicColor = props => {
  if (props.graphicColor) return props.graphicColor

  return getTextThemeColor(props)
}

const renderIcon = props => (
  <Graphic
    name={props.graphic}
    size={props.graphicSize || 16}
    themeColor={getGraphicColor(props)}
  />
)

const AnimatedButton = posed.button({
  pressable: true,
  init: {
    scale: 1
  },
  press: {
    scale: 0.98
  }
})

const ButtonChildren = props => {
  const color = getTextThemeColor(props)

  return (
    <MaybeLink {...props}>
      <AnimatedButton onClick={props.onClick} type="button" {...props}>
        <Spacing direction="row" justify={props.justify} align="center">
          {props.graphic && renderIcon(props)}
          {props.prefix && (
            <Text h6 color={color} inline>
              {props.prefix}
            </Text>
          )}

          <Text h6 color={color} inline center>
            {props.children}
          </Text>
        </Spacing>
      </AnimatedButton>
    </MaybeLink>
  )
}

const MaybeLink = ({ to, children, ...props }) =>
  to ? <Link to={to}>{children}</Link> : <Fragment>{children}</Fragment>

export const Button = styled(ButtonChildren)`
  ${props => {
    const { theme, outline, block, link } = props

    const backgroundColor = getBackgroundColor(props)
    const borderColor = getBorderColor(props)
    const borderColorHover = getBorderColor(props, { hover: true })

    return css`
      background: ${backgroundColor};

      border: 1px solid ${borderColor};
      border-radius: ${theme.borderRadius.base};
      justify-content: center;

      padding: ${!link ? `${theme.padding.m} ${theme.padding.xl}` : 0};

      cursor: pointer;
      outline: none;

      transition: opacity ${theme.animationSpeed.slow} ease-in-out;

      ${block && `width: 100%;`}
      ${outline && `box-shadow: ${theme.boxShadow.default};`}

      &:hover {
        opacity: ${!outline ? 0.7 : 1};
        border: 1px solid ${borderColorHover};
        transition: border-color ${theme.animationSpeed.normal};
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.4;
        border: 1px solid ${borderColor};
        cursor: not-allowed;
      }
    `
  }}
`
const propOptions = {
  colors: Object.keys(theme.color)
}

Button.defaultProps = {
  justify: 'center'
}

Button.propTypes = {
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  link: PropTypes.bool,
  transparent: PropTypes.bool,
  themeColor: PropTypes.oneOf(propOptions.colors),
  graphic: PropTypes.string,
  graphicColor: PropTypes.oneOf(propOptions.colors),
  prefix: PropTypes.string,
  justify: PropTypes.string
}
