import gql from 'graphql-tag'

export const logIn = gql`
  mutation LogIn($email: String!, $password: String!) {
    logIn(email: $email, password: $password, roles: ["admin", "company"]) {
      token
      user {
        id
        username
        email
      }
      errors {
        key
        message
      }
    }
  }
`
