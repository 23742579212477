import React from 'react'
import styled from 'styled-components/macro'

const ProgressWrapper = styled.div`
  width: 100%;
  height: 5px;
  background: ${({ theme }) => theme.color.purpleL2};
`

const ProgressInner = styled.div`
  width: ${({ progress }) => `${progress}%`};
  height: 5px;
  background: ${({ theme }) => theme.color.main};
`

export const ProgressBar = ({ total, current }) => {
  const progress = (current / total) * 100

  return (
    <ProgressWrapper>
      <ProgressInner progress={progress} />
    </ProgressWrapper>
  )
}
