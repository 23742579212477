import React, { useState, useEffect } from 'react'
import { is } from 'ramda'
import theme from '../../../theme'
import { Text } from '../'

export const AnimatedText = ({
  children,
  onComplete,
  disabled = false,
  speed,
  state,
  ...props
}) => {
  if (disabled || state === 'completed') {
    if (state === 'unmounted') {
      onComplete()
    }
    return <Text {...props}>{children}</Text>
  }

  if (!is(String, children))
    throw new Error('AnimatedText accepts only strings')

  const [typedText, setTypedText] = useState('')

  useEffect(() => {
    function pushLetterToTyped(typed, input = []) {
      if (!input.length) return onComplete()

      setTimeout(() => {
        const newTyped = typed + input[0]
        pushLetterToTyped(newTyped, input.slice(1))
        setTypedText(newTyped)
      }, theme.animationTextSpeed[speed])
    }

    pushLetterToTyped('', children.split(''))
  }, [])

  return <Text {...props}>{typedText}</Text>
}

AnimatedText.defaultProps = {
  disabled: false,
  speed: 'normal',
  onComplete: () => {}
}
