import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

import { withMutation } from '../../../../components/wrappers'
import { Graphic } from '../../../../components/ui'

const TypeformIframe = styled.iframe`
  width: 100%;
  height: 500px;
  border: none;
`

const ContentWrapper = styled.div`
  position: relative;
`

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(178, 178, 178, 0.3);
  display: flex;
`

const GraphicWrap = styled(Graphic)`
  align-self: center;
  justify-content: center;
  flex: 1;
`

const TypeformEditorView = ({
  id,
  survey,
  createTypeform,
  pendingTypeformId,
  onCreate = () => {},
  editorDisabled = false
}) => {
  useEffect(() => {
    if (!survey || !survey.typeformId) {
      createTypeform({ variables: { id } }).then(
        ({ data, userErrors, errors }) => {
          if (!userErrors && data && data.createTypeform) {
            const { typeformId } = data.createTypeform

            onCreate(typeformId)

            return
          }

          return userErrors || errors
        }
      )
    }
  }, [])

  const typeformId = (survey && survey.typeformId) || pendingTypeformId

  if (!typeformId) return null

  return (
    <ContentWrapper>
      <TypeformIframe
        title="typeform-editor"
        src={`https://admin.typeform.com/form/${typeformId}/create`}
      />
      {editorDisabled && (
        <SpinnerWrapper>
          <GraphicWrap name="Spinner" size={100} themeColor="main" />
        </SpinnerWrapper>
      )}
    </ContentWrapper>
  )
}

export const TypeformEditor = withMutation('createTypeform')(TypeformEditorView)
