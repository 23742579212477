import React from 'react'
import { isEmpty } from 'ramda'
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider'
import { SliderRail, KeyboardHandle, Track } from './components'

const sliderStyle = {
  position: 'relative',
  width: '100%'
}

export const RangeSlider = ({
  domain,
  input,
  hideValue = false,
  multirange = false
}) => {
  const setValues = values => {
    if (multirange) input.onChange(values)
    else input.onChange(values[0])
  }

  const getValues = () => {
    const { value } = input

    if (isEmpty(value)) {
      return multirange ? domain : [domain[0]]
    }

    return multirange ? value : [value]
  }

  return (
    <div style={{ width: '100%', padding: '12px 2px' }}>
      <Slider
        mode={1}
        step={1}
        domain={domain}
        rootStyle={sliderStyle}
        onChange={setValues}
        values={getValues()}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Tracks left={!multirange} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map(handle => (
                <KeyboardHandle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                  hideValue={hideValue}
                />
              ))}
            </div>
          )}
        </Handles>
      </Slider>
    </div>
  )
}
