import { useState, useEffect } from 'react'
import {
  take,
  pipe,
  equals,
  join,
  dropLast,
  split,
  append,
  includes,
  prop,
  findIndex,
  map,
  ascend,
  sortWith,
  remove,
  contains,
  sort,
  findLastIndex
} from 'ramda'

export const useQuerySentences = () => {
  const [querySentences, setQuerySentences] = useState([])
  const [currentQuerySentence, setCurrentSentence] = useState(null)
  const [forceCurrentSentence, setForceCurrentSentence] = useState(false)

  useEffect(() => {
    setQuerySentences(sortList(querySentences))
  }, [querySentences.length])

  const _findSentenceIndex = sentenceId => {
    const sentenceName = trimPieceIdFromSentenceName(sentenceId)
    return findIndex(equals(sentenceName), querySentences)
  }

  const _findGroupFirstSentence = sentenceName => {
    const sentenceGroupName = getFirstPartOfName(sentenceName)
    return findIndex(contains(sentenceGroupName))(querySentences)
  }

  const _findLastOne = sentenceName => {
    const sentenceGroupName = getFirstPartOfName(sentenceName)
    return findLastIndex(contains(sentenceGroupName))(querySentences)
  }

  const addQuerySentence = sentenceId => {
    const sentenceName = trimPieceIdFromSentenceName(sentenceId)

    if (!includes(sentenceName, querySentences)) {
      setQuerySentences(append(sentenceName))
      setCurrentQuerySentence(sentenceName)
    }
  }

  const setCurrentQuerySentence = (
    sentenceId,
    { trimPieceId = false } = {}
  ) => {
    const sentenceName = trimPieceId
      ? trimPieceIdFromSentenceName(sentenceId)
      : sentenceId

    if (sentenceName !== currentQuerySentence) {
      setCurrentSentence(sentenceName)
    }
  }

  const removeQuerySentence = sentenceName => {
    const indexOfCurrent = _findSentenceIndex(sentenceName)
    const indexFirstOfGroup = _findGroupFirstSentence(sentenceName)
    const indexLastOfGroup = _findLastOne(sentenceName, querySentences)

    const nextSentence =
      indexOfCurrent === indexFirstOfGroup + 1 // if first of group
        ? indexOfCurrent // stay first of group
        : indexOfCurrent - 1 // previous in group

    setQuerySentences(remove(indexLastOfGroup, 1))
    setCurrentQuerySentence(querySentences[nextSentence])
    setForceCurrentSentence(true)
  }

  const removeQuerySentenceChildren = sentenceName => {
    const indexOfCurrent = _findSentenceIndex(sentenceName)
    const indexFirstOfGroup = _findGroupFirstSentence(sentenceName)
    const indexLastOfGroup = _findLastOne(sentenceName, querySentences)

    if (indexOfCurrent === indexFirstOfGroup)
      setQuerySentences(
        remove(indexFirstOfGroup, indexLastOfGroup - indexFirstOfGroup + 1)
      )
  }

  const completeCurrentGroupQuerySentence = sentenceName => {
    const firstSentenceOfGroup = _findGroupFirstSentence(sentenceName)

    setCurrentQuerySentence(querySentences[firstSentenceOfGroup])
    setForceCurrentSentence(false)
  }

  return {
    currentQuerySentence,
    setCurrentQuerySentence,
    addQuerySentence,
    removeQuerySentence,
    completeCurrentGroupQuerySentence,
    forceCurrentSentence,
    removeQuerySentenceChildren
  }
}

// trim last part of name (sentence piece name)
const trimPieceIdFromSentenceName = pipe(
  split('.'),
  dropLast(1),
  join('.')
)

const getFirstPartOfName = pipe(
  split('.'),
  take(2),
  join('.')
)

const sortByLength = (a, b) => {
  return a.length - b.length
}

const sortList = list => {
  // const maxDepth = pipe(
  //   map(
  //     pipe(
  //       split('.'),
  //       length
  //     )
  //   ),
  //   reduce(maxBy(i => i), 0)
  // )(list)

  // const sortWithDepth = []
  // for (let i = 0; i < maxDepth; i++) {
  //   sortWithDepth.push(ascend(prop(toString(i))))
  // }

  return pipe(
    map(split('.')),
    sort(sortByLength),
    sortWith([
      ascend(prop('0')),
      ascend(prop('1')),
      ascend(prop('2')),
      ascend(prop('3'))
    ]),
    map(join('.'))
  )(list)
}
