export const DATASOURCE_STATS_COLORS = [
  {
    name: 'Facebook',
    color: '#2A5297'
  },
  {
    name: 'Spotify',
    color: '#1CD760'
  },
  {
    name: 'Instagram',
    color: '#D93175'
  },
  {
    name: 'Fitbit',
    color: '#1CAFBA'
  },
  {
    name: 'Twitter',
    color: '#1BA1F2'
  },
  {
    name: 'YouTube',
    color: '#EA4B4B'
  },
  {
    name: 'Garmin',
    color: '#454545'
  },
  {
    name: 'Finance',
    color: '#F79E1B'
  },
  {
    name: 'Pinterest',
    color: '#EA1515'
  }
]
