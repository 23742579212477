import React from 'react'
import styled from 'styled-components/macro'
import { Field } from 'react-final-form'
import { omit } from 'ramda'
import { Spacing, Text, CollapsableBox } from '../../../../../components/ui'
import {
  AudienceQuota,
  AudienceGroupPicker
} from '../../../../../components/blocks'
import { AddTags, AddQueries } from './'

const HorizontalLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.greyL2};
`

export const AudienceBucket = ({
  input,
  onRemove,
  onUpdate,
  opportunity,
  ...props
}) => {
  const {
    bucketsInfo: { currentBucket, totalBuckets }
  } = props

  const onRemoveSystemGroups = () =>
    input.onChange({
      ...input.value,
      tags: { and: { ...omit(['system_groups'], input.value.tags.and) } }
    })

  return (
    <CollapsableBox
      renderHeader={() => <Text h4>{`Audience bucket #${currentBucket}`}</Text>}
      renderHeaderAction={() =>
        totalBuckets > 1 && (
          <Text h7 color="grey" onClick={onRemove}>
            delete bucket
          </Text>
        )
      }
    >
      {() => (
        <Spacing size="l">
          <HorizontalLine />
          <Field
            {...props}
            name={`${input.name}.tags.and.system_groups`}
            component={AudienceGroupPicker}
            onRemoveSystemGroups={onRemoveSystemGroups}
          />
          <HorizontalLine />
          <Field
            name={`${input.name}.tags`}
            component={AddTags}
            onUpdate={values => onUpdate({ ...input.value, ...values })}
          />
          <HorizontalLine />
          <AddQueries
            input={input}
            title="Target by data sources"
            btnText="Select data sources"
            graphicName="DataBank"
            {...props}
          />
          <HorizontalLine />
          <AddQueries
            input={input}
            title="Target by keywords"
            btnText="Search by keywords"
            graphicName="Search"
            includeKeywords
            {...props}
          />
          <HorizontalLine />
          <AddQueries
            input={input}
            title="Target by data (advanced)"
            btnText="Create advanced data query"
            graphicName="Coins"
            advanced
            {...props}
          />
          <HorizontalLine />
          <AudienceQuota
            input={input}
            text={
              opportunity.category === 'Ad'
                ? ['I want', 'clicks']
                : ['I want a sample of', 'people']
            }
            {...props}
          />
        </Spacing>
      )}
    </CollapsableBox>
  )
}
