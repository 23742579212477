import React, { useState } from 'react'
import arrayMutators from 'final-form-arrays'
import { Form, Field, FormSpy } from 'react-final-form'
import styled from 'styled-components'
import { pipe, pathOr, keys, length, filter } from 'ramda'

import { RenderFromQuery } from '../../../components/util'
import { AudienceSchemaQuery } from '../../../state/queries'
import { TagQuestions } from '../../../components/blocks/TagFilters/TagQuestions'
import { TagFilters } from '../../../components/blocks'

import { Spacing, SpacingItem, Notice, Text } from '../../../components/ui'
import { DemographicsEstimateQuery } from '../../../state/queries/DemographicsEstimateQueries'
import { parseFilterTags } from '../../../util'

const CountWrap = styled.div`
  position: sticky;
  top: 0;
  padding-top: ${props => props.theme.padding.xxl};
`

const hasFilters = filters =>
  pipe(
    pathOr({}, ['and']),
    keys,
    filter(key => filters.and[key].length > 0),
    length
  )(filters)

export const Demographics = () => {
  const [filters, setFilters] = useState({ and: {} })

  return (
    <Spacing>
      <Notice title="Check out the demographics of UBDI">
        Play with the user tags and get an estimated number of users in the
        system that fit that description.
      </Notice>
      <Spacing direction="row">
        <SpacingItem flex={4}>
          <Form mutators={arrayMutators} onSubmit={() => {}}>
            {({ handleSubmit, submitError, form, ...formProps }) => (
              <>
                <FormSpy
                  subscription={{ values: true }}
                  onChange={({ values }) => setFilters(values)}
                />
                <RenderFromQuery
                  query={AudienceSchemaQuery}
                  renderData={({ audienceSchema }) => (
                    <TagFilters
                      categories={audienceSchema}
                      renderFilterComponent={props => (
                        <Field
                          name="and"
                          component={TagQuestions}
                          form={form}
                          {...props}
                        />
                      )}
                    />
                  )}
                />
              </>
            )}
          </Form>
        </SpacingItem>
        <SpacingItem flex={1}>
          <CountWrap>
            {!hasFilters(filters) ? (
              <Text color="grey" center>
                No filters selected
              </Text>
            ) : (
              <>
                <Text h2 center>
                  <RenderFromQuery
                    query={DemographicsEstimateQuery}
                    variables={{
                      filters: { and: parseFilterTags(filters.and) }
                    }}
                    renderData={({ getDemographicsEstimate: { totalCount } }) =>
                      `~${totalCount}`
                    }
                  />
                </Text>
                <Text color="grey" center>
                  estimated count
                </Text>
              </>
            )}
          </CountWrap>
        </SpacingItem>
      </Spacing>
    </Spacing>
  )
}
