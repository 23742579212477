import React, { useState, useEffect } from 'react'
import { isNil, isEmpty } from 'ramda'

import { Text, Spacing, Graphic, Button } from '../../../../../components/ui'

import {
  SimpleQueriesBuilder,
  QueriesBuilder
} from '../../../../../components/blocks'
import { getEmptySimpleQuery } from '../../../../../util'

export const AddQueries = ({
  input,
  title,
  includeKeywords = false,
  advanced,
  graphicName,
  btnText
}) => {
  const [showBuilder, setShowBuilder] = useState(false)
  const key = advanced ? 'queries' : 'simpleQueries'

  useEffect(() => {
    console.log({ data: input.value })

    if (!isNil(input.value[key]) && !isEmpty(input.value[key])) {
      if (key === 'simpleQueries') {
        return setShowBuilder(
          input.value[key].filter(
            query => query.includeKeywords === includeKeywords
          ).length > 0
        )
      }

      return
    }

    setShowBuilder(false)
  }, [input.value.simpleQueries, input.value.queries])

  const QueriesComponent = advanced ? QueriesBuilder : SimpleQueriesBuilder

  return !showBuilder ? (
    <Spacing direction="row" justify="space-between" align="center">
      <Spacing direction="row" align="center">
        <Graphic size={40} name={graphicName} themeColor="main" />
        <Text t1 color="dark">
          {title}
        </Text>
      </Spacing>
      <Button
        outline
        block
        onClick={() => {
          setShowBuilder(true)

          if (key === 'simpleQueries') {
            input.onChange({
              ...input.value,
              simpleQueries: [
                ...(input.value.simpleQueries || []),
                getEmptySimpleQuery({ includeKeywords })
              ]
            })
          } else {
            input.onChange({
              ...input.value,
              queries: [{}]
            })
          }
        }}
      >
        {btnText}
      </Button>
    </Spacing>
  ) : (
    <Spacing>
      <Spacing direction="row" align="center">
        <Graphic size={40} name={graphicName} themeColor="main" />
        <Text t1 color="dark">
          {title}
        </Text>
      </Spacing>
      <QueriesComponent
        input={input}
        includeKeywords={includeKeywords}
        initialSelectFieldValue="COUNT (*)"
      />
    </Spacing>
  )
}
