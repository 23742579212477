import gql from 'graphql-tag'

export const StatisticsQuery = gql`
  query Statistics {
    statistics {
      member {
        totalPerCountry {
          country
          total
        }
        liveUsers
        totalUsers
        distinctCountries
      }
    }
  }
`
