import gql from 'graphql-tag'

export const switchRole = gql`
  mutation SwitchRole($role: String!) {
    switchRole(role: $role) {
      token
      user {
        id
        username
        email
      }
      errors {
        key
        message
      }
    }
  }
`
