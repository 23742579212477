import * as React from 'react'
import styled from 'styled-components/macro'

const avatarSizes = {
  thumbnail: 100,
  default: 250,
  large: 500
}

const AvatarPhoto = ({ user, size = 'default', className }) => (
  <img
    className={className}
    src={getUserImgSrc(user, size)}
    alt={user.fullName}
    width={avatarSizes[size] / 2}
  />
)

const getUserImgSrc = ({ company }, size) => {
  return company && company.photo ? company.photo[size] : null
}

export const Avatar = styled(AvatarPhoto)`
  border-radius: ${({ theme }) => theme.borderRadius.base};
`
