import { useState } from 'react'
import { isEmpty } from 'ramda'

export const useValidation = validator => {
  const [failedValidations, setFailedValidations] = useState({})

  const validate = data => {
    const validationResult = validator(data)

    if (isEmpty(validationResult)) {
      setFailedValidations(false)
      return false
    }

    setFailedValidations(validationResult)
    return validationResult
  }

  return { validate, failedValidations, setFailedValidations }
}
