import gql from 'graphql-tag'

export const confirmPayout = gql`
  mutation ConfirmPayout($id: String!) {
    confirmPayout(id: $id) {
      success
      errors {
        key
        message
      }
    }
  }
`
