import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  Spacing,
  Graphic,
  Input,
  SpacingItem,
  Text,
  Checkbox
} from '../../../../../../components/ui'
import { Field } from 'react-final-form'
import { QuestionType } from '../QuestionType'
import { pathOr, includes, isNil } from 'ramda'
import { Choices } from '../Choices'
import { SurveySchemaQuery } from '../../../../../../state/queries/SurveySchemaQueries'
import { RenderFromQuery } from '../../../../../../components/util'
import { SURVEY_QUESTION_TYPES } from '../../constants'
import { getSingleChoiceOption } from '../../../../../../util/survey'
import { useModal } from '../../../../../../hooks'
import { ImagePreview } from '../ImagePreview'
import { parseImageUrl } from '../../../../util/imageUpload'

const typesWithChoices = [
  SURVEY_QUESTION_TYPES.singleChoice,
  SURVEY_QUESTION_TYPES.multipleChoice
]

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.purpleL4};
  padding: ${({ theme }) => theme.padding.xl};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
`

const WrapField = styled(Field)`
  margin-left: ${({ theme }) => theme.padding.xl};
`

const ActionsWrapper = styled.div`
  border-top: 1px solid rgba(111, 98, 255, 0.1);
  padding-top: ${({ theme }) => theme.padding.xl};
  padding-left: 40px;
`

const ClickableText = styled(Text)`
  &:hover {
    cursor: pointer;
  }
`

const GraphicWrap = styled(Graphic)`
  margin-top: 14px;
`

const isTypeWithChoices = type => includes(type, typesWithChoices)

const updateChoicesField = (input, update) => {
  if (!isTypeWithChoices(input.value.type)) {
    return update({
      ...input.value,
      choices: null
    })
  }
  return update({
    ...input.value,
    choices: isNil(input.value.choices)
      ? [getSingleChoiceOption({})]
      : input.value.choices
  })
}

const onAddMedia = ({ image, videoUrl }, input, update) =>
  update({
    ...input.value,
    image,
    videoUrl
  })

const RequiredCheckbox = ({ ...props }) => {
  return (
    <Spacing direction="row" size="xxs" align="center">
      <Checkbox
        {...props}
        label="Required to answer"
        textProps={{ t3: true }}
      />
    </Spacing>
  )
}

export const QuestionBox = ({ input, update, remove, duplicate }) => {
  const image = parseImageUrl(input.value)
  useEffect(() => {
    updateChoicesField(input, update)
  }, [input.value.type])

  const { openModal } = useModal()

  const openAddMediaModal = () =>
    openModal('AddMedia', {
      image,
      videoUrl: input.value.videoUrl,
      onSave: props => onAddMedia(props, input, update)
    })

  const removeImage = () => {
    return update({
      ...input.value,
      image:
        image instanceof File
          ? null
          : {
              x: 1,
              y: 1,
              width: 1,
              height: 1,
              src: ''
            }
    })
  }

  return (
    <RenderFromQuery
      query={SurveySchemaQuery}
      renderData={({ surveySchema: { supportedTypes } }) => {
        const initialTypeValue = pathOr(
          supportedTypes[0].name,
          ['value', 'type'],
          input
        )
        const questionTypes = supportedTypes.map(({ displayName, name }) => {
          return {
            label: displayName,
            value: name
          }
        })

        return (
          <Wrapper>
            <Spacing size="xl">
              <Spacing direction="row" size="l">
                <GraphicWrap name="Dashes" size={22} />
                <SpacingItem flex="0 1 95%">
                  <Spacing size="l">
                    <Spacing direction="row">
                      <SpacingItem flex="0 1 75%">
                        <Spacing size="l">
                          <Field
                            name={`${input.name}.title`}
                            component={Input}
                            placeholder="Type your question"
                            label="Question"
                            block
                          />
                          {image && image.src && (
                            <ImagePreview
                              image={image}
                              replace={() => openAddMediaModal()}
                              remove={() => removeImage()}
                            />
                          )}
                        </Spacing>
                      </SpacingItem>
                      <SpacingItem flex={1}>
                        <Field
                          name={`${input.name}.type`}
                          component={QuestionType}
                          questionTypes={questionTypes}
                          initialValue={initialTypeValue}
                        />
                      </SpacingItem>
                    </Spacing>

                    {isTypeWithChoices(input.value.type) && (
                      <WrapField
                        name={`${input.name}.choices`}
                        component={Choices}
                      />
                    )}
                  </Spacing>
                </SpacingItem>
              </Spacing>
              <ActionsWrapper>
                <Spacing direction="row" justify="space-between" stretchen>
                  <Field
                    name={`${input.name}.required`}
                    type="checkbox"
                    component={RequiredCheckbox}
                  />

                  <Spacing direction="row">
                    <ClickableText
                      h7
                      color="grey"
                      onClick={() => openAddMediaModal()}
                    >
                      Add media
                    </ClickableText>

                    <Text h7 color="grey">
                      ·
                    </Text>

                    <ClickableText h7 color="grey" onClick={duplicate}>
                      Duplicate
                    </ClickableText>
                    <Text h7 color="grey">
                      ·
                    </Text>
                    <ClickableText h7 color="grey" onClick={remove}>
                      Delete
                    </ClickableText>
                  </Spacing>
                </Spacing>
              </ActionsWrapper>
            </Spacing>
          </Wrapper>
        )
      }}
    />
  )
}
