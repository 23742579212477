import React from 'react'
import styled from 'styled-components/macro'
import { Text } from '..'

const LabelWrapper = styled.label`
  cursor: pointer;
  display: flex;
`

const Box = styled.div`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  display: inline-block;
  margin-right: ${props => props.theme.padding.s};
  border-radius: ${props => props.theme.borderRadius.small};
  background-color: ${props =>
    props.checked ? props.theme.color.main : props.theme.color.purpleL2};

  transition: background-color ${props => props.theme.animationSpeed.normal};
`

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

export const Checkbox = ({ label, input, meta, textProps }) => (
  <LabelWrapper>
    <div>
      <CheckboxInput {...input} type="checkbox" />
      <Box checked={input.checked} />
    </div>
    <Text
      inline
      color={input.checked ? 'main' : 'dark'}
      {...(textProps || { t2: true })}
    >
      {label}
    </Text>
  </LabelWrapper>
)
