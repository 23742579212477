import React from 'react'
import {
  pipe,
  split,
  head,
  equals,
  remove,
  findIndex,
  contains,
  pathEq,
  find,
  filter,
  map,
  dropLast,
  join,
  uniq,
  gt
} from 'ramda'

import { Spacing, SpacingItem, Text, Touchable } from '../../../components/ui'

export const ResponseTagQuestionsOverview = ({
  input,
  categoryUniqueId,
  questions,
  audienceRequirementsCategories,
  requiredResponseTags
}) => (
  <Spacing size="m">
    {pipe(
      filter(belongsToParentCategory(categoryUniqueId)),
      tags => flattenAudienceTags(tags),
      map(uniqueId => find(pathEq(['uniqueId'], uniqueId), questions)),
      map(
        renderQuestionRow({
          input,
          audienceRequirementsCategories,
          requiredResponseTags
        })
      )
    )(input.value)}
  </Spacing>
)

const renderQuestionRow = ({
  input,
  audienceRequirementsCategories = [],
  requiredResponseTags = []
}) => ({ uniqueId, label }) => {
  const onQuestionRemove = () => {
    const index = findIndex(equals(uniqueId))(input.value)
    return input.onChange(remove(index, 1, input.value))
  }

  const shouldTagBeRemoved = pipe(
    findIndex(contains(uniqueId)),
    gt(0)
  )(audienceRequirementsCategories)

  const isRequired = requiredResponseTags
    ? find(equals(uniqueId))(requiredResponseTags)
    : false

  return (
    <Spacing key={uniqueId} size="l" direction="row" stretchChildren>
      <SpacingItem>
        <Spacing size="s" direction="row" align="center">
          <Text h6>{label}</Text>
          {isRequired && (
            <Text h7 color="main">
              required
            </Text>
          )}
        </Spacing>
      </SpacingItem>
      <SpacingItem style={{ flex: 0 }}>
        {shouldTagBeRemoved && (
          <Touchable onClick={onQuestionRemove}>
            <Text h7 color="grey">
              remove
            </Text>
          </Touchable>
        )}
      </SpacingItem>
    </Spacing>
  )
}

const hasParentUniqueId = uniqueId => parentUniqueId =>
  contains(uniqueId, parentUniqueId)

const parentCategoryUniqueId = pipe(
  split('_'),
  head
)

const belongsToParentCategory = categoryUniqueId => uniqueId =>
  hasParentUniqueId(parentCategoryUniqueId(uniqueId))(categoryUniqueId)

// e.g. uniqueId = "general_location_(city || country || zipCode)" => general_location
const handleCustomTags = uniqueId => {
  const splittedUnique = split('_', uniqueId)

  if (splittedUnique.length <= 2) return uniqueId

  return pipe(
    dropLast(1),
    join('_')
  )(splittedUnique)
}

const flattenAudienceTags = pipe(
  map(handleCustomTags),
  uniq
)
