import React from 'react'
import { pipe, omit, pathOr, mergeDeepRight, map } from 'ramda'
import { withMutation } from '../../../../components/wrappers'

import { QuestionBox } from './components/QuestionBox'
import { Field } from 'react-final-form'
import { Button, Spacing } from '../../../../components/ui'
import { FieldArray } from 'react-final-form-arrays'

import { getEmptyQuestion } from '../../../../util/survey'
import { validatorInHouseSurveyFilled } from '../../../../util/validators'

const SurveyBuilderInner = () => {
  const duplicateQuestion = (fields, index) => {
    const duplicate = omit(['id'], fields.value[index])
    fields.push(duplicate)
  }
  return (
    <FieldArray name="survey.inHouse.questions">
      {({ fields }) => {
        return (
          <Spacing size="l">
            {fields.map((name, index) => (
              <Field
                key={name}
                name={name}
                render={props => (
                  <QuestionBox
                    update={data => fields.update(index, data)}
                    remove={() => fields.remove(index)}
                    duplicate={() => duplicateQuestion(fields, index)}
                    {...props}
                  />
                )}
              />
            ))}
            <Button
              outline
              block
              onClick={() => fields.push(getEmptyQuestion())}
            >
              + Add a new question
            </Button>
          </Spacing>
        )
      }}
    </FieldArray>
  )
}

export const SurveyBuilder = pipe(withMutation('updateOpportunity'))(
  SurveyBuilderInner
)
SurveyBuilder.format = survey => {
  return omit(['totalQuestions'], survey || {})
}

SurveyBuilder.parse = survey => {
  const questions = pathOr([], ['inHouse', 'questions'], survey)

  const result = mergeDeepRight(
    { survey },
    {
      survey: {
        inHouse: {
          questions: map(question => ({
            ...omit(['imageUrl'], question)
          }))(questions)
        }
      }
    }
  )
  return result.survey
}

SurveyBuilder.validate = validatorInHouseSurveyFilled
