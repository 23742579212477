import React from 'react'
import styled from 'styled-components/macro'
import { equals, propOr } from 'ramda'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'

import { QueryBuilderConditionAnd, QueryBuilderConditionOr } from '../'
import { getFieldIndexFromName } from '../../utils/sentenceHelper'

const WhereWrap = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.xl}`};
`

const WhereWrapVerticalLeft = styled.div`
  width: 6px;
  border-left: 1px solid ${({ theme }) => theme.color.greyL1};
  border-top: 1px solid ${({ theme }) => theme.color.greyL1};
  border-bottom: 1px solid ${({ theme }) => theme.color.greyL1};
`

const WhereWrapVerticalRight = styled.div`
  width: 6px;
  border-right: 1px solid ${({ theme }) => theme.color.greyL1};
  border-top: 1px solid ${({ theme }) => theme.color.greyL1};
  border-bottom: 1px solid ${({ theme }) => theme.color.greyL1};
`

export const QueryBuilderWhere = ({
  field: { fields: fieldsAnd, ...fieldRest },
  state: stateWherePiece,
  ...props
}) => {
  if (equals(fieldsAnd.length, 0) && equals(stateWherePiece, 'unmounted'))
    fieldsAnd.push({})

  return fieldsAnd.map((fieldName, i) => {
    const fieldIndex = getFieldIndexFromName(fieldName)
    const currentField = fieldsAnd.value[fieldIndex]
    const subfields = propOr([], 'groups', currentField)

    if (!subfields.length)
      return (
        <Field
          {...fieldRest}
          {...props}
          component={QueryBuilderConditionAnd}
          onCompleteWhereAndCondition={() => fieldsAnd.push({})}
          onRemoveWhereRow={() => fieldsAnd.remove(i)}
          name={fieldName}
          key={fieldName}
          onUpdateFormField={fieldsAnd.update}
          conditionInfo={{
            totalConditions: fieldsAnd.length,
            currentContitionIndex: i + 1
          }}
        />
      )

    return (
      <WhereWrap key={`${fieldName}.groups`}>
        <WhereWrapVerticalLeft />
        <div style={{ flex: 1 }}>
          <FieldArray
            name={`${fieldName}.groups`}
            render={({ fields: fieldsOr }) =>
              fieldsOr.map((fieldName, i) => (
                <Field
                  {...fieldRest}
                  {...props}
                  component={QueryBuilderConditionOr}
                  onCompleteWhereAndCondition={() => fieldsAnd.push({})}
                  onCompleteWhereOrCondition={() => fieldsOr.push({})}
                  onRemoveWhereRow={() => fieldsOr.remove(i)}
                  name={fieldName}
                  key={fieldName}
                  onUpdateFormField={fieldsOr.update}
                  conditionInfo={{
                    totalConditions: fieldsOr.length,
                    currentContitionIndex: i + 1
                  }}
                />
              ))
            }
            {...props}
          />
        </div>
        <WhereWrapVerticalRight />
      </WhereWrap>
    )
  })
}
