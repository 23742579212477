import gql from 'graphql-tag'
import { UserFragment } from './User'
import { TransactionFragment } from './Transaction'

export const PayoutRequestFragment = gql`
  fragment PayoutRequest on PayoutRequest {
    id
    amount
    status
    createdAt
    payoutUser {
      id
      ...User
    }
    referrerUser {
      id
      ...User
    }
    referredUsers {
      id
      ...User
    }
    transactions {
      id
      ...Transaction
    }
  }
  ${UserFragment}
  ${TransactionFragment}
`
