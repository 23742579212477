import gql from 'graphql-tag'
import { CompanyFragment } from '../fragments'

export const SignUpCompanyQuery = gql`
  query SignUpCompany {
    currentUser {
      id
      email
      fullName
      hasPassword
      company {
        id
        photo {
          default
        }
        paymentMethod {
          paymentToken
        }
      }
    }
  }
`

export const CurrentCompanyQuery = gql`
  query CurrentCompany {
    currentUser {
      id
      email
      verified
      company {
        id
        ...Company
      }
    }
  }
  ${CompanyFragment}
`
