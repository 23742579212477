import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'

import { Spacing, SpacingItem, Select, Graphic, InlinePicker, Text } from '../'
import { buildHelpers } from './utils'
import { QueryRowValue, QueryRowChangeListeners } from './components'

const RemoveRow = styled.div`
  cursor: pointer;
  flex: 0 !important;
  color: ${props => props.theme.color.grey};
  transition: color ${props => props.theme.animationSpeed.normal};

  &:hover {
    color: ${props => props.theme.color.red};
  }
`

const HamburgerIcon = styled.div`
  flex: 0 0 50px !important;
`

export const QueryRow = ({ schema, name, value, update, remove }) => {
  const {
    getSourceOptions,
    getColumnOptions,
    getConditionOptions
  } = buildHelpers(schema)

  const sourceOptions = getSourceOptions(schema)
  const columnOptions = getColumnOptions(value)
  const conditionValues = getConditionOptions(value)

  return (
    <>
      <QueryRowChangeListeners name={name} update={update} value={value} />
      <Spacing>
        <Spacing
          direction="row"
          align="center"
          justify="space-between"
          stretchChildren
        >
          <SpacingItem as={HamburgerIcon}>
            <Graphic name="Hamburger" color="grey" size={18} />
          </SpacingItem>
          <Field
            label="Source"
            name={`${name}.source`}
            component={InlinePicker}
            options={sourceOptions}
          />
          <Text>and</Text>
          <Field
            label="Column"
            name={`${name}.column`}
            component={Select}
            options={columnOptions}
          />
          <Field
            label="Condition"
            name={`${name}.condition`}
            component={Select}
            disabled={!value.column}
            options={conditionValues}
          />
          <QueryRowValue name={`${name}.value`} schema={schema} value={value} />
          <SpacingItem as={RemoveRow} onClick={remove}>
            <Graphic name="XInCircle" size={40} />
          </SpacingItem>
        </Spacing>
      </Spacing>
    </>
  )
}
