import React from 'react'

import { Spacing } from '../../../../components/ui'
import { StudyReviewBlock } from '../../../../components/blocks'
import {
  AudienceBucketsSummary,
  QuestionsSummary,
  SurveySummary,
  AdSummary
} from '../../../../components/blocks/StudyDetails/components'

import {
  isCompletedAudienceStep,
  isCompletedDataSourcesStep,
  isCompletedSurveyStep,
  isCompletedAdStep
} from '../../../../util/validators'
import { isNil, isEmpty } from 'ramda'

export const StudySummaryReview = ({
  opportunity,
  opportunity: {
    name,
    targetAudienceBuckets,
    inputDataSources,
    survey,
    dependsOn,
    ad
  }
}) => {
  return (
    <StudyReviewBlock>
      <StudyReviewBlock.Header title="Study summary" />
      <Spacing size="xxl" separated>
        {(isCompletedAudienceStep({ targetAudienceBuckets }) ||
          (!isNil(dependsOn) && !isEmpty(dependsOn))) && (
          <AudienceBucketsSummary opportunity={opportunity} />
        )}
        {isCompletedDataSourcesStep({ inputDataSources }) && (
          <QuestionsSummary
            title="Study"
            inputDataSources={inputDataSources}
            direction="row"
          />
        )}
        {isCompletedSurveyStep({ survey }) && (
          <SurveySummary survey={survey} rowWrap />
        )}
        {isCompletedAdStep({ ad }) && <AdSummary name={name} ad={ad} rowWrap />}
      </Spacing>
    </StudyReviewBlock>
  )
}
