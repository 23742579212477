import React from 'react'
import styled from 'styled-components/macro'

import { Spacing, SubmitError, Text } from '../../../../../components/ui'
import { Field } from 'react-final-form'
import { StudyReviewBlock } from '../../../../../components/blocks'
import { includes, path, pathOr } from 'ramda'
import { DataSchemaQuery } from '../../../../../state/queries'
import { RenderFromQuery } from '../../../../../components/util'
import { getPadding, getColor } from '../../../../../util/styled'

const RANGE_OPTIONS = [
  {
    label: 'No limit',
    value: null
  },
  {
    label: '1 week',
    value: 7
  },
  {
    label: '1 month',
    value: 30
  },
  {
    label: '2 months',
    value: 60
  },
  {
    label: '6 months',
    value: 180
  },
  {
    label: '1 year',
    value: 365
  }
]

const LabelWrapper = styled.label`
  cursor: pointer;
  display: flex;
`

const Box = styled.div`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  display: inline-block;
  margin-right: ${props => props.theme.padding.s};
  border-radius: ${props => props.theme.borderRadius.small};
  background-color: ${props =>
    props.checked ? props.theme.color.main : props.theme.color.purpleL2};

  transition: background-color ${props => props.theme.animationSpeed.normal};
`

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

const ServicesCount = styled.div`
  border-radius: ${props => props.theme.borderRadius.small};
  background-color: ${getColor('greyL2')};
  padding: ${getPadding('xs')} ${getPadding('s')};
`

const generateRangeQuery = range => ({
  condition: {
    field: 'createddate',
    comparison: 'previous-days',
    value: [`${range}`]
  }
})

const InputDataSources = ({ input, meta, dataSchema, onSave }) => {
  const baseInput = input.value || []

  const includedIds = baseInput.map(path(['query', 'dataSourceIds', '0']))
  const isDataSourceSelected = id => includes(id, includedIds)

  const selectedDateRange = pathOr(
    null,
    ['0', 'query', 'where', '0', 'condition', 'value', '0'],
    baseInput
  )
  const isSelectedDateRange = range => {
    if (!range && !selectedDateRange) {
      return true
    }

    // Have to use strings because that is the typing of query value field (to support other comparisons)
    return `${range}` === selectedDateRange
  }

  const handleRangePick = range => () => {
    input.onChange(
      baseInput.map(input => ({
        ...input,
        query: {
          ...input.query,
          where: range ? [generateRangeQuery(range)] : []
        }
      }))
    )

    onSave()
  }

  const handleToggle = (id, availableObjectTypes) => () => {
    if (isDataSourceSelected(id)) {
      // Remove input data source
      input.onChange(
        baseInput.filter(({ query }) => query.dataSourceIds[0] !== id)
      )
    } else {
      // Add input data source
      const newInputDataSources = [
        ...baseInput,
        ...availableObjectTypes.map(objectTypeId => ({
          query: {
            // We create a no-filter query
            dataSourceIds: [id],
            objectTypeId,
            where: selectedDateRange
              ? [generateRangeQuery(selectedDateRange)]
              : []
          },
          required: true // We can also add this as a check
        }))
      ]

      input.onChange(newInputDataSources)
    }

    onSave()
  }

  return (
    <Spacing size="xl" separated>
      <Spacing direction="row" wrap>
        {dataSchema.serviceGroups.map(serviceGroup => {
          const services = dataSchema.services.filter(
            ({ serviceGroup: { id }, availableObjectTypes }) =>
              serviceGroup.id === id && availableObjectTypes.length > 0
          )

          return (
            <div style={{ width: '200px' }}>
              <Spacing>
                <Spacing direction="row">
                  <Text h4 color="gray">
                    {serviceGroup.name}
                  </Text>
                  <ServicesCount>
                    <Text t5>{services.length}</Text>
                  </ServicesCount>
                </Spacing>
                <Spacing size="xs">
                  {services.map(service => (
                    <LabelWrapper>
                      <div>
                        <CheckboxInput
                          onChange={handleToggle(
                            service.id,
                            service.availableObjectTypes
                          )}
                          type="checkbox"
                        />
                        <Box checked={isDataSourceSelected(service.id)} />
                      </div>
                      <Text
                        inline
                        color={
                          isDataSourceSelected(service.id) ? 'main' : 'dark'
                        }
                      >
                        {service.name}
                      </Text>
                    </LabelWrapper>
                  ))}
                </Spacing>
              </Spacing>
            </div>
          )
        })}
      </Spacing>

      <Spacing>
        <Text h5 color="gray">
          Limit by date range
        </Text>
        <Spacing>
          {RANGE_OPTIONS.map(option => (
            <LabelWrapper>
              <div>
                <CheckboxInput
                  onChange={handleRangePick(option.value)}
                  type="checkbox"
                />
                <Box checked={isSelectedDateRange(option.value)} />
              </div>
              <Text
                inline
                color={isSelectedDateRange(option.value) ? 'main' : 'dark'}
              >
                {option.label}
              </Text>
            </LabelWrapper>
          ))}
        </Spacing>
      </Spacing>
    </Spacing>
  )
}

export const AddSimpleDataInsights = ({ opportunity, submitError, form }) => {
  return (
    <Spacing>
      {submitError && <SubmitError submitError={submitError} />}
      <StudyReviewBlock>
        <StudyReviewBlock.Header title="Pick data sources you'd like to fetch" />
        <Spacing size="xl" stretched>
          <Text t2>
            Specify the data sources you want fetched from the user for this
            study
          </Text>
          <RenderFromQuery
            query={DataSchemaQuery}
            renderData={({ dataSchema, dataTypesSchema }) => (
              <Field
                name="inputDataSources"
                component={InputDataSources}
                dataSchema={dataSchema}
                onSave={() => form.submit()}
              />
            )}
          />
        </Spacing>
      </StudyReviewBlock>
    </Spacing>
  )
}
