import React from 'react'
import styled from 'styled-components'
import { Text } from '../Text'

const getColor = (color, selected) => (selected ? color : 'greyL1')

const Container = styled.div`
  height: 28px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const OptionsWrapper = styled.div`
  position: absolute;
`

const Option = styled.div`
  display: inline-block;
  border-radius: 40px;
  border: 1px solid ${props => props.theme.color[props.color]};
  padding: 5px 8px;
  cursor: pointer;
  background-color: #fff;
  margin: 0 ${props => props.theme.padding.s};
`

const BackgroundLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.color.greyL2};
  position: absolute;
  top: 50%;
`

const options = [
  { option: 'OR', color: 'main' },
  { option: 'AND', color: 'secondary' }
]

export const ConjuctionSelect = ({ input, hidden }) =>
  !hidden && (
    <Container>
      <BackgroundLine />
      <OptionsWrapper>
        {options.map(({ option, color }) => {
          const isSelected = input.value === option
          return (
            <Option
              key={option}
              selected={isSelected}
              onClick={() => input.onChange(option)}
              color={getColor(color, isSelected)}
            >
              <Text h7 color={getColor(color, isSelected)}>
                {option}
              </Text>
            </Option>
          )
        })}
      </OptionsWrapper>
    </Container>
  )
