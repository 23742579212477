import { path, contains } from 'ramda'

export const CheckGroup = ({
  fields,
  options,
  render = ({ children }) => children,
  renderCheck,
  identifier = [],
  onGroupRemove = () => {}
}) => {
  const identityPath = path(identifier)
  const fieldIdentifiers = fields.value ? fields.value.map(identityPath) : []

  const toggleOnChange = (option, onChange) => () => {
    !isSelected(option)
      ? fields.push(option)
      : fields.remove(fieldIdentifiers.indexOf(identityPath(option)))

    onChange && onChange()
  }
  const isSelected = option => {
    return contains(identityPath(option))(fieldIdentifiers)
  }

  const onRemoveItem = index => {
    // if last element, remove group also
    if (fields.length === 1) {
      return onGroupRemove()
    }

    fields.remove(index)
  }

  const fieldNames = fields.map(name => name)

  return render({
    children: options.map(
      ({ value, label, disabled, onChange, ...extra }, i) => {
        const index = fieldIdentifiers.indexOf(identityPath(value))
        return renderCheck({
          key: value,
          input: {
            name: fieldNames[index],
            onChange: toggleOnChange(value, onChange),
            value: isSelected(value),
            checked: isSelected(value),
            disabled
          },
          label,
          onRemove: () => onRemoveItem(index),
          extra
        })
      }
    )
  })
}
