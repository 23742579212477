import gql from 'graphql-tag'

export const rejectPayout = gql`
  mutation RejectPayout($id: String!) {
    rejectPayout(id: $id) {
      success
      errors {
        key
        message
      }
    }
  }
`
