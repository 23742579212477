import gql from 'graphql-tag'

import { OpportunityFragment } from '../fragments'

export const updateOpportunity = gql`
  mutation UpdateOpportunity(
    $id: String!
    $name: String
    $internalName: String
    $shortDescription: String
    $description: String
    $type: String
    $fees: FeesInput
    $inputDataSources: [InputDataSourceInput!]
    $outputDataQueries: [DataQueryInput!]
    $customDataFields: [DataFieldInput!]
    $survey: SurveyInput
    $targetAudienceBuckets: [TargetAudienceBucketInput!]
    $duration: OpportunityDurationInput
    $showCompanyLogo: Boolean
    $responseConfig: ResponseConfigInput
    $insightsDataType: DataTypeState
    $insightsStorageLocation: StorageLocationState
    $dependsOn: [String!]
    $requestedQueryDescription: String
    $ad: AdInput
    $isMultipartStudy: Boolean
    $story: StoryInput
  ) {
    updateOpportunity(
      id: $id
      name: $name
      internalName: $internalName
      shortDescription: $shortDescription
      description: $description
      type: $type
      fees: $fees
      inputDataSources: $inputDataSources
      outputDataQueries: $outputDataQueries
      customDataFields: $customDataFields
      survey: $survey
      targetAudienceBuckets: $targetAudienceBuckets
      duration: $duration
      showCompanyLogo: $showCompanyLogo
      responseConfig: $responseConfig
      insightsDataType: $insightsDataType
      insightsStorageLocation: $insightsStorageLocation
      dependsOn: $dependsOn
      requestedQueryDescription: $requestedQueryDescription
      ad: $ad
      isMultipartStudy: $isMultipartStudy
      story: $story
    ) {
      opportunity {
        id
        ...Opportunity
      }
      errors {
        key
        message
      }
    }
  }
  ${OpportunityFragment}
`
