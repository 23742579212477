import gql from 'graphql-tag'

export const deactivateMemberAccount = gql`
  mutation DeactivateMemberAccount($id: String!, $message: String) {
    deactivateMemberAccount(id: $id, message: $message) {
      success
      errors {
        key
        message
      }
    }
  }
`
