import gql from 'graphql-tag'

export const pauseOpportunity = gql`
  mutation PauseOpportunity($id: String!) {
    pauseOpportunity(id: $id) {
      opportunity {
        id
        state
      }
      errors {
        key
        message
      }
    }
  }
`
