import { useState } from 'react'
import { isNil } from 'ramda'

export const useSectionExpansion = (sections, skipCollapse) => {
  const [sectionsExpanded, setSectionsExpanded] = useState(
    sections.reduce((acc, [section], i) => ({ ...acc, [section]: i === 0 }), {})
  )

  const expandSection = sectionName => () =>
    setSectionsExpanded({ ...sectionsExpanded, [sectionName]: true })

  const collapseSection = sectionName => isSuccess => {
    if (skipCollapse) return

    const [nextSection] =
      sections[sections.map(([key]) => key).indexOf(sectionName) + 1] || []

    setSectionsExpanded({
      ...sectionsExpanded,
      [sectionName]: false,
      [nextSection]: !isNil(isSuccess)
        ? !!isSuccess
        : sectionsExpanded[nextSection]
    })
  }

  return {
    sectionsExpanded,
    expandSection,
    collapseSection
  }
}
