import React from 'react'
import { Field } from 'react-final-form'
import { Input } from '../Input'
import { Graphic } from '../Graphic'
import styled from 'styled-components/macro'

const SearchWrap = styled.div`
  position: relative;
  width: 320px;
`
const SearchIconWrap = styled.div`
  position: absolute;
  top: 22px;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
`

export const Search = ({ onSearch, name, label }) => {
  return (
    <SearchWrap>
      <Field name={name} label={label} component={Input} />
      <SearchIconWrap onClick={onSearch}>
        <Graphic name="Search" width={19} height={20} themeColor="main" />
      </SearchIconWrap>
    </SearchWrap>
  )
}
