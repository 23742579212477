import axios from 'axios'

const { REACT_APP_WAREHOUSE_API_URL } = process.env

export const getWarehouseInsights = (token, requestOptions) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }

  return axios.post(`${REACT_APP_WAREHOUSE_API_URL}/read`, requestOptions, {
    headers: headers
  })
}

export const fetchWarehouseToken = async (getWarehouseToken, variables) => {
  const { data, errors } = await getWarehouseToken({
    variables
  })

  if (errors) {
    console.error(errors)
    return errors
  }

  if (!data) {
    throw new Error('Error fetching warehouse token, no data was returned')
  }

  return data.getWarehouseToken.token
}
