import gql from 'graphql-tag'

import { CompanyFragment } from '../fragments'

export const updateCompany = gql`
  mutation UpdateCompany($photo: PhotoInput, $typeformAccessToken: String) {
    updateCompany(photo: $photo, typeformAccessToken: $typeformAccessToken) {
      currentUser {
        id
        company {
          id
          ...Company
        }
      }
      errors {
        key
        message
      }
    }
  }
  ${CompanyFragment}
`
