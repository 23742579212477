import gql from 'graphql-tag'

export const getWarehouseToken = gql`
  mutation GetWarehouseToken($opportunity: String!, $demo: Boolean) {
    getWarehouseToken(opportunity: $opportunity, demo: $demo) {
      token
      errors {
        key
        message
      }
    }
  }
`
