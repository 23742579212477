import React from 'react'

import { Spacing, Select, InlineField } from '../../ui'
import { withMutation } from '../../wrappers'

const StudyAttributeSection = ({
  opportunity,
  updateOpportunity,
  initialValue,
  inlineError,
  selectKey,
  graphic = 'StudyType',
  options,
  label
}) => {
  const onUpdate = value => {
    return updateOpportunity({
      variables: {
        id: opportunity.id,
        [selectKey]: value
      }
    })
  }

  return (
    <Spacing size="s">
      <InlineField
        component={Select}
        onUpdate={onUpdate}
        graphic={graphic}
        graphicSize={20}
        graphicColor="main"
        options={options}
        label={label}
        initialValue={initialValue}
        inlineError={inlineError}
      />
    </Spacing>
  )
}

export const StudyAttribute = withMutation('updateOpportunity')(
  StudyAttributeSection
)
