import React from 'react'
import {
  Spacing,
  Input,
  Button,
  Text,
  SpacingItem
} from '../../../../../../components/ui'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import styled from 'styled-components'
import { getSingleChoiceOption } from '../../../../../../util/survey'

const TextWrap = styled(Text)`
  &:hover {
    cursor: pointer;
  }
`

const ButtonContainer = styled.div`
  display: flex;
`

const ButtonWrap = styled(Button)`
  flex: 0 1 75%;
`

export const Choices = ({ input }) => (
  <FieldArray name={input.name}>
    {({ fields }) => (
      <Spacing size="s" flex={1}>
        {fields.map((name, index) => (
          <Spacing direction="row" size="xl" key={name} align="center">
            <SpacingItem flex="0 1 75%">
              <Field
                label={`Option ${index + 1}`}
                name={`${name}.label`}
                render={props => <Input {...props} color="white" />}
                block
                autoFocus
              />
            </SpacingItem>
            {index > 0 && (
              <TextWrap h7 color="grey" onClick={() => fields.remove(index)}>
                Remove option
              </TextWrap>
            )}
          </Spacing>
        ))}
        <ButtonContainer>
          <ButtonWrap
            outline
            block
            onClick={() => fields.push(getSingleChoiceOption({}))}
          >
            + New Option
          </ButtonWrap>
        </ButtonContainer>
      </Spacing>
    )}
  </FieldArray>
)
