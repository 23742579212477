import React from 'react'
import { Select } from '../../../../../../components/ui'
import theme from '../../../../../../theme'
import camelCase from 'camelcase'

export const QuestionType = ({ meta, input, questionTypes }) => {
  return (
    <Select
      input={input}
      meta={meta}
      options={questionTypes}
      graphic={camelCase(input.value)}
      graphicSize={22}
      textColor={theme.color.main}
    />
  )
}
