import React from 'react'
import PropTypes from 'prop-types'
import posed from 'react-pose'

import { Button } from '..'

const ShakePose = posed.div({
  shake: {
    applyAtEnd: { x: 0 },
    applyAtStart: { x: -10 },
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 1000,
      damping: 10,
      duration: 3
    }
  }
})

export const SubmitButton = ({
  onClick = () => {},
  children = 'Submit',
  invalid,
  touched,
  submitting,
  disabled,
  dirtySinceLastSubmit,
  submitFailed,
  block
}) => (
  <ShakePose
    pose={submitFailed && touched && invalid && 'shake'}
    poseKey={touched && invalid}
  >
    <Button
      primary
      disabled={disabled || submitting || (invalid && !dirtySinceLastSubmit)}
      onClick={onClick}
      type="submit"
      block={block}
    >
      {children}
    </Button>
  </ShakePose>
)

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  invalid: PropTypes.bool,
  touched: PropTypes.object,
  submitting: PropTypes.bool,
  submitFailed: PropTypes.bool
}
