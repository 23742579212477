import React from 'react'
import { Button } from '../../../ui'
import { DropdownItem } from './DropdownItem'
import { withMutation } from '../../../wrappers'

const SyncTypeformButtonInner = ({ id, syncTypeform, dropdownItem }) => {
  const text = 'Sync Typeform'

  const clickHandler = () =>
    syncTypeform({
      variables: { opportunity: id }
    })

  if (dropdownItem) {
    return <DropdownItem text={text} onClick={clickHandler} />
  }

  return (
    <Button onClick={clickHandler} outline>
      {text}
    </Button>
  )
}

export const SyncTypeformButton = withMutation('syncTypeform', {
  showErrorNotification: true
})(SyncTypeformButtonInner)
