import gql from 'graphql-tag'

export const syncTypeform = gql`
  mutation SyncTypeform($opportunity: String!) {
    syncTypeform(opportunity: $opportunity) {
      errors {
        key
        message
      }
    }
  }
`
