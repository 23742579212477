import React, { useState, useEffect, forwardRef } from 'react'
import styled from 'styled-components'
import {
  reject,
  prop,
  propEq,
  pipe,
  find,
  split,
  join,
  length,
  propOr
} from 'ramda'

import { Text } from '../../ui'
import { useTypeahead } from '../../../hooks'

const Wrap = styled.div`
  position: relative;
  display: inline-block;
`

const PlainInput = styled.input`
  ${({ theme }) => theme.text.t1};
  position: relative;
  background: transparent;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.color.dark};
  padding: 0;
  line-height: 1.4;
  z-index: 20;
`

const Suggestions = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  margin: 0 -10px;
`

const Suggestion = styled.a`
  ${({ theme }) => theme.text.t1};
  color: ${({ theme }) => theme.color.grey};
  margin: 0 10px;
  line-height: 1.4;
`

export const InlineTypeahead = forwardRef(
  (
    {
      input,
      options,
      onTerminate: onTerminatePiece,
      onTerminateCleanUp,
      onTerminateParent,
      onComplete: onCompletePiece,
      onCompleteCleanUp,
      onRemove: onRemoveSentencePiece,
      onContinue: onContinueSkippedPieces,
      state
    },
    ref
  ) => {
    if (state === 'skipped') return null

    const initialState = input.value
      ? pipe(
          find(propEq('value', input.value)),
          propOr('', ['label'])
        )(options)
      : ''

    if (initialState === '.' && state === 'unmounted') {
      onTerminateParent && onTerminateParent()
      onTerminatePiece()
      return
    }
    if (initialState && state === 'unmounted') return onCompletePiece()

    useEffect(() => {
      setTyped(initialState)
    }, [initialState])

    const [typed, setTyped] = useState(initialState)
    const { typeaheadSuggestion, suggestions } = useTypeahead({
      options,
      typed,
      getSuggestionLabel: prop('label')
    })

    // Auto-select option is full match is typed
    useEffect(() => {
      const exactMatch = options.find(
        ({ label }) => label.toLowerCase() === typed.toLowerCase()
      )

      if (exactMatch) {
        onSelect(exactMatch)
      }
    }, [typed])

    const onSelect = option => {
      if (!option || option.value === input.value) return

      input.onChange(option.value)
      setTyped(option.label)

      if (option.terminate) {
        onTerminatePiece()
        onTerminateCleanUp && onTerminateCleanUp()
        return
      }

      // if was terminated but user changed condition from '.'
      if (input.value === '.' && input.value !== option.value)
        onContinueSkippedPieces()

      onCompleteCleanUp && onCompleteCleanUp(option.value)
      return onCompletePiece()
    }

    const handleKeyDown = e => {
      switch (e.keyCode) {
        case 8:
          if (!typed.length) {
            e.preventDefault()
            input.onChange('')
            onRemoveSentencePiece()
          }

          break
        case 13: // enter
        case 39: // arrow right
          return onSelect(typeaheadSuggestion)
        default:
      }
    }

    const renderedSuggestions = getSuggestions({
      typed,
      suggestions,
      typeaheadSuggestion
    })

    const getCharsLength = pipe(
      split(' '),
      join(''),
      length
    )

    const calculatedWidth =
      Math.max(
        4,
        renderedSuggestions.reduce(
          (total, { label }) => total + getCharsLength(label),
          0
        ),
        getCharsLength(typed)
      ) + 'ch'

    return (
      <Wrap>
        <PlainInput
          {...input}
          onChange={e => setTyped(e.target.value)}
          value={typed}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          style={{
            width: calculatedWidth
          }}
          ref={ref}
        />
        {renderedSuggestions && (
          <Suggestions>
            {renderedSuggestions.map((suggestion, i) => (
              <React.Fragment key={suggestion.value}>
                <Suggestion>{suggestion.label}</Suggestion>
                {i !== renderedSuggestions.length - 1 && (
                  <Text t1 color="grey">
                    /
                  </Text>
                )}
              </React.Fragment>
            ))}
          </Suggestions>
        )}
      </Wrap>
    )
  }
)

const getSuggestions = ({ typed, suggestions, typeaheadSuggestion }) => {
  if (!typeaheadSuggestion) return suggestions

  return [
    {
      label: typed + typeaheadSuggestion.cutoff,
      value: typeaheadSuggestion.value
    },
    ...reject(propEq('value', typeaheadSuggestion.value), suggestions)
  ]
}

InlineTypeahead.defaultProps = {
  onComplete: () => {},
  onRemove: () => {},
  onTerminate: () => {}
}
