import React from 'react'
import { OpportunitiesOverview } from '../../Studies/components/OpportunitiesOverview'
import {
  ADMIN_ADS_FILTERS,
  INITIAL_ADS_FILTERS,
  CREATE_AD_LINK,
  CREATE_AD_TEXT,
  ADS_SIDEBAR_TEXT
} from '../constants'
import { StudiesQueryVariables } from '../../../state/queries'

export const AdminAdsScreen = () => (
  <OpportunitiesOverview
    allStates={StudiesQueryVariables.allAdStates}
    filtersList={ADMIN_ADS_FILTERS}
    initialFilters={INITIAL_ADS_FILTERS}
    buttonLink={CREATE_AD_LINK}
    buttonText={CREATE_AD_TEXT}
    sidebarTitle={ADS_SIDEBAR_TEXT}
    category="Ad"
    userRole="admin"
  />
)
