import gql from 'graphql-tag'

export const deleteOpportunity = gql`
  mutation DeleteOpportunity($id: String!) {
    deleteOpportunity(id: $id) {
      success
      errors {
        key
        message
      }
    }
  }
`
