import React from 'react'
import styled from 'styled-components/macro'
import { pathEq, pipe, map, path, flatten } from 'ramda'

import { Modal, Spacing, Text } from '../../components/ui'
import { RenderFromQuery } from '../../components/util'
import {
  AllDataSourcesQuery,
  AudienceDataVerifiedTagsQuery
} from '../../state/queries'

const ModalWrap = styled.div`
  width: 600px;
  position: relative;
  padding-bottom: ${props => props.theme.padding.xl};
`

const QueryDetails = ({
  objectTypeId,
  dataSourceIds,
  nativeQueryDescription: { filter }
}) => (
  <div>
    <RenderFromQuery
      query={AllDataSourcesQuery}
      renderData={({ allDataSources: { edges } }) => {
        const allObjectTypes = pipe(
          map(path(['node', 'availableObjectTypes'])),
          flatten
        )(edges)
        const objectType = allObjectTypes.find(pathEq(['id'], objectTypeId))
        console.log({ allObjectTypes, objectType, objectTypeId })
        return (
          <Spacing>
            <Text bold>Data Sources</Text>
            {dataSourceIds
              .map(id => {
                const dataSource = edges.find(pathEq(['node', 'id'], id))
                console.log({ edges, id, dataSource })
                return dataSource.node.name
              })
              .join(', ')}

            <Text bold>Object Type</Text>
            {objectType.name}

            <Text bold>Filter</Text>
            {filter.length === 0 ? (
              `No filters required, anyone who has at least 1 row of data from ${objectType.name} will match.`
            ) : (
              <div dangerouslySetInnerHTML={{ __html: filter[0] }} />
            )}
          </Spacing>
        )
      }}
    />
  </div>
)

export const GroupDetails = ({ onDismiss, params: { id, name, logoUrl } }) => {
  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title={`"${name}" Details`}
    >
      {({ onDismiss }) => (
        <ModalWrap>
          <RenderFromQuery
            query={AudienceDataVerifiedTagsQuery}
            variables={{ tags: [id] }}
            renderData={({ audienceDataVerifiedTags }) => (
              <div>
                {audienceDataVerifiedTags.length === 0 ? (
                  <Text>No details yet</Text>
                ) : (
                  <Spacing>
                    <Text h3>Entry Query</Text>
                    {audienceDataVerifiedTags[0].queries.map(query => (
                      <QueryDetails {...query} />
                    ))}
                  </Spacing>
                )}
              </div>
            )}
          />
        </ModalWrap>
      )}
    </Modal>
  )
}
