import React from 'react'
import { keys, split, pipe, head, filter, prop, omit } from 'ramda'

import { Spacing, Tag } from '../../../components/ui'

import { TagCategoryOverview } from './TagCategoryOverview'

import { getAgeRange } from '../../../util'

export const TagFiltersOverview = ({
  input,
  categories,
  renderFilterComponent,
  onRemoveCategory
}) => (
  <Spacing>
    {categoriesWithValues({ input, categories }).map(
      ({ label, uniqueId, children }) => (
        <TagCategoryOverview
          key={label}
          label={label}
          uniqueId={uniqueId}
          questions={addCustomRendering(children, input)}
          input={input}
          onRemoveCategory={onRemoveCategory}
        >
          {renderFilterComponent({
            categoryUniqueId: uniqueId,
            questions: addCustomRendering(children, input),
            input
          })}
        </TagCategoryOverview>
      )
    )}
  </Spacing>
)

const addCustomRendering = (children, input) =>
  children.map(child => {
    return { ...child, renderTags: getCustomRenderer(child, input) }
  })

const getCustomRenderer = ({ uniqueId }, input) => {
  switch (uniqueId) {
    case 'general_age':
      return ({ children }) => {
        return (
          <Tag
            label={getAgeRange(children.map(prop('uniqueId'))).join('-')}
            onRemove={() => {
              return input.onChange(omit([uniqueId], input.value))
            }}
            removable
          />
        )
      }
    default:
      return undefined
  }
}

const categoriesWithValues = ({ input, categories }) => {
  const { value: values } = input

  if (input.name === 'additionalResponseTags') {
    return filter(({ uniqueId }) =>
      values.map(parentCategory).includes(uniqueId)
    )(categories)
  }

  return filter(({ uniqueId }) =>
    keys(values)
      .filter(k => values[k] && values[k].length > 0)
      .map(parentCategory)
      .includes(uniqueId)
  )(categories)
}

const parentCategory = pipe(
  split('_'),
  head
)
