import {
  pipe,
  filter,
  isNil,
  isEmpty,
  values,
  map,
  includes,
  or,
  length,
  equals,
  flatten,
  complement,
  mergeAll,
  find,
  pathOr
} from 'ramda'
import { validatorAtLeastOneRequired, mergeValidators } from './utils'
import { formatQueries } from '../audience'
import { SURVEY_QUESTION_TYPES } from '../../screens/StudyBuilder/components/SurveyBuilder/constants'

const hasPopulatedWhere = map(query => {
  const { groups, conjunction } = query

  if (isEmpty(query) || (equals(length(query), 1) && isEmpty(query[0])))
    return false

  if (groups) {
    return hasPopulatedWhere(groups)
  }

  if (isNil(conjunction)) return false

  return query
})

const hasPopulatedQueries = pipe(
  map(query => {
    const { conjuctionQ } = query

    // to distingush steps `Insights` and `Other`
    const [queryFinal] = !isNil(conjuctionQ) ? [query] : formatQueries([query])

    const { objectTypeId, where } = queryFinal

    if (isNil(objectTypeId) || isNil(where)) return false
    return hasPopulatedWhere(where)
  }),
  flatten,
  filter(complement(Boolean)),
  length,
  equals(0)
)

export const anyAudienceTagFilled = tags =>
  !isNil(tags) && !isEmpty(tags) && !isNil(tags.and) && !isEmpty(tags.and)

export const anyAudienceRequirementFilled = queries =>
  !isNil(queries) && !isEmpty(queries) && !isEmpty(queries[0])

export const isCompletedAudienceStep = ({ targetAudienceBuckets }) => {
  if (isNil(targetAudienceBuckets) || isEmpty(targetAudienceBuckets))
    return false

  return pipe(
    map(
      ({ tags, queries }) =>
        anyAudienceTagFilled(tags) || anyAudienceRequirementFilled(queries)
    ),
    filter(complement(Boolean)),
    length,
    equals(0)
  )(targetAudienceBuckets)
}

export const isCompletedAdStep = ({ ad }) => !isNil(ad) && !isNil(ad.fullUrl)

export const allRequirementQueriesFilled = ({
  audienceRequirements: { queries = [] }
}) =>
  pipe(
    map(values),
    filter(or(includes(null), includes('')))
  )(queries).length === 0

export const validatorAllRequirementQueriesFilled = ({
  targetAudienceBuckets
}) =>
  pipe(
    map(({ queries, quota }) => {
      if (quota && quota < 0) {
        return {
          _general: 'Audience quota must be positive number.'
        }
      }

      const anyFilled = anyAudienceRequirementFilled(queries)

      if (!anyFilled) return false
      // const allPopulatedQueries = hasPopulatedQueries(queries)

      // if (!allPopulatedQueries) {
      //   return {
      //     _general: 'Please fill out all fields for queries.'
      //   }
      // }

      return false
    }),
    mergeAll
  )(targetAudienceBuckets || [])

export const validatorInHouseSurveyFilled = ({ survey }) => {
  const questions = pathOr([], ['inHouse', 'questions'], survey)
  const typesWithChoices = [
    SURVEY_QUESTION_TYPES.singleChoice,
    SURVEY_QUESTION_TYPES.multipleChoice
  ]
  return pipe(
    map(({ title, type, choices }) => {
      if (isEmpty(title) || isNil(title)) {
        return { _general: 'All questions must be filled' }
      }

      if (includes(type, typesWithChoices)) {
        if (isNil(choices) || isEmpty(choices)) {
          return { _general: `Type ${type} has to have options provided` }
        }

        const emptyLabel = find(
          option => isEmpty(option.label) || isNil(option.label)
        )(choices || [])

        if (!isNil(emptyLabel)) {
          return { _general: `All labels must be filled for type ${type}` }
        }
      }
    }),
    mergeAll
  )(questions)
}

export const isCompletedDataSourcesStep = ({
  inputDataSources,
  requestedQueryDescription
}) => !isEmpty(inputDataSources) || !isNil(requestedQueryDescription)

export const isCompletedSurveyStep = ({ survey }) =>
  !isNil(survey) && (survey.typeformId || survey.inHouse)

export const validatorAlreadyPublished = ({ state }) => {
  if (['inReview'].includes(state)) {
    return {
      _general:
        'The study has been submitted for review and cannot be edited anymore.'
    }
  }

  if (['published', 'completed'].includes(state)) {
    return {
      _general: 'The study has been published and cannot be edited anymore.'
    }
  }

  return {}
}

export const validatorAdFilled = ({ name, ad }) => {
  if (!ad) return {}

  if (!name)
    return {
      name: 'Ad headline is mandatory'
    }

  if (!ad.fullUrl)
    return {
      ad: { fullUrl: 'Ad url is mandatory' }
    }

  if (!ad.budget)
    return {
      ad: { budget: 'Ad budget is mandatory' }
    }

  if (name && name.length > 120)
    return {
      name: 'Ad headline too long - 120 characters max.'
    }

  return {}
}

export const validatorEnoughAdditionalTags = ({ additionalResponseTags }) => {
  return {}
  // if (additionalResponseTags.length <= 10) return {}

  // return {
  //   FORM_ERROR: `The study has too much additional response tags. The limit is 10.`
  // }
}

const validatorDataSourcesOrSurvey = validatorAtLeastOneRequired(
  ['inputDataSources', 'survey', 'requestedQueryDescription', 'ad', 'story'],
  ['Data Source', 'Survey', 'Query Description', 'Ad', 'Story'],
  'You have to either select one Data Source, describe query or build a Survey before continuing.'
)

export const validatorContainsQueries = ({ inputDataSources = [] }) => {
  if (inputDataSources && inputDataSources.length > 0) {
    if (
      inputDataSources
        .filter(complement(isEmpty))
        .filter(
          ({ query }) =>
            !anyAudienceRequirementFilled([query]) ||
            !hasPopulatedQueries([query])
        ).length > 0
    ) {
      return {
        filters:
          'You must create Data Insight Queries for the selected Data Sources'
      }
    }
  }

  return {}
}

export const validatorsBeforePublishing = mergeValidators([
  validatorAlreadyPublished,
  validatorDataSourcesOrSurvey,
  validatorContainsQueries
])
