import { lighten, darken } from 'polished'

const DEFAULT_COLOR = 'purpleL3'

export const getBackgroundColor = ({ theme, meta, color = DEFAULT_COLOR }) => {
  if (!meta.active) {
    return theme.color[color]
  } else {
    return lighten(0.012, theme.color[color])
  }
}

export const getHoveredBackgroundColor = ({ theme, color = DEFAULT_COLOR }) =>
  lighten(0.012, theme.color[color])

export const getBorder = ({ theme, meta, color = DEFAULT_COLOR }) => {
  return !meta.active
    ? `1px solid transparent`
    : `1px solid ${darken(0.012, theme.color[color])}}`
}
