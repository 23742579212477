import React from 'react'
import styled from 'styled-components/macro'
import { Spacing, Text } from '../../ui'

const SectionWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.purpleL4};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  padding: ${({ theme }) => theme.padding.xl};
`

export const StudySection = ({ title, rightContent, children }) => (
  <SectionWrap>
    <Spacing size="xl" stretched>
      {(title || rightContent) && (
        <Spacing direction="row" justify="space-between">
          {title && <Text h3>{title}</Text>}
          {rightContent}
        </Spacing>
      )}
      {children}
    </Spacing>
  </SectionWrap>
)
