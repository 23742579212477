import React from 'react'
import styled from 'styled-components/macro'

import {
  Box,
  Text,
  Spacing,
  SpacingItem,
  NumberBox,
  ContainerSpinner
} from '../../../../components/ui'

import { RenderFromQuery } from '../../../../components/util'
import { DashboardAudienceTagsQuery } from '../../../../state/queries'

const RowWrap = styled.div`
  flex-grow: 1;
  max-width: 100%;
  min-width: 33%;
  margin-bottom: ${({ theme }) => theme.padding.s};
`

export const AudienceStats = () => (
  <Box backgroundColor="white" size="xl">
    <Spacing size="xl">
      <Text h2>Audience</Text>
      <RenderFromQuery
        query={DashboardAudienceTagsQuery}
        renderLoading={() => <ContainerSpinner />}
        renderData={({ stats: { dashboard } }) => (
          <Spacing direction="row" size="s" wrap>
            {dashboard.audienceTags.audienceTag.map(({ tag, total }) => (
              <SpacingItem as={RowWrap} key={tag}>
                <NumberBox title={tag} backgroundColor="purpleL3" size="xs" tag>
                  {total}
                </NumberBox>
              </SpacingItem>
            ))}
          </Spacing>
        )}
      />
    </Spacing>
  </Box>
)
