import React from 'react'
import styled from 'styled-components'
import { DataSourceTrend } from '../DataSourceTrend'
import {
  Box,
  Spacing,
  SpacingItem,
  Text,
  Graphic,
  Number
} from '../../../../components/ui'

import { map } from 'ramda'
import { DATASOURCE_STATS_COLORS } from '../../../../constants/datasourcesColors'

const ChartWrapper = styled.div`
  flex: 1;
  height: 40px;
  max-width: 80%;
`

const Stats = ({ label, value }) => (
  <div>
    <Text t4 color="grey">
      {label}
    </Text>
    <Text h4>
      <Number>{value}</Number>
    </Text>
  </div>
)

export const DataSourceListItem = ({
  data: { dataSource, linkedUsersHistory, dataPoints }
}) => {
  const datasourceColor = DATASOURCE_STATS_COLORS.find(
    color => dataSource.name === color.name
  )
  const totalUsers = linkedUsersHistory[0] ? linkedUsersHistory[0].total : 0

  const trend = map(
    ({ date, total }) => [new Date(date), total],
    linkedUsersHistory
  )

  const graphicName = 'Source' + dataSource.name.replace(/ /g, '')

  return (
    <Box size="xxl" backgroundColor="purpleL4" noShadow>
      <Spacing size="xl">
        <Spacing direction="row" size="xl" align="center">
          <Graphic name={graphicName} size={40} />
          <Text h3>{dataSource.name}</Text>
        </Spacing>

        <Spacing direction="row" size="xl" align="center">
          <SpacingItem as={ChartWrapper}>
            <DataSourceTrend
              trend={trend}
              color={datasourceColor ? datasourceColor.color : 'black'}
            />
          </SpacingItem>
          <Stats label="Users" value={totalUsers} />
          <Stats label="Datapoints" value={dataPoints} />
        </Spacing>
      </Spacing>
    </Box>
  )
}
