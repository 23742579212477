import gql from 'graphql-tag'
import { CompanyFragment } from '../fragments'

export const generateBraintreeClientToken = gql`
  mutation GenerateBraintreeClientToken {
    generateBraintreeClientToken {
      success
      clientToken
      query {
        currentUser {
          id
          company {
            id
            ...Company
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
  ${CompanyFragment}
`
