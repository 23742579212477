import React from 'react'
import styled from 'styled-components/macro'
import { find, propEq } from 'ramda'

import { Text, Spacing, SpacingItem } from '../../../../ui'
import { TabledDataSummary } from '../'
import { RenderFromQuery } from '../../../../util'
import { DataSchemaQuery } from '../../../../../state/queries'

const RowWrap = styled.div`
  flex: 1 0 50%;
  max-width: 50%;
  margin-bottom: ${({ theme }) => theme.padding.s};
`

export const QuestionsSummary = ({
  inputDataSources,
  direction = 'column',
  title
}) => {
  const rowWrap = direction === 'row'

  return (
    <RenderFromQuery
      query={DataSchemaQuery}
      renderData={({ dataSchema, dataTypesSchema }) => (
        <Spacing size="s">
          <Text h6>{title}</Text>
          <Spacing direction={direction} wrap={rowWrap}>
            {inputDataSources.map(({ query }, i) => {
              if (!query) return null

              // default case is that dataSourceIds=['1'] has one element in array
              // otherwise we assume that user selected `All sources` since API calculates dataSourceIds in case they are not provided ('All sources' case)
              const dataSource =
                query.dataSourceIds && query.dataSourceIds.length === 1
                  ? find(propEq('id', query.dataSourceIds[0]))(
                      dataSchema.services
                    )
                  : { name: 'All Sources', availableObjectTypes: [] }

              const objectType = dataSchema.objectTypes.find(
                ({ id }) => query.objectTypeId === id
              )

              return (
                <SpacingItem as={rowWrap && RowWrap} key={'query-' + i}>
                  <QuestionSummary
                    dataSource={dataSource}
                    objectType={objectType}
                    {...query.nativeQueryDescription}
                  />
                </SpacingItem>
              )
            })}
          </Spacing>
        </Spacing>
      )}
    />
  )
}

const QuestionSummary = ({
  dataSource,
  objectType,
  aggregation = [],
  filter = [],
  breakout = []
}) => {
  return (
    <Spacing size="xxs">
      <TabledDataSummary
        label="- Data Source:"
        value={[dataSource.name, objectType && objectType.name]
          .filter(Boolean)
          .join(' / ')}
      />
      <TabledDataSummary
        label="- View:"
        value={aggregation.length ? aggregation[0] : 'Everything'}
        injectHtml
      />
      {filter.length && (
        <TabledDataSummary
          label="- Filtered by:"
          value={filter[0]}
          injectHtml
        />
      )}
      {breakout.length && (
        <TabledDataSummary
          label="- Grouped by:"
          value={breakout[0]}
          injectHtml
        />
      )}
    </Spacing>
  )
}
