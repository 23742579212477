import React from 'react'
import { Query } from 'react-apollo'

import { Text, Notice } from '../ui'

export const RenderFromQuery = ({
  renderError = defaultRenderError,
  renderLoading = defaultRenderLoading,
  renderEmpty = defaultRenderEmpty,
  emptyValidator = defaultEmptyValidator,
  renderData,
  query,
  ...queryProps
}) => {
  if (!query) throw new Error('No Query provided')

  return (
    <Query query={query} {...queryProps}>
      {({ error, loading, data, ...rest }) => {
        if (error) {
          return renderError(error)
        }

        if (loading) {
          return renderLoading()
        }

        if (emptyValidator(data)) {
          return renderEmpty()
        }

        return renderData(data, { loading, ...rest })
      }}
    </Query>
  )
}

const defaultEmptyValidator = data => {
  const firstQueryData = data[Object.keys(data)[0]]
  return (
    firstQueryData && firstQueryData.edges && firstQueryData.edges.length === 0
  )
}

const defaultRenderEmpty = () => <Notice>No results.</Notice>

const defaultRenderLoading = () => null

const defaultRenderError = error => (
  <Text color="redNegative">
    {error.message}
    {error.networkError && `Network Error: ${error.networkError.toString()}`}
  </Text>
)
