export const Number = ({ children, ...props }) => {
  if (typeof children !== 'number') {
    console.error(
      `Provided child for Number component is not number: ${children}`
    )
    return null
  }

  const options = {
    minimumFractionDigits: props.currency === 'USD' ? 2 : 0
  }

  const currencyMap = {
    USD: '$'
  }

  const getPrefix = ({ currency, prefix }) => {
    if (prefix) return prefix
    if (currency) return currencyMap[currency]
    return null
  }

  const prefix = getPrefix(props)

  const localeNumber = new Intl.NumberFormat(undefined, options).format(
    children
  )

  return prefix ? prefix + localeNumber : localeNumber
}
