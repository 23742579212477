import gql from 'graphql-tag'

export const AudienceSchemaItemFragment = gql`
  fragment AudienceSchemaItem on AudienceSchemaItem {
    label
    uniqueId
    question
    multi
    custom
  }
`
