import React from 'react'
import styled from 'styled-components/macro'
import { Text, Spacing, Graphic } from '../ui'

const Header = styled.div`
  background-image: ${({ theme }) => theme.gradient.headerGradient};
  padding: ${({ theme }) => theme.padding.l} ${({ theme }) => theme.padding.xl};
  display: flex;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const WrapGradientBg = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(218.83deg, #c6c0fa 0%, #fafaff 99.05%);
`

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return (
        <WrapGradientBg>
          <Container>
            <Header>
              <a href="/">
                <Graphic name="UbdiLogo" width={82} />
              </a>
            </Header>
            <Spacing>
              <Text center h2>
                WELP. This isn’t right!
              </Text>
              <Text center>
                We sincerely apologize for the inconvenience!
                <br />
                Our team has been notified of this error and we’re already
                fixing it!
              </Text>
            </Spacing>
          </Container>
        </WrapGradientBg>
      )
    }

    return this.props.children
  }
}
