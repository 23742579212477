export default {
  AudienceNotice: {
    title: 'Building your audience',
    text:
      'You can completely personalize your audience with the tags. By adding requirements, you can narrow down the audience by various parameters and define a minimum number of people required to match them.'
  },
  DataSourcesNotice: {
    title: 'Getting data insights',
    text:
      'While building a completely custom study, you can opt for a full study including both both data insights and survey, or you can create a study with any of options individually. If you don’t want to get the insights, you can continue and skip this step to create a survey.'
  },
  SurveyNotice: {
    title: 'Creating a survey',
    text:
      'You can use Typeform to create and attach a Survey to your Study. We will serve it to your reached audience and you will receive aggregated results back.'
  }
}
