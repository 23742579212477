import React, { Fragment } from 'react'
import styled from 'styled-components/macro'

import { isEmpty } from 'ramda'

import {
  AudienceBucketsSummary,
  DataSourcesSummary,
  DurationSummary,
  QuestionsSummary,
  SurveySummary
} from '../StudyDetails/components'

import { Spacing, SpacingItem } from '../../ui'

const SectionWrap = styled.div`
  flex: 1 0 50%;
  max-width: 50%;
  margin-bottom: ${({ theme }) => theme.padding.xl};
`

const STUDY_SECTIONS = [
  'Audience',
  'Data Sources',
  'Survey Link',
  'Study Duration'
]

// unused
export const StudySummarySections = ({ opportunity }) => {
  const getSectionData = section => {
    switch (section) {
      case 'Audience': {
        return {
          data: opportunity.audienceRequirements,
          renderSection: audienceRequirements => (
            <AudienceBucketsSummary
              audienceRequirements={audienceRequirements}
            />
          )
        }
      }
      case 'Data Sources': {
        return {
          data: !isEmpty(opportunity.inputDataSources)
            ? opportunity.inputDataSources
            : null,
          renderSection: inputDataSources => (
            <DataSourcesSummary inputDataSources={inputDataSources} />
          )
        }
      }
      case 'Questions': {
        return {
          data: !isEmpty(opportunity.inputDataSources)
            ? opportunity.inputDataSources
            : null,
          renderSection: inputDataSources => (
            <QuestionsSummary
              title="Questions"
              inputDataSources={inputDataSources}
            />
          )
        }
      }
      case 'Study Duration': {
        return {
          data: opportunity.duration ? opportunity.duration.lengthInDays : null,
          renderSection: lengthInDays => (
            <DurationSummary lengthInDays={lengthInDays} />
          )
        }
      }
      case 'Survey Link': {
        return {
          data: opportunity.survey,
          renderSection: survey => <SurveySummary survey={survey} rowWrap />
        }
      }
      default:
        throw new Error(`Section ${section} does not exist`)
    }
  }

  const renderQuestionsSection = () => {
    const { data, renderSection } = getSectionData('Questions')

    return data ? renderSection(data) : null
  }

  return (
    <Fragment>
      <Spacing direction="row" wrap>
        {STUDY_SECTIONS.map(getSectionData)
          .filter(({ data }) => data)
          .map(({ data, renderSection }, n) => (
            <SpacingItem as={SectionWrap} key={n}>
              {renderSection(data)}
            </SpacingItem>
          ))}
      </Spacing>
      {renderQuestionsSection()}
    </Fragment>
  )
}
