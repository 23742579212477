import gql from 'graphql-tag'

export const submitForReviewOpportunity = gql`
  mutation SubmitForReviewOpportunity($id: String!) {
    submitForReviewOpportunity(id: $id) {
      opportunity {
        id
        state
      }
      errors {
        key
        message
      }
    }
  }
`
