import React from 'react'
import styled, { css } from 'styled-components'

import { includes } from 'ramda'
import { Spacing, Text, Graphic } from '../../ui'

export const StudyStepsHeader = ({
  steps,
  currentStep,
  completedSteps,
  goToStep
}) => {
  const getStepStatus = step => {
    if (includes(step, completedSteps)) return 'completed'
    if (step === currentStep) return 'active'
    if (step >= currentStep) return 'inactive'

    return 'skipped'
  }

  return (
    <Spacing direction="row" stretchChildren>
      {steps.map(([stepName], i) => {
        const stepStatus = getStepStatus(i)

        const { disabled, iconColor, renderContent } = getStepIconProps(
          stepStatus,
          i
        )

        return (
          <StepWrapper
            disabled={disabled}
            onClick={() =>
              !disabled && goToStep({ shouldHandleStepChange: true })(i)
            }
            key={stepName}
          >
            <Spacing direction="row" size="l" align="center">
              <StepCount color={iconColor}>{renderContent()}</StepCount>
              <StepName h5 stepStatus={stepStatus}>
                {stepName}
              </StepName>
            </Spacing>
          </StepWrapper>
        )
      })}
    </Spacing>
  )
}

const StepWrapper = styled.div`
  cursor: ${({ disabled }) => (disabled ? `default` : `pointer`)};
`

const StepCount = styled.div`
  ${props => {
    const { theme, color } = props

    const backgroundColor = theme.color[color]

    return css`
      width: 40px;
      height: 40px;

      background: ${backgroundColor};
      color: ${({ theme }) => theme.color.white};

      border-radius: 50%;

      align-items: center;
      justify-content: center;
      display: flex;
    `
  }}
`

const StepName = styled(Text)`
  ${({ theme, stepStatus }) => {
    const isInactive = stepStatus === 'inactive'
    const color = isInactive ? theme.color.greyL2 : theme.color.dark
    const hover = !isInactive && `color: ${theme.color.main}`

    return css`
      color: ${color};

      &:hover {
        ${hover}
      }
    `
  }}
`

const getStepIconProps = (status, step) => {
  switch (status) {
    case 'active': {
      return {
        disabled: true,
        iconColor: 'main',
        renderContent: () => (
          <Text h6 color="white">
            {step + 1}
          </Text>
        )
      }
    }
    case 'completed': {
      return {
        disabled: false,
        iconColor: 'green',
        renderContent: () => (
          <Graphic name="Tick" size={20}>
            {step}
          </Graphic>
        )
      }
    }
    case 'skipped': {
      return {
        disabled: false,
        iconColor: 'green',
        renderContent: () => (
          <Graphic name="Skipped" size={20}>
            {step}
          </Graphic>
        )
      }
    }
    // inactive
    default: {
      return {
        disabled: true,
        iconColor: 'greyL2',
        renderContent: () => (
          <Text h6 color="white">
            {step + 1}
          </Text>
        )
      }
    }
  }
}
