import React from 'react'

import { StatePage } from '../../../../components'
import { Text, Button } from '../../../../components/ui'

import launchedStudy from './launchedStudy.svg'

export const StudyLaunchedScreen = ({ location: { pathname } }) => {
  const categoryRoute = pathname.split('/')[1]
  const singular = { ads: 'Ad', studies: 'Study', stories: 'Story' }[
    categoryRoute
  ]

  return (
    <StatePage
      headline={`The ${singular} has been launched!`}
      graphic={launchedStudy}
      buttons={[
        <Button to={`/${categoryRoute}`} key={1}>
          Go to {categoryRoute}
        </Button>,
        <Button
          to={`/${categoryRoute}/create/`}
          key={2}
          transparent
          themeColor="grey"
        >
          <span role="img" aria-label="">
            🎉
          </span>{' '}
          That was easy, let's create another one!
        </Button>
      ]}
    >
      <Text t0 key={1}>
        You are done, now it's our turn. We will march ahead and verify your{' '}
        {singular} is ready for your audience.
      </Text>
      <Text t0 key={2}>
        It should be up in a jiffy, but please be ready to provide some
        additional details to our team, if need be.
      </Text>
    </StatePage>
  )
}
