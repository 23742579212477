import gql from 'graphql-tag'

export const OpportunityResponsesQuery = gql`
  query OpportunityResponses(
    $opportunity: String!
    $after: Int
    $first: Int = 10
    $sort: String
    $orderBy: String
  ) {
    allOpportunityResponses(
      opportunity: $opportunity
      first: $first
      after: $after
      sort: $sort
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        node {
          shortId
          matchedTagsLength
          insightsLength
          surveyAnswersLength
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

export const OpportunityResponseInsightResultsQuery = gql`
  query OpportunityResponseInsightResults(
    $shortId: String!
    $dataSourceId: String!
    $objectTypeId: Int!
    $after: Int
    $first: Int = 10
  ) {
    opportunityResponseInsightResults(
      shortId: $shortId
      dataSourceId: $dataSourceId
      objectTypeId: $objectTypeId
      first: $first
      after: $after
    ) {
      type
      columns
      totalCount
      edges {
        node {
          answers
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`

export const OpportunityResponseQuery = gql`
  query OpportunityResponse($shortId: String!) {
    opportunityResponse(shortId: $shortId) {
      matchedTags {
        name
        values
      }
      survey {
        answers
        columns
      }
      insights {
        dataSourceId
        objectTypeId
      }
    }
  }
`
