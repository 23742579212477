import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { Modal, Spacing, Button, Tabs, Input } from '../../components/ui'
import { ImageUpload } from './components/ImageUpload'
import { Field, Form } from 'react-final-form'
import { VideoPlayer } from './components/VideoPlayer'

const ModalWrap = styled.div`
  width: 650px;
  position: relative;
`

// Checks if file was uploaded to API
const isUnsavedFile = image =>
  image && (image instanceof File || !!image.src.match(/data:image/i))

const extractImageSrc = image => {
  /**
   * There are 3 cases of handling image changes:
   * 1) Image exists, but file was not uploaded yet, we return it in base64 format which then when uploaded changes the image
   * 2) Image exists, but the file was already uploaded, which means we return null (no changes should happen on API, image stays)
   * 3) Image does not exist at all - it was removed. We return an empty img src which deletes the image on API.
   */
  if (isUnsavedFile(image)) {
    return {
      x: 0,
      y: 0,
      height: 1,
      width: 1,
      src: image.src
    }
  } else if (image) {
    return null
  } else {
    return {
      x: 0,
      y: 0,
      height: 1,
      width: 1,
      src: ''
    }
  }
}

export const AddMedia = ({ onDismiss, params = {} }) => {
  const { onSave, image, videoUrl } = params

  const [uploadedImage, setUploadedImage] = useState(image)
  const [selectedVideo, setSelectedVideo] = useState(videoUrl)

  const onSubmit = values => {
    onSave({
      image: extractImageSrc(values.image),
      videoUrl: values.videoUrl
    })
    onDismiss()
  }

  return (
    <Modal isOpen onDismiss={onDismiss} title="Add media">
      {({ onDismiss }) => (
        <Form
          onSubmit={onSubmit}
          initialValues={{ videoUrl: selectedVideo, image: uploadedImage }}
        >
          {({ handleSubmit, values }) => (
            <ModalWrap>
              <Tabs size="xxl">
                <Tabs.Tab label="Image">
                  <Field
                    name="image"
                    component={ImageUpload}
                    uploadedImage={uploadedImage}
                    onSetImage={setUploadedImage}
                  />
                </Tabs.Tab>
                <Tabs.Tab label="Video">
                  <Spacing size="xl">
                    <Field
                      name="videoUrl"
                      component={Input}
                      label="Youtube video link"
                      onBlur={setSelectedVideo(values.videoUrl)}
                      block
                    />
                    <VideoPlayer videoUrl={selectedVideo} />
                  </Spacing>
                </Tabs.Tab>
              </Tabs>
              <Modal.Footer>
                <Spacing direction="row" justify="flex-end" align="center">
                  <Button outline onClick={onDismiss}>
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit}>Save</Button>
                </Spacing>
              </Modal.Footer>
            </ModalWrap>
          )}
        </Form>
      )}
    </Modal>
  )
}
