import React from 'react'
import { Text, Spacing, Box, GradientWrapper } from '../../../components/ui'

export const SummaryCard = ({ title, value }) => {
  return (
    <Box as={GradientWrapper} size="l" gradient="gradient1">
      <Spacing size="m">
        <Text h6 color="white">
          {title}
        </Text>
        <Text h3 color="white">
          {value}
        </Text>
      </Spacing>
    </Box>
  )
}

export const SummaryCardSmall = ({ title, value }) => {
  return (
    <Box as={GradientWrapper} size="l" gradient="gradient1">
      <Spacing size="s">
        <Text t3 color="white">
          {title}
        </Text>
        <Text h3 color="white">
          {value}
        </Text>
      </Spacing>
    </Box>
  )
}
