import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { Spacing, ToggleChevron } from '../'

const Wrap = styled.div`
  padding: ${({ theme }) => theme.padding.xl};
  border: 1px solid ${({ theme }) => theme.color.greyL2};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  ${({ noShadow, theme }) =>
    !noShadow && `box-shadow: ${theme.boxShadow.large};`}

  display: flex;
  flex-direction: column;

  transition: all ${({ theme }) => theme.animationSpeed.slow};
  background-color: ${({ theme }) => theme.color.white};
`

const PointerWrapper = styled.div`
  cursor: pointer;
`

export const CollapsableBox = ({
  noShadow,
  expanded,
  renderHeader,
  renderHeaderAction,
  children
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    setIsExpanded(expanded)
  }, [])

  const onExpand = () => {
    setIsExpanded(true)
  }

  const toggleExpanded = () => setIsExpanded(!isExpanded)

  return (
    <Wrap onClick={!isExpanded ? onExpand : () => {}} noShadow={noShadow}>
      <Spacing size="l">
        <Spacing direction="row" justify="space-between" align="center">
          {renderHeader()}
          <Spacing direction="row" size="xl" align="center">
            {!isExpanded && (
              <PointerWrapper>{renderHeaderAction()}</PointerWrapper>
            )}
            <ToggleChevron toggled={isExpanded} onToggle={toggleExpanded} />
          </Spacing>
        </Spacing>
        {isExpanded &&
          children &&
          children({
            expanded
          })}
      </Spacing>
    </Wrap>
  )
}

CollapsableBox.propTypes = {
  expanded: PropTypes.bool,
  noShadow: PropTypes.bool,
  renderHeader: PropTypes.func.isRequired,
  renderHeaderAction: PropTypes.func
}

CollapsableBox.defaultProps = {
  noShadow: false,
  expanded: true,
  renderHeaderAction: () => {}
}
