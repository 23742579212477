import React, { PureComponent } from 'react'
import AvatarEditor from 'react-avatar-editor'

import styled from 'styled-components/macro'

import { Button, Box, Spacing, Text, RangeSlider } from '..'

const domainStart = 0
const domainEnd = 100
const cropScaleStart = 1
const cropScaleEnd = 1.5

const InputUploadImage = styled.input({
  visibility: 'hidden',
  height: 0
})

const UploadWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.padding.m};
`

const WrappedText = styled(Text)({
  wordBreak: 'break-all'
})

const UploadedPhotoWrapper = styled.div`
  margin-bottom: ${({ hasImageUploaded }) =>
    hasImageUploaded ? `40px` : `0px`};
`

export class UploadPhoto extends PureComponent {
  constructor(props) {
    super(props)

    this.uploadFiles = React.createRef()
    this.avatarEditor = React.createRef()

    this.state = {
      imageSrc: '',
      imageName: '',
      zoom: (cropScaleEnd + cropScaleStart) / 2
    }
  }

  clickUploadImages = () => this.uploadFiles.current.click()

  readUploadedImage = event => {
    event.preventDefault()

    const image = event.target.files[0]
    const reader = new FileReader()

    if (!image.type.startsWith('image/')) return false

    reader.onload = e => {
      this.setState({ imageSrc: e.target.result, imageName: image.name })
    }

    reader.readAsDataURL(image)
  }

  onAvatarLoadSuccess = ({ resource: { src } }) => {
    this._updateCropArea(src)
  }

  onAvatarPositionChange = () => {
    this._updateCropArea(this.props.input.value.src)
  }

  onZoomChange = value => {
    const zoom =
      (value / domainEnd) * (cropScaleEnd - cropScaleStart) + cropScaleStart

    this.setState({ zoom }, () => {
      this._updateCropArea(this.state.imageSrc)
    })
  }

  _updateCropArea = src => {
    const { onChange } = this.props.input
    const rect = this.avatarEditor.current.getCroppingRect()

    onChange({
      ...rect,
      src
    })
  }

  _renderAfterUpload = ({ size = 300 } = {}) => (
    <UploadWrapper>
      <Spacing>
        <Spacing align="center">
          <AvatarEditor
            ref={this.avatarEditor}
            image={this.state.imageSrc}
            width={size}
            height={size}
            border={3}
            color={[220, 220, 220, 0.9]} // RGBA, main
            scale={this.state.zoom}
            rotate={0}
            onLoadSuccess={this.onAvatarLoadSuccess}
            onPositionChange={this.onAvatarPositionChange}
          />
        </Spacing>
        <RangeSlider
          meta={{}}
          input={{
            onChange: this.onZoomChange,
            value: (domainEnd - domainStart) / 2
          }}
          domain={[domainStart, domainEnd]}
          hideValue
        />
      </Spacing>
    </UploadWrapper>
  )

  render() {
    const { photoFor = 'Company' } = this.props

    if (photoFor === 'Company')
      return (
        <React.Fragment>
          <Box backgroundColor="purpleL3" size="s">
            <Spacing direction="row" align="center">
              <Button outline onClick={this.clickUploadImages}>
                Add image
              </Button>
              <WrappedText t3>{this.imageName}</WrappedText>
            </Spacing>
          </Box>

          <InputUploadImage
            type="file"
            ref={this.uploadFiles}
            accept="image/*"
            onChange={event => {
              this.readUploadedImage(event)
            }}
          />
          {!!this.state.imageSrc && this._renderAfterUpload()}
        </React.Fragment>
      )

    return (
      <Spacing direction="row" align="flex-end" size="l">
        {!!this.state.imageSrc && this._renderAfterUpload({ size: 150 })}
        <UploadedPhotoWrapper hasImageUploaded={!!this.state.imageSrc}>
          <Spacing size="l">
            <div style={{ width: 300 }}>
              <Text t4 left>
                You can add an image along with headline and link. Aspect ration
                is 1:1, min size 200px, max 500px.
              </Text>
            </div>
            <Spacing direction="row" align="center">
              <Button outline onClick={this.clickUploadImages}>
                Add image
              </Button>
              <WrappedText t3>{this.imageName}</WrappedText>
            </Spacing>
          </Spacing>
          <InputUploadImage
            type="file"
            ref={this.uploadFiles}
            accept="image/*"
            onChange={event => {
              this.readUploadedImage(event)
            }}
          />
        </UploadedPhotoWrapper>
      </Spacing>
    )
  }

  get imageName() {
    if (this.props.photoFor === 'Ad') return null
    return this.state.imageName || 'No image selected'
  }
}
