import React from 'react'
import styled from 'styled-components/macro'
import { equals } from 'ramda'

import { Spacing, Graphic } from '../../ui'

const ColorCircle = styled.div`
  background-color: ${({ color }) => color};
  border: 1px solid rgba(73, 73, 73, 0.1);
  border-radius: 50px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DEFAULT_AD_COLORS = [
  '#FFFFFF',
  '#D9798B',
  '#9279D9',
  '#799AD9',
  '#6180EC',
  '#59CEA4'
]

export const StudyColorPicker = ({ input }) => {
  const isSelectedColor = equals(input.value)
  return (
    <Spacing direction="row" align="center" size="s">
      {DEFAULT_AD_COLORS.map(color => (
        <ColorCircle
          key={color}
          color={color}
          onClick={() => input.onChange(color)}
        >
          {isSelectedColor(color) && (
            <Graphic
              name="Tick"
              size={10}
              color={color === '#FFFFFF' ? 'dark' : 'white'}
            />
          )}
        </ColorCircle>
      ))}
    </Spacing>
  )
}
