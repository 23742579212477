import React, { useState } from 'react'
import { withMutation } from '../../../wrappers'
import { Button } from '../../../ui'
import { DropdownItem } from './DropdownItem'

const ExportResultsButtonInner = ({
  id,
  exportOpportunityResults,
  dropdownItem
}) => {
  const [downloading, setDownloading] = useState(false)

  const text = 'Export Results CSV'
  const clickHandler = async () => {
    if (downloading) {
      return
    }

    setDownloading(true)
    await exportOpportunityResults({
      variables: { id }
    })

    setDownloading(false)

    alert(
      'Export is being generated, expect results soon on Slack channel #exports'
    )
  }

  if (dropdownItem) {
    return <DropdownItem text={text} onClick={clickHandler} />
  }

  return (
    <Button onClick={clickHandler} outline disabled={downloading}>
      {text}
    </Button>
  )
}

export const ExportResultsButton = withMutation('exportOpportunityResults', {
  showErrorNotifications: true
})(ExportResultsButtonInner)
