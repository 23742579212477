import React, { forwardRef } from 'react'
import posed from 'react-pose'

import { Spacing, Text } from '../ui'

const Error = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    height: 'auto'
  },
  preEnter: {
    y: '-100%',
    opacity: 0,
    height: 0
  }
})

export const withFieldError = () => (Component, ref) =>
  forwardRef((props, ref) => (
    <Spacing>
      <Component {...props} ref={ref} isErrored={isFieldErrored(props)} />
      {isFieldErrored(props) && (
        <Error initialPose="preEnter" pose="enter">
          <Text t5 color="red">
            {props.meta.error || props.meta.submitError || props.inlineError}
          </Text>
        </Error>
      )}
    </Spacing>
  ))

const isFieldErrored = ({ meta, inlineError }) =>
  !meta.submitting &&
  ((meta.touched && meta.error) ||
    inlineError ||
    (meta.submitError && !meta.dirtySinceLastSubmit))
