import React from 'react'

import {
  TextArea,
  InlineField,
  Spacing,
  Button,
  Text,
  Box
} from '../../../../../components/ui'
import { withMutation } from '../../../../../components/wrappers'
import { useState } from 'react'
import { isNil } from 'ramda'

export const RequestedQueryDescriptionInner = ({
  opportunity,
  updateOpportunity
}) => {
  const [textAreaVisible, setTextAreaVisible] = useState(false)

  const QueryDescription = () => (
    <InlineField
      initialValue={opportunity.requestedQueryDescription}
      component={TextArea}
      onUpdate={requestedQueryDescription =>
        updateOpportunity({
          variables: {
            id: opportunity.id,
            requestedQueryDescription
          }
        })
      }
      label="Describe your business and enter the query"
      subscription={{ active: true }}
      updateConditions={({ active }) => !active}
    />
  )
  const showQueryDescription =
    textAreaVisible || !isNil(opportunity.requestedQueryDescription)

  return (
    <Spacing size="l">
      <Box>
        <Text t2>
          If you'd like to extract data for your research study, please describe
          your business and the query you'd like us to run for review. We'll
          follow up with you shortly if we have questions or to instruct you on
          how to properly use the data extraction tool.
        </Text>
      </Box>
      {showQueryDescription ? (
        <QueryDescription />
      ) : (
        <Button onClick={() => setTextAreaVisible(!textAreaVisible)}>
          Request access to feature
        </Button>
      )}
    </Spacing>
  )
}
export const RequestedQueryDescription = withMutation('updateOpportunity')(
  RequestedQueryDescriptionInner
)
