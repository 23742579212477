import React from 'react'
import styled from 'styled-components/macro'
import { last } from 'ramda'
import VisibilitySensor from 'react-visibility-sensor'

import { Graphic } from '../'

const SpinnerWrap = styled.div`
  text-align: center;
`

export const LoadMore = ({
  name,
  data: {
    edges,
    pageInfo: { hasNextPage }
  },
  loading,
  fetchMore
}) => {
  const onVisibilityChange = isVisible =>
    isVisible &&
    !loading &&
    fetchMore({
      variables: { after: last(edges).cursor },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return {
          [name]: {
            ...fetchMoreResult[name],
            edges: prev[name].edges.concat(fetchMoreResult[name].edges)
          }
        }
      }
    })

  if (!hasNextPage) return null
  return (
    <VisibilitySensor onChange={onVisibilityChange}>
      <SpinnerWrap>
        <Graphic name="Spinner" size="50" themeColor="main" />
      </SpinnerWrap>
    </VisibilitySensor>
  )
}
