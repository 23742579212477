import React from 'react'

import { Spacing } from '../../../components/ui'
import { useSectionExpansion } from '../../../hooks'

import { TagCategory } from './TagCategory'

export const TagFilters = ({ categories, renderFilterComponent }) => {
  const {
    sectionsExpanded,
    expandSection,
    collapseSection
  } = useSectionExpansion(categories.map(({ uniqueId }) => [uniqueId]))

  return (
    <Spacing>
      {categories.map(({ label, uniqueId, children }) => (
        <TagCategory
          key={uniqueId}
          uniqueId={uniqueId}
          label={label}
          expanded={sectionsExpanded[uniqueId]}
          questions={children}
          onToggle={() =>
            sectionsExpanded[uniqueId]
              ? collapseSection(uniqueId)()
              : expandSection(uniqueId)()
          }
        >
          {renderFilterComponent({ questions: children, uniqueId })}
        </TagCategory>
      ))}
    </Spacing>
  )
}
