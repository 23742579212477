import React from 'react'
import { withRouter } from 'react-router-dom'
import { equals } from 'ramda'
import { Text, Box, Spacing } from '../../../../components/ui'
import { StudyStats, StudyStateTag } from '../../../../components/blocks'

const StudyCardMiniInner = ({ opportunity, history }) => {
  const { id, state, name } = opportunity
  const isCompleted = equals('completed', state)

  return (
    <Box link size="l" onClick={() => history.push(`/studies/summary/${id}`)}>
      <Spacing size="l">
        <Spacing direction="row" align="center">
          <Text h3>{name}</Text>
          {!isCompleted && <StudyStateTag state={state} />}
        </Spacing>
        <StudyStats
          mini
          opportunity={opportunity}
          size={isCompleted ? 'xs' : 's'}
        />
      </Spacing>
    </Box>
  )
}

export const StudyCardMini = withRouter(StudyCardMiniInner)
