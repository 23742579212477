import gql from 'graphql-tag'
import { UserGroupFragment } from '../fragments'

export const AllUserGroupsQuery = gql`
  query AllUserGroups {
    allUserGroups {
      edges {
        node {
          id
          ...UserGroup
        }
      }
    }
  }
  ${UserGroupFragment}
`
