import { pipe } from 'ramda'
import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components/macro'
import { Form, Field } from 'react-final-form'

import { Button, Text, Spacing, Input } from '../../ui'
import { withMutation } from '../../wrappers'
import { Back } from '../Back'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Name = styled.div`
  flex: 1;
  margin: 0 ${({ theme }) => theme.padding.xl};
`

const Controls = styled.div`
  align-self: flex-end;
`

const NameField = ({ handleSubmit }) => {
  const nameFieldEl = useRef(null)

  useEffect(() => {
    if (nameFieldEl.current) {
      nameFieldEl.current.select()
    }
  }, [])

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSubmit()
    }
  }

  return (
    <Field
      ref={nameFieldEl}
      name="internalName"
      autoFocus
      component={Input}
      block
      onKeyDown={handleKeyDown}
      placeholder="Study Name"
      clean
    />
  )
}

const StudyNameInner = ({
  opportunity: { id, internalName, category },
  updateOpportunity,
  editable = true,
  backRouteName
}) => {
  const [edit, setEdit] = useState(false)

  const onSubmit = ({ internalName }) => {
    return updateOpportunity({
      variables: {
        id,
        internalName: internalName || ''
      }
    }).then(({ errors }) => {
      if (errors) {
        return errors
      }

      setEdit(false)
    })
  }

  const toggle = () => setEdit(!edit)

  return (
    <Form
      initialValues={{ internalName }}
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <Container>
            <Back backRouteName={backRouteName} />
            <Name>
              {edit ? (
                <NameField handleSubmit={handleSubmit} />
              ) : (
                <Text h1>{internalName || `New ${category}`}</Text>
              )}
            </Name>

            {editable && (
              <Controls>
                {edit ? (
                  <Spacing direction="row">
                    <Button
                      onClick={() => {
                        toggle()
                        form.reset()
                      }}
                      outline
                    >
                      Cancel
                    </Button>
                    <Button type="submit">Save</Button>
                  </Spacing>
                ) : (
                  <Button graphic="Edit" onClick={toggle} outline>
                    Rename
                  </Button>
                )}
              </Controls>
            )}
          </Container>
        </form>
      )}
    />
  )
}

export const StudyName = pipe(withMutation('updateOpportunity'))(StudyNameInner)
