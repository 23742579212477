import React, { useEffect, useState } from 'react'
import { TableView } from './components'
import { withMutation } from '../../wrappers'
import {
  fetchWarehouseToken,
  getWarehouseInsights
} from '../../../util/warehouse'

const ROWS_PER_PAGE = 10
const defaultRequestOptions = {
  limit: ROWS_PER_PAGE,
  offset: 0
}

const DataViewerInner = ({ shortId, opportunity, getWarehouseToken, demo }) => {
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const [insightResults, setInsightResults] = useState({
    results: [],
    total: 0
  })

  const [requestOptions, setRequestOptions] = useState({
    ...defaultRequestOptions,
    opportunity: opportunity.id,
    searchTerm: shortId
  })

  useEffect(() => {
    ;(async () => {
      const token = await fetchWarehouseToken(getWarehouseToken, {
        opportunity: opportunity.id,
        ...(demo ? { demo: true } : {})
      })
      setToken(token)
    })()
  }, [])

  useEffect(() => {
    if (!token) return
    getInsights()
  }, [token, requestOptions])

  const getInsights = async () => {
    setLoading(true)

    try {
      const response = await getWarehouseInsights(token, requestOptions)
      setInsightResults({
        results: requestOptions.offset
          ? [...insightResults.results, ...response.data.results]
          : response.data.results,
        total: response.data.total
      })

      setLoading(false)
      setError(false)
    } catch (error) {
      setLoading(false)
      setError(true)
      throw error
    }
  }

  return (
    <TableView
      insightResults={insightResults.results}
      opportunity={opportunity}
      total={insightResults.total}
      setRequestOptions={setRequestOptions}
      requestOptions={requestOptions}
      getInsights={getInsights}
      rowsPerPage={ROWS_PER_PAGE}
      shortId={shortId}
      loading={loading}
      error={error}
    />
  )
}

export const DataViewer = withMutation('getWarehouseToken')(DataViewerInner)
