import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { NumberBox, Spacing } from '../../ui/'

const ImpressionStats = ({
  opportunity: { reach, study },
  direction,
  ...props
}) => (
  <Spacing direction="row" stretchChildren size="s">
    <NumberBox title="Impressions" gradient="gradient1" size="s" {...props}>
      {study ? study.stats.impressions : 0}
    </NumberBox>
    <NumberBox title="Details open" gradient="gradient1" size="s" {...props}>
      {study ? study.stats.detailsOpen : 0}
    </NumberBox>
    <NumberBox title="Responses" gradient="gradient1" size="s" {...props}>
      {reach.reached}
    </NumberBox>
  </Spacing>
)

const StudyStatsActive = ({
  mini,
  opportunity,
  opportunity: { reach, fees },
  direction,
  showImpressions,
  ...props
}) => {
  const spotsLeft = reach.maxPermitted - reach.reached
  return (
    <Spacing>
      <Spacing direction={direction} stretchChildren size="s">
        <NumberBox
          title="Money Left"
          gradient="gradient1"
          currency="USD"
          size="s"
          {...props}
        >
          {spotsLeft < 0 ? 0 : fees.cash * spotsLeft}
        </NumberBox>
        {!mini && (
          <NumberBox
            title="Money Spent"
            gradient="gradient1"
            currency="USD"
            size="s"
            {...props}
          >
            {fees.cash * reach.reached}
          </NumberBox>
        )}
        {!showImpressions && (
          <NumberBox title="Responses" gradient="gradient1" size="s" {...props}>
            {reach.reached}
          </NumberBox>
        )}
      </Spacing>
      {showImpressions && (
        <ImpressionStats opportunity={opportunity} {...props} />
      )}
    </Spacing>
  )
}

const DraftStatsPlaceholder = styled.div`
  display: flex;
  flex: 1;
  background: ${({ theme }) => theme.color.purpleL3};
  border-radius: 15px;
  height: 100px;
`

const StudyStatsDraft = ({
  opportunity: { reach, fees },
  direction,
  ...props
}) => {
  return (
    <Spacing direction={direction} size="s" stretched stretchChildren>
      <DraftStatsPlaceholder />
      <DraftStatsPlaceholder />
      <DraftStatsPlaceholder />
    </Spacing>
  )
}

const StudyStatsCompletedSmall = ({
  opportunity: { reach, fees },
  direction,
  ...props
}) => (
  <Spacing direction={direction} stretchChildren size="s">
    <NumberBox title="Spent" backgroundColor="main" currency="USD" {...props}>
      {fees.firstTime * reach.reached}
    </NumberBox>
    <NumberBox title="Reached" backgroundColor="purple" {...props}>
      {reach.reached}
    </NumberBox>
    {/* <NumberBox title="Records" backgroundColor="secondary" {...props}>

    </NumberBox> */}
  </Spacing>
)

const StudyStatsCompletedNormal = ({
  opportunity,
  opportunity: { reach, fees },
  direction,
  showImpressions,
  ...props
}) => (
  <Spacing>
    <Spacing direction={direction} stretchChildren size="s">
      <NumberBox
        title="Money Spent"
        backgroundColor="main"
        currency="USD"
        {...props}
      >
        {fees.firstTime * reach.reached}
      </NumberBox>
      {!showImpressions && (
        <NumberBox title="People Responded" backgroundColor="purple" {...props}>
          {reach.reached}
        </NumberBox>
      )}
    </Spacing>

    {showImpressions && (
      <ImpressionStats opportunity={opportunity} {...props} />
    )}
  </Spacing>
)

const StudyStatsCompleted = props =>
  props.size === 'xs' ? (
    <StudyStatsCompletedSmall {...props} />
  ) : (
    <StudyStatsCompletedNormal {...props} />
  )

export const StudyStats = props => {
  const {
    opportunity: { state }
  } = props
  switch (state) {
    case 'draft':
      return <StudyStatsDraft {...props} />
    case 'completed':
      return <StudyStatsCompleted {...props} />
    default:
      return <StudyStatsActive {...props} />
  }
}

const propOptions = {
  size: ['m', 's', 'xs']
}

StudyStats.defaultProps = {
  size: 'm',
  direction: 'row'
}

NumberBox.propTypes = {
  size: PropTypes.oneOf(propOptions.size)
}
