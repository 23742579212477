import React from 'react'
import styled from 'styled-components/macro'

import { Text, Spacing, SpacingItem } from '../../../../ui'
import { TabledDataSummary } from '../'

const RowWrap = styled.div`
  flex: 1 0 50%;
  max-width: 50%;
  margin-bottom: ${({ theme }) => theme.padding.xs};
`

export const SurveySummary = ({ survey, rowWrap = false }) =>
  survey ? (
    <Spacing size="s">
      <Text h6>Survey</Text>
      <>
        {survey.typeformId && (
          <SpacingItem as={rowWrap && RowWrap}>
            <TabledDataSummary
              label="- Survey ID: "
              value={survey.typeformId}
            ></TabledDataSummary>
          </SpacingItem>
        )}
        {survey.totalQuestions > 0 && (
          <SpacingItem as={rowWrap && RowWrap}>
            <TabledDataSummary
              label="- Number of questions:"
              value={survey.totalQuestions}
            ></TabledDataSummary>
          </SpacingItem>
        )}
      </>
    </Spacing>
  ) : null
