import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { slice, length } from 'ramda'

import { Spacing, Graphic, Text } from '../'

const Other = styled.div`
  background-color: ${({ theme }) => theme.color.purpleL2};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  align-items: center;
  padding: ${({ theme }) => `${theme.padding.xs} ${theme.padding.xs}`};
`

export const SourceLogos = ({
  sources,
  direction = 'row',
  size = 26,
  short = false
}) => {
  const OPPORTUNITES_SLICE = 2

  const sourcesToRender =
    short && length(sources) > 3
      ? slice(0, OPPORTUNITES_SLICE, sources)
      : sources

  const maybeOtherNotRendered = length(sources) - OPPORTUNITES_SLICE

  return (
    <Spacing size="xs" direction={direction}>
      <Spacing size="xs" direction={direction}>
        {sourcesToRender.map(({ name }) => (
          <Graphic key={name} name={['Source' + name]} size={size} />
        ))}
      </Spacing>
      {short && maybeOtherNotRendered > 1 && (
        <Other>
          <Text t3 color="white">
            +{maybeOtherNotRendered}
          </Text>
        </Other>
      )}
    </Spacing>
  )
}

export const propOptions = {
  directions: ['row', 'row-reverse', 'column', 'column-reverse']
}

SourceLogos.propTypes = {
  sources: PropTypes.array,
  direction: PropTypes.oneOf(propOptions.directions),
  size: PropTypes.number,
  short: PropTypes.bool
}
