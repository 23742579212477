import React from 'react'
import styled from 'styled-components/macro'
import qs from 'qs'
import { Text, Spacing, Graphic, Box } from '../../components/ui'
import { CardForm } from '../Onboarding/steps/step_card'

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`

const TextWrap = styled.div`
  width: 50%;
  height: 100%;
  padding: 100px;
`

const WrapGradientBg = styled.div`
  height: 100%;
  width: 50%;
  background: linear-gradient(218.83deg, #c6c0fa 0%, #fafaff 99.05%);
  display: flex;
  align-items: center;
  justify-content: center;
`

const CardBoxWrap = styled.div`
  width: 410px;
`

export const ConnectCardScreen = ({
  history: { goBack, replace },
  location: { search }
}) => {
  const onContinue = () => {
    const { redirect } = qs.parse(search, { ignoreQueryPrefix: true })
    replace(redirect || `/studies/create`)
  }

  return (
    <Wrap>
      <TextWrap>
        <Spacing size="xxl">
          <Spacing size="xl">
            <Text h1>Please connect your credit card to continue</Text>
            <Text t1>
              UBDI is devoted to privacy preserving tools and business practices
              that help users receive fair compensation for the data they
              generate while simultaneously providing businesses with rich and
              accurate insights and opportunities across research and
              advertising verticals.
            </Text>
            <Text t1>
              We require your credit card information because it is our
              responsibility to protect our users against bots, spams and bad
              actors. Here’s what you neet to know about our payments:
            </Text>
          </Spacing>
          <Spacing direction="row" size="xl" align="center">
            <Graphic name="OneFinger" size={70} />
            <Text t1>
              <Text t1 bold inline>
                Pay as you go!
              </Text>{' '}
              You’ll only be charged for the studies and ads you build an
              launch. Set a budget and pause launched studies and ads at any
              time
            </Text>
          </Spacing>
          <Spacing direction="row" size="xl" align="center">
            <Graphic name="Eye" size={70} />
            <Text t1>
              <Text t1 bold inline>
                No hidden fees!
              </Text>{' '}
              You’re in control of what tools you use, allowing you to decide
              how much you’re spending
            </Text>
          </Spacing>
        </Spacing>
      </TextWrap>
      <WrapGradientBg>
        <CardBoxWrap>
          <Spacing size="xxxl">
            <Box size="xl">
              <CardForm onCollapse={() => goBack()} onNextStep={onContinue} />
            </Box>
            <Spacing align="center" size="l">
              <Text t3 center>
                We use Braintree owned by Paypal for our payment processing. You
                can read their Terms of Services here.
              </Text>
              <Graphic name="Paypal" height={22} png />
            </Spacing>
          </Spacing>
        </CardBoxWrap>
      </WrapGradientBg>
    </Wrap>
  )
}
